import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  File: any;
  Json: any;
  JsonArray: any;
};

export type AdHocService = {
  __typename?: 'AdHocService';
  appointments: Array<Appointment>;
  bookingEndpoint?: Maybe<BookingEndpoint>;
  business: Business;
  createdAt?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  maxAppointments: Scalars['Int'];
  service?: Maybe<Service>;
  timeIncrement: Scalars['Int'];
  times: Array<Scalars['DateTime']>;
  timeslots: Scalars['JsonArray'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AddressInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
};

export type Analytics = {
  __typename?: 'Analytics';
  appointments: Array<AnalyticsAppointmentEntry>;
  events: Array<AnalyticsEventEntry>;
  sms: Array<AnalyticsSmsEntry>;
};


export type AnalyticsAppointmentsArgs = {
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  staffId?: InputMaybe<Scalars['ID']>;
};


export type AnalyticsEventsArgs = {
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
};


export type AnalyticsSmsArgs = {
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
};

export type AnalyticsAppointmentEntry = {
  __typename?: 'AnalyticsAppointmentEntry';
  cancelled: Scalars['Int'];
  created: Scalars['Int'];
  date: Scalars['DateTime'];
};

export type AnalyticsEventEntry = {
  __typename?: 'AnalyticsEventEntry';
  all: Scalars['Int'];
  appointmentCreated: Scalars['Int'];
  appointmentDeleted: Scalars['Int'];
  appointmentUpdated: Scalars['Int'];
  businessUpdated: Scalars['Int'];
  date: Scalars['DateTime'];
  formCreated: Scalars['Int'];
  formDeleted: Scalars['Int'];
  formUpdated: Scalars['Int'];
  patientCreated: Scalars['Int'];
  patientDeleted: Scalars['Int'];
  patientUpdated: Scalars['Int'];
  serviceCreated: Scalars['Int'];
  serviceDeleted: Scalars['Int'];
  serviceUpdated: Scalars['Int'];
  staffCreated: Scalars['Int'];
  staffDeleted: Scalars['Int'];
  staffUpdated: Scalars['Int'];
};

export type AnalyticsSmsEntry = {
  __typename?: 'AnalyticsSMSEntry';
  credits: Scalars['Int'];
  date: Scalars['DateTime'];
  delivered: Scalars['Int'];
  sent: Scalars['Int'];
};

export type ApiKey = Node & {
  __typename?: 'ApiKey';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  invalidatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  value?: Maybe<Scalars['String']>;
};

export type Appointment = {
  __typename?: 'Appointment';
  adHocService?: Maybe<AdHocService>;
  additionalStaff?: Maybe<Array<Staff>>;
  answers?: Maybe<Scalars['JsonArray']>;
  approved?: Maybe<Scalars['Boolean']>;
  business: Business;
  cancelled?: Maybe<Scalars['Boolean']>;
  clientTimezone?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currency?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  discount?: Maybe<Scalars['Float']>;
  duration: Scalars['Int'];
  events?: Maybe<Array<Event>>;
  groupBookingId?: Maybe<Scalars['String']>;
  guests?: Maybe<Array<Maybe<Guest>>>;
  hangoutLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoices?: Maybe<Array<Invoice>>;
  location?: Maybe<Location>;
  locationType?: Maybe<Scalars['String']>;
  locationValue?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  originalDate?: Maybe<Scalars['DateTime']>;
  patient: Patient;
  patientVerified?: Maybe<Scalars['Boolean']>;
  paymentId?: Maybe<Scalars['String']>;
  paymentProvider?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  recurrence?: Maybe<Scalars['Json']>;
  recurringAppointmentId?: Maybe<Scalars['ID']>;
  requestUrl?: Maybe<Scalars['String']>;
  reschedulingForm: Form;
  rrule?: Maybe<Scalars['String']>;
  service: Service;
  staff: Staff;
  token?: Maybe<Scalars['String']>;
  transactionUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type AppointmentReschedulingFormArgs = {
  token: Scalars['String'];
};

export const AppointmentOrderByInput = {
  AnswersAsc: 'answers_ASC',
  AnswersDesc: 'answers_DESC',
  ApprovedAsc: 'approved_ASC',
  ApprovedDesc: 'approved_DESC',
  CancelledAsc: 'cancelled_ASC',
  CancelledDesc: 'cancelled_DESC',
  ClientTimezoneAsc: 'clientTimezone_ASC',
  ClientTimezoneDesc: 'clientTimezone_DESC',
  CreatedAtAsc: 'createdAt_ASC',
  CreatedAtDesc: 'createdAt_DESC',
  CurrencyAsc: 'currency_ASC',
  CurrencyDesc: 'currency_DESC',
  DateAsc: 'date_ASC',
  DateDesc: 'date_DESC',
  DurationAsc: 'duration_ASC',
  DurationDesc: 'duration_DESC',
  GroupBookingIdAsc: 'groupBookingId_ASC',
  GroupBookingIdDesc: 'groupBookingId_DESC',
  HangoutLinkAsc: 'hangoutLink_ASC',
  HangoutLinkDesc: 'hangoutLink_DESC',
  IdAsc: 'id_ASC',
  IdDesc: 'id_DESC',
  IsVideoCallAsc: 'isVideoCall_ASC',
  IsVideoCallDesc: 'isVideoCall_DESC',
  LocationTypeAsc: 'locationType_ASC',
  LocationTypeDesc: 'locationType_DESC',
  LocationValueAsc: 'locationValue_ASC',
  LocationValueDesc: 'locationValue_DESC',
  OriginalDateAsc: 'originalDate_ASC',
  OriginalDateDesc: 'originalDate_DESC',
  PatientEmailRemindedAsc: 'patientEmailReminded_ASC',
  PatientEmailRemindedDesc: 'patientEmailReminded_DESC',
  PatientIdDuplicateAsc: 'patientIdDuplicate_ASC',
  PatientIdDuplicateDesc: 'patientIdDuplicate_DESC',
  PatientSmsRemindedAsc: 'patientSMSReminded_ASC',
  PatientSmsRemindedDesc: 'patientSMSReminded_DESC',
  PaymentIdAsc: 'paymentId_ASC',
  PaymentIdDesc: 'paymentId_DESC',
  PaymentProviderAsc: 'paymentProvider_ASC',
  PaymentProviderDesc: 'paymentProvider_DESC',
  PriceAsc: 'price_ASC',
  PriceDesc: 'price_DESC',
  RecurrenceAsc: 'recurrence_ASC',
  RecurrenceDesc: 'recurrence_DESC',
  RecurringAppointmentIdAsc: 'recurringAppointmentId_ASC',
  RecurringAppointmentIdDesc: 'recurringAppointmentId_DESC',
  RruleAsc: 'rrule_ASC',
  RruleDesc: 'rrule_DESC',
  StaffEmailRemindedAsc: 'staffEmailReminded_ASC',
  StaffEmailRemindedDesc: 'staffEmailReminded_DESC',
  StaffIdDuplicateAsc: 'staffIdDuplicate_ASC',
  StaffIdDuplicateDesc: 'staffIdDuplicate_DESC',
  StaffSmsRemindedAsc: 'staffSMSReminded_ASC',
  StaffSmsRemindedDesc: 'staffSMSReminded_DESC',
  TokenAsc: 'token_ASC',
  TokenDesc: 'token_DESC',
  TransactionUrlAsc: 'transactionUrl_ASC',
  TransactionUrlDesc: 'transactionUrl_DESC',
  UpdatedAtAsc: 'updatedAt_ASC',
  UpdatedAtDesc: 'updatedAt_DESC'
} as const;

export type AppointmentOrderByInput = typeof AppointmentOrderByInput[keyof typeof AppointmentOrderByInput];
export type AuthPayload = {
  __typename?: 'AuthPayload';
  abTestVariant?: Maybe<Scalars['String']>;
  forcedSessionId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  redirectUrl?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  user: AuthUserPayload;
  userIsNew: Scalars['Boolean'];
};

export type AuthUserPayload = {
  __typename?: 'AuthUserPayload';
  id: Scalars['ID'];
};

export const AvailabilityBlocker = {
  Appointments: 'APPOINTMENTS',
  ExternalEvents: 'EXTERNAL_EVENTS',
  SameServiceAppointments: 'SAME_SERVICE_APPOINTMENTS',
  TimeBlockers: 'TIME_BLOCKERS'
} as const;

export type AvailabilityBlocker = typeof AvailabilityBlocker[keyof typeof AvailabilityBlocker];
export type AvailableTimes = {
  __typename?: 'AvailableTimes';
  times?: Maybe<Scalars['Json']>;
};

export const BasicAuthCalendarConnectionProvider = {
  Icloud: 'ICLOUD'
} as const;

export type BasicAuthCalendarConnectionProvider = typeof BasicAuthCalendarConnectionProvider[keyof typeof BasicAuthCalendarConnectionProvider];
export const BiologicalSex = {
  Female: 'FEMALE',
  Male: 'MALE',
  Other: 'OTHER'
} as const;

export type BiologicalSex = typeof BiologicalSex[keyof typeof BiologicalSex];
export type BookAppointment = {
  __typename?: 'BookAppointment';
  addToGoogleUrl?: Maybe<Scalars['String']>;
  addToOutlookUrl?: Maybe<Scalars['String']>;
  answers?: Maybe<Scalars['JsonArray']>;
  approved?: Maybe<Scalars['Boolean']>;
  business?: Maybe<Scalars['Json']>;
  clientTimezone?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  hangoutLink?: Maybe<Scalars['String']>;
  icsFileContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  locationType?: Maybe<Scalars['String']>;
  locationValue?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  patient?: Maybe<Scalars['Json']>;
  paymentId?: Maybe<Scalars['String']>;
  paymentProvider?: Maybe<Scalars['String']>;
  pendingPayment?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  rrule?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['Json']>;
  staff?: Maybe<Scalars['Json']>;
  token?: Maybe<Scalars['String']>;
  transactionUrl?: Maybe<Scalars['String']>;
};

export type BookingEndpoint = Node & {
  __typename?: 'BookingEndpoint';
  adHocService?: Maybe<AdHocService>;
  alias?: Maybe<Scalars['String']>;
  business?: Maybe<Business>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  resourceType: BookingEndpointResourceType;
  service?: Maybe<Service>;
  staff?: Maybe<Staff>;
  updatedAt: Scalars['DateTime'];
  upvioUrl: Scalars['String'];
};

export type BookingEndpointPayload = {
  __typename?: 'BookingEndpointPayload';
  hasAvailableTimes?: Maybe<Scalars['Boolean']>;
  resourceId?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
};

export const BookingEndpointResourceType = {
  Adhocservice: 'ADHOCSERVICE',
  Business: 'BUSINESS',
  Service: 'SERVICE',
  Staff: 'STAFF'
} as const;

export type BookingEndpointResourceType = typeof BookingEndpointResourceType[keyof typeof BookingEndpointResourceType];
export type BookingEndpointWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export const BookingEvent = {
  AppointmentCreated: 'APPOINTMENT_CREATED',
  AppointmentDeleted: 'APPOINTMENT_DELETED',
  AppointmentUpdated: 'APPOINTMENT_UPDATED',
  RecurringAppointmentCreated: 'RECURRING_APPOINTMENT_CREATED',
  RecurringAppointmentDeleted: 'RECURRING_APPOINTMENT_DELETED',
  RecurringAppointmentUpdated: 'RECURRING_APPOINTMENT_UPDATED'
} as const;

export type BookingEvent = typeof BookingEvent[keyof typeof BookingEvent];
export type BookingEventNotification = {
  __typename?: 'BookingEventNotification';
  appointment: Appointment;
  bookingEventNotificationTemplate: BookingEventNotificationTemplate;
  business: Business;
  createdAt: Scalars['DateTime'];
  event: BookingEvent;
  id: Scalars['ID'];
  recipient: MessageRecipient;
  sentAt?: Maybe<Scalars['DateTime']>;
  sentTo?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type BookingEventNotificationTemplate = {
  __typename?: 'BookingEventNotificationTemplate';
  bookingEventNotifications: Array<BookingEventNotification>;
  business: Business;
  createdAt: Scalars['DateTime'];
  defaultTemplate?: Maybe<Scalars['Json']>;
  deleted?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  event: BookingEvent;
  id: Scalars['ID'];
  messageTemplate?: Maybe<Scalars['Json']>;
  recipient: MessageRecipient;
  services: Array<Service>;
  type: MessageType;
  updatedAt: Scalars['DateTime'];
};

export type Business = {
  __typename?: 'Business';
  activeServices?: Maybe<Array<Service>>;
  alias: Scalars['String'];
  allowChoosingStaff?: Maybe<Scalars['Boolean']>;
  allowNoStaffPreference?: Maybe<Scalars['Boolean']>;
  allowStaffApprovals?: Maybe<Scalars['Boolean']>;
  allowTimeRequests?: Maybe<Scalars['Boolean']>;
  anyStaffCalendarSyncEnabled?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<Array<Appointment>>;
  appointmentsColumns?: Maybe<Array<Scalars['String']>>;
  attachICSFiles?: Maybe<Scalars['Boolean']>;
  autoGenerateBookingInvoices?: Maybe<Scalars['Boolean']>;
  availabilityLimitDays?: Maybe<Scalars['Int']>;
  availableFeatures?: Maybe<Scalars['Json']>;
  bookingEndpoints?: Maybe<Array<BookingEndpoint>>;
  bookingForm: Form;
  bookingFormLegacyConfig?: Maybe<Scalars['Json']>;
  bookingLocale?: Maybe<Scalars['String']>;
  bookingNotificationsReplyTo?: Maybe<NotificationsReplyToType>;
  bookingPageUrl?: Maybe<Scalars['String']>;
  bookingTemplates?: Maybe<Scalars['Json']>;
  bookingTemplatesHtml?: Maybe<Scalars['Json']>;
  bookingsRequireApprovalUntilPaid?: Maybe<Scalars['Boolean']>;
  bufferAfterMinutes?: Maybe<Scalars['Int']>;
  bufferBeforeMinutes?: Maybe<Scalars['Int']>;
  calendarConnectionsCount?: Maybe<Scalars['Int']>;
  calendarSyncEnabled?: Maybe<Scalars['Boolean']>;
  calendarView?: Maybe<CalendarView>;
  canAcceptStripePayments?: Maybe<Scalars['Boolean']>;
  canGenerateHangoutLinks?: Maybe<Scalars['Boolean']>;
  canGenerateTeamsMeetings?: Maybe<Scalars['Boolean']>;
  canGenerateZoomMeetings?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currency?: Maybe<Scalars['String']>;
  dateFormat?: Maybe<Scalars['Json']>;
  defaultInvoiceDaysUntilDue?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  enabledStaffsCount?: Maybe<Scalars['Int']>;
  exportedEventsTemplate?: Maybe<Scalars['Json']>;
  features?: Maybe<Scalars['Json']>;
  forceServiceStep?: Maybe<Scalars['Boolean']>;
  googleTrackingId?: Maybe<Scalars['String']>;
  hidePatientTimezoneDropdwon?: Maybe<Scalars['Boolean']>;
  hidePrivacyButton?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  includeAnswersInExportedEvents?: Maybe<Scalars['Boolean']>;
  includeAnswersOnRedirect?: Maybe<Scalars['Boolean']>;
  invoicesTaxBehaviour?: Maybe<TaxBehaviour>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  locations?: Maybe<Array<Location>>;
  logo?: Maybe<Picture>;
  logoPosition?: Maybe<Scalars['String']>;
  marketingMeta?: Maybe<Scalars['Json']>;
  name: Scalars['String'];
  notificationSettings?: Maybe<Scalars['Json']>;
  notifications?: Maybe<Array<Notification>>;
  onboardingCompletedAt?: Maybe<Scalars['DateTime']>;
  ownerNotificationsCC?: Maybe<Scalars['String']>;
  ownerNotificationsRecipient?: Maybe<OwnerNotificationsRecipient>;
  patients?: Maybe<Array<Patient>>;
  patientsCount?: Maybe<Scalars['Int']>;
  physicalAddress?: Maybe<Scalars['String']>;
  postOnRedirect?: Maybe<Scalars['Boolean']>;
  previewMode?: Maybe<Scalars['Boolean']>;
  primaryDomain?: Maybe<Scalars['String']>;
  primaryDomainVerifiedAt?: Maybe<Scalars['DateTime']>;
  publicServices?: Maybe<Array<Service>>;
  redirectAfterBookingUrl?: Maybe<Scalars['String']>;
  remainingSMSCredits?: Maybe<Scalars['Int']>;
  remainingTrialDays?: Maybe<Scalars['Int']>;
  reminderPatientEmail?: Maybe<Scalars['Int']>;
  reminderPatientSMS?: Maybe<Scalars['Int']>;
  reminderStaffEmail?: Maybe<Scalars['Int']>;
  reminderStaffSMS?: Maybe<Scalars['Int']>;
  reminderTemplates?: Maybe<Array<ReminderTemplate>>;
  reschedulingForm: Form;
  sendSMSFromStaffNumber?: Maybe<Scalars['Boolean']>;
  serviceCategories?: Maybe<Array<ServiceCategory>>;
  services?: Maybe<Array<Service>>;
  servicesColumns?: Maybe<Array<Scalars['String']>>;
  servicesCount?: Maybe<Scalars['Int']>;
  servicesOrder?: Maybe<Array<Scalars['String']>>;
  servicesPageView?: Maybe<Scalars['JsonArray']>;
  showCalendarEventsNames?: Maybe<Scalars['Boolean']>;
  showDropdownFilter?: Maybe<Scalars['Boolean']>;
  staffColours?: Maybe<Scalars['Json']>;
  staffRoles: Array<StaffRole>;
  staffs?: Maybe<Array<Staff>>;
  staffsColumns?: Maybe<Array<Scalars['String']>>;
  staffsCount?: Maybe<Scalars['Int']>;
  stripeConnectAccount?: Maybe<StripeConnectAccount>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeData?: Maybe<Scalars['Json']>;
  stripeProducts: Array<StripeProduct>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
  teamsConnection?: Maybe<ThirdPartyConnection>;
  templates?: Maybe<Scalars['JsonArray']>;
  timeRequestButtonLabel?: Maybe<Scalars['String']>;
  timeRequestText?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  trackingPixelUrl?: Maybe<Scalars['String']>;
  unreadNotificationsCount?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  usage: Scalars['Json'];
  user?: Maybe<User>;
  /** @deprecated Use staffRole instead */
  userRole?: Maybe<StaffRole>;
  webhooks: Array<OutgoingWebhook>;
  website?: Maybe<Scalars['String']>;
  websiteContainingEmbedCode?: Maybe<Scalars['String']>;
  workingHours: Scalars['Json'];
  zoomConnection?: Maybe<ThirdPartyConnection>;
};


export type BusinessBookingEndpointsArgs = {
  where?: InputMaybe<BookingEndpointWhereInput>;
};


export type BusinessLocationsArgs = {
  where?: InputMaybe<LocationWhereInput>;
};


export type BusinessPatientsArgs = {
  where?: InputMaybe<PatientWhereInput>;
};


export type BusinessPublicServicesArgs = {
  includeServiceForAppointmentId?: InputMaybe<Scalars['ID']>;
};


export type BusinessReminderTemplatesArgs = {
  where?: InputMaybe<ReminderTemplateWhereInput>;
};


export type BusinessServicesPageViewArgs = {
  appointmentsDateEnd?: InputMaybe<Scalars['DateTime']>;
  appointmentsDateStart?: InputMaybe<Scalars['DateTime']>;
};


export type BusinessUsageArgs = {
  cached?: InputMaybe<Scalars['Boolean']>;
};

export type CalendarConnection = {
  __typename?: 'CalendarConnection';
  accountId?: Maybe<Scalars['String']>;
  allDayEventsTimezone?: Maybe<Scalars['String']>;
  business?: Maybe<Business>;
  calendarEvents: Array<CalendarEvent>;
  calendars?: Maybe<Scalars['JsonArray']>;
  connectedResourceType: CalendarConnectionResourceType;
  createdAt: Scalars['DateTime'];
  enabled?: Maybe<Scalars['Boolean']>;
  exportAttendees?: Maybe<Array<CalendarEventAttendee>>;
  exportToCalendarId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  importFromCalendarsIds: Array<Scalars['String']>;
  isAuthorized: Scalars['Boolean'];
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  logs: Array<SynchronizationLog>;
  oauthTokens: Array<OAuthToken>;
  provider: CalendarConnectionProvider;
  providerUser?: Maybe<Scalars['String']>;
  staff?: Maybe<Staff>;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export const CalendarConnectionProvider = {
  Google: 'GOOGLE',
  Icloud: 'ICLOUD',
  Microsoft: 'MICROSOFT'
} as const;

export type CalendarConnectionProvider = typeof CalendarConnectionProvider[keyof typeof CalendarConnectionProvider];
export const CalendarConnectionResourceType = {
  Business: 'BUSINESS',
  Staff: 'STAFF'
} as const;

export type CalendarConnectionResourceType = typeof CalendarConnectionResourceType[keyof typeof CalendarConnectionResourceType];
export type CalendarConnectionWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  startDate: Scalars['DateTime'];
  title: Scalars['String'];
};

export const CalendarEventAttendee = {
  AdditionalStaff: 'ADDITIONAL_STAFF',
  Guest: 'GUEST',
  Patient: 'PATIENT',
  Staff: 'STAFF'
} as const;

export type CalendarEventAttendee = typeof CalendarEventAttendee[keyof typeof CalendarEventAttendee];
export type CalendarEventWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export const CalendarView = {
  Month: 'MONTH',
  Week: 'WEEK'
} as const;

export type CalendarView = typeof CalendarView[keyof typeof CalendarView];
export type CalendarViewExternalEventPayload = {
  __typename?: 'CalendarViewExternalEventPayload';
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  staffId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  title?: Maybe<Scalars['String']>;
};

export type CancelAppointment = {
  __typename?: 'CancelAppointment';
  id?: Maybe<Scalars['String']>;
};

export type CheckoutSessionPayload = {
  __typename?: 'CheckoutSessionPayload';
  id: Scalars['String'];
};

export type Coupon = {
  __typename?: 'Coupon';
  amountOff?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  percentOff?: Maybe<Scalars['Float']>;
  redemptionsCount: Scalars['Int'];
  stripeCouponId?: Maybe<Scalars['String']>;
  stripePromotionCodeId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CreateFormSubmissionPayload = {
  __typename?: 'CreateFormSubmissionPayload';
  events: Array<FormSubmissionEvent>;
  id: Scalars['ID'];
  redirectDelay?: Maybe<Scalars['Int']>;
  redirectUrl?: Maybe<Scalars['String']>;
};

export type CreateGuestInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  phoneMobile?: InputMaybe<Scalars['String']>;
};

export type CustomField = {
  __typename?: 'CustomField';
  alias: Scalars['String'];
  allowedValues?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  fieldType: CustomFieldType;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export const CustomFieldType = {
  Boolean: 'BOOLEAN',
  Date: 'DATE',
  Number: 'NUMBER',
  Select: 'SELECT',
  Text: 'TEXT'
} as const;

export type CustomFieldType = typeof CustomFieldType[keyof typeof CustomFieldType];
export type CustomFieldValue = {
  __typename?: 'CustomFieldValue';
  createdAt: Scalars['DateTime'];
  fieldKey: Scalars['String'];
  fieldType: CustomFieldType;
  id: Scalars['ID'];
  patientId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type DataRequest = {
  __typename?: 'DataRequest';
  id: Scalars['ID'];
};

export type DefaultNotificationsTemplatesPayload = {
  __typename?: 'DefaultNotificationsTemplatesPayload';
  templates?: Maybe<Scalars['JsonArray']>;
};

export type DeleteResourcePayload = {
  __typename?: 'DeleteResourcePayload';
  id?: Maybe<Scalars['String']>;
};

export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  requestId: Scalars['String'];
};

export const DiabeticType = {
  None: 'None',
  Type1: 'Type1',
  Type2: 'Type2'
} as const;

export type DiabeticType = typeof DiabeticType[keyof typeof DiabeticType];
export type Event = {
  __typename?: 'Event';
  createdAt: Scalars['DateTime'];
  data: Scalars['Json'];
  id: Scalars['ID'];
  name: Scalars['String'];
  outgoingWebhookDeliveries: Array<OutgoingWebhookDelivery>;
};

export const ExportableResource = {
  Appointments: 'APPOINTMENTS',
  Patients: 'PATIENTS'
} as const;

export type ExportableResource = typeof ExportableResource[keyof typeof ExportableResource];
export const FaceVitalsBaseRiskCategory = {
  High: 'High',
  Low: 'Low',
  LowMedium: 'LowMedium',
  Medium: 'Medium',
  OutOfBounds: 'OutOfBounds',
  Unknown: 'Unknown',
  VeryHigh: 'VeryHigh'
} as const;

export type FaceVitalsBaseRiskCategory = typeof FaceVitalsBaseRiskCategory[keyof typeof FaceVitalsBaseRiskCategory];
export const FaceVitalsBloodPressureRiskCategory = {
  Elevated: 'Elevated',
  HypertensionStage1: 'HypertensionStage1',
  HypertensionStage2: 'HypertensionStage2',
  HypertensiveCrisis: 'HypertensiveCrisis',
  Hypotensive: 'Hypotensive',
  Normal: 'Normal',
  OutOfBounds: 'OutOfBounds',
  Unknown: 'Unknown'
} as const;

export type FaceVitalsBloodPressureRiskCategory = typeof FaceVitalsBloodPressureRiskCategory[keyof typeof FaceVitalsBloodPressureRiskCategory];
export type FaceVitalsScanResult = {
  __typename?: 'FaceVitalsScanResult';
  appointment?: Maybe<Appointment>;
  bloodPressureDiastolicMmhg?: Maybe<Scalars['Float']>;
  bloodPressureRiskCategory?: Maybe<FaceVitalsBloodPressureRiskCategory>;
  bloodPressureSystolicMmhg?: Maybe<Scalars['Float']>;
  breathingRateBpm?: Maybe<Scalars['Float']>;
  business: Business;
  cardiacWorkload?: Maybe<Scalars['Float']>;
  cardiovascularDiseaseRisk?: Maybe<Scalars['Float']>;
  cardiovascularDiseaseRiskCategory?: Maybe<FaceVitalsBaseRiskCategory>;
  createdAt: Scalars['DateTime'];
  formSubmission?: Maybe<FormSubmission>;
  heartAttackRisk?: Maybe<Scalars['Float']>;
  heartAttackRiskCategory?: Maybe<FaceVitalsBaseRiskCategory>;
  heartRateBpm?: Maybe<Scalars['Int']>;
  heartRateHz?: Maybe<Scalars['Float']>;
  heartRateIrregularBpm?: Maybe<Scalars['Float']>;
  heartRateVariabilityRiskCategory?: Maybe<FaceVitalsBaseRiskCategory>;
  heartRateVariabilitySdnn?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  inputAge: Scalars['Int'];
  inputBloodPressureMedication: Scalars['Boolean'];
  inputDiabeticType: Scalars['String'];
  inputGender: Scalars['String'];
  inputHeight: Scalars['Float'];
  inputHypertension: Scalars['Boolean'];
  inputSmoker: Scalars['Boolean'];
  inputWeight: Scalars['Float'];
  patient?: Maybe<Patient>;
  provider: Scalars['String'];
  providerId: Scalars['String'];
  staff?: Maybe<Staff>;
  strokeRisk?: Maybe<Scalars['Float']>;
  strokeRiskCategory?: Maybe<FaceVitalsBaseRiskCategory>;
  updatedAt: Scalars['DateTime'];
  vascularCapacitySecs?: Maybe<Scalars['Float']>;
};

export type Form = {
  __typename?: 'Form';
  alias?: Maybe<Scalars['String']>;
  business?: Maybe<Business>;
  category?: Maybe<Scalars['String']>;
  config?: Maybe<FormConfig>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<Scalars['Json']>;
  status: FormStatus;
  steps?: Maybe<Scalars['Json']>;
  submissionsCount?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['Json']>;
  title?: Maybe<Scalars['String']>;
  unreadSubmissionsCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  useAsTemplate?: Maybe<Scalars['Boolean']>;
  warnings?: Maybe<Array<FormWarning>>;
};

export type FormBackground = {
  __typename?: 'FormBackground';
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundSize?: Maybe<Scalars['String']>;
  image?: Maybe<FormImage>;
};

export type FormConfig = {
  __typename?: 'FormConfig';
  background?: Maybe<FormBackground>;
  dateFormat?: Maybe<Scalars['Json']>;
  defaultImage?: Maybe<FormImage>;
  defaultLayout?: Maybe<Scalars['String']>;
  logoPosition?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  nextStepButtonLabel?: Maybe<Scalars['String']>;
  omitPrefilledSteps?: Maybe<Scalars['Boolean']>;
  primaryColor?: Maybe<Scalars['String']>;
  requirePatientAuthentication?: Maybe<Scalars['Boolean']>;
};

export type FormImage = {
  __typename?: 'FormImage';
  croppedUrl?: Maybe<Scalars['String']>;
  transforms?: Maybe<Scalars['Json']>;
  url?: Maybe<Scalars['String']>;
};

export const FormQueryMode = {
  Live: 'LIVE',
  Preview: 'PREVIEW'
} as const;

export type FormQueryMode = typeof FormQueryMode[keyof typeof FormQueryMode];
export const FormStatus = {
  Draft: 'DRAFT',
  Published: 'PUBLISHED',
  PublishedWithDraft: 'PUBLISHED_WITH_DRAFT'
} as const;

export type FormStatus = typeof FormStatus[keyof typeof FormStatus];
export type FormSubmission = {
  __typename?: 'FormSubmission';
  answers: Scalars['Json'];
  booking?: Maybe<Scalars['Json']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  form: Form;
  id: Scalars['ID'];
  patient?: Maybe<Patient>;
  patientVerified?: Maybe<Scalars['Boolean']>;
  questions: Scalars['Json'];
  readAt?: Maybe<Scalars['DateTime']>;
  startedAt: Scalars['DateTime'];
};

export type FormSubmissionEvent = {
  __typename?: 'FormSubmissionEvent';
  data: Scalars['Json'];
  name: Scalars['String'];
};

export type FormSubmissionsMetaPayload = {
  __typename?: 'FormSubmissionsMetaPayload';
  count?: Maybe<Scalars['Int']>;
};

export type FormSubmissionsResponse = {
  __typename?: 'FormSubmissionsResponse';
  formSubmissions: Array<FormSubmission>;
  formSubmissionsMeta: FormSubmissionsMetaPayload;
};

export type FormTemplate = {
  __typename?: 'FormTemplate';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  previewUrl: Scalars['String'];
  thumbnailUrl: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export const FormTemplateLibrary = {
  Custom: 'CUSTOM',
  Official: 'OFFICIAL'
} as const;

export type FormTemplateLibrary = typeof FormTemplateLibrary[keyof typeof FormTemplateLibrary];
export type FormWarning = {
  __typename?: 'FormWarning';
  text: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type GetAppointment = {
  __typename?: 'GetAppointment';
  adHocService?: Maybe<Scalars['Json']>;
  answers?: Maybe<Scalars['Json']>;
  business?: Maybe<Scalars['Json']>;
  clientTimezone?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  found?: Maybe<Scalars['Boolean']>;
  hangoutLink?: Maybe<Scalars['String']>;
  hasMoreOccurrences?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  locationType?: Maybe<Scalars['String']>;
  locationValue?: Maybe<Scalars['String']>;
  noOcurrences?: Maybe<Scalars['Boolean']>;
  originalDate?: Maybe<Scalars['DateTime']>;
  patient?: Maybe<Scalars['Json']>;
  price?: Maybe<Scalars['Float']>;
  service?: Maybe<Scalars['Json']>;
  staff?: Maybe<Scalars['Json']>;
  token?: Maybe<Scalars['String']>;
  tokenError?: Maybe<Scalars['Boolean']>;
};

export type Guest = {
  __typename?: 'Guest';
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phoneMobile?: Maybe<Scalars['String']>;
};

export type ImportedPatient = {
  __typename?: 'ImportedPatient';
  email?: Maybe<Scalars['String']>;
  isValid: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  notValidReason?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updatePatientId?: Maybe<Scalars['String']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  amountDiscounted?: Maybe<Scalars['Int']>;
  amountExcludingTax?: Maybe<Scalars['Int']>;
  amountOutstanding?: Maybe<Scalars['Int']>;
  amountSubtotal?: Maybe<Scalars['Int']>;
  amountTax?: Maybe<Scalars['Int']>;
  amountTotal?: Maybe<Scalars['Int']>;
  appointment?: Maybe<Appointment>;
  business: Business;
  coupon?: Maybe<Coupon>;
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  dueDate?: Maybe<Scalars['DateTime']>;
  finalizedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  invoicePdfUrl?: Maybe<Scalars['String']>;
  items?: Maybe<Array<InvoiceItem>>;
  markedUncollectibleAt?: Maybe<Scalars['DateTime']>;
  markedUncollectibleBy?: Maybe<Staff>;
  number?: Maybe<Scalars['String']>;
  patient: Patient;
  paymentPageUrl?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<InvoiceStatus>;
  updatedAt: Scalars['DateTime'];
  voidedAt?: Maybe<Scalars['DateTime']>;
  voidedBy?: Maybe<Staff>;
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  amount?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoice: Invoice;
  quantity?: Maybe<Scalars['Int']>;
  unitAmount?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export const InvoiceStatus = {
  Draft: 'DRAFT',
  Open: 'OPEN',
  Paid: 'PAID',
  Uncollectible: 'UNCOLLECTIBLE',
  Void: 'VOID'
} as const;

export type InvoiceStatus = typeof InvoiceStatus[keyof typeof InvoiceStatus];
export type LeastBusyStaff = {
  __typename?: 'LeastBusyStaff';
  id?: Maybe<Scalars['String']>;
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Scalars['String']>;
  business: Business;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  googlePlaceAddress?: Maybe<Scalars['String']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  googlePlaceImageUrl?: Maybe<Scalars['String']>;
  googlePlaceMapUrl?: Maybe<Scalars['String']>;
  googlePlaceName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  picture?: Maybe<UploadedFile>;
  services?: Maybe<Array<Service>>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  workingHours?: Maybe<Scalars['Json']>;
};

export const LocationType = {
  FreeformText: 'FREEFORM_TEXT',
  GoogleHangoutsUrl: 'GOOGLE_HANGOUTS_URL',
  GoogleMapsAddress: 'GOOGLE_MAPS_ADDRESS',
  None: 'NONE',
  SetByPatient: 'SET_BY_PATIENT',
  SetByPatientGoogleMapsAddress: 'SET_BY_PATIENT_GOOGLE_MAPS_ADDRESS',
  SetByPatientVirtual: 'SET_BY_PATIENT_VIRTUAL',
  Telehealth: 'TELEHEALTH',
  Url: 'URL'
} as const;

export type LocationType = typeof LocationType[keyof typeof LocationType];
export type LocationWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Log = {
  __typename?: 'Log';
  changes: Scalars['JsonArray'];
  createdAt: Scalars['DateTime'];
  direction: Scalars['String'];
  errors: Scalars['JsonArray'];
  id: Scalars['ID'];
  inboundGoogleError: Scalars['Json'];
  inboundGoogleHasChanges: Scalars['Boolean'];
  inboundGoogleLog: Scalars['Json'];
  inboundOutlookError: Scalars['Json'];
  inboundOutlookHasChanges: Scalars['Boolean'];
  inboundOutlookLog: Scalars['Json'];
  service: Scalars['String'];
  success: Scalars['Boolean'];
};

export const MessageRecipient = {
  BusinessOwner: 'BUSINESS_OWNER',
  Guests: 'GUESTS',
  Patient: 'PATIENT',
  Staff: 'STAFF'
} as const;

export type MessageRecipient = typeof MessageRecipient[keyof typeof MessageRecipient];
export const MessageType = {
  Email: 'EMAIL',
  Sms: 'SMS'
} as const;

export type MessageType = typeof MessageType[keyof typeof MessageType];
export type Mutation = {
  __typename?: 'Mutation';
  _dbQueryCount?: Maybe<Scalars['Int']>;
  _dbQueryDuration?: Maybe<Scalars['Int']>;
  _dbQueryLogs?: Maybe<Scalars['Json']>;
  _dbQueryMetrics?: Maybe<Scalars['Json']>;
  abortAccountRemoval?: Maybe<Scalars['Boolean']>;
  addInvoiceItem: Invoice;
  approveAppointment?: Maybe<Appointment>;
  assignStaffStripeProductIds: Staff;
  attachPaymentToAppointment?: Maybe<BookAppointment>;
  authenticateUser: AuthPayload;
  bookAdHocAppointment?: Maybe<BookAppointment>;
  bookAppointment?: Maybe<BookAppointment>;
  cancelAppointment?: Maybe<CancelAppointment>;
  cancelGroupBooking: Array<Appointment>;
  completeStripeConnectAccountOnboarding: StripeConnectAccount;
  createAdHocAppointment: Appointment;
  createAdHocService: AdHocService;
  createApiKey: ApiKey;
  createAppointment?: Maybe<Appointment>;
  createBasicAuthCalendarConnection?: Maybe<CalendarConnection>;
  createBookingEventNotificationTemplate?: Maybe<BookingEventNotificationTemplate>;
  createBusiness: Business;
  createCoupon: Coupon;
  createCustomField: CustomField;
  createDraftInvoice: Invoice;
  createForm: Form;
  createFormSubmission: CreateFormSubmissionPayload;
  createLocation?: Maybe<Location>;
  createNote: Note;
  createOutgoingWebhook: OutgoingWebhook;
  createPatient?: Maybe<Patient>;
  createPatientBusiness: Business;
  createPostAppointmentMessageTemplate?: Maybe<PostAppointmentMessageTemplate>;
  createReminderTemplate?: Maybe<ReminderTemplate>;
  createService?: Maybe<Service>;
  createServiceCategory?: Maybe<ServiceCategory>;
  createServiceStripePaymentIntent?: Maybe<Scalars['Json']>;
  createStaff?: Maybe<Staff>;
  createStripeBillingPortalSession: StripeBillingPortalSession;
  createStripeCheckoutSession: StripeCheckoutSession;
  createStripeConnectAccount: StripeConnectAccountCreatePayload;
  createStripeConnectAccountSession?: Maybe<StripeConnectAccountSession>;
  createStripeSetupIntent: StripeSetupIntent;
  createSupportIdentificationToken: SupportIdentificationToken;
  createTag: Tag;
  createThirdPartyConnectionOAuthSession: ThirdPartyConnectionOAuthSession;
  createTimeBlocker?: Maybe<TimeBlocker>;
  createWorkingHoursSchedule?: Maybe<WorkingHoursSchedule>;
  defaultToBusinessWorkingHours?: Maybe<Scalars['Boolean']>;
  deleteApiKey: Scalars['Boolean'];
  deleteAppointment?: Maybe<Appointment>;
  deleteBusiness?: Maybe<Business>;
  deleteCoupon: Scalars['Boolean'];
  deleteDraftInvoice: Scalars['Boolean'];
  deleteForm: Scalars['Boolean'];
  deleteFormSubmission: Scalars['Boolean'];
  deleteInvoiceItem: Invoice;
  deleteLocation?: Maybe<Scalars['Boolean']>;
  deleteNote: Scalars['Boolean'];
  deleteOutgoingWebhook: Scalars['Boolean'];
  deletePatient?: Maybe<DeleteResourcePayload>;
  deletePatientCustomFieldValue: Scalars['Boolean'];
  deleteServiceCategory?: Maybe<ServiceCategory>;
  deleteStaff?: Maybe<Staff>;
  deleteThirdPartyConnection?: Maybe<Scalars['Boolean']>;
  deleteTimeBlocker?: Maybe<TimeBlocker>;
  deleteUser?: Maybe<DeleteUserPayload>;
  discardFormDraft?: Maybe<Scalars['Boolean']>;
  disconnectCalendarConnection: Scalars['ID'];
  disconnectStripeAccount?: Maybe<Business>;
  dismissNotice?: Maybe<User>;
  duplicateForm: Form;
  duplicateService?: Maybe<Service>;
  enablePatientBilling: Patient;
  finalizeInvoice: Invoice;
  flagTutorialVideoAsSeen?: Maybe<User>;
  getSignedUploadUrl: SignedUploadParams;
  invalidateApiKey: ApiKey;
  optIn?: Maybe<Scalars['Boolean']>;
  publishForm: Form;
  purchaseProduct: User;
  renameForm: Form;
  requestAccountDeletion?: Maybe<DataRequest>;
  requestExport?: Maybe<Scalars['Boolean']>;
  rescheduleAppointment?: Maybe<RescheduleAppointment>;
  resetBusinessBookingForm?: Maybe<Scalars['Boolean']>;
  sendInvoice: Scalars['Boolean'];
  sendPatientIntakeInvite: Scalars['Boolean'];
  sendPatientInvite: Scalars['Boolean'];
  sendPatientLoginEmail: Scalars['Boolean'];
  sendReminders?: Maybe<SendRemindersPayload>;
  sendSignupEmail?: Maybe<Scalars['Boolean']>;
  setPatientCustomFieldValue: CustomFieldValue;
  setPatientTags: Patient;
  syncStripeCustomer: SyncStripeCustomerPayload;
  updateAppointment?: Maybe<Appointment>;
  updateBasicAuthCalendarConnection?: Maybe<CalendarConnection>;
  updateBookingEndpoint: BookingEndpoint;
  updateBookingEventNotificationTemplate?: Maybe<BookingEventNotificationTemplate>;
  updateBusiness?: Maybe<Business>;
  updateCalendarConnection?: Maybe<CalendarConnection>;
  updateCoupon: Coupon;
  updateCustomField: CustomField;
  updateDraftInvoice: Invoice;
  updateFormDraft: Form;
  updateFormSubmission: FormSubmission;
  updateGroupBooking: Array<Appointment>;
  updateInvoiceItem: Invoice;
  updateLocation?: Maybe<Location>;
  updateNote: Note;
  updateNotification?: Maybe<Notification>;
  updateOutgoingWebhook: OutgoingWebhook;
  updatePatient?: Maybe<Patient>;
  updatePostAppointmentMessageTemplate?: Maybe<PostAppointmentMessageTemplate>;
  updateReminderTemplate?: Maybe<ReminderTemplate>;
  updateSeats: UpdateSeatsResponse;
  updateService?: Maybe<Service>;
  updateServiceCategory?: Maybe<ServiceCategory>;
  updateStaff?: Maybe<Staff>;
  updateStaffColours?: Maybe<Business>;
  updateStaffRoles?: Maybe<Staff>;
  updateTag: Tag;
  updateThirdPartyConnection: ThirdPartyConnection;
  updateUser?: Maybe<User>;
  updateUserPersonalData: User;
  updateUserVentureData: User;
  uploadFile?: Maybe<UploadedFile>;
  voidInvoice: Invoice;
};


export type MutationAddInvoiceItemArgs = {
  amount: Scalars['Float'];
  description: Scalars['String'];
  invoiceId: Scalars['ID'];
  quantity: Scalars['Int'];
};


export type MutationApproveAppointmentArgs = {
  id: Scalars['ID'];
  ignoreBusyTimes?: InputMaybe<Scalars['Boolean']>;
};


export type MutationAssignStaffStripeProductIdsArgs = {
  productIds: Array<Scalars['String']>;
  staffId: Scalars['ID'];
};


export type MutationAttachPaymentToAppointmentArgs = {
  id: Scalars['ID'];
  paymentId: Scalars['String'];
  paymentProvider: Scalars['String'];
  token: Scalars['String'];
  transactionUrl?: InputMaybe<Scalars['String']>;
};


export type MutationAuthenticateUserArgs = {
  abTestVariant?: InputMaybe<Scalars['String']>;
  auth0Code: Scalars['String'];
  currency?: InputMaybe<Scalars['String']>;
  signupKey?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};


export type MutationBookAdHocAppointmentArgs = {
  businessId: Scalars['ID'];
  clientTimezone?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  date: Scalars['DateTime'];
  duration: Scalars['Int'];
  locationType?: InputMaybe<Scalars['String']>;
  locationValue?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  patientEmail?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['ID']>;
  patientName?: InputMaybe<Scalars['String']>;
  patientPhone?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  requiresApproval?: InputMaybe<Scalars['Boolean']>;
  rescheduleUrl?: InputMaybe<Scalars['String']>;
  serviceName: Scalars['String'];
  staffId?: InputMaybe<Scalars['ID']>;
};


export type MutationBookAppointmentArgs = {
  answers?: InputMaybe<Scalars['Json']>;
  clientTimezone?: InputMaybe<Scalars['String']>;
  date: Scalars['DateTime'];
  duration: Scalars['Int'];
  guests?: InputMaybe<Array<CreateGuestInput>>;
  isCustomTime?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['String']>;
  patientEmail?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['ID']>;
  patientName?: InputMaybe<Scalars['String']>;
  patientPhone?: InputMaybe<Scalars['String']>;
  paymentId?: InputMaybe<Scalars['String']>;
  paymentProvider?: InputMaybe<Scalars['String']>;
  requestTimeMessage?: InputMaybe<Scalars['String']>;
  requestUrl?: InputMaybe<Scalars['String']>;
  serviceId: Scalars['ID'];
  staffId?: InputMaybe<Scalars['ID']>;
  transactionUrl?: InputMaybe<Scalars['String']>;
};


export type MutationCancelAppointmentArgs = {
  cancelFutureOccurrences?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  occurrenceDate?: InputMaybe<Scalars['DateTime']>;
  token?: InputMaybe<Scalars['String']>;
};


export type MutationCancelGroupBookingArgs = {
  groupBookingId: Scalars['String'];
};


export type MutationCompleteStripeConnectAccountOnboardingArgs = {
  id: Scalars['ID'];
};


export type MutationCreateAdHocAppointmentArgs = {
  end: Scalars['DateTime'];
  locationId?: InputMaybe<Scalars['ID']>;
  locationType?: InputMaybe<Scalars['String']>;
  locationValue?: InputMaybe<Scalars['String']>;
  patientId: Scalars['ID'];
  staffId: Scalars['ID'];
  start: Scalars['DateTime'];
  title: Scalars['String'];
};


export type MutationCreateAdHocServiceArgs = {
  businessId: Scalars['ID'];
  duration: Scalars['Int'];
  locationId?: InputMaybe<Scalars['ID']>;
  locationType?: InputMaybe<Scalars['String']>;
  locationValue?: InputMaybe<Scalars['String']>;
  maxAppointments: Scalars['Int'];
  name: Scalars['String'];
  staffId: Scalars['ID'];
  timeIncrement: Scalars['Int'];
  timeslots: Scalars['Json'];
};


export type MutationCreateApiKeyArgs = {
  description: Scalars['String'];
};


export type MutationCreateAppointmentArgs = {
  answers?: InputMaybe<Scalars['Json']>;
  clientTimezone?: InputMaybe<Scalars['String']>;
  date: Scalars['DateTime'];
  duration: Scalars['Int'];
  ignoreBusyTimes?: InputMaybe<Scalars['Boolean']>;
  patient: PatientCreateOneWithoutAppointmentsInput;
  requestUrl?: InputMaybe<Scalars['String']>;
  serviceId: Scalars['ID'];
  staffId: Scalars['ID'];
};


export type MutationCreateBasicAuthCalendarConnectionArgs = {
  password: Scalars['String'];
  provider: BasicAuthCalendarConnectionProvider;
  resourceId: Scalars['ID'];
  resourceType: CalendarConnectionResourceType;
  user: Scalars['String'];
};


export type MutationCreateBookingEventNotificationTemplateArgs = {
  businessId: Scalars['ID'];
  event: BookingEvent;
  recipient: MessageRecipient;
  services: Array<Scalars['ID']>;
  type: MessageType;
};


export type MutationCreateBusinessArgs = {
  alias: Scalars['String'];
  name: Scalars['String'];
  timezone: Scalars['String'];
};


export type MutationCreateCouponArgs = {
  amountOff?: InputMaybe<Scalars['Int']>;
  code: Scalars['String'];
  currency?: InputMaybe<Scalars['String']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  percentOff?: InputMaybe<Scalars['Float']>;
  type: Scalars['String'];
};


export type MutationCreateCustomFieldArgs = {
  alias: Scalars['String'];
  allowedValues?: InputMaybe<Array<Scalars['String']>>;
  description: Scalars['String'];
  fieldType: CustomFieldType;
  name: Scalars['String'];
};


export type MutationCreateDraftInvoiceArgs = {
  appointmentId?: InputMaybe<Scalars['ID']>;
  currency?: InputMaybe<Scalars['String']>;
  patientId: Scalars['ID'];
};


export type MutationCreateFormArgs = {
  formTemplateId?: InputMaybe<Scalars['ID']>;
  steps?: InputMaybe<Scalars['Json']>;
  title: Scalars['String'];
};


export type MutationCreateFormSubmissionArgs = {
  answers: Scalars['Json'];
  completedAt?: InputMaybe<Scalars['DateTime']>;
  formId: Scalars['ID'];
  requestUrl: Scalars['String'];
  startedAt?: InputMaybe<Scalars['DateTime']>;
};


export type MutationCreateLocationArgs = {
  address?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  googlePlaceAddress?: InputMaybe<Scalars['String']>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  googlePlaceImageUrl?: InputMaybe<Scalars['String']>;
  googlePlaceMapUrl?: InputMaybe<Scalars['String']>;
  googlePlaceName?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  pictureId?: InputMaybe<Scalars['ID']>;
  timezone?: InputMaybe<Scalars['String']>;
  workingHours?: InputMaybe<Scalars['Json']>;
};


export type MutationCreateNoteArgs = {
  appointmentId?: InputMaybe<Scalars['ID']>;
  content: Scalars['String'];
  patientId: Scalars['ID'];
};


export type MutationCreateOutgoingWebhookArgs = {
  businessId: Scalars['ID'];
  endpoint: Scalars['String'];
};


export type MutationCreatePatientArgs = {
  businessId: Scalars['ID'];
  dob?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  name: Scalars['String'];
  phoneMobile?: InputMaybe<Scalars['String']>;
  primaryStaffId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreatePatientBusinessArgs = {
  currency: Scalars['String'];
  name: Scalars['String'];
  patientUserId: Scalars['ID'];
  timezone: Scalars['String'];
};


export type MutationCreatePostAppointmentMessageTemplateArgs = {
  businessId: Scalars['ID'];
  minutes: Scalars['Int'];
  recipient: MessageRecipient;
  services: Array<Scalars['ID']>;
  type: MessageType;
};


export type MutationCreateReminderTemplateArgs = {
  businessId: Scalars['ID'];
  minutes: Scalars['Int'];
  resourceType: ReminderResourceType;
  services: Array<Scalars['ID']>;
  type: ReminderType;
};


export type MutationCreateServiceArgs = {
  additionalStaffIds?: InputMaybe<Array<Scalars['ID']>>;
  allowGuests?: InputMaybe<Scalars['Boolean']>;
  availabilityLimitDays?: InputMaybe<Scalars['Int']>;
  bookingsPerTimeslot?: InputMaybe<Scalars['Int']>;
  bookingsRequireApproval?: InputMaybe<Scalars['Boolean']>;
  bufferAfterMinutes?: InputMaybe<Scalars['Int']>;
  bufferBeforeMinutes?: InputMaybe<Scalars['Int']>;
  businessId: Scalars['ID'];
  cancelLeadTime?: InputMaybe<Scalars['Int']>;
  categoriesIds?: InputMaybe<Array<Scalars['ID']>>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  durations: Scalars['Json'];
  hideTimeslotsSeats?: InputMaybe<Scalars['Boolean']>;
  leadTime?: InputMaybe<Scalars['Int']>;
  locationOverridesStaffs?: InputMaybe<Scalars['Boolean']>;
  locationType?: InputMaybe<Scalars['String']>;
  locationValue?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  pictureId?: InputMaybe<Scalars['ID']>;
  recurrence?: InputMaybe<Scalars['Json']>;
  requiredAdditionalStaffCount?: InputMaybe<Scalars['Int']>;
  requiresStripePayment?: InputMaybe<Scalars['Boolean']>;
  staffsIds?: InputMaybe<Array<Scalars['ID']>>;
  timeIncrement?: InputMaybe<Scalars['Int']>;
  workingHours?: InputMaybe<Scalars['Json']>;
};


export type MutationCreateServiceCategoryArgs = {
  businessId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationCreateServiceStripePaymentIntentArgs = {
  duration: Scalars['Int'];
  serviceId: Scalars['ID'];
};


export type MutationCreateStaffArgs = {
  alias?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phoneMobile?: InputMaybe<Scalars['String']>;
  pictureId?: InputMaybe<Scalars['ID']>;
  roles?: InputMaybe<Array<StaffRole>>;
  stripeProductIds?: InputMaybe<Array<Scalars['String']>>;
  timezone?: InputMaybe<Scalars['String']>;
  workingHours?: InputMaybe<Scalars['Json']>;
};


export type MutationCreateStripeBillingPortalSessionArgs = {
  flow: Scalars['String'];
};


export type MutationCreateStripeConnectAccountArgs = {
  country: Scalars['String'];
};


export type MutationCreateStripeSetupIntentArgs = {
  confirmationToken?: InputMaybe<Scalars['String']>;
  interval: StripeInterval;
  productQuantities: Scalars['Json'];
};


export type MutationCreateSupportIdentificationTokenArgs = {
  provider: Scalars['String'];
};


export type MutationCreateTagArgs = {
  alias: Scalars['String'];
  color: Scalars['String'];
  description: Scalars['String'];
  name: Scalars['String'];
};


export type MutationCreateThirdPartyConnectionOAuthSessionArgs = {
  provider: ThirdPartyConnectionProvider;
  redirectUrl: Scalars['String'];
  resourceType: ThirdPartyConnectionResourceType;
};


export type MutationCreateTimeBlockerArgs = {
  endDate: Scalars['DateTime'];
  reason?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['ID']>;
  startDate: Scalars['DateTime'];
};


export type MutationCreateWorkingHoursScheduleArgs = {
  from: Scalars['DateTime'];
  staffId: Scalars['ID'];
  to?: InputMaybe<Scalars['DateTime']>;
  workingHours: Scalars['Json'];
};


export type MutationDefaultToBusinessWorkingHoursArgs = {
  staffId: Scalars['ID'];
};


export type MutationDeleteApiKeyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAppointmentArgs = {
  futureOccurrences?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  occurrenceDate?: InputMaybe<Scalars['DateTime']>;
};


export type MutationDeleteBusinessArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCouponArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDraftInvoiceArgs = {
  invoiceId: Scalars['ID'];
};


export type MutationDeleteFormArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFormSubmissionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteInvoiceItemArgs = {
  invoiceId: Scalars['ID'];
  invoiceItemId: Scalars['ID'];
};


export type MutationDeleteLocationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNoteArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOutgoingWebhookArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePatientArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePatientCustomFieldValueArgs = {
  fieldKey: Scalars['String'];
  patientId: Scalars['ID'];
};


export type MutationDeleteServiceCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteStaffArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteThirdPartyConnectionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTimeBlockerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
  verificationToken?: InputMaybe<Scalars['String']>;
};


export type MutationDiscardFormDraftArgs = {
  formId: Scalars['ID'];
};


export type MutationDisconnectCalendarConnectionArgs = {
  id: Scalars['ID'];
};


export type MutationDisconnectStripeAccountArgs = {
  businessId: Scalars['ID'];
};


export type MutationDismissNoticeArgs = {
  noticeId: Scalars['ID'];
};


export type MutationDuplicateFormArgs = {
  id: Scalars['ID'];
};


export type MutationDuplicateServiceArgs = {
  id: Scalars['ID'];
};


export type MutationEnablePatientBillingArgs = {
  id: Scalars['ID'];
};


export type MutationFinalizeInvoiceArgs = {
  invoiceId: Scalars['ID'];
};


export type MutationFlagTutorialVideoAsSeenArgs = {
  tutorialId: Scalars['String'];
};


export type MutationGetSignedUploadUrlArgs = {
  acl: Scalars['String'];
  filename: Scalars['String'];
};


export type MutationInvalidateApiKeyArgs = {
  id: Scalars['ID'];
};


export type MutationOptInArgs = {
  feature: Scalars['String'];
};


export type MutationPublishFormArgs = {
  id: Scalars['ID'];
};


export type MutationPurchaseProductArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
  productId: Scalars['ID'];
};


export type MutationRenameFormArgs = {
  alias?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  thumbnail?: InputMaybe<Scalars['Json']>;
  title?: InputMaybe<Scalars['String']>;
  useAsTemplate?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRequestAccountDeletionArgs = {
  email: Scalars['String'];
};


export type MutationRequestExportArgs = {
  businessId: Scalars['ID'];
  resourcesToExport?: InputMaybe<ExportableResource>;
};


export type MutationRescheduleAppointmentArgs = {
  clientTimezone?: InputMaybe<Scalars['String']>;
  date: Scalars['DateTime'];
  duration?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  location?: InputMaybe<Scalars['String']>;
  occurrenceDate?: InputMaybe<Scalars['DateTime']>;
  rescheduleFutureOccurrences?: InputMaybe<Scalars['Boolean']>;
  staffId?: InputMaybe<Scalars['ID']>;
  token?: InputMaybe<Scalars['String']>;
};


export type MutationSendInvoiceArgs = {
  appointmentId?: InputMaybe<Scalars['ID']>;
  invoiceId: Scalars['ID'];
};


export type MutationSendPatientIntakeInviteArgs = {
  body: Scalars['String'];
  formList: Array<Scalars['String']>;
  patientId: Scalars['ID'];
  subject: Scalars['String'];
};


export type MutationSendPatientInviteArgs = {
  body: Scalars['String'];
  patientId: Scalars['ID'];
  subject: Scalars['String'];
};


export type MutationSendPatientLoginEmailArgs = {
  businessId: Scalars['ID'];
  email: Scalars['String'];
  redirectUrl: Scalars['String'];
};


export type MutationSendSignupEmailArgs = {
  patientId: Scalars['String'];
};


export type MutationSetPatientCustomFieldValueArgs = {
  fieldKey: Scalars['String'];
  patientId: Scalars['ID'];
  value: Scalars['String'];
};


export type MutationSetPatientTagsArgs = {
  patientId: Scalars['ID'];
  tagIds: Array<Scalars['String']>;
};


export type MutationUpdateAppointmentArgs = {
  answers?: InputMaybe<Scalars['Json']>;
  date?: InputMaybe<Scalars['DateTime']>;
  duration?: InputMaybe<Scalars['Int']>;
  futureOccurrences?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  ignoreBusyTimes?: InputMaybe<Scalars['Boolean']>;
  originalDate?: InputMaybe<Scalars['DateTime']>;
  patient?: InputMaybe<PatientCreateOneWithoutAppointmentsInput>;
  serviceId?: InputMaybe<Scalars['ID']>;
  singleOccurrence?: InputMaybe<Scalars['Boolean']>;
  staffId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateBasicAuthCalendarConnectionArgs = {
  id: Scalars['ID'];
  password: Scalars['String'];
};


export type MutationUpdateBookingEndpointArgs = {
  alias?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationUpdateBookingEventNotificationTemplateArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  messageTemplate?: InputMaybe<Scalars['Json']>;
};


export type MutationUpdateBusinessArgs = {
  alias?: InputMaybe<Scalars['String']>;
  allowChoosingStaff?: InputMaybe<Scalars['Boolean']>;
  allowNoStaffPreference?: InputMaybe<Scalars['Boolean']>;
  allowStaffApprovals?: InputMaybe<Scalars['Boolean']>;
  allowTimeRequests?: InputMaybe<Scalars['Boolean']>;
  appointmentsColumns?: InputMaybe<Array<Scalars['String']>>;
  attachICSFiles?: InputMaybe<Scalars['Boolean']>;
  autoGenerateBookingInvoices?: InputMaybe<Scalars['Boolean']>;
  availabilityLimitDays?: InputMaybe<Scalars['Int']>;
  bookingFormLegacyConfig?: InputMaybe<Scalars['Json']>;
  bookingLocale?: InputMaybe<Scalars['String']>;
  bookingNotificationsReplyTo?: InputMaybe<NotificationsReplyToType>;
  bookingTemplates?: InputMaybe<Scalars['Json']>;
  bookingsRequireApprovalUntilPaid?: InputMaybe<Scalars['Boolean']>;
  bufferAfterMinutes?: InputMaybe<Scalars['Int']>;
  bufferBeforeMinutes?: InputMaybe<Scalars['Int']>;
  calendarView?: InputMaybe<CalendarView>;
  color?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  dateFormat?: InputMaybe<Scalars['Json']>;
  defaultInvoiceDaysUntilDue?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  exportedEventsTemplate?: InputMaybe<Scalars['Json']>;
  forceServiceStep?: InputMaybe<Scalars['Boolean']>;
  googleTrackingId?: InputMaybe<Scalars['String']>;
  hidePatientTimezoneDropdwon?: InputMaybe<Scalars['Boolean']>;
  hidePrivacyButton?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  includeAnswersInExportedEvents?: InputMaybe<Scalars['Boolean']>;
  includeAnswersOnRedirect?: InputMaybe<Scalars['Boolean']>;
  invoicesTaxBehaviour?: InputMaybe<TaxBehaviour>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  logoId?: InputMaybe<Scalars['ID']>;
  logoPosition?: InputMaybe<Scalars['String']>;
  marketingMeta?: InputMaybe<Scalars['Json']>;
  name?: InputMaybe<Scalars['String']>;
  notificationSettings?: InputMaybe<Scalars['Json']>;
  onboardingCompleted?: InputMaybe<Scalars['Boolean']>;
  ownerNotificationsCC?: InputMaybe<Scalars['String']>;
  ownerNotificationsRecipient?: InputMaybe<OwnerNotificationsRecipient>;
  physicalAddress?: InputMaybe<Scalars['String']>;
  postOnRedirect?: InputMaybe<Scalars['Boolean']>;
  redirectAfterBookingUrl?: InputMaybe<Scalars['String']>;
  reminderPatientEmail?: InputMaybe<Scalars['Int']>;
  reminderPatientSMS?: InputMaybe<Scalars['Int']>;
  reminderStaffEmail?: InputMaybe<Scalars['Int']>;
  reminderStaffSMS?: InputMaybe<Scalars['Int']>;
  sendSMSFromStaffNumber?: InputMaybe<Scalars['Boolean']>;
  servicesColumns?: InputMaybe<Array<Scalars['String']>>;
  servicesOrder?: InputMaybe<Array<Scalars['String']>>;
  showCalendarEventsNames?: InputMaybe<Scalars['Boolean']>;
  showDropdownFilter?: InputMaybe<Scalars['Boolean']>;
  staffsColumns?: InputMaybe<Array<Scalars['String']>>;
  templates?: InputMaybe<Scalars['Json']>;
  timeRequestButtonLabel?: InputMaybe<Scalars['String']>;
  timeRequestText?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  trackingPixelUrl?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  websiteContainingEmbedCode?: InputMaybe<Scalars['String']>;
  workingHours?: InputMaybe<Scalars['Json']>;
};


export type MutationUpdateCalendarConnectionArgs = {
  allDayEventsTimezone?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  exportAttendees?: InputMaybe<Array<CalendarEventAttendee>>;
  exportToCalendarId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  importFromCalendarsIds?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationUpdateCouponArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateCustomFieldArgs = {
  allowedValues?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDraftInvoiceArgs = {
  couponCode?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  invoiceId: Scalars['ID'];
};


export type MutationUpdateFormDraftArgs = {
  formId: Scalars['ID'];
  settings?: InputMaybe<Scalars['Json']>;
  steps?: InputMaybe<Scalars['Json']>;
};


export type MutationUpdateFormSubmissionArgs = {
  id: Scalars['ID'];
  read: Scalars['Boolean'];
};


export type MutationUpdateGroupBookingArgs = {
  date: Scalars['DateTime'];
  groupBookingId: Scalars['String'];
};


export type MutationUpdateInvoiceItemArgs = {
  amount: Scalars['Float'];
  description: Scalars['String'];
  invoiceId: Scalars['ID'];
  invoiceItemId: Scalars['ID'];
  quantity: Scalars['Int'];
};


export type MutationUpdateLocationArgs = {
  address?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  googlePlaceAddress?: InputMaybe<Scalars['String']>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  googlePlaceImageUrl?: InputMaybe<Scalars['String']>;
  googlePlaceMapUrl?: InputMaybe<Scalars['String']>;
  googlePlaceName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  instructions?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  pictureId?: InputMaybe<Scalars['ID']>;
  timezone?: InputMaybe<Scalars['String']>;
  workingHours?: InputMaybe<Scalars['Json']>;
};


export type MutationUpdateNoteArgs = {
  content: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationUpdateNotificationArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  read?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateOutgoingWebhookArgs = {
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
};


export type MutationUpdatePatientArgs = {
  billingAddress?: InputMaybe<AddressInput>;
  biologicalSex?: InputMaybe<BiologicalSex>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  patientType?: InputMaybe<Scalars['String']>;
  phoneMobile?: InputMaybe<Scalars['String']>;
  preferredName?: InputMaybe<Scalars['String']>;
  primaryStaffId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdatePostAppointmentMessageTemplateArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  messageTemplate?: InputMaybe<Scalars['Json']>;
};


export type MutationUpdateReminderTemplateArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  messageTemplate?: InputMaybe<Scalars['Json']>;
};


export type MutationUpdateSeatsArgs = {
  interval: StripeInterval;
  productQuantities: Scalars['Json'];
};


export type MutationUpdateServiceArgs = {
  additionalStaffIds?: InputMaybe<Array<Scalars['ID']>>;
  alias?: InputMaybe<Scalars['String']>;
  allowGuests?: InputMaybe<Scalars['Boolean']>;
  availabilityLimitDays?: InputMaybe<Scalars['Int']>;
  bookingPageVisibility?: InputMaybe<Scalars['String']>;
  bookingsPerTimeslot?: InputMaybe<Scalars['Int']>;
  bookingsRequireApproval?: InputMaybe<Scalars['Boolean']>;
  bufferAfterMinutes?: InputMaybe<Scalars['Int']>;
  bufferBeforeMinutes?: InputMaybe<Scalars['Int']>;
  cancelLeadTime?: InputMaybe<Scalars['Int']>;
  categoriesIds?: InputMaybe<Array<Scalars['ID']>>;
  currency?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  durations?: InputMaybe<Scalars['Json']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  hideTimeslotsSeats?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  leadTime?: InputMaybe<Scalars['Int']>;
  locationId?: InputMaybe<Scalars['ID']>;
  locationOverridesStaffs?: InputMaybe<Scalars['Boolean']>;
  locationType?: InputMaybe<Scalars['String']>;
  locationValue?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  overrideStaffVideoConferenceProvider?: InputMaybe<Scalars['Boolean']>;
  pictureId?: InputMaybe<Scalars['ID']>;
  recurrence?: InputMaybe<Scalars['Json']>;
  requiredAdditionalStaffCount?: InputMaybe<Scalars['Int']>;
  requiresStripePayment?: InputMaybe<Scalars['Boolean']>;
  staffsIds?: InputMaybe<Array<Scalars['ID']>>;
  timeIncrement?: InputMaybe<Scalars['Int']>;
  videoConferenceProvider?: InputMaybe<VideoConferenceProvider>;
  workingHours?: InputMaybe<Scalars['Json']>;
};


export type MutationUpdateServiceCategoryArgs = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateStaffArgs = {
  alias?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['ID']>;
  locationType?: InputMaybe<Scalars['String']>;
  locationValue?: InputMaybe<Scalars['String']>;
  managedStaff?: InputMaybe<Array<Scalars['ID']>>;
  marketingMeta?: InputMaybe<Scalars['Json']>;
  name?: InputMaybe<Scalars['String']>;
  occupation?: InputMaybe<Scalars['String']>;
  onboardingCompleted?: InputMaybe<Scalars['Boolean']>;
  phoneMobile?: InputMaybe<Scalars['String']>;
  pictureId?: InputMaybe<Scalars['ID']>;
  resendInvite?: InputMaybe<Scalars['Boolean']>;
  roles?: InputMaybe<Array<StaffRole>>;
  signature?: InputMaybe<Scalars['Json']>;
  timezone?: InputMaybe<Scalars['String']>;
  videoConferenceProvider?: InputMaybe<VideoConferenceProvider>;
  workingHours?: InputMaybe<Scalars['Json']>;
};


export type MutationUpdateStaffColoursArgs = {
  businessId: Scalars['ID'];
  staffColours: Scalars['Json'];
};


export type MutationUpdateStaffRolesArgs = {
  id: Scalars['ID'];
  roles: Array<StaffRole>;
};


export type MutationUpdateTagArgs = {
  color?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateThirdPartyConnectionArgs = {
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  signature?: InputMaybe<Scalars['Json']>;
};


export type MutationUpdateUserPersonalDataArgs = {
  name: Scalars['String'];
};


export type MutationUpdateUserVentureDataArgs = {
  venture: Scalars['Json'];
};


export type MutationUploadFileArgs = {
  acl?: InputMaybe<Scalars['String']>;
  s3Key: Scalars['String'];
};


export type MutationVoidInvoiceArgs = {
  invoiceId: Scalars['ID'];
};

export type Node = {
  id: Scalars['ID'];
};

export type Note = {
  __typename?: 'Note';
  appointment?: Maybe<Appointment>;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  patient: Patient;
  staff: Staff;
  updatedAt: Scalars['DateTime'];
};

export type Notification = {
  __typename?: 'Notification';
  business: Business;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deleted?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  read?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

export type NotificationsMetaPayload = {
  __typename?: 'NotificationsMetaPayload';
  count?: Maybe<Scalars['Int']>;
};

export const NotificationsReplyToType = {
  Business: 'BUSINESS',
  Staff: 'STAFF'
} as const;

export type NotificationsReplyToType = typeof NotificationsReplyToType[keyof typeof NotificationsReplyToType];
export type OAuth2CallbackPayload = {
  __typename?: 'OAuth2CallbackPayload';
  configureUrl?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type OAuthToken = {
  __typename?: 'OAuthToken';
  accessToken: Scalars['String'];
  createdAt: Scalars['DateTime'];
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  invalidatedAt?: Maybe<Scalars['DateTime']>;
  provider: OAuthTokenProvider;
  refreshToken: Scalars['String'];
  scope?: Maybe<Scalars['String']>;
  tokenType: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export const OAuthTokenProvider = {
  Google: 'GOOGLE',
  Microsoft: 'MICROSOFT'
} as const;

export type OAuthTokenProvider = typeof OAuthTokenProvider[keyof typeof OAuthTokenProvider];
export type OAuthUrlsPayload = {
  __typename?: 'OAuthUrlsPayload';
  google?: Maybe<Scalars['String']>;
  microsoft?: Maybe<Scalars['String']>;
};

export type OutgoingWebhook = {
  __typename?: 'OutgoingWebhook';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  endpoint: Scalars['String'];
  events: Array<Event>;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};


export type OutgoingWebhookEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type OutgoingWebhookDelivery = {
  __typename?: 'OutgoingWebhookDelivery';
  business: Business;
  createdAt: Scalars['DateTime'];
  duration?: Maybe<Scalars['Int']>;
  event: Event;
  id: Scalars['ID'];
  outgoingWebhook: OutgoingWebhook;
  requestHeaders: Scalars['Json'];
  requestPayload: Scalars['Json'];
  responseHeaders?: Maybe<Scalars['Json']>;
  responsePayload?: Maybe<Scalars['Json']>;
  responseStatus?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export const OwnerNotificationsRecipient = {
  Business: 'BUSINESS',
  Owner: 'OWNER'
} as const;

export type OwnerNotificationsRecipient = typeof OwnerNotificationsRecipient[keyof typeof OwnerNotificationsRecipient];
export type Patient = {
  __typename?: 'Patient';
  age?: Maybe<Scalars['Int']>;
  appointments?: Maybe<Array<Appointment>>;
  billingAddress?: Maybe<Address>;
  biologicalSex?: Maybe<BiologicalSex>;
  business?: Maybe<Business>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customFieldValues: Array<CustomFieldValue>;
  dob?: Maybe<Scalars['Date']>;
  email: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  lastIntakeInviteSentAt?: Maybe<Scalars['DateTime']>;
  lastInvitedToPortalAt?: Maybe<Scalars['DateTime']>;
  lastLoginAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  patientType?: Maybe<PatientType>;
  phoneMobile?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  primaryStaff?: Maybe<Staff>;
  selfDeclaredDiabeticType?: Maybe<DiabeticType>;
  selfDeclaredHypertension?: Maybe<Scalars['Boolean']>;
  selfDeclaredIsSmoker?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  tagIds: Array<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PatientCreateOneWithoutAppointmentsInput = {
  connect?: InputMaybe<PatientWhereUniqueInput>;
  create?: InputMaybe<PatientCreateWithoutAppointmentsInput>;
};

export type PatientCreateWithoutAppointmentsInput = {
  businessId?: InputMaybe<Scalars['ID']>;
  email: Scalars['String'];
  name: Scalars['String'];
  phoneMobile?: InputMaybe<Scalars['String']>;
};

export const PatientType = {
  Adolescent: 'ADOLESCENT',
  Adult: 'ADULT',
  Child: 'CHILD',
  Couple: 'COUPLE',
  Infant: 'INFANT'
} as const;

export type PatientType = typeof PatientType[keyof typeof PatientType];
export type PatientWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PatientWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PatientsMetaPayload = {
  __typename?: 'PatientsMetaPayload';
  count?: Maybe<Scalars['Int']>;
};

export const PatientsOrderByInput = {
  CreatedAtAsc: 'createdAt_ASC',
  CreatedAtDesc: 'createdAt_DESC',
  NameAsc: 'name_ASC',
  NameDesc: 'name_DESC'
} as const;

export type PatientsOrderByInput = typeof PatientsOrderByInput[keyof typeof PatientsOrderByInput];
export type Picture = {
  __typename?: 'Picture';
  id: Scalars['ID'];
  url: Scalars['String'];
};


export type PictureUrlArgs = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type PostAppointmentMessage = {
  __typename?: 'PostAppointmentMessage';
  appointment: Appointment;
  appointmentDate?: Maybe<Scalars['DateTime']>;
  business: Business;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  patient?: Maybe<Patient>;
  postAppointmentMessageTemplate: PostAppointmentMessageTemplate;
  sentAt?: Maybe<Scalars['DateTime']>;
  staff?: Maybe<Staff>;
};

export type PostAppointmentMessageTemplate = {
  __typename?: 'PostAppointmentMessageTemplate';
  business: Business;
  createdAt: Scalars['DateTime'];
  defaultTemplate?: Maybe<Scalars['Json']>;
  deleted?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  messageTemplate?: Maybe<Scalars['Json']>;
  minutes: Scalars['Int'];
  postAppointmentMessages: Array<PostAppointmentMessage>;
  recipient: MessageRecipient;
  services: Array<Service>;
  type: MessageType;
  updatedAt: Scalars['DateTime'];
};

export type Query = {
  __typename?: 'Query';
  Appointment?: Maybe<Appointment>;
  Business?: Maybe<Business>;
  GroupBookingAppointments?: Maybe<Array<Appointment>>;
  Invoice: Invoice;
  Location?: Maybe<Location>;
  Patient: Patient;
  Service?: Maybe<Service>;
  Staff?: Maybe<Staff>;
  Tag?: Maybe<Tag>;
  _dbQueryCount?: Maybe<Scalars['Int']>;
  _dbQueryDuration?: Maybe<Scalars['Int']>;
  _dbQueryLogs?: Maybe<Scalars['Json']>;
  _dbQueryMetrics?: Maybe<Scalars['Json']>;
  _synchronizationLogsCount: Scalars['Int'];
  adHocAvailableTimesUTC?: Maybe<AvailableTimes>;
  adhocServices: Array<AdHocService>;
  adhocServicesCount: Scalars['Int'];
  allApiKeys: Array<ApiKey>;
  allBusinessAppointments: Array<Appointment>;
  allCoupons: Array<Coupon>;
  allCustomFields: Array<CustomField>;
  allReminderTemplates: Array<ReminderTemplate>;
  allTags: Array<Tag>;
  allTimeBlockers: Array<TimeBlocker>;
  analytics?: Maybe<Analytics>;
  availableFeatures: Array<Scalars['String']>;
  availableTimes?: Maybe<AvailableTimes>;
  availableTimesUTC?: Maybe<AvailableTimes>;
  businessAppointments?: Maybe<Scalars['JsonArray']>;
  businessAppointmentsById?: Maybe<Scalars['JsonArray']>;
  businessAppointmentsByIdTyped: Array<Appointment>;
  businessAppointmentsTyped: Array<Appointment>;
  businessBookingEventNotificationTemplates: Array<BookingEventNotificationTemplate>;
  businessBookingForm: Form;
  businessByAppointment?: Maybe<Business>;
  businessLocations: Array<Location>;
  businessLocationsCount: Scalars['Int'];
  businessNotifications: Array<Notification>;
  businessNotificationsCount: Scalars['Int'];
  businessPatients: Array<Patient>;
  businessPatientsCount: Scalars['Int'];
  businessPostAppointmentMessageTemplates: Array<PostAppointmentMessageTemplate>;
  businessRoleConfigurations: Array<RoleConfiguration>;
  businessServices?: Maybe<Array<Service>>;
  businessServicesCount: Scalars['Int'];
  businessStaffs: Array<Staff>;
  calendarConnection?: Maybe<CalendarConnection>;
  calendarConnections: Array<CalendarConnection>;
  calendarViewAppointments: Array<Appointment>;
  calendarViewExternalEvents: Array<CalendarViewExternalEventPayload>;
  currentBusiness: Business;
  currentStaff: Staff;
  currentUser?: Maybe<User>;
  defaultBookingTemplates?: Maybe<Scalars['Json']>;
  defaultNotificationsTemplates?: Maybe<DefaultNotificationsTemplatesPayload>;
  faceVitalsScanResult?: Maybe<FaceVitalsScanResult>;
  faceVitalsScanResults: Array<FaceVitalsScanResult>;
  form: Form;
  formSubmission: FormSubmission;
  formSubmissionAttachmentURL?: Maybe<Scalars['String']>;
  formSubmissions: FormSubmissionsResponse;
  formTemplates: Array<FormTemplate>;
  forms: Array<Form>;
  formsCount: Scalars['Int'];
  getAppointment?: Maybe<GetAppointment>;
  getBusiness?: Maybe<Scalars['Json']>;
  getEndpoint?: Maybe<BookingEndpointPayload>;
  leastBusyStaff?: Maybe<LeastBusyStaff>;
  logs?: Maybe<Array<Log>>;
  managedStaffs: Array<Staff>;
  managedStaffsCount: Scalars['Int'];
  note?: Maybe<Note>;
  oAuthUrls?: Maybe<OAuthUrlsPayload>;
  otherStaffCalendarConnections: Array<CalendarConnection>;
  outgoingWebhook?: Maybe<OutgoingWebhook>;
  outgoingWebhooks: Array<OutgoingWebhook>;
  patientInvoices: Array<Invoice>;
  patientInvoicesCount: Scalars['Int'];
  patientNotes: Array<Note>;
  patientNotesCount: Scalars['Int'];
  recurringOccurrences?: Maybe<RecurrenceOccurrencesPayload>;
  staffAppointments?: Maybe<Scalars['JsonArray']>;
  staffAppointmentsTyped: Array<Appointment>;
  staffCalendarConnections: Array<CalendarConnection>;
  staffIsAvailable: Scalars['Boolean'];
  staffOAuthUrls?: Maybe<OAuthUrlsPayload>;
  staffTeamsOAuthUrl: Scalars['String'];
  staffsById: Array<Staff>;
  synchronizationLogs: Array<SynchronizationLog>;
  teamsOAuthUrl: Scalars['String'];
  thirdPartyConnections: Array<ThirdPartyConnection>;
  /** @deprecated Use currentStaff */
  userStaff?: Maybe<Staff>;
  workingHoursSlots: WorkingHoursSlots;
};


export type QueryAppointmentArgs = {
  id: Scalars['ID'];
};


export type QueryBusinessArgs = {
  alias?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGroupBookingAppointmentsArgs = {
  groupBookingId: Scalars['ID'];
};


export type QueryInvoiceArgs = {
  id: Scalars['ID'];
};


export type QueryLocationArgs = {
  id: Scalars['ID'];
};


export type QueryPatientArgs = {
  id: Scalars['ID'];
};


export type QueryServiceArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryStaffArgs = {
  id: Scalars['ID'];
};


export type QueryTagArgs = {
  id: Scalars['ID'];
};


export type Query_SynchronizationLogsCountArgs = {
  connectionId: Scalars['ID'];
};


export type QueryAdHocAvailableTimesUtcArgs = {
  availabilityLimitDays?: InputMaybe<Scalars['Int']>;
  businessId: Scalars['ID'];
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  duration: Scalars['Int'];
  minimumNoticeTime?: InputMaybe<Scalars['Int']>;
  staffIds?: InputMaybe<Array<Scalars['ID']>>;
  timeIncrement?: InputMaybe<Scalars['Int']>;
};


export type QueryAdhocServicesArgs = {
  businessId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryAdhocServicesCountArgs = {
  businessId: Scalars['ID'];
};


export type QueryAllBusinessAppointmentsArgs = {
  businessId: Scalars['ID'];
};


export type QueryAllReminderTemplatesArgs = {
  businessId: Scalars['ID'];
};


export type QueryAllTimeBlockersArgs = {
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  staffsIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryAvailableFeaturesArgs = {
  businessId: Scalars['ID'];
};


export type QueryAvailableTimesArgs = {
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  duration: Scalars['Int'];
  filterStaffsIds?: InputMaybe<Array<Scalars['ID']>>;
  ignoreAppointmentId?: InputMaybe<Scalars['ID']>;
  includeDisabledStaffId?: InputMaybe<Scalars['ID']>;
  serviceId: Scalars['ID'];
};


export type QueryAvailableTimesUtcArgs = {
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  duration: Scalars['Int'];
  filterStaffsIds?: InputMaybe<Array<Scalars['ID']>>;
  ignoreBufferTimes?: InputMaybe<Scalars['Boolean']>;
  includeDisabledStaffId?: InputMaybe<Scalars['ID']>;
  reschedulingId?: InputMaybe<Scalars['ID']>;
  serviceId: Scalars['ID'];
};


export type QueryBusinessAppointmentsArgs = {
  businessId: Scalars['ID'];
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
};


export type QueryBusinessAppointmentsByIdArgs = {
  appointmentsIds: Array<Scalars['ID']>;
  businessId: Scalars['ID'];
};


export type QueryBusinessAppointmentsByIdTypedArgs = {
  appointmentsIds: Array<Scalars['ID']>;
  businessId: Scalars['ID'];
};


export type QueryBusinessAppointmentsTypedArgs = {
  businessId: Scalars['ID'];
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
};


export type QueryBusinessBookingEventNotificationTemplatesArgs = {
  businessId: Scalars['ID'];
};


export type QueryBusinessBookingFormArgs = {
  adHocServiceId?: InputMaybe<Scalars['ID']>;
  businessId: Scalars['ID'];
  serviceCategoryId?: InputMaybe<Scalars['ID']>;
  serviceId?: InputMaybe<Scalars['ID']>;
  staffId?: InputMaybe<Scalars['ID']>;
};


export type QueryBusinessByAppointmentArgs = {
  appointmentId: Scalars['ID'];
};


export type QueryBusinessLocationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryBusinessLocationsCountArgs = {
  search?: InputMaybe<Scalars['String']>;
};


export type QueryBusinessNotificationsArgs = {
  after?: InputMaybe<Scalars['ID']>;
  businessId: Scalars['ID'];
  limit: Scalars['Int'];
};


export type QueryBusinessNotificationsCountArgs = {
  businessId: Scalars['ID'];
};


export type QueryBusinessPatientsArgs = {
  after?: InputMaybe<Scalars['ID']>;
  businessId: Scalars['ID'];
  limit: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<PatientsOrderByInput>;
};


export type QueryBusinessPatientsCountArgs = {
  businessId: Scalars['ID'];
  search?: InputMaybe<Scalars['String']>;
};


export type QueryBusinessPostAppointmentMessageTemplatesArgs = {
  businessId: Scalars['ID'];
};


export type QueryBusinessRoleConfigurationsArgs = {
  businessId: Scalars['ID'];
};


export type QueryBusinessServicesArgs = {
  businessId: Scalars['ID'];
  excludeIds?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryBusinessServicesCountArgs = {
  businessId: Scalars['ID'];
  excludeIds?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryBusinessStaffsArgs = {
  businessId: Scalars['ID'];
  filterId?: InputMaybe<Scalars['ID']>;
};


export type QueryCalendarConnectionArgs = {
  id: Scalars['ID'];
};


export type QueryCalendarConnectionsArgs = {
  resourceId: Scalars['ID'];
  resourceType: CalendarConnectionResourceType;
};


export type QueryCalendarViewAppointmentsArgs = {
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  groupBookingId?: InputMaybe<Scalars['String']>;
  locationType?: InputMaybe<LocationType>;
  patientId?: InputMaybe<Scalars['ID']>;
  singleId?: InputMaybe<Scalars['ID']>;
  staffsIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryCalendarViewExternalEventsArgs = {
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  staffsIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryCurrentBusinessArgs = {
  businessId: Scalars['ID'];
};


export type QueryCurrentStaffArgs = {
  businessId: Scalars['ID'];
};


export type QueryFaceVitalsScanResultArgs = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
};


export type QueryFaceVitalsScanResultsArgs = {
  businessId: Scalars['ID'];
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  patientId: Scalars['ID'];
};


export type QueryFormArgs = {
  id: Scalars['ID'];
  mode?: InputMaybe<FormQueryMode>;
};


export type QueryFormSubmissionArgs = {
  id: Scalars['ID'];
};


export type QueryFormSubmissionAttachmentUrlArgs = {
  answerKey: Scalars['String'];
  formSubmissionId: Scalars['ID'];
};


export type QueryFormSubmissionsArgs = {
  appointmentId?: InputMaybe<Scalars['ID']>;
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  formId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['ID']>;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryFormTemplatesArgs = {
  library?: InputMaybe<FormTemplateLibrary>;
};


export type QueryFormsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryFormsCountArgs = {
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetAppointmentArgs = {
  id?: InputMaybe<Scalars['ID']>;
  token?: InputMaybe<Scalars['String']>;
};


export type QueryGetBusinessArgs = {
  appointmentId?: InputMaybe<Scalars['ID']>;
  endpointId?: InputMaybe<Scalars['String']>;
};


export type QueryGetEndpointArgs = {
  appointmentId?: InputMaybe<Scalars['ID']>;
  endpointId?: InputMaybe<Scalars['String']>;
};


export type QueryLeastBusyStaffArgs = {
  date: Scalars['DateTime'];
  duration: Scalars['Int'];
  serviceId: Scalars['String'];
};


export type QueryLogsArgs = {
  first: Scalars['Int'];
  skip: Scalars['Int'];
  userId: Scalars['ID'];
};


export type QueryManagedStaffsArgs = {
  excludeIds?: InputMaybe<Array<Scalars['ID']>>;
  filterIds?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  roles?: InputMaybe<Array<StaffRole>>;
  search?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<StaffStatus>>;
};


export type QueryManagedStaffsCountArgs = {
  excludeIds?: InputMaybe<Array<Scalars['ID']>>;
  filterIds?: InputMaybe<Array<Scalars['ID']>>;
  roles?: InputMaybe<Array<StaffRole>>;
  search?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Array<StaffStatus>>;
};


export type QueryNoteArgs = {
  id: Scalars['ID'];
};


export type QueryOAuthUrlsArgs = {
  businessId: Scalars['ID'];
  redirectUrl?: InputMaybe<Scalars['String']>;
};


export type QueryOtherStaffCalendarConnectionsArgs = {
  businessId: Scalars['ID'];
};


export type QueryOutgoingWebhookArgs = {
  businessId: Scalars['ID'];
  outgoingWebhookId: Scalars['ID'];
};


export type QueryOutgoingWebhooksArgs = {
  businessId: Scalars['ID'];
};


export type QueryPatientInvoicesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  patientId: Scalars['ID'];
  skip?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<InvoiceStatus>>;
};


export type QueryPatientInvoicesCountArgs = {
  patientId: Scalars['ID'];
  statuses?: InputMaybe<Array<InvoiceStatus>>;
};


export type QueryPatientNotesArgs = {
  appointmentId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  patientId: Scalars['ID'];
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryPatientNotesCountArgs = {
  appointmentId?: InputMaybe<Scalars['ID']>;
  patientId: Scalars['ID'];
};


export type QueryRecurringOccurrencesArgs = {
  count: Scalars['Int'];
  date: Scalars['DateTime'];
  recurrence: Scalars['Json'];
  timezone: Scalars['String'];
};


export type QueryStaffAppointmentsArgs = {
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  staffId: Scalars['ID'];
};


export type QueryStaffAppointmentsTypedArgs = {
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  staffId: Scalars['ID'];
};


export type QueryStaffCalendarConnectionsArgs = {
  businessId: Scalars['ID'];
};


export type QueryStaffIsAvailableArgs = {
  date: Scalars['DateTime'];
  duration: Scalars['Int'];
  ignoreAppointmentId?: InputMaybe<Scalars['ID']>;
  serviceId: Scalars['ID'];
  staffId: Scalars['ID'];
};


export type QueryStaffOAuthUrlsArgs = {
  businessId: Scalars['ID'];
  redirectUrl?: InputMaybe<Scalars['String']>;
};


export type QueryStaffTeamsOAuthUrlArgs = {
  businessId: Scalars['ID'];
  redirectUrl: Scalars['String'];
};


export type QueryStaffsByIdArgs = {
  staffsIds: Array<Scalars['ID']>;
};


export type QuerySynchronizationLogsArgs = {
  after?: InputMaybe<Scalars['ID']>;
  connectionId: Scalars['ID'];
  limit: Scalars['Int'];
};


export type QueryTeamsOAuthUrlArgs = {
  redirectUrl: Scalars['String'];
  resourceId: Scalars['String'];
  resourceType: Scalars['String'];
};


export type QueryThirdPartyConnectionsArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
  provider: ThirdPartyConnectionProvider;
  staffId?: InputMaybe<Scalars['ID']>;
};


export type QueryUserStaffArgs = {
  businessId: Scalars['ID'];
};


export type QueryWorkingHoursSlotsArgs = {
  from: Scalars['DateTime'];
  staffIds: Array<Scalars['ID']>;
  timeIncrement?: InputMaybe<Scalars['Int']>;
  to: Scalars['DateTime'];
};

export type RawData = {
  __typename?: 'RawData';
  data?: Maybe<Scalars['Json']>;
};

export type RecurrenceOccurrencesPayload = {
  __typename?: 'RecurrenceOccurrencesPayload';
  hasMore: Scalars['Boolean'];
  occurrences: Array<Scalars['String']>;
};

export type Reminder = Node & {
  __typename?: 'Reminder';
  appointment: Appointment;
  appointmentDate?: Maybe<Scalars['DateTime']>;
  business: Business;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  patient?: Maybe<Patient>;
  reminderTemplate: ReminderTemplate;
  sentAt?: Maybe<Scalars['DateTime']>;
  staff?: Maybe<Staff>;
  updatedAt: Scalars['DateTime'];
};


export type ReminderPatientArgs = {
  where?: InputMaybe<PatientWhereInput>;
};


export type ReminderReminderTemplateArgs = {
  where?: InputMaybe<ReminderTemplateWhereInput>;
};

export const ReminderResourceType = {
  Patient: 'PATIENT',
  Staff: 'STAFF'
} as const;

export type ReminderResourceType = typeof ReminderResourceType[keyof typeof ReminderResourceType];
export type ReminderTemplate = {
  __typename?: 'ReminderTemplate';
  business: Business;
  createdAt: Scalars['DateTime'];
  defaultTemplate?: Maybe<Scalars['Json']>;
  deleted?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  messageTemplate?: Maybe<Scalars['Json']>;
  minutes: Scalars['Int'];
  reminders: Array<Reminder>;
  resourceType: ReminderResourceType;
  services?: Maybe<Array<Service>>;
  type: ReminderType;
  updatedAt: Scalars['DateTime'];
};

export type ReminderTemplateWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export const ReminderType = {
  Email: 'EMAIL',
  Sms: 'SMS'
} as const;

export type ReminderType = typeof ReminderType[keyof typeof ReminderType];
export type RescheduleAppointment = {
  __typename?: 'RescheduleAppointment';
  addToGoogleUrl?: Maybe<Scalars['String']>;
  addToOutlookUrl?: Maybe<Scalars['String']>;
  answers?: Maybe<Scalars['JsonArray']>;
  approved?: Maybe<Scalars['Boolean']>;
  business?: Maybe<Scalars['Json']>;
  clientTimezone?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  hangoutLink?: Maybe<Scalars['String']>;
  icsFileContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  locationType?: Maybe<Scalars['String']>;
  locationValue?: Maybe<Scalars['String']>;
  patient?: Maybe<Scalars['Json']>;
  price?: Maybe<Scalars['Float']>;
  rrule?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['Json']>;
  staff?: Maybe<Scalars['Json']>;
};

export type RoleConfiguration = {
  __typename?: 'RoleConfiguration';
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  scopes: Array<Scalars['String']>;
};

export type SendRemindersPayload = {
  __typename?: 'SendRemindersPayload';
  success: Scalars['Boolean'];
};

export type Service = {
  __typename?: 'Service';
  adHocService?: Maybe<AdHocService>;
  additionalStaff?: Maybe<Array<Staff>>;
  alias?: Maybe<Scalars['String']>;
  allowGuests?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<Array<Appointment>>;
  appointmentsWithinRange?: Maybe<Scalars['JsonArray']>;
  availabilityLimitDays?: Maybe<Scalars['Int']>;
  bookingEndpoints?: Maybe<Array<BookingEndpoint>>;
  bookingPageUrl?: Maybe<Scalars['String']>;
  bookingPageVisibility?: Maybe<Scalars['String']>;
  bookingsPerTimeslot?: Maybe<Scalars['Int']>;
  bookingsRequireApproval?: Maybe<Scalars['Boolean']>;
  bufferAfterMinutes?: Maybe<Scalars['Int']>;
  bufferBeforeMinutes?: Maybe<Scalars['Int']>;
  business: Business;
  cancelLeadTime?: Maybe<Scalars['Int']>;
  categories?: Maybe<Array<ServiceCategory>>;
  currency: Scalars['String'];
  deleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['String']>;
  durations?: Maybe<Scalars['JsonArray']>;
  enabled?: Maybe<Scalars['Boolean']>;
  hideTimeslotsSeats?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  leadTime: Scalars['Int'];
  location?: Maybe<Location>;
  locationOverridesStaffs?: Maybe<Scalars['Boolean']>;
  locationType?: Maybe<Scalars['String']>;
  locationValue?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  overrideStaffVideoConferenceProvider?: Maybe<Scalars['Boolean']>;
  picture?: Maybe<UploadedFile>;
  recurrence?: Maybe<Scalars['Json']>;
  requiredAdditionalStaffCount?: Maybe<Scalars['Int']>;
  requiresStripePayment?: Maybe<Scalars['Boolean']>;
  staffs?: Maybe<Array<Staff>>;
  timeIncrement: Scalars['Int'];
  videoConferenceProvider?: Maybe<VideoConferenceProvider>;
  workingHours?: Maybe<Scalars['Json']>;
};


export type ServiceAppointmentsWithinRangeArgs = {
  dateEnd?: InputMaybe<Scalars['DateTime']>;
  dateStart?: InputMaybe<Scalars['DateTime']>;
};


export type ServiceBookingEndpointsArgs = {
  where?: InputMaybe<BookingEndpointWhereInput>;
};

export type ServiceCategory = {
  __typename?: 'ServiceCategory';
  business: Business;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SignedUploadParams = {
  __typename?: 'SignedUploadParams';
  headers: Scalars['Json'];
  url: Scalars['String'];
};

export const SignupStep = {
  EmailVerified: 'EMAIL_VERIFIED',
  PersonalDataProvided: 'PERSONAL_DATA_PROVIDED',
  SignedUp: 'SIGNED_UP',
  VentureDataProvided: 'VENTURE_DATA_PROVIDED'
} as const;

export type SignupStep = typeof SignupStep[keyof typeof SignupStep];
export const SortDirection = {
  Asc: 'ASC',
  Desc: 'DESC'
} as const;

export type SortDirection = typeof SortDirection[keyof typeof SortDirection];
export type Staff = {
  __typename?: 'Staff';
  activeServices?: Maybe<Array<Service>>;
  alias?: Maybe<Scalars['String']>;
  appointments?: Maybe<Array<Appointment>>;
  appointmentsCount?: Maybe<Scalars['Int']>;
  appointmentsWithinRange?: Maybe<Scalars['JsonArray']>;
  availableBoolFeatures: Array<Scalars['String']>;
  bookingEndpoints?: Maybe<Array<BookingEndpoint>>;
  bookingPageUrl?: Maybe<Scalars['String']>;
  business: Business;
  calendarConnections?: Maybe<Array<CalendarConnection>>;
  canGenerateHangoutLinks?: Maybe<Scalars['Boolean']>;
  canGenerateTeamsMeetings?: Maybe<Scalars['Boolean']>;
  canGenerateZoomMeetings?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<StaffCapacity>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  lastInvitedAt?: Maybe<Scalars['DateTime']>;
  locationType?: Maybe<Scalars['String']>;
  locationValue?: Maybe<Scalars['String']>;
  managedStaff?: Maybe<Array<Staff>>;
  marketingMeta?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  occupation?: Maybe<Scalars['String']>;
  onboardingCompletedAt?: Maybe<Scalars['DateTime']>;
  phoneMobile?: Maybe<Scalars['String']>;
  picture?: Maybe<UploadedFile>;
  resendInvite?: Maybe<Scalars['Boolean']>;
  roles: Array<StaffRole>;
  scopes: Array<Scalars['String']>;
  services?: Maybe<Array<Service>>;
  signature?: Maybe<Scalars['Json']>;
  status?: Maybe<StaffStatus>;
  stripeProductIds?: Maybe<Array<Scalars['String']>>;
  teamsConnection?: Maybe<ThirdPartyConnection>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  videoConferenceProvider?: Maybe<VideoConferenceProvider>;
  workingHours?: Maybe<Scalars['Json']>;
  zoomConnection?: Maybe<ThirdPartyConnection>;
};


export type StaffAppointmentsWithinRangeArgs = {
  dateEnd?: InputMaybe<Scalars['DateTime']>;
  dateStart?: InputMaybe<Scalars['DateTime']>;
};


export type StaffBookingEndpointsArgs = {
  where?: InputMaybe<BookingEndpointWhereInput>;
};


export type StaffCalendarConnectionsArgs = {
  where?: InputMaybe<CalendarConnectionWhereInput>;
};


export type StaffCapacityArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type StaffWorkingHoursArgs = {
  time?: InputMaybe<Scalars['DateTime']>;
};

export type StaffCapacity = {
  __typename?: 'StaffCapacity';
  availableBookableMinutes: Scalars['Int'];
  blockedTime: StaffCapacityBlockedTime;
  bookableMinutes: Scalars['Int'];
  bookedMinutes: Scalars['Int'];
};

export type StaffCapacityBlockedTime = {
  __typename?: 'StaffCapacityBlockedTime';
  externalEvents: Scalars['Int'];
  timeBlockers: Scalars['Int'];
};

export const StaffRole = {
  Admin: 'ADMIN',
  DataManager: 'DATA_MANAGER',
  Developer: 'DEVELOPER',
  Manager: 'MANAGER',
  Owner: 'OWNER',
  Staff: 'STAFF',
  Support: 'SUPPORT'
} as const;

export type StaffRole = typeof StaffRole[keyof typeof StaffRole];
export const StaffStatus = {
  Active: 'ACTIVE',
  Disabled: 'DISABLED',
  NotInvited: 'NOT_INVITED',
  PendingInvite: 'PENDING_INVITE'
} as const;

export type StaffStatus = typeof StaffStatus[keyof typeof StaffStatus];
export type StripeBillingPortalSession = {
  __typename?: 'StripeBillingPortalSession';
  configuration: Scalars['String'];
  customer: Scalars['String'];
  id: Scalars['String'];
  return_url: Scalars['String'];
  url: Scalars['String'];
};

export type StripeCheckoutSession = {
  __typename?: 'StripeCheckoutSession';
  id: Scalars['String'];
  url: Scalars['String'];
};

export type StripeConnectAccount = {
  __typename?: 'StripeConnectAccount';
  business: Business;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  livemode?: Maybe<Scalars['Boolean']>;
  onboardingCompletedAt?: Maybe<Scalars['DateTime']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripePublishableKey?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type StripeConnectAccountCreatePayload = {
  __typename?: 'StripeConnectAccountCreatePayload';
  session?: Maybe<StripeConnectAccountSession>;
  stripeConnectAccountId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type StripeConnectAccountSession = {
  __typename?: 'StripeConnectAccountSession';
  clientSecret: Scalars['String'];
  expiresAt: Scalars['Date'];
};

export const StripeIntentStatus = {
  Canceled: 'CANCELED',
  Processing: 'PROCESSING',
  RequiresAction: 'REQUIRES_ACTION',
  RequiresCapture: 'REQUIRES_CAPTURE',
  RequiresConfirmation: 'REQUIRES_CONFIRMATION',
  RequiresPaymentMethod: 'REQUIRES_PAYMENT_METHOD',
  Succeeded: 'SUCCEEDED'
} as const;

export type StripeIntentStatus = typeof StripeIntentStatus[keyof typeof StripeIntentStatus];
export const StripeInterval = {
  Month: 'MONTH',
  Year: 'YEAR'
} as const;

export type StripeInterval = typeof StripeInterval[keyof typeof StripeInterval];
export type StripePrice = {
  __typename?: 'StripePrice';
  currency: Scalars['String'];
  id: Scalars['ID'];
  interval: Scalars['String'];
  stripeId: Scalars['String'];
  unitAmount: Scalars['Int'];
};

export type StripeProduct = {
  __typename?: 'StripeProduct';
  createdAt: Scalars['DateTime'];
  defaultPriceId: Scalars['String'];
  description: Scalars['String'];
  featureList: Array<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  prices: Array<StripePrice>;
  updatedAt: Scalars['DateTime'];
};

export type StripeSetupIntent = {
  __typename?: 'StripeSetupIntent';
  clientSecret: Scalars['String'];
  customerId: Scalars['String'];
  id: Scalars['String'];
  nextAction?: Maybe<StripeSetupIntentNextAction>;
  status: StripeIntentStatus;
  usage: Scalars['String'];
};

export type StripeSetupIntentNextAction = {
  __typename?: 'StripeSetupIntentNextAction';
  redirectToUrl?: Maybe<StripeSetupIntentNextActionRedirectToUrl>;
  type: Scalars['String'];
  useStripeSDK?: Maybe<Scalars['Boolean']>;
};

export type StripeSetupIntentNextActionRedirectToUrl = {
  __typename?: 'StripeSetupIntentNextActionRedirectToUrl';
  returnUrl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export const SubscriptionStatus = {
  Active: 'ACTIVE',
  Canceled: 'CANCELED',
  Incomplete: 'INCOMPLETE',
  IncompleteExpired: 'INCOMPLETE_EXPIRED',
  PastDue: 'PAST_DUE',
  Paused: 'PAUSED',
  Trialing: 'TRIALING',
  Unpaid: 'UNPAID'
} as const;

export type SubscriptionStatus = typeof SubscriptionStatus[keyof typeof SubscriptionStatus];
export type SupportIdentificationToken = {
  __typename?: 'SupportIdentificationToken';
  expiresAt?: Maybe<Scalars['Int']>;
  token: Scalars['String'];
};

export type SyncStripeCustomerPayload = {
  __typename?: 'SyncStripeCustomerPayload';
  business: Business;
};

export type SynchronizationLog = {
  __typename?: 'SynchronizationLog';
  calendarConnection: CalendarConnection;
  changes?: Maybe<Scalars['JsonArray']>;
  createdAt: Scalars['DateTime'];
  executionTime?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  type: SynchronizationLogType;
  updatedAt: Scalars['DateTime'];
};

export const SynchronizationLogType = {
  Export: 'EXPORT',
  Import: 'IMPORT'
} as const;

export type SynchronizationLogType = typeof SynchronizationLogType[keyof typeof SynchronizationLogType];
export type SynchronizationLogsMetaPayload = {
  __typename?: 'SynchronizationLogsMetaPayload';
  count?: Maybe<Scalars['Int']>;
};

export type Tag = {
  __typename?: 'Tag';
  alias: Scalars['String'];
  color: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export const TaxBehaviour = {
  Disabled: 'DISABLED',
  Exclusive: 'EXCLUSIVE',
  Inclusive: 'INCLUSIVE'
} as const;

export type TaxBehaviour = typeof TaxBehaviour[keyof typeof TaxBehaviour];
export type ThirdPartyConnection = {
  __typename?: 'ThirdPartyConnection';
  business: Business;
  email: Scalars['String'];
  id: Scalars['ID'];
  isAuthorized?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pictureUrl: Scalars['String'];
  provider: Scalars['String'];
  staff?: Maybe<Staff>;
};

export type ThirdPartyConnectionOAuthSession = {
  __typename?: 'ThirdPartyConnectionOAuthSession';
  redirectUrl: Scalars['String'];
};

export const ThirdPartyConnectionProvider = {
  Teams: 'TEAMS',
  Zoom: 'ZOOM'
} as const;

export type ThirdPartyConnectionProvider = typeof ThirdPartyConnectionProvider[keyof typeof ThirdPartyConnectionProvider];
export const ThirdPartyConnectionResourceType = {
  Business: 'BUSINESS',
  Staff: 'STAFF'
} as const;

export type ThirdPartyConnectionResourceType = typeof ThirdPartyConnectionResourceType[keyof typeof ThirdPartyConnectionResourceType];
export type TimeBlocker = Node & {
  __typename?: 'TimeBlocker';
  business?: Maybe<Business>;
  createdAt: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  staff?: Maybe<Staff>;
  startDate: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TokenPayload = {
  __typename?: 'TokenPayload';
  expiresAt?: Maybe<Scalars['DateTime']>;
  isValid: Scalars['Boolean'];
  userId?: Maybe<Scalars['ID']>;
};

export type UpdateSeatsResponse = {
  __typename?: 'UpdateSeatsResponse';
  business: Business;
  checkoutSession?: Maybe<StripeCheckoutSession>;
};

export type UploadedFile = Node & {
  __typename?: 'UploadedFile';
  business?: Maybe<Business>;
  contentType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  secret: Scalars['String'];
  size: Scalars['Int'];
  staffPicture?: Maybe<Staff>;
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  user?: Maybe<User>;
};


export type UploadedFileUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type User = {
  __typename?: 'User';
  /** @deprecated Use availableFeatures resolver directly */
  availableFeatures?: Maybe<Scalars['Json']>;
  businessesCount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  ownedBusinessesCount: Scalars['Int'];
  role: UserRole;
  staffs: Array<UserStaff>;
  updatedAt: Scalars['DateTime'];
};

export const UserRole = {
  Superadmin: 'SUPERADMIN',
  SupportAgent: 'SUPPORT_AGENT',
  User: 'USER'
} as const;

export type UserRole = typeof UserRole[keyof typeof UserRole];
export type UserStaff = {
  __typename?: 'UserStaff';
  alias?: Maybe<Scalars['String']>;
  business: UserStaffBusiness;
  email: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  onboardingCompletedAt?: Maybe<Scalars['DateTime']>;
  picture?: Maybe<Picture>;
  roles: Array<StaffRole>;
};

export type UserStaffBusiness = {
  __typename?: 'UserStaffBusiness';
  alias: Scalars['String'];
  availableFeatures?: Maybe<Scalars['Json']>;
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  logo?: Maybe<Picture>;
  name: Scalars['String'];
  onboardingCompletedAt?: Maybe<Scalars['DateTime']>;
  remainingTrialDays?: Maybe<Scalars['Int']>;
  servicesCount: Scalars['Int'];
  /** @deprecated Use roles field on Staff */
  staffRoles: Array<StaffRole>;
  /** @deprecated No longer needed to pre condition subscription status */
  stripeData?: Maybe<Scalars['Json']>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
};

export type UserSynchronization = {
  __typename?: 'UserSynchronization';
  changes?: Maybe<Scalars['JsonArray']>;
  createdAt: Scalars['DateTime'];
  direction: Scalars['String'];
  errors?: Maybe<Scalars['JsonArray']>;
  id: Scalars['ID'];
  inboundGoogleError?: Maybe<Scalars['Json']>;
  inboundGoogleHasChanges?: Maybe<Scalars['Boolean']>;
  inboundGoogleLog?: Maybe<Scalars['Json']>;
  inboundOutlookError?: Maybe<Scalars['Json']>;
  inboundOutlookHasChanges?: Maybe<Scalars['Boolean']>;
  inboundOutlookLog?: Maybe<Scalars['Json']>;
  service?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  user: User;
};

export type UserWhereInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export const VideoConferenceProvider = {
  GoogleMeet: 'GOOGLE_MEET',
  MicrosoftTeams: 'MICROSOFT_TEAMS',
  None: 'NONE',
  UpvioTelehealth: 'UPVIO_TELEHEALTH',
  Zoom: 'ZOOM'
} as const;

export type VideoConferenceProvider = typeof VideoConferenceProvider[keyof typeof VideoConferenceProvider];
export type WorkingHoursSchedule = {
  __typename?: 'WorkingHoursSchedule';
  from: Scalars['DateTime'];
  id: Scalars['ID'];
  staff?: Maybe<Staff>;
  to?: Maybe<Scalars['DateTime']>;
  workingHours?: Maybe<Scalars['Json']>;
  workingHoursUTC?: Maybe<Scalars['Json']>;
};

export type WorkingHoursSlots = {
  __typename?: 'WorkingHoursSlots';
  slots: Array<Array<Scalars['DateTime']>>;
  staffSlots?: Maybe<Scalars['Json']>;
};

export type CreateAdHocAppointmentMutationVariables = Exact<{
  title: Scalars['String'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  staffId: Scalars['ID'];
  patientId: Scalars['ID'];
  locationType?: InputMaybe<Scalars['String']>;
  locationValue?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['ID']>;
}>;


export type CreateAdHocAppointmentMutation = { __typename?: 'Mutation', createAdHocAppointment: { __typename?: 'Appointment', id: string } };

export type CreateAdHocServiceMutationVariables = Exact<{
  businessId: Scalars['ID'];
  staffId: Scalars['ID'];
  timeslots: Scalars['Json'];
  timeIncrement: Scalars['Int'];
  name: Scalars['String'];
  duration: Scalars['Int'];
  maxAppointments: Scalars['Int'];
  locationId?: InputMaybe<Scalars['ID']>;
  locationType?: InputMaybe<Scalars['String']>;
  locationValue?: InputMaybe<Scalars['String']>;
}>;


export type CreateAdHocServiceMutation = { __typename?: 'Mutation', adHocService: { __typename?: 'AdHocService', id: string } };

export type AllApiKeysQueryVariables = Exact<{ [key: string]: never; }>;


export type AllApiKeysQuery = { __typename?: 'Query', allApiKeys: Array<{ __typename?: 'ApiKey', id: string, value?: string | null, description: string, createdAt: any, updatedAt: any, expiresAt?: any | null, invalidatedAt?: any | null }> };

export type AllBusinessPatientsQueryVariables = Exact<{
  businessId: Scalars['ID'];
  limit: Scalars['Int'];
  skip?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<PatientsOrderByInput>;
}>;


export type AllBusinessPatientsQuery = { __typename?: 'Query', count: number, patients: Array<{ __typename?: 'Patient', id: string, name: string, preferredName?: string | null, email: string, phoneMobile?: string | null, patientType?: PatientType | null, dob?: any | null, age?: number | null, gender?: string | null }> };

export type AnalyticsSmsQueryVariables = Exact<{
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
}>;


export type AnalyticsSmsQuery = { __typename?: 'Query', analytics?: { __typename?: 'Analytics', sms: Array<{ __typename?: 'AnalyticsSMSEntry', date: any, sent: number, delivered: number, credits: number }> } | null };

export type AnalyticsAppointmentsQueryVariables = Exact<{
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  staffId?: InputMaybe<Scalars['ID']>;
}>;


export type AnalyticsAppointmentsQuery = { __typename?: 'Query', analytics?: { __typename?: 'Analytics', appointments: Array<{ __typename?: 'AnalyticsAppointmentEntry', date: any, created: number, cancelled: number }> } | null };

export type AnalyticsEventsQueryVariables = Exact<{
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
}>;


export type AnalyticsEventsQuery = { __typename?: 'Query', analytics?: { __typename?: 'Analytics', events: Array<{ __typename?: 'AnalyticsEventEntry', date: any, all: number, appointmentCreated: number, appointmentUpdated: number, appointmentDeleted: number, businessUpdated: number, formCreated: number, formUpdated: number, formDeleted: number, patientCreated: number, patientUpdated: number, patientDeleted: number, serviceCreated: number, serviceUpdated: number, serviceDeleted: number, staffCreated: number, staffUpdated: number, staffDeleted: number }> } | null };

export type AppointmentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AppointmentQuery = { __typename?: 'Query', appointment?: { __typename?: 'Appointment', id: string, date: any, duration: number, approved?: boolean | null, locationType?: string | null, locationValue?: string | null, currency?: string | null, price?: number | null, answers?: any | null, paymentProvider?: string | null, rrule?: string | null, originalDate?: any | null, transactionUrl?: string | null, patientVerified?: boolean | null, staff: { __typename?: 'Staff', id: string, name?: string | null, email: string }, patient: { __typename?: 'Patient', id: string, name: string, email: string, phoneMobile?: string | null }, service: { __typename?: 'Service', id: string, name: string, description?: string | null, durations?: any | null }, location?: { __typename?: 'Location', id: string, name: string } | null } | null };

export type ApproveAppointmentMutationVariables = Exact<{
  id: Scalars['ID'];
  ignoreBusyTimes?: InputMaybe<Scalars['Boolean']>;
}>;


export type ApproveAppointmentMutation = { __typename?: 'Mutation', approveAppointment?: { __typename?: 'Appointment', id: string, approved?: boolean | null } | null };

export type CancelAppointmentMutationVariables = Exact<{
  id: Scalars['ID'];
  futureOccurrences?: InputMaybe<Scalars['Boolean']>;
  occurrenceDate?: InputMaybe<Scalars['DateTime']>;
}>;


export type CancelAppointmentMutation = { __typename?: 'Mutation', deleteAppointment?: { __typename?: 'Appointment', id: string, cancelled?: boolean | null } | null };

export type CancelGroupBookingMutationVariables = Exact<{
  groupBookingId: Scalars['String'];
}>;


export type CancelGroupBookingMutation = { __typename?: 'Mutation', cancelGroupBooking: Array<{ __typename?: 'Appointment', id: string }> };

export type CreateAppointmentMutationVariables = Exact<{
  date: Scalars['DateTime'];
  duration: Scalars['Int'];
  staffId: Scalars['ID'];
  patientId: Scalars['ID'];
  serviceId: Scalars['ID'];
  requestUrl?: InputMaybe<Scalars['String']>;
  ignoreBusyTimes?: InputMaybe<Scalars['Boolean']>;
}>;


export type CreateAppointmentMutation = { __typename?: 'Mutation', createAppointment?: { __typename?: 'Appointment', id: string, date: any, price?: number | null, currency?: string | null, duration: number, rrule?: string | null, originalDate?: any | null, requestUrl?: string | null, business: { __typename?: 'Business', id: string }, staff: { __typename?: 'Staff', id: string }, patient: { __typename?: 'Patient', id: string }, service: { __typename?: 'Service', id: string } } | null };

export type GroupBookingAppointmentsQueryVariables = Exact<{
  groupBookingId: Scalars['ID'];
}>;


export type GroupBookingAppointmentsQuery = { __typename?: 'Query', appointments?: Array<{ __typename?: 'Appointment', id: string, date: any, duration: number, approved?: boolean | null, cancelled?: boolean | null, staff: { __typename?: 'Staff', id: string, name?: string | null, email: string }, patient: { __typename?: 'Patient', id: string, name: string, email: string, phoneMobile?: string | null }, service: { __typename?: 'Service', id: string, name: string } }> | null };

export type UpdateAppointmentMutationVariables = Exact<{
  id: Scalars['ID'];
  date: Scalars['DateTime'];
  duration?: InputMaybe<Scalars['Int']>;
  staffId?: InputMaybe<Scalars['ID']>;
  patientId?: InputMaybe<Scalars['ID']>;
  serviceId?: InputMaybe<Scalars['ID']>;
  singleOccurrence?: InputMaybe<Scalars['Boolean']>;
  originalDate?: InputMaybe<Scalars['DateTime']>;
  futureOccurrences?: InputMaybe<Scalars['Boolean']>;
  ignoreBusyTimes?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateAppointmentMutation = { __typename?: 'Mutation', updateAppointment?: { __typename?: 'Appointment', id: string, date: any, price?: number | null, currency?: string | null, duration: number, rrule?: string | null, originalDate?: any | null, business: { __typename?: 'Business', id: string }, staff: { __typename?: 'Staff', id: string }, patient: { __typename?: 'Patient', id: string }, service: { __typename?: 'Service', id: string } } | null };

export type UpdateGroupBookingMutationVariables = Exact<{
  groupBookingId: Scalars['String'];
  date: Scalars['DateTime'];
}>;


export type UpdateGroupBookingMutation = { __typename?: 'Mutation', updateGroupBooking: Array<{ __typename?: 'Appointment', id: string, date: any, groupBookingId?: string | null }> };

export type AssignStaffStripeProductIdsMutationVariables = Exact<{
  staffId: Scalars['ID'];
  productIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type AssignStaffStripeProductIdsMutation = { __typename?: 'Mutation', assignStaffStripeProductIds: { __typename?: 'Staff', id: string, stripeProductIds?: Array<string> | null, availableBoolFeatures: Array<string>, business: { __typename?: 'Business', id: string, stripeCustomerId?: string | null, stripeSubscriptionId?: string | null, stripeData?: any | null } } };

export type AuthenticateUserMutationVariables = Exact<{
  auth0Code: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  signupKey?: InputMaybe<Scalars['String']>;
  abTestVariant?: InputMaybe<Scalars['String']>;
}>;


export type AuthenticateUserMutation = { __typename?: 'Mutation', authenticateUser: { __typename?: 'AuthPayload', id: string, token: string, userIsNew: boolean, forcedSessionId?: string | null, redirectUrl?: string | null, user: { __typename?: 'AuthUserPayload', id: string } } };

export type FieldsFragment = { __typename?: 'Business', id: string, alias: string, name: string, bookingPageUrl?: string | null, timezone?: string | null, website?: string | null, staffRoles: Array<StaffRole>, availableFeatures?: any | null, enabled?: boolean | null, dateFormat?: any | null, usage: any, marketingMeta?: any | null, onboardingCompletedAt?: any | null, primaryDomain?: string | null, primaryDomainVerifiedAt?: any | null, stripeCustomerId?: string | null, stripeSubscriptionId?: string | null, stripeData?: any | null, remainingTrialDays?: number | null, subscriptionStatus?: SubscriptionStatus | null, invoicesTaxBehaviour?: TaxBehaviour | null, logo?: { __typename?: 'Picture', id: string, url: string } | null, zoomConnection?: { __typename?: 'ThirdPartyConnection', id: string } | null, teamsConnection?: { __typename?: 'ThirdPartyConnection', id: string } | null, bookingForm: { __typename?: 'Form', id: string }, reschedulingForm: { __typename?: 'Form', id: string }, stripeConnectAccount?: { __typename?: 'StripeConnectAccount', id: string, stripeAccountId?: string | null, onboardingCompletedAt?: any | null } | null };

export type BusinessQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BusinessQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, alias: string, name: string, bookingPageUrl?: string | null, timezone?: string | null, website?: string | null, staffRoles: Array<StaffRole>, availableFeatures?: any | null, enabled?: boolean | null, dateFormat?: any | null, usage: any, marketingMeta?: any | null, onboardingCompletedAt?: any | null, primaryDomain?: string | null, primaryDomainVerifiedAt?: any | null, stripeCustomerId?: string | null, stripeSubscriptionId?: string | null, stripeData?: any | null, remainingTrialDays?: number | null, subscriptionStatus?: SubscriptionStatus | null, invoicesTaxBehaviour?: TaxBehaviour | null, logo?: { __typename?: 'Picture', id: string, url: string } | null, zoomConnection?: { __typename?: 'ThirdPartyConnection', id: string } | null, teamsConnection?: { __typename?: 'ThirdPartyConnection', id: string } | null, bookingForm: { __typename?: 'Form', id: string }, reschedulingForm: { __typename?: 'Form', id: string }, stripeConnectAccount?: { __typename?: 'StripeConnectAccount', id: string, stripeAccountId?: string | null, onboardingCompletedAt?: any | null } | null } | null };

export type CurrentBusinessQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type CurrentBusinessQuery = { __typename?: 'Query', currentBusiness: { __typename?: 'Business', id: string, alias: string, name: string, bookingPageUrl?: string | null, timezone?: string | null, website?: string | null, staffRoles: Array<StaffRole>, availableFeatures?: any | null, enabled?: boolean | null, dateFormat?: any | null, usage: any, marketingMeta?: any | null, onboardingCompletedAt?: any | null, primaryDomain?: string | null, primaryDomainVerifiedAt?: any | null, stripeCustomerId?: string | null, stripeSubscriptionId?: string | null, stripeData?: any | null, remainingTrialDays?: number | null, subscriptionStatus?: SubscriptionStatus | null, invoicesTaxBehaviour?: TaxBehaviour | null, logo?: { __typename?: 'Picture', id: string, url: string } | null, zoomConnection?: { __typename?: 'ThirdPartyConnection', id: string } | null, teamsConnection?: { __typename?: 'ThirdPartyConnection', id: string } | null, bookingForm: { __typename?: 'Form', id: string }, reschedulingForm: { __typename?: 'Form', id: string }, stripeConnectAccount?: { __typename?: 'StripeConnectAccount', id: string, stripeAccountId?: string | null, onboardingCompletedAt?: any | null } | null } };

export type BusinessSettingsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BusinessSettingsQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, alias: string, name: string, email?: string | null, website?: string | null, timezone?: string | null, currency?: string | null, dateFormat?: any | null, physicalAddress?: string | null, bufferBeforeMinutes?: number | null, bufferAfterMinutes?: number | null, allowStaffApprovals?: boolean | null, availabilityLimitDays?: number | null, availableFeatures?: any | null, workingHours: any, ownerNotificationsRecipient?: OwnerNotificationsRecipient | null, ownerNotificationsCC?: string | null, bookingNotificationsReplyTo?: NotificationsReplyToType | null, attachICSFiles?: boolean | null, sendSMSFromStaffNumber?: boolean | null, color?: string | null, logoPosition?: string | null, allowChoosingStaff?: boolean | null, allowNoStaffPreference?: boolean | null, marketingMeta?: any | null, defaultInvoiceDaysUntilDue?: number | null, autoGenerateBookingInvoices?: boolean | null, bookingsRequireApprovalUntilPaid?: boolean | null, invoicesTaxBehaviour?: TaxBehaviour | null, logo?: { __typename?: 'Picture', id: string, url: string } | null } | null };

export type UpdateBusinessSettingsMutationVariables = Exact<{
  id: Scalars['ID'];
  enabled?: InputMaybe<Scalars['Boolean']>;
  alias?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  logoId?: InputMaybe<Scalars['ID']>;
  website?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  physicalAddress?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  workingHours?: InputMaybe<Scalars['Json']>;
  dateFormat?: InputMaybe<Scalars['Json']>;
  bufferBeforeMinutes?: InputMaybe<Scalars['Int']>;
  bufferAfterMinutes?: InputMaybe<Scalars['Int']>;
  redirectAfterBookingUrl?: InputMaybe<Scalars['String']>;
  postOnRedirect?: InputMaybe<Scalars['Boolean']>;
  includeAnswersOnRedirect?: InputMaybe<Scalars['Boolean']>;
  googleTrackingId?: InputMaybe<Scalars['String']>;
  trackingPixelUrl?: InputMaybe<Scalars['String']>;
  availabilityLimitDays?: InputMaybe<Scalars['Int']>;
  allowStaffApprovals?: InputMaybe<Scalars['Boolean']>;
  logoPosition?: InputMaybe<Scalars['String']>;
  allowTimeRequests?: InputMaybe<Scalars['Boolean']>;
  timeRequestButtonLabel?: InputMaybe<Scalars['String']>;
  timeRequestText?: InputMaybe<Scalars['String']>;
  hidePrivacyButton?: InputMaybe<Scalars['Boolean']>;
  allowChoosingStaff?: InputMaybe<Scalars['Boolean']>;
  allowNoStaffPreference?: InputMaybe<Scalars['Boolean']>;
  exportedEventsTemplate?: InputMaybe<Scalars['Json']>;
  marketingMeta?: InputMaybe<Scalars['Json']>;
  onboardingCompleted?: InputMaybe<Scalars['Boolean']>;
  defaultInvoiceDaysUntilDue?: InputMaybe<Scalars['Int']>;
  autoGenerateBookingInvoices?: InputMaybe<Scalars['Boolean']>;
  bookingsRequireApprovalUntilPaid?: InputMaybe<Scalars['Boolean']>;
  invoicesTaxBehaviour?: InputMaybe<TaxBehaviour>;
}>;


export type UpdateBusinessSettingsMutation = { __typename?: 'Mutation', updateBusiness?: { __typename?: 'Business', id: string, enabled?: boolean | null, alias: string, name: string, email?: string | null, website?: string | null, currency?: string | null, physicalAddress?: string | null, timezone?: string | null, color?: string | null, logoPosition?: string | null, workingHours: any, availableFeatures?: any | null, dateFormat?: any | null, bufferBeforeMinutes?: number | null, bufferAfterMinutes?: number | null, redirectAfterBookingUrl?: string | null, postOnRedirect?: boolean | null, includeAnswersOnRedirect?: boolean | null, googleTrackingId?: string | null, trackingPixelUrl?: string | null, availabilityLimitDays?: number | null, allowStaffApprovals?: boolean | null, allowTimeRequests?: boolean | null, timeRequestButtonLabel?: string | null, timeRequestText?: string | null, hidePrivacyButton?: boolean | null, allowChoosingStaff?: boolean | null, allowNoStaffPreference?: boolean | null, exportedEventsTemplate?: any | null, marketingMeta?: any | null, onboardingCompletedAt?: any | null, defaultInvoiceDaysUntilDue?: number | null, autoGenerateBookingInvoices?: boolean | null, bookingsRequireApprovalUntilPaid?: boolean | null, invoicesTaxBehaviour?: TaxBehaviour | null, logo?: { __typename?: 'Picture', id: string, url: string } | null } | null };

export type BusinessRoleConfigurationsQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type BusinessRoleConfigurationsQuery = { __typename?: 'Query', businessRoleConfigurations: Array<{ __typename?: 'RoleConfiguration', id: string, name: string, description: string, scopes: Array<string> }> };

export type CalendarConnectionsQueryQueryVariables = Exact<{
  businessId: Scalars['ID'];
  redirectUrl: Scalars['String'];
}>;


export type CalendarConnectionsQueryQuery = { __typename?: 'Query', businessCalendarConnections: Array<{ __typename?: 'CalendarConnection', id: string, provider: CalendarConnectionProvider, accountId?: string | null, enabled?: boolean | null, isAuthorized: boolean, lastSyncedAt?: any | null, importFromCalendarsIds: Array<string>, calendars?: any | null, exportToCalendarId?: string | null, exportAttendees?: Array<CalendarEventAttendee> | null }>, personalCalendarConnections: Array<{ __typename?: 'CalendarConnection', id: string, provider: CalendarConnectionProvider, accountId?: string | null, enabled?: boolean | null, isAuthorized: boolean, lastSyncedAt?: any | null, importFromCalendarsIds: Array<string>, calendars?: any | null, exportToCalendarId?: string | null, exportAttendees?: Array<CalendarEventAttendee> | null }>, businessOAuthUrls?: { __typename?: 'OAuthUrlsPayload', google?: string | null, microsoft?: string | null } | null, personalOAuthUrls?: { __typename?: 'OAuthUrlsPayload', google?: string | null, microsoft?: string | null } | null };

export type CalendarSyncSettingsQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type CalendarSyncSettingsQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, exportedEventsTemplate?: any | null } | null };

export type DisconnectCalendarConnectionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DisconnectCalendarConnectionMutation = { __typename?: 'Mutation', disconnectCalendarConnection: string };

export type UpdateCalendarConnectionMutationVariables = Exact<{
  id: Scalars['ID'];
  importFromCalendarsIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  exportToCalendarId?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  allDayEventsTimezone?: InputMaybe<Scalars['String']>;
  exportAttendees?: InputMaybe<Array<CalendarEventAttendee> | CalendarEventAttendee>;
}>;


export type UpdateCalendarConnectionMutation = { __typename?: 'Mutation', updateCalendarConnection?: { __typename?: 'CalendarConnection', id: string, importFromCalendarsIds: Array<string>, exportToCalendarId?: string | null, calendars?: any | null, enabled?: boolean | null, allDayEventsTimezone?: string | null, exportAttendees?: Array<CalendarEventAttendee> | null } | null };

export type CalendarAppointmentsQueryVariables = Exact<{
  dateStart: Scalars['DateTime'];
  dateEnd: Scalars['DateTime'];
  staffsIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  patientId?: InputMaybe<Scalars['ID']>;
  locationType?: InputMaybe<LocationType>;
}>;


export type CalendarAppointmentsQuery = { __typename?: 'Query', appointments: Array<{ __typename?: 'Appointment', id: string, approved?: boolean | null, cancelled?: boolean | null, date: any, duration: number, price?: number | null, currency?: string | null, groupBookingId?: string | null, locationType?: string | null, locationValue?: string | null, rrule?: string | null, originalDate?: any | null, recurringAppointmentId?: string | null, staff: { __typename?: 'Staff', id: string, name?: string | null }, patient: { __typename?: 'Patient', id: string, name: string }, service: { __typename?: 'Service', id: string, name: string } }> };

export type DeleteTimeBlockerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTimeBlockerMutation = { __typename?: 'Mutation', deleteTimeBlocker?: { __typename?: 'TimeBlocker', id: string } | null };

export type CalendarExternalEventsQueryVariables = Exact<{
  dateStart: Scalars['DateTime'];
  dateEnd: Scalars['DateTime'];
  staffsIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CalendarExternalEventsQuery = { __typename?: 'Query', externalEvents: Array<{ __typename?: 'CalendarViewExternalEventPayload', id: string, startDate: any, endDate: any, title?: string | null, staffId?: string | null }> };

export type CalendarResourcesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  time: Scalars['DateTime'];
  businessId: Scalars['ID'];
  filterIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CalendarResourcesQuery = { __typename?: 'Query', staffs: Array<{ __typename?: 'Staff', id: string, name?: string | null, workingHours?: any | null, picture?: { __typename?: 'UploadedFile', id: string, url: string } | null }>, business?: { __typename?: 'Business', id: string, workingHours: any } | null };

export type CouponsQueryVariables = Exact<{ [key: string]: never; }>;


export type CouponsQuery = { __typename?: 'Query', coupons: Array<{ __typename?: 'Coupon', id: string, createdAt: any, updatedAt: any, name: string, code: string, type: string, amountOff?: number | null, percentOff?: number | null, currency?: string | null, expiresAt?: any | null, redemptionsCount: number }> };

export type CreateCouponMutationVariables = Exact<{
  code: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
  amountOff?: InputMaybe<Scalars['Int']>;
  percentOff?: InputMaybe<Scalars['Float']>;
  currency?: InputMaybe<Scalars['String']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
}>;


export type CreateCouponMutation = { __typename?: 'Mutation', createCoupon: { __typename?: 'Coupon', id: string, createdAt: any, updatedAt: any, name: string, code: string, type: string, amountOff?: number | null, percentOff?: number | null, currency?: string | null, expiresAt?: any | null, redemptionsCount: number } };

export type DeleteCouponMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCouponMutation = { __typename?: 'Mutation', deleteCoupon: boolean };

export type UpdateCouponMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type UpdateCouponMutation = { __typename?: 'Mutation', updateCoupon: { __typename?: 'Coupon', id: string, name: string } };

export type CreateApiKeyMutationVariables = Exact<{
  description: Scalars['String'];
}>;


export type CreateApiKeyMutation = { __typename?: 'Mutation', createApiKey: { __typename?: 'ApiKey', id: string, value?: string | null, description: string, createdAt: any, updatedAt: any, expiresAt?: any | null } };

export type CreateBusinessMutationVariables = Exact<{
  name: Scalars['String'];
  alias: Scalars['String'];
  timezone: Scalars['String'];
}>;


export type CreateBusinessMutation = { __typename?: 'Mutation', createBusiness: { __typename?: 'Business', id: string, alias: string, name: string, timezone?: string | null } };

export type CreateStripeBillingPortalSessionMutationVariables = Exact<{
  flow: Scalars['String'];
}>;


export type CreateStripeBillingPortalSessionMutation = { __typename?: 'Mutation', createStripeBillingPortalSession: { __typename?: 'StripeBillingPortalSession', url: string } };

export type CreateStripeCheckoutSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateStripeCheckoutSessionMutation = { __typename?: 'Mutation', createStripeCheckoutSession: { __typename?: 'StripeCheckoutSession', url: string } };

export type CreateStripeSetupIntentMutationVariables = Exact<{
  productQuantities: Scalars['Json'];
  interval: StripeInterval;
  confirmationToken?: InputMaybe<Scalars['String']>;
}>;


export type CreateStripeSetupIntentMutation = { __typename?: 'Mutation', createStripeSetupIntent: { __typename?: 'StripeSetupIntent', id: string, customerId: string, clientSecret: string, status: StripeIntentStatus, usage: string, nextAction?: { __typename?: 'StripeSetupIntentNextAction', type: string, useStripeSDK?: boolean | null, redirectToUrl?: { __typename?: 'StripeSetupIntentNextActionRedirectToUrl', returnUrl?: string | null, url?: string | null } | null } | null } };

export type CurrentBusinessUsageQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type CurrentBusinessUsageQuery = { __typename?: 'Query', currentBusiness: { __typename?: 'Business', id: string, usage: any } };

export type CurrentStaffQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type CurrentStaffQuery = { __typename?: 'Query', currentStaff: { __typename?: 'Staff', id: string, alias?: string | null, enabled?: boolean | null, availableBoolFeatures: Array<string>, status?: StaffStatus | null, name?: string | null, occupation?: string | null, marketingMeta?: any | null, roles: Array<StaffRole>, description?: string | null, email: string, phoneMobile?: string | null, timezone?: string | null, bookingPageUrl?: string | null, stripeProductIds?: Array<string> | null, onboardingCompletedAt?: any | null, business: { __typename?: 'Business', id: string, alias: string }, managedStaff?: Array<{ __typename?: 'Staff', id: string, name?: string | null }> | null, picture?: { __typename?: 'UploadedFile', id: string, url: string } | null, zoomConnection?: { __typename?: 'ThirdPartyConnection', id: string } | null, teamsConnection?: { __typename?: 'ThirdPartyConnection', id: string } | null } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', id: string, name?: string | null, email: string, role: UserRole, businessesCount: number, ownedBusinessesCount: number, availableFeatures?: any | null, createdAt: any, updatedAt: any, staffs: Array<{ __typename?: 'UserStaff', id: string, onboardingCompletedAt?: any | null, business: { __typename?: 'UserStaffBusiness', id: string, name: string, alias: string, enabled: boolean, servicesCount: number, staffRoles: Array<StaffRole>, onboardingCompletedAt?: any | null, subscriptionStatus?: SubscriptionStatus | null, availableFeatures?: any | null, stripeData?: any | null, remainingTrialDays?: number | null } }> } | null };

export type AllCustomFieldsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllCustomFieldsQuery = { __typename?: 'Query', allCustomFields: Array<{ __typename?: 'CustomField', id: string, alias: string, name: string, description: string, fieldType: CustomFieldType, allowedValues?: Array<string> | null, createdAt: any, updatedAt: any }> };

export type CreateCustomFieldMutationVariables = Exact<{
  alias: Scalars['String'];
  name: Scalars['String'];
  fieldType: CustomFieldType;
  description: Scalars['String'];
  allowedValues?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type CreateCustomFieldMutation = { __typename?: 'Mutation', createCustomField: { __typename?: 'CustomField', id: string, alias: string, name: string, description: string, fieldType: CustomFieldType, allowedValues?: Array<string> | null } };

export type UpdateCustomFieldMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  allowedValues?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type UpdateCustomFieldMutation = { __typename?: 'Mutation', updateCustomField: { __typename?: 'CustomField', id: string, name: string, description: string, allowedValues?: Array<string> | null } };

export type SetPatientCustomFieldValueMutationVariables = Exact<{
  patientId: Scalars['ID'];
  fieldKey: Scalars['String'];
  value: Scalars['String'];
}>;


export type SetPatientCustomFieldValueMutation = { __typename?: 'Mutation', setPatientCustomFieldValue: { __typename?: 'CustomFieldValue', id: string, patientId: string, fieldKey: string, value: string } };

export type CreatePatientMutationVariables = Exact<{
  businessId: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  primaryStaffId?: InputMaybe<Scalars['ID']>;
  phoneMobile?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['String']>;
}>;


export type CreatePatientMutation = { __typename?: 'Mutation', createPatient?: { __typename?: 'Patient', id: string, name: string, email: string, phoneMobile?: string | null, dob?: any | null, age?: number | null, primaryStaff?: { __typename?: 'Staff', id: string, name?: string | null, picture?: { __typename?: 'UploadedFile', id: string, url: string } | null } | null } | null };

export type PatientQueryVariables = Exact<{
  patientId: Scalars['ID'];
}>;


export type PatientQuery = { __typename?: 'Query', patient: { __typename?: 'Patient', id: string, name: string, preferredName?: string | null, email: string, phoneMobile?: string | null, dob?: any | null, age?: number | null, gender?: string | null, biologicalSex?: BiologicalSex | null, patientType?: PatientType | null, height?: number | null, weight?: number | null, selfDeclaredDiabeticType?: DiabeticType | null, selfDeclaredHypertension?: boolean | null, selfDeclaredIsSmoker?: boolean | null, stripeCustomerId?: string | null, tagIds: Array<string>, billingAddress?: { __typename?: 'Address', id: string, city: string, country: string, line1: string, line2?: string | null, name: string, postalCode: string, state: string } | null, customFieldValues: Array<{ __typename?: 'CustomFieldValue', id: string, fieldKey: string, fieldType: CustomFieldType, value: string }>, primaryStaff?: { __typename?: 'Staff', id: string, name?: string | null, email: string, picture?: { __typename?: 'UploadedFile', id: string, url: string } | null } | null } };

export type DeletePatientMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePatientMutation = { __typename?: 'Mutation', deletePatient?: { __typename?: 'DeleteResourcePayload', id?: string | null } | null };

export type EnablePatientBillingMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EnablePatientBillingMutation = { __typename?: 'Mutation', enablePatientBilling: { __typename?: 'Patient', id: string, stripeCustomerId?: string | null } };

export type UpdatePatientMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  preferredName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  phoneMobile?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['String']>;
  patientType?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  biologicalSex?: InputMaybe<BiologicalSex>;
  billingAddress?: InputMaybe<AddressInput>;
  primaryStaffId?: InputMaybe<Scalars['ID']>;
}>;


export type UpdatePatientMutation = { __typename?: 'Mutation', updatePatient?: { __typename?: 'Patient', id: string, name: string, preferredName?: string | null, email: string, phoneMobile?: string | null, dob?: any | null, age?: number | null, patientType?: PatientType | null, gender?: string | null, biologicalSex?: BiologicalSex | null, billingAddress?: { __typename?: 'Address', id: string, city: string, country: string, line1: string, line2?: string | null, name: string, postalCode: string, state: string } | null, primaryStaff?: { __typename?: 'Staff', id: string, name?: string | null, picture?: { __typename?: 'UploadedFile', id: string, url: string } | null } | null } | null };

export type DisconnectStripeAccountMutationVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type DisconnectStripeAccountMutation = { __typename?: 'Mutation', disconnectStripeAccount?: { __typename?: 'Business', id: string, stripeConnectAccount?: { __typename?: 'StripeConnectAccount', id: string, stripeAccountId?: string | null, stripePublishableKey?: string | null } | null } | null };

export type FaceVitalsScanResultQueryVariables = Exact<{
  businessId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type FaceVitalsScanResultQuery = { __typename?: 'Query', faceVitalsScanResult?: { __typename?: 'FaceVitalsScanResult', id: string, createdAt: any, inputAge: number, inputGender: string, inputHeight: number, inputWeight: number, inputDiabeticType: string, inputBloodPressureMedication: boolean, inputHypertension: boolean, inputSmoker: boolean, bloodPressureDiastolicMmhg?: number | null, bloodPressureSystolicMmhg?: number | null, bloodPressureRiskCategory?: FaceVitalsBloodPressureRiskCategory | null, breathingRateBpm?: number | null, cardiacWorkload?: number | null, cardiovascularDiseaseRisk?: number | null, cardiovascularDiseaseRiskCategory?: FaceVitalsBaseRiskCategory | null, heartAttackRisk?: number | null, heartAttackRiskCategory?: FaceVitalsBaseRiskCategory | null, heartRateHz?: number | null, heartRateBpm?: number | null, heartRateIrregularBpm?: number | null, heartRateVariabilitySdnn?: number | null, heartRateVariabilityRiskCategory?: FaceVitalsBaseRiskCategory | null, strokeRisk?: number | null, strokeRiskCategory?: FaceVitalsBaseRiskCategory | null, vascularCapacitySecs?: number | null, appointment?: { __typename?: 'Appointment', id: string } | null, patient?: { __typename?: 'Patient', id: string } | null } | null };

export type FaceVitalsScanResultsQueryVariables = Exact<{
  businessId: Scalars['ID'];
  patientId: Scalars['ID'];
}>;


export type FaceVitalsScanResultsQuery = { __typename?: 'Query', faceVitalsScanResults: Array<{ __typename?: 'FaceVitalsScanResult', id: string, createdAt: any, inputAge: number, inputGender: string, inputHeight: number, inputWeight: number, inputBloodPressureMedication: boolean, inputHypertension: boolean, inputSmoker: boolean, bloodPressureDiastolicMmhg?: number | null, bloodPressureSystolicMmhg?: number | null, bloodPressureRiskCategory?: FaceVitalsBloodPressureRiskCategory | null, breathingRateBpm?: number | null, cardiacWorkload?: number | null, cardiovascularDiseaseRisk?: number | null, cardiovascularDiseaseRiskCategory?: FaceVitalsBaseRiskCategory | null, heartAttackRisk?: number | null, heartAttackRiskCategory?: FaceVitalsBaseRiskCategory | null, heartRateHz?: number | null, heartRateBpm?: number | null, heartRateIrregularBpm?: number | null, heartRateVariabilitySdnn?: number | null, heartRateVariabilityRiskCategory?: FaceVitalsBaseRiskCategory | null, strokeRisk?: number | null, strokeRiskCategory?: FaceVitalsBaseRiskCategory | null, vascularCapacitySecs?: number | null, appointment?: { __typename?: 'Appointment', id: string } | null, patient?: { __typename?: 'Patient', id: string, name: string, email: string } | null }> };

export type BusinessFormsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type BusinessFormsQuery = { __typename?: 'Query', count: number, forms: Array<{ __typename?: 'Form', id: string, url: string, title?: string | null, alias?: string | null, description?: string | null, useAsTemplate?: boolean | null, category?: string | null, createdAt?: any | null, updatedAt?: any | null, status: FormStatus, steps?: any | null, submissionsCount?: number | null, unreadSubmissionsCount?: number | null, business?: { __typename?: 'Business', id: string, alias: string } | null }> };

export type CreateFormMutationVariables = Exact<{
  title: Scalars['String'];
  formTemplateId?: InputMaybe<Scalars['ID']>;
}>;


export type CreateFormMutation = { __typename?: 'Mutation', createForm: { __typename?: 'Form', id: string, url: string, title?: string | null, alias?: string | null, description?: string | null, thumbnail?: any | null, category?: string | null, useAsTemplate?: boolean | null, createdAt?: any | null, updatedAt?: any | null, status: FormStatus, steps?: any | null, submissionsCount?: number | null, unreadSubmissionsCount?: number | null } };

export type DeleteFormMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteFormMutation = { __typename?: 'Mutation', deleteForm: boolean };

export type DuplicateFormMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DuplicateFormMutation = { __typename?: 'Mutation', duplicateForm: { __typename?: 'Form', id: string, url: string, title?: string | null, alias?: string | null, description?: string | null, thumbnail?: any | null, category?: string | null, useAsTemplate?: boolean | null, createdAt?: any | null, updatedAt?: any | null, status: FormStatus, steps?: any | null, submissionsCount?: number | null, unreadSubmissionsCount?: number | null } };

export type FormQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FormQuery = { __typename?: 'Query', form: { __typename?: 'Form', id: string, url: string, title?: string | null, alias?: string | null, description?: string | null, thumbnail?: any | null, category?: string | null, useAsTemplate?: boolean | null, createdAt?: any | null, updatedAt?: any | null, status: FormStatus, steps?: any | null, submissionsCount?: number | null, unreadSubmissionsCount?: number | null } };

export type FormFieldsFragment = { __typename?: 'Form', id: string, url: string, title?: string | null, alias?: string | null, description?: string | null, thumbnail?: any | null, category?: string | null, useAsTemplate?: boolean | null, createdAt?: any | null, updatedAt?: any | null, status: FormStatus, steps?: any | null, submissionsCount?: number | null, unreadSubmissionsCount?: number | null };

export type FormEditorFieldsFragment = { __typename?: 'Form', settings?: any | null, id: string, url: string, title?: string | null, alias?: string | null, description?: string | null, thumbnail?: any | null, category?: string | null, useAsTemplate?: boolean | null, createdAt?: any | null, updatedAt?: any | null, status: FormStatus, steps?: any | null, submissionsCount?: number | null, unreadSubmissionsCount?: number | null };

export type FormStepsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FormStepsQuery = { __typename?: 'Query', form: { __typename?: 'Form', settings?: any | null, id: string, url: string, title?: string | null, alias?: string | null, description?: string | null, thumbnail?: any | null, category?: string | null, useAsTemplate?: boolean | null, createdAt?: any | null, updatedAt?: any | null, status: FormStatus, steps?: any | null, submissionsCount?: number | null, unreadSubmissionsCount?: number | null } };

export type GetAllFormTemplatesQueryVariables = Exact<{
  library: FormTemplateLibrary;
}>;


export type GetAllFormTemplatesQuery = { __typename?: 'Query', formTemplates: Array<{ __typename?: 'FormTemplate', id: string, name: string, description: string, thumbnailUrl: string, previewUrl: string }> };

export type PublishFormMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PublishFormMutation = { __typename?: 'Mutation', form: { __typename?: 'Form', id: string, url: string, title?: string | null, alias?: string | null, description?: string | null, thumbnail?: any | null, category?: string | null, useAsTemplate?: boolean | null, createdAt?: any | null, updatedAt?: any | null, status: FormStatus, steps?: any | null, submissionsCount?: number | null, unreadSubmissionsCount?: number | null } };

export type RenameFormMutationVariables = Exact<{
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
  alias?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  thumbnail?: InputMaybe<Scalars['Json']>;
  category?: InputMaybe<Scalars['String']>;
  useAsTemplate?: InputMaybe<Scalars['Boolean']>;
}>;


export type RenameFormMutation = { __typename?: 'Mutation', renameForm: { __typename?: 'Form', id: string, url: string, title?: string | null, alias?: string | null, description?: string | null, thumbnail?: any | null, category?: string | null, useAsTemplate?: boolean | null, createdAt?: any | null, updatedAt?: any | null, status: FormStatus, steps?: any | null, submissionsCount?: number | null, unreadSubmissionsCount?: number | null } };

export type ResetBusinessBookingFormMutationVariables = Exact<{ [key: string]: never; }>;


export type ResetBusinessBookingFormMutation = { __typename?: 'Mutation', resetBusinessBookingForm?: boolean | null };

export type FormSubmissionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FormSubmissionQuery = { __typename?: 'Query', formSubmission: { __typename?: 'FormSubmission', id: string, startedAt: any, answers: any, createdAt: any, questions: any, readAt?: any | null, form: { __typename?: 'Form', id: string, title?: string | null } } };

export type FormSubmissionAttachmentUrlQueryVariables = Exact<{
  formSubmissionId: Scalars['ID'];
  answerKey: Scalars['String'];
}>;


export type FormSubmissionAttachmentUrlQuery = { __typename?: 'Query', url?: string | null };

export type FormSubmissionsQueryVariables = Exact<{
  formId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  search?: InputMaybe<Scalars['String']>;
  appointmentId?: InputMaybe<Scalars['ID']>;
  patientId?: InputMaybe<Scalars['ID']>;
}>;


export type FormSubmissionsQuery = { __typename?: 'Query', response: { __typename?: 'FormSubmissionsResponse', submissions: Array<{ __typename?: 'FormSubmission', id: string, startedAt: any, answers: any, createdAt: any, questions: any, readAt?: any | null, patientVerified?: boolean | null, patient?: { __typename?: 'Patient', id: string, name: string } | null, form: { __typename?: 'Form', id: string, title?: string | null } }>, meta: { __typename?: 'FormSubmissionsMetaPayload', count?: number | null } } };

export type UpdateFormSubmissionMutationVariables = Exact<{
  id: Scalars['ID'];
  read: Scalars['Boolean'];
}>;


export type UpdateFormSubmissionMutation = { __typename?: 'Mutation', submission: { __typename?: 'FormSubmission', id: string, readAt?: any | null } };

export type UpdateFormDraftMutationVariables = Exact<{
  formId: Scalars['ID'];
  steps?: InputMaybe<Scalars['Json']>;
  settings?: InputMaybe<Scalars['Json']>;
}>;


export type UpdateFormDraftMutation = { __typename?: 'Mutation', updateFormDraft: { __typename?: 'Form', settings?: any | null, id: string, url: string, title?: string | null, alias?: string | null, description?: string | null, thumbnail?: any | null, category?: string | null, useAsTemplate?: boolean | null, createdAt?: any | null, updatedAt?: any | null, status: FormStatus, steps?: any | null, submissionsCount?: number | null, unreadSubmissionsCount?: number | null } };

export type InvalidateApiKeyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type InvalidateApiKeyMutation = { __typename?: 'Mutation', invalidateApiKey: { __typename?: 'ApiKey', id: string, value?: string | null, description: string, createdAt: any, updatedAt: any, expiresAt?: any | null, invalidatedAt?: any | null } };

export type BusinessLocationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BusinessLocationQuery = { __typename?: 'Query', Location?: { __typename?: 'Location', description?: string | null, address?: string | null, createdAt: any, googlePlaceAddress?: string | null, googlePlaceId?: string | null, googlePlaceMapUrl?: string | null, googlePlaceImageUrl?: string | null, googlePlaceName?: string | null, id: string, instructions?: string | null, lat?: number | null, lng?: number | null, name: string, phone?: string | null, timezone?: string | null, updatedAt: any, workingHours?: any | null, business: { __typename?: 'Business', id: string, name: string }, picture?: { __typename?: 'UploadedFile', id: string, url: string } | null, services?: Array<{ __typename?: 'Service', id: string, name: string }> | null } | null };

export type BusinessLocationsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type BusinessLocationsQuery = { __typename?: 'Query', count: number, businessLocations: Array<{ __typename?: 'Location', address?: string | null, createdAt: any, googlePlaceAddress?: string | null, googlePlaceId?: string | null, googlePlaceMapUrl?: string | null, googlePlaceImageUrl?: string | null, googlePlaceName?: string | null, id: string, instructions?: string | null, lat?: number | null, lng?: number | null, name: string, phone?: string | null, timezone?: string | null, updatedAt: any, workingHours?: any | null, business: { __typename?: 'Business', id: string, name: string }, picture?: { __typename?: 'UploadedFile', id: string, url: string } | null, services?: Array<{ __typename?: 'Service', id: string, name: string }> | null }> };

export type BusinessLocationsNamesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type BusinessLocationsNamesQuery = { __typename?: 'Query', businessLocations: Array<{ __typename?: 'Location', id: string, name: string }> };

export type CreateBusinessLocationMutationVariables = Exact<{
  name: Scalars['String'];
  address?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  timezone?: InputMaybe<Scalars['String']>;
  workingHours?: InputMaybe<Scalars['Json']>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  googlePlaceName?: InputMaybe<Scalars['String']>;
  googlePlaceAddress?: InputMaybe<Scalars['String']>;
  googlePlaceMapUrl?: InputMaybe<Scalars['String']>;
  googlePlaceImageUrl?: InputMaybe<Scalars['String']>;
  pictureId?: InputMaybe<Scalars['ID']>;
}>;


export type CreateBusinessLocationMutation = { __typename?: 'Mutation', createLocation?: { __typename?: 'Location', id: string, name: string, address?: string | null, instructions?: string | null, phone?: string | null, lat?: number | null, lng?: number | null, timezone?: string | null, workingHours?: any | null, googlePlaceId?: string | null, googlePlaceName?: string | null, googlePlaceAddress?: string | null, googlePlaceMapUrl?: string | null, googlePlaceImageUrl?: string | null, picture?: { __typename?: 'UploadedFile', id: string } | null, business: { __typename?: 'Business', id: string } } | null };

export type DeleteBusinessLocationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBusinessLocationMutation = { __typename?: 'Mutation', deleteLocation?: boolean | null };

export type UpdateBusinessLocationMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  address?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  timezone?: InputMaybe<Scalars['String']>;
  workingHours?: InputMaybe<Scalars['Json']>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  googlePlaceName?: InputMaybe<Scalars['String']>;
  googlePlaceAddress?: InputMaybe<Scalars['String']>;
  googlePlaceMapUrl?: InputMaybe<Scalars['String']>;
  googlePlaceImageUrl?: InputMaybe<Scalars['String']>;
  pictureId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
}>;


export type UpdateBusinessLocationMutation = { __typename?: 'Mutation', updateLocation?: { __typename?: 'Location', id: string, name: string, address?: string | null, instructions?: string | null, phone?: string | null, lat?: number | null, lng?: number | null, timezone?: string | null, workingHours?: any | null, googlePlaceId?: string | null, googlePlaceName?: string | null, googlePlaceAddress?: string | null, googlePlaceMapUrl?: string | null, googlePlaceImageUrl?: string | null, description?: string | null, picture?: { __typename?: 'UploadedFile', id: string, url: string } | null, business: { __typename?: 'Business', id: string }, services?: Array<{ __typename?: 'Service', id: string }> | null } | null };

export type CreateNoteMutationVariables = Exact<{
  appointmentId?: InputMaybe<Scalars['ID']>;
  patientId: Scalars['ID'];
  content: Scalars['String'];
}>;


export type CreateNoteMutation = { __typename?: 'Mutation', createNote: { __typename?: 'Note', id: string, content: string, createdAt: any, updatedAt: any, appointment?: { __typename?: 'Appointment', id: string } | null, patient: { __typename?: 'Patient', id: string } } };

export type DeleteNoteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteNoteMutation = { __typename?: 'Mutation', deleteNote: boolean };

export type NoteQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NoteQuery = { __typename?: 'Query', note?: { __typename?: 'Note', id: string, createdAt: any, updatedAt: any, content: string, staff: { __typename?: 'Staff', id: string } } | null };

export type PatientNotesQueryVariables = Exact<{
  patientId: Scalars['ID'];
  appointmentId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type PatientNotesQuery = { __typename?: 'Query', count: number, notes: Array<{ __typename?: 'Note', id: string, createdAt: any, content: string, appointment?: { __typename?: 'Appointment', id: string } | null, staff: { __typename?: 'Staff', id: string, name?: string | null, email: string, picture?: { __typename?: 'UploadedFile', id: string, url: string } | null } }> };

export type UpdateNoteMutationVariables = Exact<{
  id: Scalars['ID'];
  content: Scalars['String'];
}>;


export type UpdateNoteMutation = { __typename?: 'Mutation', updateNote: { __typename?: 'Note', id: string, content: string, createdAt: any, updatedAt: any, appointment?: { __typename?: 'Appointment', id: string } | null, patient: { __typename?: 'Patient', id: string } } };

export type BusinessNotificationsQueryVariables = Exact<{
  businessId: Scalars['ID'];
  limit: Scalars['Int'];
  after?: InputMaybe<Scalars['ID']>;
}>;


export type BusinessNotificationsQuery = { __typename?: 'Query', totalCount: number, notifications: Array<{ __typename?: 'Notification', id: string, title: string, content?: string | null, type?: string | null, read?: boolean | null, createdAt: any }> };

export type BookingEventNotificationTemplatesQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type BookingEventNotificationTemplatesQuery = { __typename?: 'Query', templates: Array<{ __typename?: 'BookingEventNotificationTemplate', id: string, event: BookingEvent, type: MessageType, enabled?: boolean | null, deleted?: boolean | null, recipient: MessageRecipient, messageTemplate?: any | null, defaultTemplate?: any | null, services: Array<{ __typename?: 'Service', id: string, name: string }> }>, services?: Array<{ __typename?: 'Service', id: string, name: string, enabled?: boolean | null }> | null, business?: { __typename?: 'Business', id: string, templates?: any | null } | null };

export type CreateBookingEventNotificationTemplateMutationVariables = Exact<{
  businessId: Scalars['ID'];
  recipient: MessageRecipient;
  type: MessageType;
  event: BookingEvent;
  services: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type CreateBookingEventNotificationTemplateMutation = { __typename?: 'Mutation', createBookingEventNotificationTemplate?: { __typename?: 'BookingEventNotificationTemplate', id: string, type: MessageType, event: BookingEvent, recipient: MessageRecipient, enabled?: boolean | null, deleted?: boolean | null, business: { __typename?: 'Business', id: string }, services: Array<{ __typename?: 'Service', id: string }> } | null };

export type CreateFollowupMessageTemplateMutationVariables = Exact<{
  businessId: Scalars['ID'];
  minutes: Scalars['Int'];
  recipient: MessageRecipient;
  type: MessageType;
  services: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type CreateFollowupMessageTemplateMutation = { __typename?: 'Mutation', createPostAppointmentMessageTemplate?: { __typename?: 'PostAppointmentMessageTemplate', id: string, minutes: number, type: MessageType, recipient: MessageRecipient, enabled?: boolean | null, deleted?: boolean | null, business: { __typename?: 'Business', id: string }, services: Array<{ __typename?: 'Service', id: string }> } | null };

export type CreateReminderTemplateMutationVariables = Exact<{
  businessId: Scalars['ID'];
  minutes: Scalars['Int'];
  type: ReminderType;
  resourceType: ReminderResourceType;
  services: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type CreateReminderTemplateMutation = { __typename?: 'Mutation', createReminderTemplate?: { __typename?: 'ReminderTemplate', id: string, minutes: number, type: ReminderType, resourceType: ReminderResourceType, enabled?: boolean | null, deleted?: boolean | null, business: { __typename?: 'Business', id: string }, services?: Array<{ __typename?: 'Service', id: string }> | null } | null };

export type FollowupNotificationTemplatesQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type FollowupNotificationTemplatesQuery = { __typename?: 'Query', postBookingTemplates: Array<{ __typename?: 'PostAppointmentMessageTemplate', id: string, minutes: number, type: MessageType, enabled?: boolean | null, deleted?: boolean | null, recipient: MessageRecipient, messageTemplate?: any | null, defaultTemplate?: any | null, services: Array<{ __typename?: 'Service', id: string, name: string }> }>, services?: Array<{ __typename?: 'Service', id: string, name: string }> | null, business?: { __typename?: 'Business', id: string, templates?: any | null } | null, defaultMessageTemplates?: { __typename?: 'DefaultNotificationsTemplatesPayload', templates?: any | null } | null };

export type ReminderNotificationTemplatesQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type ReminderNotificationTemplatesQuery = { __typename?: 'Query', reminders: Array<{ __typename?: 'ReminderTemplate', id: string, minutes: number, type: ReminderType, resourceType: ReminderResourceType, enabled?: boolean | null, deleted?: boolean | null, messageTemplate?: any | null, defaultTemplate?: any | null, services?: Array<{ __typename?: 'Service', id: string, name: string }> | null }>, business?: { __typename?: 'Business', id: string, templates?: any | null } | null, services?: Array<{ __typename?: 'Service', id: string, name: string }> | null, defaultMessageTemplates?: { __typename?: 'DefaultNotificationsTemplatesPayload', templates?: any | null } | null };

export type UpdateBookingEventNotificationTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
  deleted?: InputMaybe<Scalars['Boolean']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  messageTemplate?: InputMaybe<Scalars['Json']>;
}>;


export type UpdateBookingEventNotificationTemplateMutation = { __typename?: 'Mutation', updateBookingEventNotificationTemplate?: { __typename?: 'BookingEventNotificationTemplate', id: string, enabled?: boolean | null, deleted?: boolean | null, messageTemplate?: any | null } | null };

export type UpdatePostAppointmentMessageTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
  deleted?: InputMaybe<Scalars['Boolean']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  messageTemplate?: InputMaybe<Scalars['Json']>;
}>;


export type UpdatePostAppointmentMessageTemplateMutation = { __typename?: 'Mutation', updatePostAppointmentMessageTemplate?: { __typename?: 'PostAppointmentMessageTemplate', id: string, enabled?: boolean | null, deleted?: boolean | null, messageTemplate?: any | null } | null };

export type UpdateReminderTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
  deleted?: InputMaybe<Scalars['Boolean']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  messageTemplate?: InputMaybe<Scalars['Json']>;
}>;


export type UpdateReminderTemplateMutation = { __typename?: 'Mutation', updateReminderTemplate?: { __typename?: 'ReminderTemplate', id: string, enabled?: boolean | null, deleted?: boolean | null, messageTemplate?: any | null } | null };

export type OutgoingWebhooksQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type OutgoingWebhooksQuery = { __typename?: 'Query', outgoingWebhooks: Array<{ __typename?: 'OutgoingWebhook', id: string, endpoint: string, description?: string | null, enabled: boolean }> };

export type OutgoingWebhookQueryVariables = Exact<{
  businessId: Scalars['ID'];
  outgoingWebhookId: Scalars['ID'];
  eventsLimit?: InputMaybe<Scalars['Int']>;
}>;


export type OutgoingWebhookQuery = { __typename?: 'Query', outgoingWebhook?: { __typename?: 'OutgoingWebhook', id: string, endpoint: string, description?: string | null, enabled: boolean, events: Array<{ __typename?: 'Event', id: string, name: string, data: any, createdAt: any, outgoingWebhookDeliveries: Array<{ __typename?: 'OutgoingWebhookDelivery', id: string, duration?: number | null, requestHeaders: any, requestPayload: any, responseStatus?: number | null, responseHeaders?: any | null, responsePayload?: any | null, event: { __typename?: 'Event', id: string }, outgoingWebhook: { __typename?: 'OutgoingWebhook', id: string } }> }> } | null };

export type CreateOutgoingWebhookMutationVariables = Exact<{
  businessId: Scalars['ID'];
  endpoint: Scalars['String'];
}>;


export type CreateOutgoingWebhookMutation = { __typename?: 'Mutation', createOutgoingWebhook: { __typename?: 'OutgoingWebhook', id: string, endpoint: string, description?: string | null, enabled: boolean } };

export type UpdateOutgoingWebhookMutationVariables = Exact<{
  id: Scalars['ID'];
  enabled: Scalars['Boolean'];
}>;


export type UpdateOutgoingWebhookMutation = { __typename?: 'Mutation', updateOutgoingWebhook: { __typename?: 'OutgoingWebhook', id: string, endpoint: string, description?: string | null, enabled: boolean } };

export type DeleteOutgoingWebhookMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteOutgoingWebhookMutation = { __typename?: 'Mutation', deleteOutgoingWebhook: boolean };

export type AddInvoiceItemMutationVariables = Exact<{
  invoiceId: Scalars['ID'];
  description: Scalars['String'];
  quantity: Scalars['Int'];
  amount: Scalars['Float'];
}>;


export type AddInvoiceItemMutation = { __typename?: 'Mutation', addInvoiceItem: { __typename?: 'Invoice', id: string, number?: string | null, createdAt: any, updatedAt: any, currency: string, status?: InvoiceStatus | null, voidedAt?: any | null, dueDate?: any | null, sentAt?: any | null, amountTotal?: number | null, amountSubtotal?: number | null, amountOutstanding?: number | null, amountDiscounted?: number | null, amountExcludingTax?: number | null, amountTax?: number | null, markedUncollectibleAt?: any | null, finalizedAt?: any | null, patient: { __typename?: 'Patient', id: string, name: string, email: string }, appointment?: { __typename?: 'Appointment', id: string } | null, voidedBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, business: { __typename?: 'Business', id: string, defaultInvoiceDaysUntilDue?: number | null }, coupon?: { __typename?: 'Coupon', id: string, code: string, name: string } | null, markedUncollectibleBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, items?: Array<{ __typename?: 'InvoiceItem', id: string, createdAt: any, updatedAt: any, description?: string | null, quantity?: number | null, unitAmount?: number | null, amount?: number | null }> | null } };

export type CreateDraftInvoiceMutationVariables = Exact<{
  patientId: Scalars['ID'];
  currency?: InputMaybe<Scalars['String']>;
}>;


export type CreateDraftInvoiceMutation = { __typename?: 'Mutation', createDraftInvoice: { __typename?: 'Invoice', id: string, number?: string | null, createdAt: any, updatedAt: any, currency: string, status?: InvoiceStatus | null, voidedAt?: any | null, dueDate?: any | null, sentAt?: any | null, amountTotal?: number | null, amountSubtotal?: number | null, amountOutstanding?: number | null, amountDiscounted?: number | null, amountExcludingTax?: number | null, amountTax?: number | null, markedUncollectibleAt?: any | null, finalizedAt?: any | null, patient: { __typename?: 'Patient', id: string, name: string, email: string }, appointment?: { __typename?: 'Appointment', id: string } | null, voidedBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, business: { __typename?: 'Business', id: string, defaultInvoiceDaysUntilDue?: number | null }, coupon?: { __typename?: 'Coupon', id: string, code: string, name: string } | null, markedUncollectibleBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, items?: Array<{ __typename?: 'InvoiceItem', id: string, createdAt: any, updatedAt: any, description?: string | null, quantity?: number | null, unitAmount?: number | null, amount?: number | null }> | null } };

export type DeleteDraftInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['ID'];
}>;


export type DeleteDraftInvoiceMutation = { __typename?: 'Mutation', deleted: boolean };

export type DeleteInvoiceItemMutationVariables = Exact<{
  invoiceId: Scalars['ID'];
  invoiceItemId: Scalars['ID'];
}>;


export type DeleteInvoiceItemMutation = { __typename?: 'Mutation', deleteInvoiceItem: { __typename?: 'Invoice', id: string, number?: string | null, createdAt: any, updatedAt: any, currency: string, status?: InvoiceStatus | null, voidedAt?: any | null, dueDate?: any | null, sentAt?: any | null, amountTotal?: number | null, amountSubtotal?: number | null, amountOutstanding?: number | null, amountDiscounted?: number | null, amountExcludingTax?: number | null, amountTax?: number | null, markedUncollectibleAt?: any | null, finalizedAt?: any | null, patient: { __typename?: 'Patient', id: string, name: string, email: string }, appointment?: { __typename?: 'Appointment', id: string } | null, voidedBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, business: { __typename?: 'Business', id: string, defaultInvoiceDaysUntilDue?: number | null }, coupon?: { __typename?: 'Coupon', id: string, code: string, name: string } | null, markedUncollectibleBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, items?: Array<{ __typename?: 'InvoiceItem', id: string, createdAt: any, updatedAt: any, description?: string | null, quantity?: number | null, unitAmount?: number | null, amount?: number | null }> | null } };

export type FinalizeInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['ID'];
}>;


export type FinalizeInvoiceMutation = { __typename?: 'Mutation', finalizeInvoice: { __typename?: 'Invoice', id: string, number?: string | null, createdAt: any, updatedAt: any, currency: string, status?: InvoiceStatus | null, voidedAt?: any | null, dueDate?: any | null, sentAt?: any | null, amountTotal?: number | null, amountSubtotal?: number | null, amountOutstanding?: number | null, amountDiscounted?: number | null, amountExcludingTax?: number | null, amountTax?: number | null, markedUncollectibleAt?: any | null, finalizedAt?: any | null, patient: { __typename?: 'Patient', id: string, name: string, email: string }, appointment?: { __typename?: 'Appointment', id: string } | null, voidedBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, business: { __typename?: 'Business', id: string, defaultInvoiceDaysUntilDue?: number | null }, coupon?: { __typename?: 'Coupon', id: string, code: string, name: string } | null, markedUncollectibleBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, items?: Array<{ __typename?: 'InvoiceItem', id: string, createdAt: any, updatedAt: any, description?: string | null, quantity?: number | null, unitAmount?: number | null, amount?: number | null }> | null } };

export type InvoiceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type InvoiceQuery = { __typename?: 'Query', invoice: { __typename?: 'Invoice', id: string, number?: string | null, createdAt: any, updatedAt: any, currency: string, status?: InvoiceStatus | null, voidedAt?: any | null, dueDate?: any | null, sentAt?: any | null, amountTotal?: number | null, amountSubtotal?: number | null, amountOutstanding?: number | null, amountDiscounted?: number | null, amountExcludingTax?: number | null, amountTax?: number | null, markedUncollectibleAt?: any | null, finalizedAt?: any | null, patient: { __typename?: 'Patient', id: string, name: string, email: string }, appointment?: { __typename?: 'Appointment', id: string } | null, voidedBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, business: { __typename?: 'Business', id: string, defaultInvoiceDaysUntilDue?: number | null }, coupon?: { __typename?: 'Coupon', id: string, code: string, name: string } | null, markedUncollectibleBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, items?: Array<{ __typename?: 'InvoiceItem', id: string, createdAt: any, updatedAt: any, description?: string | null, quantity?: number | null, unitAmount?: number | null, amount?: number | null }> | null } };

export type InvoiceFieldsFragment = { __typename?: 'Invoice', id: string, number?: string | null, createdAt: any, updatedAt: any, currency: string, status?: InvoiceStatus | null, voidedAt?: any | null, dueDate?: any | null, sentAt?: any | null, amountTotal?: number | null, amountSubtotal?: number | null, amountOutstanding?: number | null, amountDiscounted?: number | null, amountExcludingTax?: number | null, amountTax?: number | null, markedUncollectibleAt?: any | null, finalizedAt?: any | null, patient: { __typename?: 'Patient', id: string, name: string, email: string }, appointment?: { __typename?: 'Appointment', id: string } | null, voidedBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, business: { __typename?: 'Business', id: string, defaultInvoiceDaysUntilDue?: number | null }, coupon?: { __typename?: 'Coupon', id: string, code: string, name: string } | null, markedUncollectibleBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, items?: Array<{ __typename?: 'InvoiceItem', id: string, createdAt: any, updatedAt: any, description?: string | null, quantity?: number | null, unitAmount?: number | null, amount?: number | null }> | null };

export type PatientInvoicesQueryVariables = Exact<{
  patientId: Scalars['ID'];
  statuses?: InputMaybe<Array<InvoiceStatus> | InvoiceStatus>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type PatientInvoicesQuery = { __typename?: 'Query', count: number, invoices: Array<{ __typename?: 'Invoice', id: string, number?: string | null, createdAt: any, status?: InvoiceStatus | null, currency: string, dueDate?: any | null, amountTotal?: number | null, amountOutstanding?: number | null }> };

export type UpdateDraftInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['ID'];
  dueDate?: InputMaybe<Scalars['DateTime']>;
  couponCode?: InputMaybe<Scalars['String']>;
}>;


export type UpdateDraftInvoiceMutation = { __typename?: 'Mutation', updateDraftInvoice: { __typename?: 'Invoice', id: string, number?: string | null, createdAt: any, updatedAt: any, currency: string, status?: InvoiceStatus | null, voidedAt?: any | null, dueDate?: any | null, sentAt?: any | null, amountTotal?: number | null, amountSubtotal?: number | null, amountOutstanding?: number | null, amountDiscounted?: number | null, amountExcludingTax?: number | null, amountTax?: number | null, markedUncollectibleAt?: any | null, finalizedAt?: any | null, patient: { __typename?: 'Patient', id: string, name: string, email: string }, appointment?: { __typename?: 'Appointment', id: string } | null, voidedBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, business: { __typename?: 'Business', id: string, defaultInvoiceDaysUntilDue?: number | null }, coupon?: { __typename?: 'Coupon', id: string, code: string, name: string } | null, markedUncollectibleBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, items?: Array<{ __typename?: 'InvoiceItem', id: string, createdAt: any, updatedAt: any, description?: string | null, quantity?: number | null, unitAmount?: number | null, amount?: number | null }> | null } };

export type UpdateInvoiceItemMutationVariables = Exact<{
  invoiceId: Scalars['ID'];
  invoiceItemId: Scalars['ID'];
  description: Scalars['String'];
  quantity: Scalars['Int'];
  amount: Scalars['Float'];
}>;


export type UpdateInvoiceItemMutation = { __typename?: 'Mutation', updateInvoiceItem: { __typename?: 'Invoice', id: string, number?: string | null, createdAt: any, updatedAt: any, currency: string, status?: InvoiceStatus | null, voidedAt?: any | null, dueDate?: any | null, sentAt?: any | null, amountTotal?: number | null, amountSubtotal?: number | null, amountOutstanding?: number | null, amountDiscounted?: number | null, amountExcludingTax?: number | null, amountTax?: number | null, markedUncollectibleAt?: any | null, finalizedAt?: any | null, patient: { __typename?: 'Patient', id: string, name: string, email: string }, appointment?: { __typename?: 'Appointment', id: string } | null, voidedBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, business: { __typename?: 'Business', id: string, defaultInvoiceDaysUntilDue?: number | null }, coupon?: { __typename?: 'Coupon', id: string, code: string, name: string } | null, markedUncollectibleBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, items?: Array<{ __typename?: 'InvoiceItem', id: string, createdAt: any, updatedAt: any, description?: string | null, quantity?: number | null, unitAmount?: number | null, amount?: number | null }> | null } };

export type VoidInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['ID'];
}>;


export type VoidInvoiceMutation = { __typename?: 'Mutation', voidInvoice: { __typename?: 'Invoice', id: string, number?: string | null, createdAt: any, updatedAt: any, currency: string, status?: InvoiceStatus | null, voidedAt?: any | null, dueDate?: any | null, sentAt?: any | null, amountTotal?: number | null, amountSubtotal?: number | null, amountOutstanding?: number | null, amountDiscounted?: number | null, amountExcludingTax?: number | null, amountTax?: number | null, markedUncollectibleAt?: any | null, finalizedAt?: any | null, patient: { __typename?: 'Patient', id: string, name: string, email: string }, appointment?: { __typename?: 'Appointment', id: string } | null, voidedBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, business: { __typename?: 'Business', id: string, defaultInvoiceDaysUntilDue?: number | null }, coupon?: { __typename?: 'Coupon', id: string, code: string, name: string } | null, markedUncollectibleBy?: { __typename?: 'Staff', id: string, name?: string | null } | null, items?: Array<{ __typename?: 'InvoiceItem', id: string, createdAt: any, updatedAt: any, description?: string | null, quantity?: number | null, unitAmount?: number | null, amount?: number | null }> | null } };

export type SendPortalInviteMutationVariables = Exact<{
  patientId: Scalars['ID'];
  subject: Scalars['String'];
  body: Scalars['String'];
}>;


export type SendPortalInviteMutation = { __typename?: 'Mutation', success: boolean };

export type SelectPatientsListQueryVariables = Exact<{
  businessId: Scalars['ID'];
  limit: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
}>;


export type SelectPatientsListQuery = { __typename?: 'Query', patients: Array<{ __typename?: 'Patient', id: string, name: string, email: string }> };

export type SelectServicesListQueryVariables = Exact<{
  businessId: Scalars['ID'];
  limit: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  excludeIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type SelectServicesListQuery = { __typename?: 'Query', services?: Array<{ __typename?: 'Service', id: string, name: string, description?: string | null, durations?: any | null }> | null };

export type DeleteCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCategoryMutation = { __typename?: 'Mutation', deleteServiceCategory?: { __typename?: 'ServiceCategory', id: string } | null };

export type ServiceCategoriesQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type ServiceCategoriesQuery = { __typename?: 'Query', currentBusiness: { __typename?: 'Business', id: string, name: string, serviceCategories?: Array<{ __typename?: 'ServiceCategory', id: string, name: string }> | null } };

export type AllBusinessServicesQueryVariables = Exact<{
  businessId: Scalars['ID'];
  limit: Scalars['Int'];
  skip?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  excludeIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type AllBusinessServicesQuery = { __typename?: 'Query', count: number, services?: Array<{ __typename?: 'Service', id: string, alias?: string | null, name: string, description?: string | null, durations?: any | null, currency: string, enabled?: boolean | null, locationType?: string | null, locationValue?: string | null, bookingPageUrl?: string | null, business: { __typename?: 'Business', id: string, alias: string }, staffs?: Array<{ __typename?: 'Staff', id: string, name?: string | null, picture?: { __typename?: 'UploadedFile', id: string, url: string } | null }> | null, categories?: Array<{ __typename?: 'ServiceCategory', id: string, name: string }> | null, picture?: { __typename?: 'UploadedFile', id: string, url: string } | null }> | null };

export type BusinessServiceQueryVariables = Exact<{
  serviceId?: InputMaybe<Scalars['ID']>;
}>;


export type BusinessServiceQuery = { __typename?: 'Query', service?: { __typename?: 'Service', id: string, alias?: string | null, name: string, deleted?: boolean | null, description?: string | null, durations?: any | null, currency: string, leadTime: number, cancelLeadTime?: number | null, timeIncrement: number, workingHours?: any | null, availabilityLimitDays?: number | null, locationType?: string | null, locationValue?: string | null, recurrence?: any | null, enabled?: boolean | null, requiresStripePayment?: boolean | null, bookingsPerTimeslot?: number | null, bookingsRequireApproval?: boolean | null, hideTimeslotsSeats?: boolean | null, bookingPageVisibility?: string | null, allowGuests?: boolean | null, locationOverridesStaffs?: boolean | null, bufferBeforeMinutes?: number | null, bufferAfterMinutes?: number | null, requiredAdditionalStaffCount?: number | null, categories?: Array<{ __typename?: 'ServiceCategory', id: string, name: string }> | null, staffs?: Array<{ __typename?: 'Staff', id: string, name?: string | null, user?: { __typename?: 'User', id: string } | null }> | null, additionalStaff?: Array<{ __typename?: 'Staff', id: string }> | null, bookingEndpoints?: Array<{ __typename?: 'BookingEndpoint', id: string, alias?: string | null }> | null, picture?: { __typename?: 'UploadedFile', id: string, url: string } | null, location?: { __typename?: 'Location', id: string } | null } | null };

export type BusinessServicesNamesQueryVariables = Exact<{
  businessId: Scalars['ID'];
  limit: Scalars['Int'];
  skip?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
}>;


export type BusinessServicesNamesQuery = { __typename?: 'Query', count: number, services?: Array<{ __typename?: 'Service', id: string, name: string }> | null };

export type CreateServiceMutationVariables = Exact<{
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  durations: Scalars['Json'];
  currency: Scalars['String'];
}>;


export type CreateServiceMutation = { __typename?: 'Mutation', createService?: { __typename?: 'Service', id: string, name: string, business: { __typename?: 'Business', id: string, servicesCount?: number | null } } | null };

export type CreateCategoryMutationVariables = Exact<{
  name: Scalars['String'];
  businessId: Scalars['ID'];
}>;


export type CreateCategoryMutation = { __typename?: 'Mutation', createServiceCategory?: { __typename?: 'ServiceCategory', id: string, name: string, business: { __typename?: 'Business', id: string } } | null };

export type DeleteServiceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteServiceMutation = { __typename?: 'Mutation', updateService?: { __typename?: 'Service', id: string, business: { __typename?: 'Business', id: string, servicesCount?: number | null } } | null };

export type DuplicateServiceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DuplicateServiceMutation = { __typename?: 'Mutation', service?: { __typename?: 'Service', id: string } | null };

export type ServiceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ServiceQuery = { __typename?: 'Query', service?: { __typename?: 'Service', id: string, name: string, durations?: any | null } | null };

export type ServiceWorkingHoursQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ServiceWorkingHoursQuery = { __typename?: 'Query', service?: { __typename?: 'Service', id: string, name: string, workingHours?: any | null, business: { __typename?: 'Business', id: string, timezone?: string | null, workingHours: any } } | null };

export type UpdateServiceMutationVariables = Exact<{
  id: Scalars['ID'];
  alias?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  durations?: InputMaybe<Scalars['Json']>;
  currency?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  staffs?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  leadTime?: InputMaybe<Scalars['Int']>;
  timeIncrement?: InputMaybe<Scalars['Int']>;
  locationType?: InputMaybe<Scalars['String']>;
  locationValue?: InputMaybe<Scalars['String']>;
  recurrence?: InputMaybe<Scalars['Json']>;
  requiresStripePayment?: InputMaybe<Scalars['Boolean']>;
  workingHours?: InputMaybe<Scalars['Json']>;
  availabilityLimitDays?: InputMaybe<Scalars['Int']>;
  bookingsPerTimeslot?: InputMaybe<Scalars['Int']>;
  additionalStaff?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  bookingsRequireApproval?: InputMaybe<Scalars['Boolean']>;
  hideTimeslotsSeats?: InputMaybe<Scalars['Boolean']>;
  bookingPageVisibility?: InputMaybe<Scalars['String']>;
  allowGuests?: InputMaybe<Scalars['Boolean']>;
  locationOverridesStaffs?: InputMaybe<Scalars['Boolean']>;
  cancelLeadTime?: InputMaybe<Scalars['Int']>;
  bufferBeforeMinutes?: InputMaybe<Scalars['Int']>;
  bufferAfterMinutes?: InputMaybe<Scalars['Int']>;
  requiredAdditionalStaffCount?: InputMaybe<Scalars['Int']>;
  pictureId?: InputMaybe<Scalars['ID']>;
  locationId?: InputMaybe<Scalars['ID']>;
}>;


export type UpdateServiceMutation = { __typename?: 'Mutation', updateService?: { __typename?: 'Service', id: string, alias?: string | null, name: string, description?: string | null, currency: string, durations?: any | null, leadTime: number, timeIncrement: number, locationType?: string | null, locationValue?: string | null, recurrence?: any | null, requiresStripePayment?: boolean | null, workingHours?: any | null, availabilityLimitDays?: number | null, bookingsPerTimeslot?: number | null, bookingsRequireApproval?: boolean | null, hideTimeslotsSeats?: boolean | null, bookingPageVisibility?: string | null, allowGuests?: boolean | null, locationOverridesStaffs?: boolean | null, cancelLeadTime?: number | null, bufferBeforeMinutes?: number | null, bufferAfterMinutes?: number | null, requiredAdditionalStaffCount?: number | null, categories?: Array<{ __typename?: 'ServiceCategory', id: string }> | null, staffs?: Array<{ __typename?: 'Staff', id: string }> | null, additionalStaff?: Array<{ __typename?: 'Staff', id: string }> | null, picture?: { __typename?: 'UploadedFile', id: string, url: string } | null, business: { __typename?: 'Business', id: string, servicesCount?: number | null }, location?: { __typename?: 'Location', id: string } | null } | null };

export type UpdateServiceStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  enabled: Scalars['Boolean'];
}>;


export type UpdateServiceStatusMutation = { __typename?: 'Mutation', updateService?: { __typename?: 'Service', id: string, enabled?: boolean | null } | null };

export type UpdateBusinessEmailSettingsMutationVariables = Exact<{
  businessId: Scalars['ID'];
  ownerNotificationsRecipient?: InputMaybe<OwnerNotificationsRecipient>;
  bookingNotificationsReplyTo?: InputMaybe<NotificationsReplyToType>;
  ownerNotificationsCC?: InputMaybe<Scalars['String']>;
  attachICSFiles?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateBusinessEmailSettingsMutation = { __typename?: 'Mutation', updateBusiness?: { __typename?: 'Business', id: string, ownerNotificationsRecipient?: OwnerNotificationsRecipient | null, bookingNotificationsReplyTo?: NotificationsReplyToType | null, ownerNotificationsCC?: string | null, attachICSFiles?: boolean | null } | null };

export type UpdateSmsSettingsMutationVariables = Exact<{
  businessId: Scalars['ID'];
  sendSMSFromStaffNumber?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateSmsSettingsMutation = { __typename?: 'Mutation', updateBusiness?: { __typename?: 'Business', id: string, sendSMSFromStaffNumber?: boolean | null } | null };

export type AllManagedStaffQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<StaffRole> | StaffRole>;
  status?: InputMaybe<Array<StaffStatus> | StaffStatus>;
}>;


export type AllManagedStaffQuery = { __typename?: 'Query', count: number, staff: Array<{ __typename?: 'Staff', id: string, alias?: string | null, name?: string | null, enabled?: boolean | null, description?: string | null, email: string, lastInvitedAt?: any | null, roles: Array<StaffRole>, status?: StaffStatus | null, createdAt: any, bookingPageUrl?: string | null, stripeProductIds?: Array<string> | null, business: { __typename?: 'Business', id: string, alias: string }, user?: { __typename?: 'User', id: string } | null, picture?: { __typename?: 'UploadedFile', id: string, url: string } | null, managedStaff?: Array<{ __typename?: 'Staff', id: string, name?: string | null, picture?: { __typename?: 'UploadedFile', id: string, url: string } | null }> | null }> };

export type AllManagedStaffCompactQueryVariables = Exact<{ [key: string]: never; }>;


export type AllManagedStaffCompactQuery = { __typename?: 'Query', staff: Array<{ __typename?: 'Staff', id: string, name?: string | null }> };

export type StaffFiltersQueryVariables = Exact<{
  filterIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type StaffFiltersQuery = { __typename?: 'Query', staffs: Array<{ __typename?: 'Staff', id: string, name?: string | null }> };

export type SelectStaffListQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  excludeIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  serviceId?: InputMaybe<Scalars['ID']>;
}>;


export type SelectStaffListQuery = { __typename?: 'Query', staff: Array<{ __typename?: 'Staff', id: string, name?: string | null, email: string }> };

export type CreateStaffMutationVariables = Exact<{
  email: Scalars['String'];
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  phoneMobile?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<StaffRole> | StaffRole>;
  stripeProductIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type CreateStaffMutation = { __typename?: 'Mutation', staff?: { __typename?: 'Staff', id: string, name?: string | null, description?: string | null, email: string, phoneMobile?: string | null, roles: Array<StaffRole>, stripeProductIds?: Array<string> | null } | null };

export type CreateWorkingHoursScheduleMutationVariables = Exact<{
  staffId: Scalars['ID'];
  from: Scalars['DateTime'];
  to?: InputMaybe<Scalars['DateTime']>;
  workingHours: Scalars['Json'];
}>;


export type CreateWorkingHoursScheduleMutation = { __typename?: 'Mutation', createWorkingHoursSchedule?: { __typename?: 'WorkingHoursSchedule', id: string, staff?: { __typename?: 'Staff', id: string, workingHours?: any | null, business: { __typename?: 'Business', id: string, workingHours: any, timezone?: string | null } } | null } | null };

export type DeleteStaffMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteStaffMutation = { __typename?: 'Mutation', deleteStaff?: { __typename?: 'Staff', id: string } | null };

export type DeleteWorkingHoursSchedulesMutationVariables = Exact<{
  staffId: Scalars['ID'];
}>;


export type DeleteWorkingHoursSchedulesMutation = { __typename?: 'Mutation', defaultToBusinessWorkingHours?: boolean | null };

export type ResendStaffInviteMutationVariables = Exact<{
  staffId: Scalars['ID'];
}>;


export type ResendStaffInviteMutation = { __typename?: 'Mutation', updateStaff?: { __typename?: 'Staff', id: string, enabled?: boolean | null, status?: StaffStatus | null, lastInvitedAt?: any | null, name?: string | null, email: string } | null };

export type StaffQueryVariables = Exact<{
  staffId: Scalars['ID'];
}>;


export type StaffQuery = { __typename?: 'Query', staff?: { __typename?: 'Staff', id: string, alias?: string | null, name?: string | null, roles: Array<StaffRole>, enabled?: boolean | null, status?: StaffStatus | null, description?: string | null, email: string, occupation?: string | null, phoneMobile?: string | null, timezone?: string | null, bookingPageUrl?: string | null, locationType?: string | null, locationValue?: string | null, business: { __typename?: 'Business', id: string, alias: string }, managedStaff?: Array<{ __typename?: 'Staff', id: string, name?: string | null }> | null, picture?: { __typename?: 'UploadedFile', id: string, url: string } | null } | null };

export type StaffIsAvailableQueryVariables = Exact<{
  staffId: Scalars['ID'];
  serviceId: Scalars['ID'];
  date: Scalars['DateTime'];
  duration: Scalars['Int'];
  ignoreAppointmentId?: InputMaybe<Scalars['ID']>;
}>;


export type StaffIsAvailableQuery = { __typename?: 'Query', isAvailable: boolean };

export type UpdateStaffMutationVariables = Exact<{
  id: Scalars['ID'];
  alias?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  phoneMobile?: InputMaybe<Scalars['String']>;
  pictureId?: InputMaybe<Scalars['ID']>;
  locationType?: InputMaybe<Scalars['String']>;
  locationValue?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<StaffRole> | StaffRole>;
  managedStaff?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  occupation?: InputMaybe<Scalars['String']>;
  marketingMeta?: InputMaybe<Scalars['Json']>;
  onboardingCompleted?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateStaffMutation = { __typename?: 'Mutation', updateStaff?: { __typename?: 'Staff', id: string, alias?: string | null, enabled?: boolean | null, status?: StaffStatus | null, name?: string | null, description?: string | null, email: string, phoneMobile?: string | null, locationType?: string | null, locationValue?: string | null, timezone?: string | null, roles: Array<StaffRole>, onboardingCompletedAt?: any | null, occupation?: string | null, marketingMeta?: any | null, picture?: { __typename?: 'UploadedFile', id: string, url: string } | null, managedStaff?: Array<{ __typename?: 'Staff', id: string, name?: string | null }> | null } | null };

export type WorkingHoursSchedulesQueryVariables = Exact<{
  staffId: Scalars['ID'];
  time: Scalars['DateTime'];
}>;


export type WorkingHoursSchedulesQuery = { __typename?: 'Query', staff?: { __typename?: 'Staff', id: string, name?: string | null, workingHours?: any | null, timezone?: string | null, business: { __typename?: 'Business', id: string, workingHours: any, timezone?: string | null } } | null };

export type BusinessStripeAccountQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BusinessStripeAccountQuery = { __typename?: 'Query', business?: { __typename?: 'Business', id: string, stripeConnectAccount?: { __typename?: 'StripeConnectAccount', id: string, onboardingCompletedAt?: any | null } | null } | null };

export type CompleteStripeConnectAccountOnboardingMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompleteStripeConnectAccountOnboardingMutation = { __typename?: 'Mutation', completeStripeConnectAccountOnboarding: { __typename?: 'StripeConnectAccount', id: string, onboardingCompletedAt?: any | null } };

export type CreateStripeConnectAccountMutationVariables = Exact<{
  country: Scalars['String'];
}>;


export type CreateStripeConnectAccountMutation = { __typename?: 'Mutation', createStripeConnectAccount: { __typename?: 'StripeConnectAccountCreatePayload', success: boolean, stripeConnectAccountId?: string | null, session?: { __typename?: 'StripeConnectAccountSession', clientSecret: string, expiresAt: any } | null } };

export type CreateStripeConnectAccountSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateStripeConnectAccountSessionMutation = { __typename?: 'Mutation', session?: { __typename?: 'StripeConnectAccountSession', clientSecret: string, expiresAt: any } | null };

export type StripeProductsQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type StripeProductsQuery = { __typename?: 'Query', business?: { __typename?: 'Business', stripeData?: any | null, stripeProducts: Array<{ __typename?: 'StripeProduct', id: string, name: string, description: string, featureList: Array<string>, prices: Array<{ __typename?: 'StripePrice', id: string, currency: string, unitAmount: number, interval: string }> }> } | null };

export type SyncStripeCustomerMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncStripeCustomerMutation = { __typename?: 'Mutation', syncStripeCustomer: { __typename?: 'SyncStripeCustomerPayload', business: { __typename?: 'Business', id: string, stripeCustomerId?: string | null, stripeSubscriptionId?: string | null, stripeData?: any | null } } };

export type AllTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllTagsQuery = { __typename?: 'Query', allTags: Array<{ __typename?: 'Tag', id: string, alias: string, name: string, description: string, color: string }> };

export type TagQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TagQuery = { __typename?: 'Query', tag?: { __typename?: 'Tag', id: string, alias: string, name: string, description: string, color: string } | null };

export type CreateTagMutationVariables = Exact<{
  alias: Scalars['String'];
  name: Scalars['String'];
  color: Scalars['String'];
  description: Scalars['String'];
}>;


export type CreateTagMutation = { __typename?: 'Mutation', createTag: { __typename?: 'Tag', id: string, alias: string, name: string, color: string, description: string, createdAt: any } };

export type UpdateTagMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  color: Scalars['String'];
  description: Scalars['String'];
}>;


export type UpdateTagMutation = { __typename?: 'Mutation', updateTag: { __typename?: 'Tag', id: string, alias: string, name: string, color: string, description: string } };

export type UpdatePatientTagsMutationVariables = Exact<{
  patientId: Scalars['ID'];
  tagIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdatePatientTagsMutation = { __typename?: 'Mutation', setPatientTags: { __typename?: 'Patient', id: string, tagIds: Array<string> } };

export type ThirdPartyConnectionsQueryVariables = Exact<{
  businessId: Scalars['ID'];
  staffId: Scalars['ID'];
}>;


export type ThirdPartyConnectionsQuery = { __typename?: 'Query', businessZoomConnections: Array<{ __typename?: 'ThirdPartyConnection', id: string, provider: string, name: string, email: string }>, staffZoomConnections: Array<{ __typename?: 'ThirdPartyConnection', id: string, provider: string, name: string, email: string }> };

export type CreateThirdPartyConnectionOAuthSessionMutationVariables = Exact<{
  provider: ThirdPartyConnectionProvider;
  resourceType: ThirdPartyConnectionResourceType;
  redirectUrl: Scalars['String'];
}>;


export type CreateThirdPartyConnectionOAuthSessionMutation = { __typename?: 'Mutation', createThirdPartyConnectionOAuthSession: { __typename?: 'ThirdPartyConnectionOAuthSession', redirectUrl: string } };

export type UpdateThirdPartyConnectionMutationVariables = Exact<{
  id: Scalars['ID'];
  enabled: Scalars['Boolean'];
}>;


export type UpdateThirdPartyConnectionMutation = { __typename?: 'Mutation', updateThirdPartyConnection: { __typename?: 'ThirdPartyConnection', id: string, provider: string, name: string, email: string, staff?: { __typename?: 'Staff', id: string, zoomConnection?: { __typename?: 'ThirdPartyConnection', id: string } | null } | null, business: { __typename?: 'Business', id: string, zoomConnection?: { __typename?: 'ThirdPartyConnection', id: string } | null } } };

export type DeleteThirdPartyConnectionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteThirdPartyConnectionMutation = { __typename?: 'Mutation', deleteThirdPartyConnection?: boolean | null };

export type CreateTimeBlockerMutationVariables = Exact<{
  staffId?: InputMaybe<Scalars['ID']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  reason?: InputMaybe<Scalars['String']>;
}>;


export type CreateTimeBlockerMutation = { __typename?: 'Mutation', createTimeBlocker?: { __typename?: 'TimeBlocker', id: string, startDate: any, endDate: any, reason?: string | null, business?: { __typename?: 'Business', id: string } | null, staff?: { __typename?: 'Staff', id: string, name?: string | null } | null } | null };

export type CalendarTimeBlockersQueryVariables = Exact<{
  dateStart: Scalars['DateTime'];
  dateEnd: Scalars['DateTime'];
  staffsIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CalendarTimeBlockersQuery = { __typename?: 'Query', timeBlockers: Array<{ __typename?: 'TimeBlocker', id: string, startDate: any, endDate: any, reason?: string | null, staff?: { __typename?: 'Staff', id: string, name?: string | null } | null }> };

export type UpdateSeatsMutationVariables = Exact<{
  productQuantities: Scalars['Json'];
  interval: StripeInterval;
}>;


export type UpdateSeatsMutation = { __typename?: 'Mutation', updateSeats: { __typename?: 'UpdateSeatsResponse', business: { __typename?: 'Business', id: string, stripeData?: any | null }, checkoutSession?: { __typename?: 'StripeCheckoutSession', id: string, url: string } | null } };

export type UpdateUserPersonalDataMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type UpdateUserPersonalDataMutation = { __typename?: 'Mutation', updateUserPersonalData: { __typename?: 'User', id: string, name?: string | null } };

export type WorkingHoursSlotsQueryVariables = Exact<{
  staffIds: Array<Scalars['ID']> | Scalars['ID'];
  timeIncrement?: InputMaybe<Scalars['Int']>;
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
}>;


export type WorkingHoursSlotsQuery = { __typename?: 'Query', workingHoursSlots: { __typename?: 'WorkingHoursSlots', slots: Array<Array<any>>, staffSlots?: any | null } };

export const FieldsFragmentDoc = gql`
    fragment Fields on Business {
  id
  alias
  name
  bookingPageUrl
  timezone
  website
  logo {
    id
    url
  }
  staffRoles
  availableFeatures
  enabled
  dateFormat
  usage
  zoomConnection {
    id
  }
  teamsConnection {
    id
  }
  bookingForm {
    id
  }
  reschedulingForm {
    id
  }
  stripeConnectAccount {
    id
    stripeAccountId
    onboardingCompletedAt
  }
  marketingMeta
  onboardingCompletedAt
  primaryDomain
  primaryDomainVerifiedAt
  stripeCustomerId
  stripeSubscriptionId
  stripeData
  remainingTrialDays
  subscriptionStatus
  invoicesTaxBehaviour
}
    `;
export const FormFieldsFragmentDoc = gql`
    fragment FormFields on Form {
  id
  url
  title
  alias
  description
  thumbnail
  category
  useAsTemplate
  createdAt
  updatedAt
  status
  steps
  submissionsCount
  unreadSubmissionsCount
}
    `;
export const FormEditorFieldsFragmentDoc = gql`
    fragment FormEditorFields on Form {
  ...FormFields
  settings
}
    ${FormFieldsFragmentDoc}`;
export const InvoiceFieldsFragmentDoc = gql`
    fragment InvoiceFields on Invoice {
  id
  number
  createdAt
  updatedAt
  patient {
    id
    name
    email
  }
  currency
  appointment {
    id
  }
  status
  voidedBy {
    id
    name
  }
  business {
    id
    defaultInvoiceDaysUntilDue
  }
  voidedAt
  dueDate
  sentAt
  amountTotal
  amountSubtotal
  amountOutstanding
  amountDiscounted
  amountExcludingTax
  amountTax
  coupon {
    id
    code
    name
  }
  markedUncollectibleAt
  markedUncollectibleBy {
    id
    name
  }
  finalizedAt
  items {
    id
    createdAt
    updatedAt
    description
    quantity
    unitAmount
    amount
  }
}
    `;
export const CreateAdHocAppointmentDocument = gql`
    mutation createAdHocAppointment($title: String!, $start: DateTime!, $end: DateTime!, $staffId: ID!, $patientId: ID!, $locationType: String, $locationValue: String, $locationId: ID) {
  createAdHocAppointment(
    title: $title
    start: $start
    end: $end
    staffId: $staffId
    patientId: $patientId
    locationType: $locationType
    locationValue: $locationValue
    locationId: $locationId
  ) {
    id
  }
}
    `;
export type CreateAdHocAppointmentMutationFn = Apollo.MutationFunction<CreateAdHocAppointmentMutation, CreateAdHocAppointmentMutationVariables>;

/**
 * __useCreateAdHocAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateAdHocAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdHocAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdHocAppointmentMutation, { data, loading, error }] = useCreateAdHocAppointmentMutation({
 *   variables: {
 *      title: // value for 'title'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      staffId: // value for 'staffId'
 *      patientId: // value for 'patientId'
 *      locationType: // value for 'locationType'
 *      locationValue: // value for 'locationValue'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useCreateAdHocAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdHocAppointmentMutation, CreateAdHocAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdHocAppointmentMutation, CreateAdHocAppointmentMutationVariables>(CreateAdHocAppointmentDocument, options);
      }
export type CreateAdHocAppointmentMutationHookResult = ReturnType<typeof useCreateAdHocAppointmentMutation>;
export type CreateAdHocAppointmentMutationResult = Apollo.MutationResult<CreateAdHocAppointmentMutation>;
export type CreateAdHocAppointmentMutationOptions = Apollo.BaseMutationOptions<CreateAdHocAppointmentMutation, CreateAdHocAppointmentMutationVariables>;
export const CreateAdHocServiceDocument = gql`
    mutation createAdHocService($businessId: ID!, $staffId: ID!, $timeslots: Json!, $timeIncrement: Int!, $name: String!, $duration: Int!, $maxAppointments: Int!, $locationId: ID, $locationType: String, $locationValue: String) {
  adHocService: createAdHocService(
    businessId: $businessId
    staffId: $staffId
    timeslots: $timeslots
    timeIncrement: $timeIncrement
    name: $name
    duration: $duration
    maxAppointments: $maxAppointments
    locationType: $locationType
    locationValue: $locationValue
    locationId: $locationId
  ) {
    id
  }
}
    `;
export type CreateAdHocServiceMutationFn = Apollo.MutationFunction<CreateAdHocServiceMutation, CreateAdHocServiceMutationVariables>;

/**
 * __useCreateAdHocServiceMutation__
 *
 * To run a mutation, you first call `useCreateAdHocServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdHocServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdHocServiceMutation, { data, loading, error }] = useCreateAdHocServiceMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      staffId: // value for 'staffId'
 *      timeslots: // value for 'timeslots'
 *      timeIncrement: // value for 'timeIncrement'
 *      name: // value for 'name'
 *      duration: // value for 'duration'
 *      maxAppointments: // value for 'maxAppointments'
 *      locationId: // value for 'locationId'
 *      locationType: // value for 'locationType'
 *      locationValue: // value for 'locationValue'
 *   },
 * });
 */
export function useCreateAdHocServiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdHocServiceMutation, CreateAdHocServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdHocServiceMutation, CreateAdHocServiceMutationVariables>(CreateAdHocServiceDocument, options);
      }
export type CreateAdHocServiceMutationHookResult = ReturnType<typeof useCreateAdHocServiceMutation>;
export type CreateAdHocServiceMutationResult = Apollo.MutationResult<CreateAdHocServiceMutation>;
export type CreateAdHocServiceMutationOptions = Apollo.BaseMutationOptions<CreateAdHocServiceMutation, CreateAdHocServiceMutationVariables>;
export const AllApiKeysDocument = gql`
    query allApiKeys {
  allApiKeys {
    id
    value
    description
    createdAt
    updatedAt
    expiresAt
    invalidatedAt
  }
}
    `;

/**
 * __useAllApiKeysQuery__
 *
 * To run a query within a React component, call `useAllApiKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllApiKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllApiKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllApiKeysQuery(baseOptions?: Apollo.QueryHookOptions<AllApiKeysQuery, AllApiKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllApiKeysQuery, AllApiKeysQueryVariables>(AllApiKeysDocument, options);
      }
export function useAllApiKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllApiKeysQuery, AllApiKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllApiKeysQuery, AllApiKeysQueryVariables>(AllApiKeysDocument, options);
        }
export type AllApiKeysQueryHookResult = ReturnType<typeof useAllApiKeysQuery>;
export type AllApiKeysLazyQueryHookResult = ReturnType<typeof useAllApiKeysLazyQuery>;
export type AllApiKeysQueryResult = Apollo.QueryResult<AllApiKeysQuery, AllApiKeysQueryVariables>;
export const AllBusinessPatientsDocument = gql`
    query allBusinessPatients($businessId: ID!, $limit: Int!, $skip: Int, $search: String, $sortBy: PatientsOrderByInput) {
  patients: businessPatients(
    businessId: $businessId
    limit: $limit
    skip: $skip
    search: $search
    sortBy: $sortBy
  ) {
    id
    name
    preferredName
    email
    phoneMobile
    patientType
    dob
    age
    gender
  }
  count: businessPatientsCount(businessId: $businessId, search: $search)
}
    `;

/**
 * __useAllBusinessPatientsQuery__
 *
 * To run a query within a React component, call `useAllBusinessPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBusinessPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBusinessPatientsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      search: // value for 'search'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useAllBusinessPatientsQuery(baseOptions: Apollo.QueryHookOptions<AllBusinessPatientsQuery, AllBusinessPatientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllBusinessPatientsQuery, AllBusinessPatientsQueryVariables>(AllBusinessPatientsDocument, options);
      }
export function useAllBusinessPatientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllBusinessPatientsQuery, AllBusinessPatientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllBusinessPatientsQuery, AllBusinessPatientsQueryVariables>(AllBusinessPatientsDocument, options);
        }
export type AllBusinessPatientsQueryHookResult = ReturnType<typeof useAllBusinessPatientsQuery>;
export type AllBusinessPatientsLazyQueryHookResult = ReturnType<typeof useAllBusinessPatientsLazyQuery>;
export type AllBusinessPatientsQueryResult = Apollo.QueryResult<AllBusinessPatientsQuery, AllBusinessPatientsQueryVariables>;
export const AnalyticsSmsDocument = gql`
    query analyticsSMS($dateFrom: DateTime, $dateTo: DateTime) {
  analytics {
    sms(dateFrom: $dateFrom, dateTo: $dateTo) {
      date
      sent
      delivered
      credits
    }
  }
}
    `;

/**
 * __useAnalyticsSmsQuery__
 *
 * To run a query within a React component, call `useAnalyticsSmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsSmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsSmsQuery({
 *   variables: {
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useAnalyticsSmsQuery(baseOptions?: Apollo.QueryHookOptions<AnalyticsSmsQuery, AnalyticsSmsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticsSmsQuery, AnalyticsSmsQueryVariables>(AnalyticsSmsDocument, options);
      }
export function useAnalyticsSmsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsSmsQuery, AnalyticsSmsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticsSmsQuery, AnalyticsSmsQueryVariables>(AnalyticsSmsDocument, options);
        }
export type AnalyticsSmsQueryHookResult = ReturnType<typeof useAnalyticsSmsQuery>;
export type AnalyticsSmsLazyQueryHookResult = ReturnType<typeof useAnalyticsSmsLazyQuery>;
export type AnalyticsSmsQueryResult = Apollo.QueryResult<AnalyticsSmsQuery, AnalyticsSmsQueryVariables>;
export const AnalyticsAppointmentsDocument = gql`
    query analyticsAppointments($dateFrom: DateTime, $dateTo: DateTime, $staffId: ID) {
  analytics {
    appointments(dateFrom: $dateFrom, dateTo: $dateTo, staffId: $staffId) {
      date
      created
      cancelled
    }
  }
}
    `;

/**
 * __useAnalyticsAppointmentsQuery__
 *
 * To run a query within a React component, call `useAnalyticsAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsAppointmentsQuery({
 *   variables: {
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useAnalyticsAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<AnalyticsAppointmentsQuery, AnalyticsAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticsAppointmentsQuery, AnalyticsAppointmentsQueryVariables>(AnalyticsAppointmentsDocument, options);
      }
export function useAnalyticsAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsAppointmentsQuery, AnalyticsAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticsAppointmentsQuery, AnalyticsAppointmentsQueryVariables>(AnalyticsAppointmentsDocument, options);
        }
export type AnalyticsAppointmentsQueryHookResult = ReturnType<typeof useAnalyticsAppointmentsQuery>;
export type AnalyticsAppointmentsLazyQueryHookResult = ReturnType<typeof useAnalyticsAppointmentsLazyQuery>;
export type AnalyticsAppointmentsQueryResult = Apollo.QueryResult<AnalyticsAppointmentsQuery, AnalyticsAppointmentsQueryVariables>;
export const AnalyticsEventsDocument = gql`
    query analyticsEvents($dateFrom: DateTime, $dateTo: DateTime) {
  analytics {
    events(dateFrom: $dateFrom, dateTo: $dateTo) {
      date
      all
      appointmentCreated
      appointmentUpdated
      appointmentDeleted
      businessUpdated
      formCreated
      formUpdated
      formDeleted
      patientCreated
      patientUpdated
      patientDeleted
      serviceCreated
      serviceUpdated
      serviceDeleted
      staffCreated
      staffUpdated
      staffDeleted
    }
  }
}
    `;

/**
 * __useAnalyticsEventsQuery__
 *
 * To run a query within a React component, call `useAnalyticsEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsEventsQuery({
 *   variables: {
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useAnalyticsEventsQuery(baseOptions?: Apollo.QueryHookOptions<AnalyticsEventsQuery, AnalyticsEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticsEventsQuery, AnalyticsEventsQueryVariables>(AnalyticsEventsDocument, options);
      }
export function useAnalyticsEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsEventsQuery, AnalyticsEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticsEventsQuery, AnalyticsEventsQueryVariables>(AnalyticsEventsDocument, options);
        }
export type AnalyticsEventsQueryHookResult = ReturnType<typeof useAnalyticsEventsQuery>;
export type AnalyticsEventsLazyQueryHookResult = ReturnType<typeof useAnalyticsEventsLazyQuery>;
export type AnalyticsEventsQueryResult = Apollo.QueryResult<AnalyticsEventsQuery, AnalyticsEventsQueryVariables>;
export const AppointmentDocument = gql`
    query appointment($id: ID!) {
  appointment: Appointment(id: $id) {
    id
    date
    duration
    approved
    locationType
    locationValue
    currency
    price
    answers
    paymentProvider
    rrule
    originalDate
    transactionUrl
    patientVerified
    staff {
      id
      name
      email
    }
    patient {
      id
      name
      email
      phoneMobile
    }
    service {
      id
      name
      description
      durations
    }
    location {
      id
      name
    }
  }
}
    `;

/**
 * __useAppointmentQuery__
 *
 * To run a query within a React component, call `useAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppointmentQuery(baseOptions: Apollo.QueryHookOptions<AppointmentQuery, AppointmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentQuery, AppointmentQueryVariables>(AppointmentDocument, options);
      }
export function useAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentQuery, AppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentQuery, AppointmentQueryVariables>(AppointmentDocument, options);
        }
export type AppointmentQueryHookResult = ReturnType<typeof useAppointmentQuery>;
export type AppointmentLazyQueryHookResult = ReturnType<typeof useAppointmentLazyQuery>;
export type AppointmentQueryResult = Apollo.QueryResult<AppointmentQuery, AppointmentQueryVariables>;
export const ApproveAppointmentDocument = gql`
    mutation approveAppointment($id: ID!, $ignoreBusyTimes: Boolean) {
  approveAppointment(id: $id, ignoreBusyTimes: $ignoreBusyTimes) {
    id
    approved
  }
}
    `;
export type ApproveAppointmentMutationFn = Apollo.MutationFunction<ApproveAppointmentMutation, ApproveAppointmentMutationVariables>;

/**
 * __useApproveAppointmentMutation__
 *
 * To run a mutation, you first call `useApproveAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveAppointmentMutation, { data, loading, error }] = useApproveAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      ignoreBusyTimes: // value for 'ignoreBusyTimes'
 *   },
 * });
 */
export function useApproveAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<ApproveAppointmentMutation, ApproveAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveAppointmentMutation, ApproveAppointmentMutationVariables>(ApproveAppointmentDocument, options);
      }
export type ApproveAppointmentMutationHookResult = ReturnType<typeof useApproveAppointmentMutation>;
export type ApproveAppointmentMutationResult = Apollo.MutationResult<ApproveAppointmentMutation>;
export type ApproveAppointmentMutationOptions = Apollo.BaseMutationOptions<ApproveAppointmentMutation, ApproveAppointmentMutationVariables>;
export const CancelAppointmentDocument = gql`
    mutation cancelAppointment($id: ID!, $futureOccurrences: Boolean, $occurrenceDate: DateTime) {
  deleteAppointment(
    id: $id
    futureOccurrences: $futureOccurrences
    occurrenceDate: $occurrenceDate
  ) {
    id
    cancelled
  }
}
    `;
export type CancelAppointmentMutationFn = Apollo.MutationFunction<CancelAppointmentMutation, CancelAppointmentMutationVariables>;

/**
 * __useCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      futureOccurrences: // value for 'futureOccurrences'
 *      occurrenceDate: // value for 'occurrenceDate'
 *   },
 * });
 */
export function useCancelAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelAppointmentMutation, CancelAppointmentMutationVariables>(CancelAppointmentDocument, options);
      }
export type CancelAppointmentMutationHookResult = ReturnType<typeof useCancelAppointmentMutation>;
export type CancelAppointmentMutationResult = Apollo.MutationResult<CancelAppointmentMutation>;
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>;
export const CancelGroupBookingDocument = gql`
    mutation cancelGroupBooking($groupBookingId: String!) {
  cancelGroupBooking(groupBookingId: $groupBookingId) {
    id
  }
}
    `;
export type CancelGroupBookingMutationFn = Apollo.MutationFunction<CancelGroupBookingMutation, CancelGroupBookingMutationVariables>;

/**
 * __useCancelGroupBookingMutation__
 *
 * To run a mutation, you first call `useCancelGroupBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelGroupBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelGroupBookingMutation, { data, loading, error }] = useCancelGroupBookingMutation({
 *   variables: {
 *      groupBookingId: // value for 'groupBookingId'
 *   },
 * });
 */
export function useCancelGroupBookingMutation(baseOptions?: Apollo.MutationHookOptions<CancelGroupBookingMutation, CancelGroupBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelGroupBookingMutation, CancelGroupBookingMutationVariables>(CancelGroupBookingDocument, options);
      }
export type CancelGroupBookingMutationHookResult = ReturnType<typeof useCancelGroupBookingMutation>;
export type CancelGroupBookingMutationResult = Apollo.MutationResult<CancelGroupBookingMutation>;
export type CancelGroupBookingMutationOptions = Apollo.BaseMutationOptions<CancelGroupBookingMutation, CancelGroupBookingMutationVariables>;
export const CreateAppointmentDocument = gql`
    mutation createAppointment($date: DateTime!, $duration: Int!, $staffId: ID!, $patientId: ID!, $serviceId: ID!, $requestUrl: String, $ignoreBusyTimes: Boolean) {
  createAppointment(
    date: $date
    duration: $duration
    staffId: $staffId
    patient: {connect: {id: $patientId}}
    serviceId: $serviceId
    requestUrl: $requestUrl
    ignoreBusyTimes: $ignoreBusyTimes
  ) {
    id
    date
    price
    currency
    duration
    business {
      id
    }
    staff {
      id
    }
    patient {
      id
    }
    service {
      id
    }
    rrule
    originalDate
    requestUrl
  }
}
    `;
export type CreateAppointmentMutationFn = Apollo.MutationFunction<CreateAppointmentMutation, CreateAppointmentMutationVariables>;

/**
 * __useCreateAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentMutation, { data, loading, error }] = useCreateAppointmentMutation({
 *   variables: {
 *      date: // value for 'date'
 *      duration: // value for 'duration'
 *      staffId: // value for 'staffId'
 *      patientId: // value for 'patientId'
 *      serviceId: // value for 'serviceId'
 *      requestUrl: // value for 'requestUrl'
 *      ignoreBusyTimes: // value for 'ignoreBusyTimes'
 *   },
 * });
 */
export function useCreateAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAppointmentMutation, CreateAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAppointmentMutation, CreateAppointmentMutationVariables>(CreateAppointmentDocument, options);
      }
export type CreateAppointmentMutationHookResult = ReturnType<typeof useCreateAppointmentMutation>;
export type CreateAppointmentMutationResult = Apollo.MutationResult<CreateAppointmentMutation>;
export type CreateAppointmentMutationOptions = Apollo.BaseMutationOptions<CreateAppointmentMutation, CreateAppointmentMutationVariables>;
export const GroupBookingAppointmentsDocument = gql`
    query groupBookingAppointments($groupBookingId: ID!) {
  appointments: GroupBookingAppointments(groupBookingId: $groupBookingId) {
    id
    date
    duration
    approved
    cancelled
    staff {
      id
      name
      email
    }
    patient {
      id
      name
      email
      phoneMobile
    }
    service {
      id
      name
    }
  }
}
    `;

/**
 * __useGroupBookingAppointmentsQuery__
 *
 * To run a query within a React component, call `useGroupBookingAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupBookingAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupBookingAppointmentsQuery({
 *   variables: {
 *      groupBookingId: // value for 'groupBookingId'
 *   },
 * });
 */
export function useGroupBookingAppointmentsQuery(baseOptions: Apollo.QueryHookOptions<GroupBookingAppointmentsQuery, GroupBookingAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupBookingAppointmentsQuery, GroupBookingAppointmentsQueryVariables>(GroupBookingAppointmentsDocument, options);
      }
export function useGroupBookingAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupBookingAppointmentsQuery, GroupBookingAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupBookingAppointmentsQuery, GroupBookingAppointmentsQueryVariables>(GroupBookingAppointmentsDocument, options);
        }
export type GroupBookingAppointmentsQueryHookResult = ReturnType<typeof useGroupBookingAppointmentsQuery>;
export type GroupBookingAppointmentsLazyQueryHookResult = ReturnType<typeof useGroupBookingAppointmentsLazyQuery>;
export type GroupBookingAppointmentsQueryResult = Apollo.QueryResult<GroupBookingAppointmentsQuery, GroupBookingAppointmentsQueryVariables>;
export const UpdateAppointmentDocument = gql`
    mutation updateAppointment($id: ID!, $date: DateTime!, $duration: Int, $staffId: ID, $patientId: ID, $serviceId: ID, $singleOccurrence: Boolean, $originalDate: DateTime, $futureOccurrences: Boolean, $ignoreBusyTimes: Boolean) {
  updateAppointment(
    id: $id
    date: $date
    duration: $duration
    staffId: $staffId
    patient: {connect: {id: $patientId}}
    serviceId: $serviceId
    singleOccurrence: $singleOccurrence
    originalDate: $originalDate
    futureOccurrences: $futureOccurrences
    ignoreBusyTimes: $ignoreBusyTimes
  ) {
    id
    date
    price
    currency
    duration
    business {
      id
    }
    staff {
      id
    }
    patient {
      id
    }
    service {
      id
    }
    rrule
    originalDate
  }
}
    `;
export type UpdateAppointmentMutationFn = Apollo.MutationFunction<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>;

/**
 * __useUpdateAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentMutation, { data, loading, error }] = useUpdateAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *      duration: // value for 'duration'
 *      staffId: // value for 'staffId'
 *      patientId: // value for 'patientId'
 *      serviceId: // value for 'serviceId'
 *      singleOccurrence: // value for 'singleOccurrence'
 *      originalDate: // value for 'originalDate'
 *      futureOccurrences: // value for 'futureOccurrences'
 *      ignoreBusyTimes: // value for 'ignoreBusyTimes'
 *   },
 * });
 */
export function useUpdateAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>(UpdateAppointmentDocument, options);
      }
export type UpdateAppointmentMutationHookResult = ReturnType<typeof useUpdateAppointmentMutation>;
export type UpdateAppointmentMutationResult = Apollo.MutationResult<UpdateAppointmentMutation>;
export type UpdateAppointmentMutationOptions = Apollo.BaseMutationOptions<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>;
export const UpdateGroupBookingDocument = gql`
    mutation updateGroupBooking($groupBookingId: String!, $date: DateTime!) {
  updateGroupBooking(groupBookingId: $groupBookingId, date: $date) {
    id
    date
    groupBookingId
  }
}
    `;
export type UpdateGroupBookingMutationFn = Apollo.MutationFunction<UpdateGroupBookingMutation, UpdateGroupBookingMutationVariables>;

/**
 * __useUpdateGroupBookingMutation__
 *
 * To run a mutation, you first call `useUpdateGroupBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupBookingMutation, { data, loading, error }] = useUpdateGroupBookingMutation({
 *   variables: {
 *      groupBookingId: // value for 'groupBookingId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUpdateGroupBookingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroupBookingMutation, UpdateGroupBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGroupBookingMutation, UpdateGroupBookingMutationVariables>(UpdateGroupBookingDocument, options);
      }
export type UpdateGroupBookingMutationHookResult = ReturnType<typeof useUpdateGroupBookingMutation>;
export type UpdateGroupBookingMutationResult = Apollo.MutationResult<UpdateGroupBookingMutation>;
export type UpdateGroupBookingMutationOptions = Apollo.BaseMutationOptions<UpdateGroupBookingMutation, UpdateGroupBookingMutationVariables>;
export const AssignStaffStripeProductIdsDocument = gql`
    mutation assignStaffStripeProductIds($staffId: ID!, $productIds: [String!]!) {
  assignStaffStripeProductIds(staffId: $staffId, productIds: $productIds) {
    id
    stripeProductIds
    availableBoolFeatures
    business {
      id
      stripeCustomerId
      stripeSubscriptionId
      stripeData
    }
  }
}
    `;
export type AssignStaffStripeProductIdsMutationFn = Apollo.MutationFunction<AssignStaffStripeProductIdsMutation, AssignStaffStripeProductIdsMutationVariables>;

/**
 * __useAssignStaffStripeProductIdsMutation__
 *
 * To run a mutation, you first call `useAssignStaffStripeProductIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignStaffStripeProductIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignStaffStripeProductIdsMutation, { data, loading, error }] = useAssignStaffStripeProductIdsMutation({
 *   variables: {
 *      staffId: // value for 'staffId'
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useAssignStaffStripeProductIdsMutation(baseOptions?: Apollo.MutationHookOptions<AssignStaffStripeProductIdsMutation, AssignStaffStripeProductIdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignStaffStripeProductIdsMutation, AssignStaffStripeProductIdsMutationVariables>(AssignStaffStripeProductIdsDocument, options);
      }
export type AssignStaffStripeProductIdsMutationHookResult = ReturnType<typeof useAssignStaffStripeProductIdsMutation>;
export type AssignStaffStripeProductIdsMutationResult = Apollo.MutationResult<AssignStaffStripeProductIdsMutation>;
export type AssignStaffStripeProductIdsMutationOptions = Apollo.BaseMutationOptions<AssignStaffStripeProductIdsMutation, AssignStaffStripeProductIdsMutationVariables>;
export const AuthenticateUserDocument = gql`
    mutation authenticateUser($auth0Code: String!, $timezone: String, $currency: String, $signupKey: String, $abTestVariant: String) {
  authenticateUser(
    auth0Code: $auth0Code
    timezone: $timezone
    currency: $currency
    signupKey: $signupKey
    abTestVariant: $abTestVariant
  ) {
    id
    token
    userIsNew
    forcedSessionId
    redirectUrl
    user {
      id
    }
  }
}
    `;
export type AuthenticateUserMutationFn = Apollo.MutationFunction<AuthenticateUserMutation, AuthenticateUserMutationVariables>;

/**
 * __useAuthenticateUserMutation__
 *
 * To run a mutation, you first call `useAuthenticateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateUserMutation, { data, loading, error }] = useAuthenticateUserMutation({
 *   variables: {
 *      auth0Code: // value for 'auth0Code'
 *      timezone: // value for 'timezone'
 *      currency: // value for 'currency'
 *      signupKey: // value for 'signupKey'
 *      abTestVariant: // value for 'abTestVariant'
 *   },
 * });
 */
export function useAuthenticateUserMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateUserMutation, AuthenticateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateUserMutation, AuthenticateUserMutationVariables>(AuthenticateUserDocument, options);
      }
export type AuthenticateUserMutationHookResult = ReturnType<typeof useAuthenticateUserMutation>;
export type AuthenticateUserMutationResult = Apollo.MutationResult<AuthenticateUserMutation>;
export type AuthenticateUserMutationOptions = Apollo.BaseMutationOptions<AuthenticateUserMutation, AuthenticateUserMutationVariables>;
export const BusinessDocument = gql`
    query business($id: ID!) {
  business: Business(id: $id) {
    ...Fields
  }
}
    ${FieldsFragmentDoc}`;

/**
 * __useBusinessQuery__
 *
 * To run a query within a React component, call `useBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusinessQuery(baseOptions: Apollo.QueryHookOptions<BusinessQuery, BusinessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessQuery, BusinessQueryVariables>(BusinessDocument, options);
      }
export function useBusinessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessQuery, BusinessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessQuery, BusinessQueryVariables>(BusinessDocument, options);
        }
export type BusinessQueryHookResult = ReturnType<typeof useBusinessQuery>;
export type BusinessLazyQueryHookResult = ReturnType<typeof useBusinessLazyQuery>;
export type BusinessQueryResult = Apollo.QueryResult<BusinessQuery, BusinessQueryVariables>;
export const CurrentBusinessDocument = gql`
    query currentBusiness($businessId: ID!) {
  currentBusiness(businessId: $businessId) {
    ...Fields
  }
}
    ${FieldsFragmentDoc}`;

/**
 * __useCurrentBusinessQuery__
 *
 * To run a query within a React component, call `useCurrentBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentBusinessQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useCurrentBusinessQuery(baseOptions: Apollo.QueryHookOptions<CurrentBusinessQuery, CurrentBusinessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentBusinessQuery, CurrentBusinessQueryVariables>(CurrentBusinessDocument, options);
      }
export function useCurrentBusinessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentBusinessQuery, CurrentBusinessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentBusinessQuery, CurrentBusinessQueryVariables>(CurrentBusinessDocument, options);
        }
export type CurrentBusinessQueryHookResult = ReturnType<typeof useCurrentBusinessQuery>;
export type CurrentBusinessLazyQueryHookResult = ReturnType<typeof useCurrentBusinessLazyQuery>;
export type CurrentBusinessQueryResult = Apollo.QueryResult<CurrentBusinessQuery, CurrentBusinessQueryVariables>;
export const BusinessSettingsDocument = gql`
    query businessSettings($id: ID!) {
  business: Business(id: $id) {
    id
    alias
    name
    email
    website
    timezone
    currency
    dateFormat
    physicalAddress
    bufferBeforeMinutes
    bufferAfterMinutes
    allowStaffApprovals
    availabilityLimitDays
    availableFeatures
    workingHours
    ownerNotificationsRecipient
    ownerNotificationsCC
    bookingNotificationsReplyTo
    attachICSFiles
    sendSMSFromStaffNumber
    color
    logo {
      id
      url
    }
    logoPosition
    allowChoosingStaff
    allowNoStaffPreference
    marketingMeta
    defaultInvoiceDaysUntilDue
    autoGenerateBookingInvoices
    bookingsRequireApprovalUntilPaid
    invoicesTaxBehaviour
  }
}
    `;

/**
 * __useBusinessSettingsQuery__
 *
 * To run a query within a React component, call `useBusinessSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusinessSettingsQuery(baseOptions: Apollo.QueryHookOptions<BusinessSettingsQuery, BusinessSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessSettingsQuery, BusinessSettingsQueryVariables>(BusinessSettingsDocument, options);
      }
export function useBusinessSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessSettingsQuery, BusinessSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessSettingsQuery, BusinessSettingsQueryVariables>(BusinessSettingsDocument, options);
        }
export type BusinessSettingsQueryHookResult = ReturnType<typeof useBusinessSettingsQuery>;
export type BusinessSettingsLazyQueryHookResult = ReturnType<typeof useBusinessSettingsLazyQuery>;
export type BusinessSettingsQueryResult = Apollo.QueryResult<BusinessSettingsQuery, BusinessSettingsQueryVariables>;
export const UpdateBusinessSettingsDocument = gql`
    mutation updateBusinessSettings($id: ID!, $enabled: Boolean, $alias: String, $name: String, $email: String, $color: String, $logoId: ID, $website: String, $currency: String, $physicalAddress: String, $timezone: String, $workingHours: Json, $dateFormat: Json, $bufferBeforeMinutes: Int, $bufferAfterMinutes: Int, $redirectAfterBookingUrl: String, $postOnRedirect: Boolean, $includeAnswersOnRedirect: Boolean, $googleTrackingId: String, $trackingPixelUrl: String, $availabilityLimitDays: Int, $allowStaffApprovals: Boolean, $logoPosition: String, $allowTimeRequests: Boolean, $timeRequestButtonLabel: String, $timeRequestText: String, $hidePrivacyButton: Boolean, $allowChoosingStaff: Boolean, $allowNoStaffPreference: Boolean, $exportedEventsTemplate: Json, $marketingMeta: Json, $onboardingCompleted: Boolean, $defaultInvoiceDaysUntilDue: Int, $autoGenerateBookingInvoices: Boolean, $bookingsRequireApprovalUntilPaid: Boolean, $invoicesTaxBehaviour: TaxBehaviour) {
  updateBusiness(
    id: $id
    enabled: $enabled
    alias: $alias
    name: $name
    email: $email
    website: $website
    timezone: $timezone
    currency: $currency
    physicalAddress: $physicalAddress
    color: $color
    logoId: $logoId
    logoPosition: $logoPosition
    workingHours: $workingHours
    dateFormat: $dateFormat
    bufferBeforeMinutes: $bufferBeforeMinutes
    bufferAfterMinutes: $bufferAfterMinutes
    redirectAfterBookingUrl: $redirectAfterBookingUrl
    postOnRedirect: $postOnRedirect
    includeAnswersOnRedirect: $includeAnswersOnRedirect
    googleTrackingId: $googleTrackingId
    trackingPixelUrl: $trackingPixelUrl
    availabilityLimitDays: $availabilityLimitDays
    allowStaffApprovals: $allowStaffApprovals
    allowTimeRequests: $allowTimeRequests
    timeRequestButtonLabel: $timeRequestButtonLabel
    timeRequestText: $timeRequestText
    hidePrivacyButton: $hidePrivacyButton
    allowChoosingStaff: $allowChoosingStaff
    allowNoStaffPreference: $allowNoStaffPreference
    exportedEventsTemplate: $exportedEventsTemplate
    marketingMeta: $marketingMeta
    onboardingCompleted: $onboardingCompleted
    defaultInvoiceDaysUntilDue: $defaultInvoiceDaysUntilDue
    autoGenerateBookingInvoices: $autoGenerateBookingInvoices
    bookingsRequireApprovalUntilPaid: $bookingsRequireApprovalUntilPaid
    invoicesTaxBehaviour: $invoicesTaxBehaviour
  ) {
    id
    enabled
    alias
    name
    email
    website
    currency
    physicalAddress
    timezone
    color
    logo {
      id
      url
    }
    logoPosition
    workingHours
    availableFeatures
    dateFormat
    bufferBeforeMinutes
    bufferAfterMinutes
    redirectAfterBookingUrl
    postOnRedirect
    includeAnswersOnRedirect
    googleTrackingId
    trackingPixelUrl
    availabilityLimitDays
    allowStaffApprovals
    allowTimeRequests
    timeRequestButtonLabel
    timeRequestText
    hidePrivacyButton
    allowChoosingStaff
    allowNoStaffPreference
    exportedEventsTemplate
    marketingMeta
    onboardingCompletedAt
    defaultInvoiceDaysUntilDue
    autoGenerateBookingInvoices
    bookingsRequireApprovalUntilPaid
    invoicesTaxBehaviour
  }
}
    `;
export type UpdateBusinessSettingsMutationFn = Apollo.MutationFunction<UpdateBusinessSettingsMutation, UpdateBusinessSettingsMutationVariables>;

/**
 * __useUpdateBusinessSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessSettingsMutation, { data, loading, error }] = useUpdateBusinessSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      enabled: // value for 'enabled'
 *      alias: // value for 'alias'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      color: // value for 'color'
 *      logoId: // value for 'logoId'
 *      website: // value for 'website'
 *      currency: // value for 'currency'
 *      physicalAddress: // value for 'physicalAddress'
 *      timezone: // value for 'timezone'
 *      workingHours: // value for 'workingHours'
 *      dateFormat: // value for 'dateFormat'
 *      bufferBeforeMinutes: // value for 'bufferBeforeMinutes'
 *      bufferAfterMinutes: // value for 'bufferAfterMinutes'
 *      redirectAfterBookingUrl: // value for 'redirectAfterBookingUrl'
 *      postOnRedirect: // value for 'postOnRedirect'
 *      includeAnswersOnRedirect: // value for 'includeAnswersOnRedirect'
 *      googleTrackingId: // value for 'googleTrackingId'
 *      trackingPixelUrl: // value for 'trackingPixelUrl'
 *      availabilityLimitDays: // value for 'availabilityLimitDays'
 *      allowStaffApprovals: // value for 'allowStaffApprovals'
 *      logoPosition: // value for 'logoPosition'
 *      allowTimeRequests: // value for 'allowTimeRequests'
 *      timeRequestButtonLabel: // value for 'timeRequestButtonLabel'
 *      timeRequestText: // value for 'timeRequestText'
 *      hidePrivacyButton: // value for 'hidePrivacyButton'
 *      allowChoosingStaff: // value for 'allowChoosingStaff'
 *      allowNoStaffPreference: // value for 'allowNoStaffPreference'
 *      exportedEventsTemplate: // value for 'exportedEventsTemplate'
 *      marketingMeta: // value for 'marketingMeta'
 *      onboardingCompleted: // value for 'onboardingCompleted'
 *      defaultInvoiceDaysUntilDue: // value for 'defaultInvoiceDaysUntilDue'
 *      autoGenerateBookingInvoices: // value for 'autoGenerateBookingInvoices'
 *      bookingsRequireApprovalUntilPaid: // value for 'bookingsRequireApprovalUntilPaid'
 *      invoicesTaxBehaviour: // value for 'invoicesTaxBehaviour'
 *   },
 * });
 */
export function useUpdateBusinessSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessSettingsMutation, UpdateBusinessSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessSettingsMutation, UpdateBusinessSettingsMutationVariables>(UpdateBusinessSettingsDocument, options);
      }
export type UpdateBusinessSettingsMutationHookResult = ReturnType<typeof useUpdateBusinessSettingsMutation>;
export type UpdateBusinessSettingsMutationResult = Apollo.MutationResult<UpdateBusinessSettingsMutation>;
export type UpdateBusinessSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessSettingsMutation, UpdateBusinessSettingsMutationVariables>;
export const BusinessRoleConfigurationsDocument = gql`
    query businessRoleConfigurations($businessId: ID!) {
  businessRoleConfigurations(businessId: $businessId) {
    id
    name
    description
    scopes
  }
}
    `;

/**
 * __useBusinessRoleConfigurationsQuery__
 *
 * To run a query within a React component, call `useBusinessRoleConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessRoleConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessRoleConfigurationsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useBusinessRoleConfigurationsQuery(baseOptions: Apollo.QueryHookOptions<BusinessRoleConfigurationsQuery, BusinessRoleConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessRoleConfigurationsQuery, BusinessRoleConfigurationsQueryVariables>(BusinessRoleConfigurationsDocument, options);
      }
export function useBusinessRoleConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessRoleConfigurationsQuery, BusinessRoleConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessRoleConfigurationsQuery, BusinessRoleConfigurationsQueryVariables>(BusinessRoleConfigurationsDocument, options);
        }
export type BusinessRoleConfigurationsQueryHookResult = ReturnType<typeof useBusinessRoleConfigurationsQuery>;
export type BusinessRoleConfigurationsLazyQueryHookResult = ReturnType<typeof useBusinessRoleConfigurationsLazyQuery>;
export type BusinessRoleConfigurationsQueryResult = Apollo.QueryResult<BusinessRoleConfigurationsQuery, BusinessRoleConfigurationsQueryVariables>;
export const CalendarConnectionsQueryDocument = gql`
    query CalendarConnectionsQuery($businessId: ID!, $redirectUrl: String!) {
  businessCalendarConnections: calendarConnections(
    resourceId: $businessId
    resourceType: BUSINESS
  ) {
    id
    provider
    accountId
    enabled
    isAuthorized
    lastSyncedAt
    importFromCalendarsIds
    calendars
    exportToCalendarId
    exportAttendees
  }
  personalCalendarConnections: staffCalendarConnections(businessId: $businessId) {
    id
    provider
    accountId
    enabled
    isAuthorized
    lastSyncedAt
    importFromCalendarsIds
    calendars
    exportToCalendarId
    exportAttendees
  }
  businessOAuthUrls: oAuthUrls(businessId: $businessId, redirectUrl: $redirectUrl) {
    google
    microsoft
  }
  personalOAuthUrls: staffOAuthUrls(
    businessId: $businessId
    redirectUrl: $redirectUrl
  ) {
    google
    microsoft
  }
}
    `;

/**
 * __useCalendarConnectionsQueryQuery__
 *
 * To run a query within a React component, call `useCalendarConnectionsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarConnectionsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarConnectionsQueryQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function useCalendarConnectionsQueryQuery(baseOptions: Apollo.QueryHookOptions<CalendarConnectionsQueryQuery, CalendarConnectionsQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarConnectionsQueryQuery, CalendarConnectionsQueryQueryVariables>(CalendarConnectionsQueryDocument, options);
      }
export function useCalendarConnectionsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarConnectionsQueryQuery, CalendarConnectionsQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarConnectionsQueryQuery, CalendarConnectionsQueryQueryVariables>(CalendarConnectionsQueryDocument, options);
        }
export type CalendarConnectionsQueryQueryHookResult = ReturnType<typeof useCalendarConnectionsQueryQuery>;
export type CalendarConnectionsQueryLazyQueryHookResult = ReturnType<typeof useCalendarConnectionsQueryLazyQuery>;
export type CalendarConnectionsQueryQueryResult = Apollo.QueryResult<CalendarConnectionsQueryQuery, CalendarConnectionsQueryQueryVariables>;
export const CalendarSyncSettingsDocument = gql`
    query calendarSyncSettings($businessId: ID!) {
  business: Business(id: $businessId) {
    id
    exportedEventsTemplate
  }
}
    `;

/**
 * __useCalendarSyncSettingsQuery__
 *
 * To run a query within a React component, call `useCalendarSyncSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarSyncSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarSyncSettingsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useCalendarSyncSettingsQuery(baseOptions: Apollo.QueryHookOptions<CalendarSyncSettingsQuery, CalendarSyncSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarSyncSettingsQuery, CalendarSyncSettingsQueryVariables>(CalendarSyncSettingsDocument, options);
      }
export function useCalendarSyncSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarSyncSettingsQuery, CalendarSyncSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarSyncSettingsQuery, CalendarSyncSettingsQueryVariables>(CalendarSyncSettingsDocument, options);
        }
export type CalendarSyncSettingsQueryHookResult = ReturnType<typeof useCalendarSyncSettingsQuery>;
export type CalendarSyncSettingsLazyQueryHookResult = ReturnType<typeof useCalendarSyncSettingsLazyQuery>;
export type CalendarSyncSettingsQueryResult = Apollo.QueryResult<CalendarSyncSettingsQuery, CalendarSyncSettingsQueryVariables>;
export const DisconnectCalendarConnectionDocument = gql`
    mutation disconnectCalendarConnection($id: ID!) {
  disconnectCalendarConnection(id: $id)
}
    `;
export type DisconnectCalendarConnectionMutationFn = Apollo.MutationFunction<DisconnectCalendarConnectionMutation, DisconnectCalendarConnectionMutationVariables>;

/**
 * __useDisconnectCalendarConnectionMutation__
 *
 * To run a mutation, you first call `useDisconnectCalendarConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectCalendarConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectCalendarConnectionMutation, { data, loading, error }] = useDisconnectCalendarConnectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisconnectCalendarConnectionMutation(baseOptions?: Apollo.MutationHookOptions<DisconnectCalendarConnectionMutation, DisconnectCalendarConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisconnectCalendarConnectionMutation, DisconnectCalendarConnectionMutationVariables>(DisconnectCalendarConnectionDocument, options);
      }
export type DisconnectCalendarConnectionMutationHookResult = ReturnType<typeof useDisconnectCalendarConnectionMutation>;
export type DisconnectCalendarConnectionMutationResult = Apollo.MutationResult<DisconnectCalendarConnectionMutation>;
export type DisconnectCalendarConnectionMutationOptions = Apollo.BaseMutationOptions<DisconnectCalendarConnectionMutation, DisconnectCalendarConnectionMutationVariables>;
export const UpdateCalendarConnectionDocument = gql`
    mutation updateCalendarConnection($id: ID!, $importFromCalendarsIds: [String!], $exportToCalendarId: String, $enabled: Boolean, $allDayEventsTimezone: String, $exportAttendees: [CalendarEventAttendee!]) {
  updateCalendarConnection(
    id: $id
    importFromCalendarsIds: $importFromCalendarsIds
    exportToCalendarId: $exportToCalendarId
    enabled: $enabled
    allDayEventsTimezone: $allDayEventsTimezone
    exportAttendees: $exportAttendees
  ) {
    id
    importFromCalendarsIds
    exportToCalendarId
    calendars
    enabled
    allDayEventsTimezone
    exportAttendees
  }
}
    `;
export type UpdateCalendarConnectionMutationFn = Apollo.MutationFunction<UpdateCalendarConnectionMutation, UpdateCalendarConnectionMutationVariables>;

/**
 * __useUpdateCalendarConnectionMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarConnectionMutation, { data, loading, error }] = useUpdateCalendarConnectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      importFromCalendarsIds: // value for 'importFromCalendarsIds'
 *      exportToCalendarId: // value for 'exportToCalendarId'
 *      enabled: // value for 'enabled'
 *      allDayEventsTimezone: // value for 'allDayEventsTimezone'
 *      exportAttendees: // value for 'exportAttendees'
 *   },
 * });
 */
export function useUpdateCalendarConnectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCalendarConnectionMutation, UpdateCalendarConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCalendarConnectionMutation, UpdateCalendarConnectionMutationVariables>(UpdateCalendarConnectionDocument, options);
      }
export type UpdateCalendarConnectionMutationHookResult = ReturnType<typeof useUpdateCalendarConnectionMutation>;
export type UpdateCalendarConnectionMutationResult = Apollo.MutationResult<UpdateCalendarConnectionMutation>;
export type UpdateCalendarConnectionMutationOptions = Apollo.BaseMutationOptions<UpdateCalendarConnectionMutation, UpdateCalendarConnectionMutationVariables>;
export const CalendarAppointmentsDocument = gql`
    query calendarAppointments($dateStart: DateTime!, $dateEnd: DateTime!, $staffsIds: [ID!], $patientId: ID, $locationType: LocationType) {
  appointments: calendarViewAppointments(
    dateStart: $dateStart
    dateEnd: $dateEnd
    staffsIds: $staffsIds
    patientId: $patientId
    locationType: $locationType
  ) {
    id
    approved
    cancelled
    date
    duration
    price
    currency
    groupBookingId
    locationType
    locationValue
    rrule
    originalDate
    recurringAppointmentId
    staff {
      id
      name
    }
    patient {
      id
      name
    }
    service {
      id
      name
    }
  }
}
    `;

/**
 * __useCalendarAppointmentsQuery__
 *
 * To run a query within a React component, call `useCalendarAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarAppointmentsQuery({
 *   variables: {
 *      dateStart: // value for 'dateStart'
 *      dateEnd: // value for 'dateEnd'
 *      staffsIds: // value for 'staffsIds'
 *      patientId: // value for 'patientId'
 *      locationType: // value for 'locationType'
 *   },
 * });
 */
export function useCalendarAppointmentsQuery(baseOptions: Apollo.QueryHookOptions<CalendarAppointmentsQuery, CalendarAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarAppointmentsQuery, CalendarAppointmentsQueryVariables>(CalendarAppointmentsDocument, options);
      }
export function useCalendarAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarAppointmentsQuery, CalendarAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarAppointmentsQuery, CalendarAppointmentsQueryVariables>(CalendarAppointmentsDocument, options);
        }
export type CalendarAppointmentsQueryHookResult = ReturnType<typeof useCalendarAppointmentsQuery>;
export type CalendarAppointmentsLazyQueryHookResult = ReturnType<typeof useCalendarAppointmentsLazyQuery>;
export type CalendarAppointmentsQueryResult = Apollo.QueryResult<CalendarAppointmentsQuery, CalendarAppointmentsQueryVariables>;
export const DeleteTimeBlockerDocument = gql`
    mutation deleteTimeBlocker($id: ID!) {
  deleteTimeBlocker(id: $id) {
    id
  }
}
    `;
export type DeleteTimeBlockerMutationFn = Apollo.MutationFunction<DeleteTimeBlockerMutation, DeleteTimeBlockerMutationVariables>;

/**
 * __useDeleteTimeBlockerMutation__
 *
 * To run a mutation, you first call `useDeleteTimeBlockerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeBlockerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeBlockerMutation, { data, loading, error }] = useDeleteTimeBlockerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTimeBlockerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimeBlockerMutation, DeleteTimeBlockerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTimeBlockerMutation, DeleteTimeBlockerMutationVariables>(DeleteTimeBlockerDocument, options);
      }
export type DeleteTimeBlockerMutationHookResult = ReturnType<typeof useDeleteTimeBlockerMutation>;
export type DeleteTimeBlockerMutationResult = Apollo.MutationResult<DeleteTimeBlockerMutation>;
export type DeleteTimeBlockerMutationOptions = Apollo.BaseMutationOptions<DeleteTimeBlockerMutation, DeleteTimeBlockerMutationVariables>;
export const CalendarExternalEventsDocument = gql`
    query calendarExternalEvents($dateStart: DateTime!, $dateEnd: DateTime!, $staffsIds: [ID!]) {
  externalEvents: calendarViewExternalEvents(
    staffsIds: $staffsIds
    dateStart: $dateStart
    dateEnd: $dateEnd
  ) {
    id
    startDate
    endDate
    title
    staffId
  }
}
    `;

/**
 * __useCalendarExternalEventsQuery__
 *
 * To run a query within a React component, call `useCalendarExternalEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarExternalEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarExternalEventsQuery({
 *   variables: {
 *      dateStart: // value for 'dateStart'
 *      dateEnd: // value for 'dateEnd'
 *      staffsIds: // value for 'staffsIds'
 *   },
 * });
 */
export function useCalendarExternalEventsQuery(baseOptions: Apollo.QueryHookOptions<CalendarExternalEventsQuery, CalendarExternalEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarExternalEventsQuery, CalendarExternalEventsQueryVariables>(CalendarExternalEventsDocument, options);
      }
export function useCalendarExternalEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarExternalEventsQuery, CalendarExternalEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarExternalEventsQuery, CalendarExternalEventsQueryVariables>(CalendarExternalEventsDocument, options);
        }
export type CalendarExternalEventsQueryHookResult = ReturnType<typeof useCalendarExternalEventsQuery>;
export type CalendarExternalEventsLazyQueryHookResult = ReturnType<typeof useCalendarExternalEventsLazyQuery>;
export type CalendarExternalEventsQueryResult = Apollo.QueryResult<CalendarExternalEventsQuery, CalendarExternalEventsQueryVariables>;
export const CalendarResourcesDocument = gql`
    query calendarResources($limit: Int, $skip: Int, $time: DateTime!, $businessId: ID!, $filterIds: [ID!]) {
  staffs: managedStaffs(limit: $limit, skip: $skip, filterIds: $filterIds) {
    id
    name
    picture {
      id
      url
    }
    workingHours(time: $time)
  }
  business: Business(id: $businessId) {
    id
    workingHours
  }
}
    `;

/**
 * __useCalendarResourcesQuery__
 *
 * To run a query within a React component, call `useCalendarResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarResourcesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      time: // value for 'time'
 *      businessId: // value for 'businessId'
 *      filterIds: // value for 'filterIds'
 *   },
 * });
 */
export function useCalendarResourcesQuery(baseOptions: Apollo.QueryHookOptions<CalendarResourcesQuery, CalendarResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarResourcesQuery, CalendarResourcesQueryVariables>(CalendarResourcesDocument, options);
      }
export function useCalendarResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarResourcesQuery, CalendarResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarResourcesQuery, CalendarResourcesQueryVariables>(CalendarResourcesDocument, options);
        }
export type CalendarResourcesQueryHookResult = ReturnType<typeof useCalendarResourcesQuery>;
export type CalendarResourcesLazyQueryHookResult = ReturnType<typeof useCalendarResourcesLazyQuery>;
export type CalendarResourcesQueryResult = Apollo.QueryResult<CalendarResourcesQuery, CalendarResourcesQueryVariables>;
export const CouponsDocument = gql`
    query coupons {
  coupons: allCoupons {
    id
    createdAt
    updatedAt
    name
    code
    type
    amountOff
    percentOff
    currency
    expiresAt
    redemptionsCount
  }
}
    `;

/**
 * __useCouponsQuery__
 *
 * To run a query within a React component, call `useCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCouponsQuery(baseOptions?: Apollo.QueryHookOptions<CouponsQuery, CouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CouponsQuery, CouponsQueryVariables>(CouponsDocument, options);
      }
export function useCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CouponsQuery, CouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CouponsQuery, CouponsQueryVariables>(CouponsDocument, options);
        }
export type CouponsQueryHookResult = ReturnType<typeof useCouponsQuery>;
export type CouponsLazyQueryHookResult = ReturnType<typeof useCouponsLazyQuery>;
export type CouponsQueryResult = Apollo.QueryResult<CouponsQuery, CouponsQueryVariables>;
export const CreateCouponDocument = gql`
    mutation createCoupon($code: String!, $name: String!, $type: String!, $amountOff: Int, $percentOff: Float, $currency: String, $expiresAt: DateTime) {
  createCoupon(
    code: $code
    name: $name
    type: $type
    amountOff: $amountOff
    percentOff: $percentOff
    currency: $currency
    expiresAt: $expiresAt
  ) {
    id
    createdAt
    updatedAt
    name
    code
    type
    amountOff
    percentOff
    currency
    expiresAt
    redemptionsCount
  }
}
    `;
export type CreateCouponMutationFn = Apollo.MutationFunction<CreateCouponMutation, CreateCouponMutationVariables>;

/**
 * __useCreateCouponMutation__
 *
 * To run a mutation, you first call `useCreateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCouponMutation, { data, loading, error }] = useCreateCouponMutation({
 *   variables: {
 *      code: // value for 'code'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      amountOff: // value for 'amountOff'
 *      percentOff: // value for 'percentOff'
 *      currency: // value for 'currency'
 *      expiresAt: // value for 'expiresAt'
 *   },
 * });
 */
export function useCreateCouponMutation(baseOptions?: Apollo.MutationHookOptions<CreateCouponMutation, CreateCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCouponMutation, CreateCouponMutationVariables>(CreateCouponDocument, options);
      }
export type CreateCouponMutationHookResult = ReturnType<typeof useCreateCouponMutation>;
export type CreateCouponMutationResult = Apollo.MutationResult<CreateCouponMutation>;
export type CreateCouponMutationOptions = Apollo.BaseMutationOptions<CreateCouponMutation, CreateCouponMutationVariables>;
export const DeleteCouponDocument = gql`
    mutation deleteCoupon($id: ID!) {
  deleteCoupon(id: $id)
}
    `;
export type DeleteCouponMutationFn = Apollo.MutationFunction<DeleteCouponMutation, DeleteCouponMutationVariables>;

/**
 * __useDeleteCouponMutation__
 *
 * To run a mutation, you first call `useDeleteCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCouponMutation, { data, loading, error }] = useDeleteCouponMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCouponMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCouponMutation, DeleteCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCouponMutation, DeleteCouponMutationVariables>(DeleteCouponDocument, options);
      }
export type DeleteCouponMutationHookResult = ReturnType<typeof useDeleteCouponMutation>;
export type DeleteCouponMutationResult = Apollo.MutationResult<DeleteCouponMutation>;
export type DeleteCouponMutationOptions = Apollo.BaseMutationOptions<DeleteCouponMutation, DeleteCouponMutationVariables>;
export const UpdateCouponDocument = gql`
    mutation updateCoupon($id: ID!, $name: String!) {
  updateCoupon(id: $id, name: $name) {
    id
    name
  }
}
    `;
export type UpdateCouponMutationFn = Apollo.MutationFunction<UpdateCouponMutation, UpdateCouponMutationVariables>;

/**
 * __useUpdateCouponMutation__
 *
 * To run a mutation, you first call `useUpdateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCouponMutation, { data, loading, error }] = useUpdateCouponMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateCouponMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCouponMutation, UpdateCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCouponMutation, UpdateCouponMutationVariables>(UpdateCouponDocument, options);
      }
export type UpdateCouponMutationHookResult = ReturnType<typeof useUpdateCouponMutation>;
export type UpdateCouponMutationResult = Apollo.MutationResult<UpdateCouponMutation>;
export type UpdateCouponMutationOptions = Apollo.BaseMutationOptions<UpdateCouponMutation, UpdateCouponMutationVariables>;
export const CreateApiKeyDocument = gql`
    mutation createApiKey($description: String!) {
  createApiKey(description: $description) {
    id
    value
    description
    createdAt
    updatedAt
    expiresAt
  }
}
    `;
export type CreateApiKeyMutationFn = Apollo.MutationFunction<CreateApiKeyMutation, CreateApiKeyMutationVariables>;

/**
 * __useCreateApiKeyMutation__
 *
 * To run a mutation, you first call `useCreateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiKeyMutation, { data, loading, error }] = useCreateApiKeyMutation({
 *   variables: {
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApiKeyMutation, CreateApiKeyMutationVariables>(CreateApiKeyDocument, options);
      }
export type CreateApiKeyMutationHookResult = ReturnType<typeof useCreateApiKeyMutation>;
export type CreateApiKeyMutationResult = Apollo.MutationResult<CreateApiKeyMutation>;
export type CreateApiKeyMutationOptions = Apollo.BaseMutationOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>;
export const CreateBusinessDocument = gql`
    mutation createBusiness($name: String!, $alias: String!, $timezone: String!) {
  createBusiness(name: $name, alias: $alias, timezone: $timezone) {
    id
    alias
    name
    timezone
  }
}
    `;
export type CreateBusinessMutationFn = Apollo.MutationFunction<CreateBusinessMutation, CreateBusinessMutationVariables>;

/**
 * __useCreateBusinessMutation__
 *
 * To run a mutation, you first call `useCreateBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessMutation, { data, loading, error }] = useCreateBusinessMutation({
 *   variables: {
 *      name: // value for 'name'
 *      alias: // value for 'alias'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useCreateBusinessMutation(baseOptions?: Apollo.MutationHookOptions<CreateBusinessMutation, CreateBusinessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBusinessMutation, CreateBusinessMutationVariables>(CreateBusinessDocument, options);
      }
export type CreateBusinessMutationHookResult = ReturnType<typeof useCreateBusinessMutation>;
export type CreateBusinessMutationResult = Apollo.MutationResult<CreateBusinessMutation>;
export type CreateBusinessMutationOptions = Apollo.BaseMutationOptions<CreateBusinessMutation, CreateBusinessMutationVariables>;
export const CreateStripeBillingPortalSessionDocument = gql`
    mutation createStripeBillingPortalSession($flow: String!) {
  createStripeBillingPortalSession(flow: $flow) {
    url
  }
}
    `;
export type CreateStripeBillingPortalSessionMutationFn = Apollo.MutationFunction<CreateStripeBillingPortalSessionMutation, CreateStripeBillingPortalSessionMutationVariables>;

/**
 * __useCreateStripeBillingPortalSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripeBillingPortalSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeBillingPortalSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeBillingPortalSessionMutation, { data, loading, error }] = useCreateStripeBillingPortalSessionMutation({
 *   variables: {
 *      flow: // value for 'flow'
 *   },
 * });
 */
export function useCreateStripeBillingPortalSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeBillingPortalSessionMutation, CreateStripeBillingPortalSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeBillingPortalSessionMutation, CreateStripeBillingPortalSessionMutationVariables>(CreateStripeBillingPortalSessionDocument, options);
      }
export type CreateStripeBillingPortalSessionMutationHookResult = ReturnType<typeof useCreateStripeBillingPortalSessionMutation>;
export type CreateStripeBillingPortalSessionMutationResult = Apollo.MutationResult<CreateStripeBillingPortalSessionMutation>;
export type CreateStripeBillingPortalSessionMutationOptions = Apollo.BaseMutationOptions<CreateStripeBillingPortalSessionMutation, CreateStripeBillingPortalSessionMutationVariables>;
export const CreateStripeCheckoutSessionDocument = gql`
    mutation createStripeCheckoutSession {
  createStripeCheckoutSession {
    url
  }
}
    `;
export type CreateStripeCheckoutSessionMutationFn = Apollo.MutationFunction<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>;

/**
 * __useCreateStripeCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripeCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeCheckoutSessionMutation, { data, loading, error }] = useCreateStripeCheckoutSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateStripeCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>(CreateStripeCheckoutSessionDocument, options);
      }
export type CreateStripeCheckoutSessionMutationHookResult = ReturnType<typeof useCreateStripeCheckoutSessionMutation>;
export type CreateStripeCheckoutSessionMutationResult = Apollo.MutationResult<CreateStripeCheckoutSessionMutation>;
export type CreateStripeCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>;
export const CreateStripeSetupIntentDocument = gql`
    mutation createStripeSetupIntent($productQuantities: Json!, $interval: StripeInterval!, $confirmationToken: String) {
  createStripeSetupIntent(
    productQuantities: $productQuantities
    interval: $interval
    confirmationToken: $confirmationToken
  ) {
    id
    customerId
    clientSecret
    status
    usage
    nextAction {
      type
      redirectToUrl {
        returnUrl
        url
      }
      useStripeSDK
    }
  }
}
    `;
export type CreateStripeSetupIntentMutationFn = Apollo.MutationFunction<CreateStripeSetupIntentMutation, CreateStripeSetupIntentMutationVariables>;

/**
 * __useCreateStripeSetupIntentMutation__
 *
 * To run a mutation, you first call `useCreateStripeSetupIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeSetupIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeSetupIntentMutation, { data, loading, error }] = useCreateStripeSetupIntentMutation({
 *   variables: {
 *      productQuantities: // value for 'productQuantities'
 *      interval: // value for 'interval'
 *      confirmationToken: // value for 'confirmationToken'
 *   },
 * });
 */
export function useCreateStripeSetupIntentMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeSetupIntentMutation, CreateStripeSetupIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeSetupIntentMutation, CreateStripeSetupIntentMutationVariables>(CreateStripeSetupIntentDocument, options);
      }
export type CreateStripeSetupIntentMutationHookResult = ReturnType<typeof useCreateStripeSetupIntentMutation>;
export type CreateStripeSetupIntentMutationResult = Apollo.MutationResult<CreateStripeSetupIntentMutation>;
export type CreateStripeSetupIntentMutationOptions = Apollo.BaseMutationOptions<CreateStripeSetupIntentMutation, CreateStripeSetupIntentMutationVariables>;
export const CurrentBusinessUsageDocument = gql`
    query currentBusinessUsage($businessId: ID!) {
  currentBusiness(businessId: $businessId) {
    id
    usage: usage(cached: false)
  }
}
    `;

/**
 * __useCurrentBusinessUsageQuery__
 *
 * To run a query within a React component, call `useCurrentBusinessUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentBusinessUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentBusinessUsageQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useCurrentBusinessUsageQuery(baseOptions: Apollo.QueryHookOptions<CurrentBusinessUsageQuery, CurrentBusinessUsageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentBusinessUsageQuery, CurrentBusinessUsageQueryVariables>(CurrentBusinessUsageDocument, options);
      }
export function useCurrentBusinessUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentBusinessUsageQuery, CurrentBusinessUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentBusinessUsageQuery, CurrentBusinessUsageQueryVariables>(CurrentBusinessUsageDocument, options);
        }
export type CurrentBusinessUsageQueryHookResult = ReturnType<typeof useCurrentBusinessUsageQuery>;
export type CurrentBusinessUsageLazyQueryHookResult = ReturnType<typeof useCurrentBusinessUsageLazyQuery>;
export type CurrentBusinessUsageQueryResult = Apollo.QueryResult<CurrentBusinessUsageQuery, CurrentBusinessUsageQueryVariables>;
export const CurrentStaffDocument = gql`
    query currentStaff($businessId: ID!) {
  currentStaff(businessId: $businessId) {
    id
    alias
    enabled
    availableBoolFeatures
    status
    name
    occupation
    marketingMeta
    roles
    description
    email
    phoneMobile
    alias
    timezone
    bookingPageUrl
    stripeProductIds
    onboardingCompletedAt
    business {
      id
      alias
    }
    managedStaff {
      id
      name
    }
    picture {
      id
      url
    }
    zoomConnection {
      id
    }
    teamsConnection {
      id
    }
  }
}
    `;

/**
 * __useCurrentStaffQuery__
 *
 * To run a query within a React component, call `useCurrentStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentStaffQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useCurrentStaffQuery(baseOptions: Apollo.QueryHookOptions<CurrentStaffQuery, CurrentStaffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentStaffQuery, CurrentStaffQueryVariables>(CurrentStaffDocument, options);
      }
export function useCurrentStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentStaffQuery, CurrentStaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentStaffQuery, CurrentStaffQueryVariables>(CurrentStaffDocument, options);
        }
export type CurrentStaffQueryHookResult = ReturnType<typeof useCurrentStaffQuery>;
export type CurrentStaffLazyQueryHookResult = ReturnType<typeof useCurrentStaffLazyQuery>;
export type CurrentStaffQueryResult = Apollo.QueryResult<CurrentStaffQuery, CurrentStaffQueryVariables>;
export const CurrentUserDocument = gql`
    query currentUser {
  currentUser {
    id
    name
    email
    role
    businessesCount
    ownedBusinessesCount
    availableFeatures
    createdAt
    updatedAt
    staffs {
      id
      onboardingCompletedAt
      business {
        id
        name
        alias
        enabled
        servicesCount
        staffRoles
        onboardingCompletedAt
        subscriptionStatus
        availableFeatures
        stripeData
        remainingTrialDays
      }
    }
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const AllCustomFieldsDocument = gql`
    query allCustomFields {
  allCustomFields {
    id
    alias
    name
    description
    fieldType
    allowedValues
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useAllCustomFieldsQuery__
 *
 * To run a query within a React component, call `useAllCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCustomFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCustomFieldsQuery(baseOptions?: Apollo.QueryHookOptions<AllCustomFieldsQuery, AllCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCustomFieldsQuery, AllCustomFieldsQueryVariables>(AllCustomFieldsDocument, options);
      }
export function useAllCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCustomFieldsQuery, AllCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCustomFieldsQuery, AllCustomFieldsQueryVariables>(AllCustomFieldsDocument, options);
        }
export type AllCustomFieldsQueryHookResult = ReturnType<typeof useAllCustomFieldsQuery>;
export type AllCustomFieldsLazyQueryHookResult = ReturnType<typeof useAllCustomFieldsLazyQuery>;
export type AllCustomFieldsQueryResult = Apollo.QueryResult<AllCustomFieldsQuery, AllCustomFieldsQueryVariables>;
export const CreateCustomFieldDocument = gql`
    mutation createCustomField($alias: String!, $name: String!, $fieldType: CustomFieldType!, $description: String!, $allowedValues: [String!]) {
  createCustomField(
    alias: $alias
    name: $name
    fieldType: $fieldType
    description: $description
    allowedValues: $allowedValues
  ) {
    id
    alias
    name
    description
    fieldType
    allowedValues
  }
}
    `;
export type CreateCustomFieldMutationFn = Apollo.MutationFunction<CreateCustomFieldMutation, CreateCustomFieldMutationVariables>;

/**
 * __useCreateCustomFieldMutation__
 *
 * To run a mutation, you first call `useCreateCustomFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomFieldMutation, { data, loading, error }] = useCreateCustomFieldMutation({
 *   variables: {
 *      alias: // value for 'alias'
 *      name: // value for 'name'
 *      fieldType: // value for 'fieldType'
 *      description: // value for 'description'
 *      allowedValues: // value for 'allowedValues'
 *   },
 * });
 */
export function useCreateCustomFieldMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomFieldMutation, CreateCustomFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomFieldMutation, CreateCustomFieldMutationVariables>(CreateCustomFieldDocument, options);
      }
export type CreateCustomFieldMutationHookResult = ReturnType<typeof useCreateCustomFieldMutation>;
export type CreateCustomFieldMutationResult = Apollo.MutationResult<CreateCustomFieldMutation>;
export type CreateCustomFieldMutationOptions = Apollo.BaseMutationOptions<CreateCustomFieldMutation, CreateCustomFieldMutationVariables>;
export const UpdateCustomFieldDocument = gql`
    mutation updateCustomField($id: ID!, $name: String!, $description: String!, $allowedValues: [String!]) {
  updateCustomField(
    id: $id
    name: $name
    description: $description
    allowedValues: $allowedValues
  ) {
    id
    name
    description
    allowedValues
  }
}
    `;
export type UpdateCustomFieldMutationFn = Apollo.MutationFunction<UpdateCustomFieldMutation, UpdateCustomFieldMutationVariables>;

/**
 * __useUpdateCustomFieldMutation__
 *
 * To run a mutation, you first call `useUpdateCustomFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomFieldMutation, { data, loading, error }] = useUpdateCustomFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      allowedValues: // value for 'allowedValues'
 *   },
 * });
 */
export function useUpdateCustomFieldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomFieldMutation, UpdateCustomFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomFieldMutation, UpdateCustomFieldMutationVariables>(UpdateCustomFieldDocument, options);
      }
export type UpdateCustomFieldMutationHookResult = ReturnType<typeof useUpdateCustomFieldMutation>;
export type UpdateCustomFieldMutationResult = Apollo.MutationResult<UpdateCustomFieldMutation>;
export type UpdateCustomFieldMutationOptions = Apollo.BaseMutationOptions<UpdateCustomFieldMutation, UpdateCustomFieldMutationVariables>;
export const SetPatientCustomFieldValueDocument = gql`
    mutation setPatientCustomFieldValue($patientId: ID!, $fieldKey: String!, $value: String!) {
  setPatientCustomFieldValue(
    patientId: $patientId
    fieldKey: $fieldKey
    value: $value
  ) {
    id
    patientId
    fieldKey
    value
  }
}
    `;
export type SetPatientCustomFieldValueMutationFn = Apollo.MutationFunction<SetPatientCustomFieldValueMutation, SetPatientCustomFieldValueMutationVariables>;

/**
 * __useSetPatientCustomFieldValueMutation__
 *
 * To run a mutation, you first call `useSetPatientCustomFieldValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPatientCustomFieldValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPatientCustomFieldValueMutation, { data, loading, error }] = useSetPatientCustomFieldValueMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      fieldKey: // value for 'fieldKey'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetPatientCustomFieldValueMutation(baseOptions?: Apollo.MutationHookOptions<SetPatientCustomFieldValueMutation, SetPatientCustomFieldValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPatientCustomFieldValueMutation, SetPatientCustomFieldValueMutationVariables>(SetPatientCustomFieldValueDocument, options);
      }
export type SetPatientCustomFieldValueMutationHookResult = ReturnType<typeof useSetPatientCustomFieldValueMutation>;
export type SetPatientCustomFieldValueMutationResult = Apollo.MutationResult<SetPatientCustomFieldValueMutation>;
export type SetPatientCustomFieldValueMutationOptions = Apollo.BaseMutationOptions<SetPatientCustomFieldValueMutation, SetPatientCustomFieldValueMutationVariables>;
export const CreatePatientDocument = gql`
    mutation createPatient($businessId: ID!, $name: String!, $email: String!, $primaryStaffId: ID, $phoneMobile: String, $dob: String) {
  createPatient(
    businessId: $businessId
    name: $name
    email: $email
    phoneMobile: $phoneMobile
    primaryStaffId: $primaryStaffId
    dob: $dob
  ) {
    id
    name
    email
    phoneMobile
    dob
    age
    primaryStaff {
      id
      name
      picture {
        id
        url
      }
    }
  }
}
    `;
export type CreatePatientMutationFn = Apollo.MutationFunction<CreatePatientMutation, CreatePatientMutationVariables>;

/**
 * __useCreatePatientMutation__
 *
 * To run a mutation, you first call `useCreatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientMutation, { data, loading, error }] = useCreatePatientMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      primaryStaffId: // value for 'primaryStaffId'
 *      phoneMobile: // value for 'phoneMobile'
 *      dob: // value for 'dob'
 *   },
 * });
 */
export function useCreatePatientMutation(baseOptions?: Apollo.MutationHookOptions<CreatePatientMutation, CreatePatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePatientMutation, CreatePatientMutationVariables>(CreatePatientDocument, options);
      }
export type CreatePatientMutationHookResult = ReturnType<typeof useCreatePatientMutation>;
export type CreatePatientMutationResult = Apollo.MutationResult<CreatePatientMutation>;
export type CreatePatientMutationOptions = Apollo.BaseMutationOptions<CreatePatientMutation, CreatePatientMutationVariables>;
export const PatientDocument = gql`
    query patient($patientId: ID!) {
  patient: Patient(id: $patientId) {
    id
    name
    preferredName
    email
    phoneMobile
    dob
    age
    gender
    biologicalSex
    patientType
    height
    weight
    selfDeclaredDiabeticType
    selfDeclaredHypertension
    selfDeclaredIsSmoker
    stripeCustomerId
    billingAddress {
      id
      city
      country
      line1
      line2
      name
      postalCode
      state
    }
    tagIds
    customFieldValues {
      id
      fieldKey
      fieldType
      value
    }
    primaryStaff {
      id
      name
      email
      picture {
        id
        url
      }
    }
  }
}
    `;

/**
 * __usePatientQuery__
 *
 * To run a query within a React component, call `usePatientQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientQuery(baseOptions: Apollo.QueryHookOptions<PatientQuery, PatientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientQuery, PatientQueryVariables>(PatientDocument, options);
      }
export function usePatientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientQuery, PatientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientQuery, PatientQueryVariables>(PatientDocument, options);
        }
export type PatientQueryHookResult = ReturnType<typeof usePatientQuery>;
export type PatientLazyQueryHookResult = ReturnType<typeof usePatientLazyQuery>;
export type PatientQueryResult = Apollo.QueryResult<PatientQuery, PatientQueryVariables>;
export const DeletePatientDocument = gql`
    mutation deletePatient($id: ID!) {
  deletePatient(id: $id) {
    id
  }
}
    `;
export type DeletePatientMutationFn = Apollo.MutationFunction<DeletePatientMutation, DeletePatientMutationVariables>;

/**
 * __useDeletePatientMutation__
 *
 * To run a mutation, you first call `useDeletePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientMutation, { data, loading, error }] = useDeletePatientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePatientMutation(baseOptions?: Apollo.MutationHookOptions<DeletePatientMutation, DeletePatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePatientMutation, DeletePatientMutationVariables>(DeletePatientDocument, options);
      }
export type DeletePatientMutationHookResult = ReturnType<typeof useDeletePatientMutation>;
export type DeletePatientMutationResult = Apollo.MutationResult<DeletePatientMutation>;
export type DeletePatientMutationOptions = Apollo.BaseMutationOptions<DeletePatientMutation, DeletePatientMutationVariables>;
export const EnablePatientBillingDocument = gql`
    mutation enablePatientBilling($id: ID!) {
  enablePatientBilling(id: $id) {
    id
    stripeCustomerId
  }
}
    `;
export type EnablePatientBillingMutationFn = Apollo.MutationFunction<EnablePatientBillingMutation, EnablePatientBillingMutationVariables>;

/**
 * __useEnablePatientBillingMutation__
 *
 * To run a mutation, you first call `useEnablePatientBillingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnablePatientBillingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enablePatientBillingMutation, { data, loading, error }] = useEnablePatientBillingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnablePatientBillingMutation(baseOptions?: Apollo.MutationHookOptions<EnablePatientBillingMutation, EnablePatientBillingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnablePatientBillingMutation, EnablePatientBillingMutationVariables>(EnablePatientBillingDocument, options);
      }
export type EnablePatientBillingMutationHookResult = ReturnType<typeof useEnablePatientBillingMutation>;
export type EnablePatientBillingMutationResult = Apollo.MutationResult<EnablePatientBillingMutation>;
export type EnablePatientBillingMutationOptions = Apollo.BaseMutationOptions<EnablePatientBillingMutation, EnablePatientBillingMutationVariables>;
export const UpdatePatientDocument = gql`
    mutation updatePatient($id: ID!, $name: String, $preferredName: String, $email: String, $phoneMobile: String, $dob: String, $patientType: String, $gender: String, $biologicalSex: BiologicalSex, $billingAddress: AddressInput, $primaryStaffId: ID) {
  updatePatient(
    id: $id
    name: $name
    preferredName: $preferredName
    email: $email
    phoneMobile: $phoneMobile
    dob: $dob
    patientType: $patientType
    gender: $gender
    biologicalSex: $biologicalSex
    billingAddress: $billingAddress
    primaryStaffId: $primaryStaffId
  ) {
    id
    name
    preferredName
    email
    phoneMobile
    dob
    age
    patientType
    gender
    biologicalSex
    billingAddress {
      id
      city
      country
      line1
      line2
      name
      postalCode
      state
    }
    primaryStaff {
      id
      name
      picture {
        id
        url
      }
    }
  }
}
    `;
export type UpdatePatientMutationFn = Apollo.MutationFunction<UpdatePatientMutation, UpdatePatientMutationVariables>;

/**
 * __useUpdatePatientMutation__
 *
 * To run a mutation, you first call `useUpdatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientMutation, { data, loading, error }] = useUpdatePatientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      preferredName: // value for 'preferredName'
 *      email: // value for 'email'
 *      phoneMobile: // value for 'phoneMobile'
 *      dob: // value for 'dob'
 *      patientType: // value for 'patientType'
 *      gender: // value for 'gender'
 *      biologicalSex: // value for 'biologicalSex'
 *      billingAddress: // value for 'billingAddress'
 *      primaryStaffId: // value for 'primaryStaffId'
 *   },
 * });
 */
export function useUpdatePatientMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientMutation, UpdatePatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePatientMutation, UpdatePatientMutationVariables>(UpdatePatientDocument, options);
      }
export type UpdatePatientMutationHookResult = ReturnType<typeof useUpdatePatientMutation>;
export type UpdatePatientMutationResult = Apollo.MutationResult<UpdatePatientMutation>;
export type UpdatePatientMutationOptions = Apollo.BaseMutationOptions<UpdatePatientMutation, UpdatePatientMutationVariables>;
export const DisconnectStripeAccountDocument = gql`
    mutation disconnectStripeAccount($businessId: ID!) {
  disconnectStripeAccount(businessId: $businessId) {
    id
    stripeConnectAccount {
      id
      stripeAccountId
      stripePublishableKey
    }
  }
}
    `;
export type DisconnectStripeAccountMutationFn = Apollo.MutationFunction<DisconnectStripeAccountMutation, DisconnectStripeAccountMutationVariables>;

/**
 * __useDisconnectStripeAccountMutation__
 *
 * To run a mutation, you first call `useDisconnectStripeAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectStripeAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectStripeAccountMutation, { data, loading, error }] = useDisconnectStripeAccountMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useDisconnectStripeAccountMutation(baseOptions?: Apollo.MutationHookOptions<DisconnectStripeAccountMutation, DisconnectStripeAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisconnectStripeAccountMutation, DisconnectStripeAccountMutationVariables>(DisconnectStripeAccountDocument, options);
      }
export type DisconnectStripeAccountMutationHookResult = ReturnType<typeof useDisconnectStripeAccountMutation>;
export type DisconnectStripeAccountMutationResult = Apollo.MutationResult<DisconnectStripeAccountMutation>;
export type DisconnectStripeAccountMutationOptions = Apollo.BaseMutationOptions<DisconnectStripeAccountMutation, DisconnectStripeAccountMutationVariables>;
export const FaceVitalsScanResultDocument = gql`
    query faceVitalsScanResult($businessId: ID!, $id: ID!) {
  faceVitalsScanResult(businessId: $businessId, id: $id) {
    id
    createdAt
    appointment {
      id
    }
    patient {
      id
    }
    inputAge
    inputGender
    inputHeight
    inputWeight
    inputDiabeticType
    inputBloodPressureMedication
    inputHypertension
    inputSmoker
    bloodPressureDiastolicMmhg
    bloodPressureSystolicMmhg
    bloodPressureRiskCategory
    breathingRateBpm
    cardiacWorkload
    cardiovascularDiseaseRisk
    cardiovascularDiseaseRiskCategory
    heartAttackRisk
    heartAttackRiskCategory
    heartRateHz
    heartRateBpm
    heartRateIrregularBpm
    heartRateVariabilitySdnn
    heartRateVariabilityRiskCategory
    strokeRisk
    strokeRiskCategory
    vascularCapacitySecs
  }
}
    `;

/**
 * __useFaceVitalsScanResultQuery__
 *
 * To run a query within a React component, call `useFaceVitalsScanResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaceVitalsScanResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaceVitalsScanResultQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFaceVitalsScanResultQuery(baseOptions: Apollo.QueryHookOptions<FaceVitalsScanResultQuery, FaceVitalsScanResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FaceVitalsScanResultQuery, FaceVitalsScanResultQueryVariables>(FaceVitalsScanResultDocument, options);
      }
export function useFaceVitalsScanResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FaceVitalsScanResultQuery, FaceVitalsScanResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FaceVitalsScanResultQuery, FaceVitalsScanResultQueryVariables>(FaceVitalsScanResultDocument, options);
        }
export type FaceVitalsScanResultQueryHookResult = ReturnType<typeof useFaceVitalsScanResultQuery>;
export type FaceVitalsScanResultLazyQueryHookResult = ReturnType<typeof useFaceVitalsScanResultLazyQuery>;
export type FaceVitalsScanResultQueryResult = Apollo.QueryResult<FaceVitalsScanResultQuery, FaceVitalsScanResultQueryVariables>;
export const FaceVitalsScanResultsDocument = gql`
    query faceVitalsScanResults($businessId: ID!, $patientId: ID!) {
  faceVitalsScanResults(businessId: $businessId, patientId: $patientId) {
    id
    createdAt
    appointment {
      id
    }
    patient {
      id
      name
      email
    }
    inputAge
    inputGender
    inputHeight
    inputWeight
    inputBloodPressureMedication
    inputHypertension
    inputSmoker
    bloodPressureDiastolicMmhg
    bloodPressureSystolicMmhg
    bloodPressureRiskCategory
    breathingRateBpm
    cardiacWorkload
    cardiovascularDiseaseRisk
    cardiovascularDiseaseRiskCategory
    heartAttackRisk
    heartAttackRiskCategory
    heartRateHz
    heartRateBpm
    heartRateIrregularBpm
    heartRateVariabilitySdnn
    heartRateVariabilityRiskCategory
    strokeRisk
    strokeRiskCategory
    vascularCapacitySecs
  }
}
    `;

/**
 * __useFaceVitalsScanResultsQuery__
 *
 * To run a query within a React component, call `useFaceVitalsScanResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaceVitalsScanResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaceVitalsScanResultsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useFaceVitalsScanResultsQuery(baseOptions: Apollo.QueryHookOptions<FaceVitalsScanResultsQuery, FaceVitalsScanResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FaceVitalsScanResultsQuery, FaceVitalsScanResultsQueryVariables>(FaceVitalsScanResultsDocument, options);
      }
export function useFaceVitalsScanResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FaceVitalsScanResultsQuery, FaceVitalsScanResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FaceVitalsScanResultsQuery, FaceVitalsScanResultsQueryVariables>(FaceVitalsScanResultsDocument, options);
        }
export type FaceVitalsScanResultsQueryHookResult = ReturnType<typeof useFaceVitalsScanResultsQuery>;
export type FaceVitalsScanResultsLazyQueryHookResult = ReturnType<typeof useFaceVitalsScanResultsLazyQuery>;
export type FaceVitalsScanResultsQueryResult = Apollo.QueryResult<FaceVitalsScanResultsQuery, FaceVitalsScanResultsQueryVariables>;
export const BusinessFormsDocument = gql`
    query businessForms($limit: Int, $skip: Int, $search: String) {
  forms(limit: $limit, skip: $skip, search: $search) {
    id
    business {
      id
      alias
    }
    url
    title
    alias
    description
    useAsTemplate
    category
    createdAt
    updatedAt
    status
    steps
    submissionsCount
    unreadSubmissionsCount
  }
  count: formsCount(search: $search)
}
    `;

/**
 * __useBusinessFormsQuery__
 *
 * To run a query within a React component, call `useBusinessFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessFormsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useBusinessFormsQuery(baseOptions?: Apollo.QueryHookOptions<BusinessFormsQuery, BusinessFormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessFormsQuery, BusinessFormsQueryVariables>(BusinessFormsDocument, options);
      }
export function useBusinessFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessFormsQuery, BusinessFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessFormsQuery, BusinessFormsQueryVariables>(BusinessFormsDocument, options);
        }
export type BusinessFormsQueryHookResult = ReturnType<typeof useBusinessFormsQuery>;
export type BusinessFormsLazyQueryHookResult = ReturnType<typeof useBusinessFormsLazyQuery>;
export type BusinessFormsQueryResult = Apollo.QueryResult<BusinessFormsQuery, BusinessFormsQueryVariables>;
export const CreateFormDocument = gql`
    mutation createForm($title: String!, $formTemplateId: ID) {
  createForm(title: $title, formTemplateId: $formTemplateId) {
    ...FormFields
  }
}
    ${FormFieldsFragmentDoc}`;
export type CreateFormMutationFn = Apollo.MutationFunction<CreateFormMutation, CreateFormMutationVariables>;

/**
 * __useCreateFormMutation__
 *
 * To run a mutation, you first call `useCreateFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormMutation, { data, loading, error }] = useCreateFormMutation({
 *   variables: {
 *      title: // value for 'title'
 *      formTemplateId: // value for 'formTemplateId'
 *   },
 * });
 */
export function useCreateFormMutation(baseOptions?: Apollo.MutationHookOptions<CreateFormMutation, CreateFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFormMutation, CreateFormMutationVariables>(CreateFormDocument, options);
      }
export type CreateFormMutationHookResult = ReturnType<typeof useCreateFormMutation>;
export type CreateFormMutationResult = Apollo.MutationResult<CreateFormMutation>;
export type CreateFormMutationOptions = Apollo.BaseMutationOptions<CreateFormMutation, CreateFormMutationVariables>;
export const DeleteFormDocument = gql`
    mutation deleteForm($id: ID!) {
  deleteForm(id: $id)
}
    `;
export type DeleteFormMutationFn = Apollo.MutationFunction<DeleteFormMutation, DeleteFormMutationVariables>;

/**
 * __useDeleteFormMutation__
 *
 * To run a mutation, you first call `useDeleteFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFormMutation, { data, loading, error }] = useDeleteFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFormMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFormMutation, DeleteFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFormMutation, DeleteFormMutationVariables>(DeleteFormDocument, options);
      }
export type DeleteFormMutationHookResult = ReturnType<typeof useDeleteFormMutation>;
export type DeleteFormMutationResult = Apollo.MutationResult<DeleteFormMutation>;
export type DeleteFormMutationOptions = Apollo.BaseMutationOptions<DeleteFormMutation, DeleteFormMutationVariables>;
export const DuplicateFormDocument = gql`
    mutation duplicateForm($id: ID!) {
  duplicateForm(id: $id) {
    ...FormFields
  }
}
    ${FormFieldsFragmentDoc}`;
export type DuplicateFormMutationFn = Apollo.MutationFunction<DuplicateFormMutation, DuplicateFormMutationVariables>;

/**
 * __useDuplicateFormMutation__
 *
 * To run a mutation, you first call `useDuplicateFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateFormMutation, { data, loading, error }] = useDuplicateFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicateFormMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateFormMutation, DuplicateFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateFormMutation, DuplicateFormMutationVariables>(DuplicateFormDocument, options);
      }
export type DuplicateFormMutationHookResult = ReturnType<typeof useDuplicateFormMutation>;
export type DuplicateFormMutationResult = Apollo.MutationResult<DuplicateFormMutation>;
export type DuplicateFormMutationOptions = Apollo.BaseMutationOptions<DuplicateFormMutation, DuplicateFormMutationVariables>;
export const FormDocument = gql`
    query form($id: ID!) {
  form(id: $id, mode: PREVIEW) {
    ...FormFields
  }
}
    ${FormFieldsFragmentDoc}`;

/**
 * __useFormQuery__
 *
 * To run a query within a React component, call `useFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFormQuery(baseOptions: Apollo.QueryHookOptions<FormQuery, FormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormQuery, FormQueryVariables>(FormDocument, options);
      }
export function useFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormQuery, FormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormQuery, FormQueryVariables>(FormDocument, options);
        }
export type FormQueryHookResult = ReturnType<typeof useFormQuery>;
export type FormLazyQueryHookResult = ReturnType<typeof useFormLazyQuery>;
export type FormQueryResult = Apollo.QueryResult<FormQuery, FormQueryVariables>;
export const FormStepsDocument = gql`
    query formSteps($id: ID!) {
  form(id: $id, mode: PREVIEW) {
    ...FormEditorFields
  }
}
    ${FormEditorFieldsFragmentDoc}`;

/**
 * __useFormStepsQuery__
 *
 * To run a query within a React component, call `useFormStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormStepsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFormStepsQuery(baseOptions: Apollo.QueryHookOptions<FormStepsQuery, FormStepsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormStepsQuery, FormStepsQueryVariables>(FormStepsDocument, options);
      }
export function useFormStepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormStepsQuery, FormStepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormStepsQuery, FormStepsQueryVariables>(FormStepsDocument, options);
        }
export type FormStepsQueryHookResult = ReturnType<typeof useFormStepsQuery>;
export type FormStepsLazyQueryHookResult = ReturnType<typeof useFormStepsLazyQuery>;
export type FormStepsQueryResult = Apollo.QueryResult<FormStepsQuery, FormStepsQueryVariables>;
export const GetAllFormTemplatesDocument = gql`
    query GetAllFormTemplates($library: FormTemplateLibrary!) {
  formTemplates(library: $library) {
    id
    name
    description
    thumbnailUrl
    previewUrl
  }
}
    `;

/**
 * __useGetAllFormTemplatesQuery__
 *
 * To run a query within a React component, call `useGetAllFormTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFormTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFormTemplatesQuery({
 *   variables: {
 *      library: // value for 'library'
 *   },
 * });
 */
export function useGetAllFormTemplatesQuery(baseOptions: Apollo.QueryHookOptions<GetAllFormTemplatesQuery, GetAllFormTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllFormTemplatesQuery, GetAllFormTemplatesQueryVariables>(GetAllFormTemplatesDocument, options);
      }
export function useGetAllFormTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllFormTemplatesQuery, GetAllFormTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllFormTemplatesQuery, GetAllFormTemplatesQueryVariables>(GetAllFormTemplatesDocument, options);
        }
export type GetAllFormTemplatesQueryHookResult = ReturnType<typeof useGetAllFormTemplatesQuery>;
export type GetAllFormTemplatesLazyQueryHookResult = ReturnType<typeof useGetAllFormTemplatesLazyQuery>;
export type GetAllFormTemplatesQueryResult = Apollo.QueryResult<GetAllFormTemplatesQuery, GetAllFormTemplatesQueryVariables>;
export const PublishFormDocument = gql`
    mutation publishForm($id: ID!) {
  form: publishForm(id: $id) {
    ...FormFields
  }
}
    ${FormFieldsFragmentDoc}`;
export type PublishFormMutationFn = Apollo.MutationFunction<PublishFormMutation, PublishFormMutationVariables>;

/**
 * __usePublishFormMutation__
 *
 * To run a mutation, you first call `usePublishFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishFormMutation, { data, loading, error }] = usePublishFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishFormMutation(baseOptions?: Apollo.MutationHookOptions<PublishFormMutation, PublishFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishFormMutation, PublishFormMutationVariables>(PublishFormDocument, options);
      }
export type PublishFormMutationHookResult = ReturnType<typeof usePublishFormMutation>;
export type PublishFormMutationResult = Apollo.MutationResult<PublishFormMutation>;
export type PublishFormMutationOptions = Apollo.BaseMutationOptions<PublishFormMutation, PublishFormMutationVariables>;
export const RenameFormDocument = gql`
    mutation renameForm($id: ID!, $title: String, $alias: String, $description: String, $thumbnail: Json, $category: String, $useAsTemplate: Boolean) {
  renameForm(
    id: $id
    title: $title
    alias: $alias
    description: $description
    thumbnail: $thumbnail
    category: $category
    useAsTemplate: $useAsTemplate
  ) {
    ...FormFields
  }
}
    ${FormFieldsFragmentDoc}`;
export type RenameFormMutationFn = Apollo.MutationFunction<RenameFormMutation, RenameFormMutationVariables>;

/**
 * __useRenameFormMutation__
 *
 * To run a mutation, you first call `useRenameFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameFormMutation, { data, loading, error }] = useRenameFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      alias: // value for 'alias'
 *      description: // value for 'description'
 *      thumbnail: // value for 'thumbnail'
 *      category: // value for 'category'
 *      useAsTemplate: // value for 'useAsTemplate'
 *   },
 * });
 */
export function useRenameFormMutation(baseOptions?: Apollo.MutationHookOptions<RenameFormMutation, RenameFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenameFormMutation, RenameFormMutationVariables>(RenameFormDocument, options);
      }
export type RenameFormMutationHookResult = ReturnType<typeof useRenameFormMutation>;
export type RenameFormMutationResult = Apollo.MutationResult<RenameFormMutation>;
export type RenameFormMutationOptions = Apollo.BaseMutationOptions<RenameFormMutation, RenameFormMutationVariables>;
export const ResetBusinessBookingFormDocument = gql`
    mutation resetBusinessBookingForm {
  resetBusinessBookingForm
}
    `;
export type ResetBusinessBookingFormMutationFn = Apollo.MutationFunction<ResetBusinessBookingFormMutation, ResetBusinessBookingFormMutationVariables>;

/**
 * __useResetBusinessBookingFormMutation__
 *
 * To run a mutation, you first call `useResetBusinessBookingFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetBusinessBookingFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetBusinessBookingFormMutation, { data, loading, error }] = useResetBusinessBookingFormMutation({
 *   variables: {
 *   },
 * });
 */
export function useResetBusinessBookingFormMutation(baseOptions?: Apollo.MutationHookOptions<ResetBusinessBookingFormMutation, ResetBusinessBookingFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetBusinessBookingFormMutation, ResetBusinessBookingFormMutationVariables>(ResetBusinessBookingFormDocument, options);
      }
export type ResetBusinessBookingFormMutationHookResult = ReturnType<typeof useResetBusinessBookingFormMutation>;
export type ResetBusinessBookingFormMutationResult = Apollo.MutationResult<ResetBusinessBookingFormMutation>;
export type ResetBusinessBookingFormMutationOptions = Apollo.BaseMutationOptions<ResetBusinessBookingFormMutation, ResetBusinessBookingFormMutationVariables>;
export const FormSubmissionDocument = gql`
    query formSubmission($id: ID!) {
  formSubmission(id: $id) {
    id
    startedAt
    answers
    createdAt
    questions
    readAt
    form {
      id
      title
    }
  }
}
    `;

/**
 * __useFormSubmissionQuery__
 *
 * To run a query within a React component, call `useFormSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormSubmissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFormSubmissionQuery(baseOptions: Apollo.QueryHookOptions<FormSubmissionQuery, FormSubmissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormSubmissionQuery, FormSubmissionQueryVariables>(FormSubmissionDocument, options);
      }
export function useFormSubmissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormSubmissionQuery, FormSubmissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormSubmissionQuery, FormSubmissionQueryVariables>(FormSubmissionDocument, options);
        }
export type FormSubmissionQueryHookResult = ReturnType<typeof useFormSubmissionQuery>;
export type FormSubmissionLazyQueryHookResult = ReturnType<typeof useFormSubmissionLazyQuery>;
export type FormSubmissionQueryResult = Apollo.QueryResult<FormSubmissionQuery, FormSubmissionQueryVariables>;
export const FormSubmissionAttachmentUrlDocument = gql`
    query formSubmissionAttachmentURL($formSubmissionId: ID!, $answerKey: String!) {
  url: formSubmissionAttachmentURL(
    formSubmissionId: $formSubmissionId
    answerKey: $answerKey
  )
}
    `;

/**
 * __useFormSubmissionAttachmentUrlQuery__
 *
 * To run a query within a React component, call `useFormSubmissionAttachmentUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormSubmissionAttachmentUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormSubmissionAttachmentUrlQuery({
 *   variables: {
 *      formSubmissionId: // value for 'formSubmissionId'
 *      answerKey: // value for 'answerKey'
 *   },
 * });
 */
export function useFormSubmissionAttachmentUrlQuery(baseOptions: Apollo.QueryHookOptions<FormSubmissionAttachmentUrlQuery, FormSubmissionAttachmentUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormSubmissionAttachmentUrlQuery, FormSubmissionAttachmentUrlQueryVariables>(FormSubmissionAttachmentUrlDocument, options);
      }
export function useFormSubmissionAttachmentUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormSubmissionAttachmentUrlQuery, FormSubmissionAttachmentUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormSubmissionAttachmentUrlQuery, FormSubmissionAttachmentUrlQueryVariables>(FormSubmissionAttachmentUrlDocument, options);
        }
export type FormSubmissionAttachmentUrlQueryHookResult = ReturnType<typeof useFormSubmissionAttachmentUrlQuery>;
export type FormSubmissionAttachmentUrlLazyQueryHookResult = ReturnType<typeof useFormSubmissionAttachmentUrlLazyQuery>;
export type FormSubmissionAttachmentUrlQueryResult = Apollo.QueryResult<FormSubmissionAttachmentUrlQuery, FormSubmissionAttachmentUrlQueryVariables>;
export const FormSubmissionsDocument = gql`
    query formSubmissions($formId: ID, $limit: Int, $skip: Int, $dateFrom: DateTime, $dateTo: DateTime, $search: String, $appointmentId: ID, $patientId: ID) {
  response: formSubmissions(
    formId: $formId
    limit: $limit
    skip: $skip
    dateFrom: $dateFrom
    dateTo: $dateTo
    search: $search
    appointmentId: $appointmentId
    patientId: $patientId
  ) {
    submissions: formSubmissions {
      id
      startedAt
      answers
      createdAt
      questions
      readAt
      patientVerified
      patient {
        id
        name
      }
      form {
        id
        title
      }
    }
    meta: formSubmissionsMeta {
      count
    }
  }
}
    `;

/**
 * __useFormSubmissionsQuery__
 *
 * To run a query within a React component, call `useFormSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormSubmissionsQuery({
 *   variables: {
 *      formId: // value for 'formId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      search: // value for 'search'
 *      appointmentId: // value for 'appointmentId'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useFormSubmissionsQuery(baseOptions?: Apollo.QueryHookOptions<FormSubmissionsQuery, FormSubmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormSubmissionsQuery, FormSubmissionsQueryVariables>(FormSubmissionsDocument, options);
      }
export function useFormSubmissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormSubmissionsQuery, FormSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormSubmissionsQuery, FormSubmissionsQueryVariables>(FormSubmissionsDocument, options);
        }
export type FormSubmissionsQueryHookResult = ReturnType<typeof useFormSubmissionsQuery>;
export type FormSubmissionsLazyQueryHookResult = ReturnType<typeof useFormSubmissionsLazyQuery>;
export type FormSubmissionsQueryResult = Apollo.QueryResult<FormSubmissionsQuery, FormSubmissionsQueryVariables>;
export const UpdateFormSubmissionDocument = gql`
    mutation updateFormSubmission($id: ID!, $read: Boolean!) {
  submission: updateFormSubmission(id: $id, read: $read) {
    id
    readAt
  }
}
    `;
export type UpdateFormSubmissionMutationFn = Apollo.MutationFunction<UpdateFormSubmissionMutation, UpdateFormSubmissionMutationVariables>;

/**
 * __useUpdateFormSubmissionMutation__
 *
 * To run a mutation, you first call `useUpdateFormSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormSubmissionMutation, { data, loading, error }] = useUpdateFormSubmissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      read: // value for 'read'
 *   },
 * });
 */
export function useUpdateFormSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFormSubmissionMutation, UpdateFormSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFormSubmissionMutation, UpdateFormSubmissionMutationVariables>(UpdateFormSubmissionDocument, options);
      }
export type UpdateFormSubmissionMutationHookResult = ReturnType<typeof useUpdateFormSubmissionMutation>;
export type UpdateFormSubmissionMutationResult = Apollo.MutationResult<UpdateFormSubmissionMutation>;
export type UpdateFormSubmissionMutationOptions = Apollo.BaseMutationOptions<UpdateFormSubmissionMutation, UpdateFormSubmissionMutationVariables>;
export const UpdateFormDraftDocument = gql`
    mutation updateFormDraft($formId: ID!, $steps: Json, $settings: Json) {
  updateFormDraft(formId: $formId, steps: $steps, settings: $settings) {
    ...FormEditorFields
  }
}
    ${FormEditorFieldsFragmentDoc}`;
export type UpdateFormDraftMutationFn = Apollo.MutationFunction<UpdateFormDraftMutation, UpdateFormDraftMutationVariables>;

/**
 * __useUpdateFormDraftMutation__
 *
 * To run a mutation, you first call `useUpdateFormDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormDraftMutation, { data, loading, error }] = useUpdateFormDraftMutation({
 *   variables: {
 *      formId: // value for 'formId'
 *      steps: // value for 'steps'
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useUpdateFormDraftMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFormDraftMutation, UpdateFormDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFormDraftMutation, UpdateFormDraftMutationVariables>(UpdateFormDraftDocument, options);
      }
export type UpdateFormDraftMutationHookResult = ReturnType<typeof useUpdateFormDraftMutation>;
export type UpdateFormDraftMutationResult = Apollo.MutationResult<UpdateFormDraftMutation>;
export type UpdateFormDraftMutationOptions = Apollo.BaseMutationOptions<UpdateFormDraftMutation, UpdateFormDraftMutationVariables>;
export const InvalidateApiKeyDocument = gql`
    mutation invalidateApiKey($id: ID!) {
  invalidateApiKey(id: $id) {
    id
    value
    description
    createdAt
    updatedAt
    expiresAt
    invalidatedAt
  }
}
    `;
export type InvalidateApiKeyMutationFn = Apollo.MutationFunction<InvalidateApiKeyMutation, InvalidateApiKeyMutationVariables>;

/**
 * __useInvalidateApiKeyMutation__
 *
 * To run a mutation, you first call `useInvalidateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvalidateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invalidateApiKeyMutation, { data, loading, error }] = useInvalidateApiKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvalidateApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<InvalidateApiKeyMutation, InvalidateApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvalidateApiKeyMutation, InvalidateApiKeyMutationVariables>(InvalidateApiKeyDocument, options);
      }
export type InvalidateApiKeyMutationHookResult = ReturnType<typeof useInvalidateApiKeyMutation>;
export type InvalidateApiKeyMutationResult = Apollo.MutationResult<InvalidateApiKeyMutation>;
export type InvalidateApiKeyMutationOptions = Apollo.BaseMutationOptions<InvalidateApiKeyMutation, InvalidateApiKeyMutationVariables>;
export const BusinessLocationDocument = gql`
    query businessLocation($id: ID!) {
  Location(id: $id) {
    description
    address
    createdAt
    googlePlaceAddress
    googlePlaceId
    googlePlaceMapUrl
    googlePlaceImageUrl
    googlePlaceName
    id
    instructions
    lat
    lng
    name
    phone
    timezone
    updatedAt
    workingHours
    business {
      id
      name
    }
    picture {
      id
      url
    }
    services {
      id
      name
    }
  }
}
    `;

/**
 * __useBusinessLocationQuery__
 *
 * To run a query within a React component, call `useBusinessLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusinessLocationQuery(baseOptions: Apollo.QueryHookOptions<BusinessLocationQuery, BusinessLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessLocationQuery, BusinessLocationQueryVariables>(BusinessLocationDocument, options);
      }
export function useBusinessLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessLocationQuery, BusinessLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessLocationQuery, BusinessLocationQueryVariables>(BusinessLocationDocument, options);
        }
export type BusinessLocationQueryHookResult = ReturnType<typeof useBusinessLocationQuery>;
export type BusinessLocationLazyQueryHookResult = ReturnType<typeof useBusinessLocationLazyQuery>;
export type BusinessLocationQueryResult = Apollo.QueryResult<BusinessLocationQuery, BusinessLocationQueryVariables>;
export const BusinessLocationsDocument = gql`
    query businessLocations($limit: Int, $skip: Int, $search: String) {
  businessLocations(limit: $limit, skip: $skip, search: $search) {
    address
    createdAt
    googlePlaceAddress
    googlePlaceId
    googlePlaceMapUrl
    googlePlaceImageUrl
    googlePlaceName
    id
    instructions
    lat
    lng
    name
    phone
    timezone
    updatedAt
    workingHours
    business {
      id
      name
    }
    picture {
      id
      url
    }
    services {
      id
      name
    }
  }
  count: businessLocationsCount(search: $search)
}
    `;

/**
 * __useBusinessLocationsQuery__
 *
 * To run a query within a React component, call `useBusinessLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessLocationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useBusinessLocationsQuery(baseOptions?: Apollo.QueryHookOptions<BusinessLocationsQuery, BusinessLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessLocationsQuery, BusinessLocationsQueryVariables>(BusinessLocationsDocument, options);
      }
export function useBusinessLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessLocationsQuery, BusinessLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessLocationsQuery, BusinessLocationsQueryVariables>(BusinessLocationsDocument, options);
        }
export type BusinessLocationsQueryHookResult = ReturnType<typeof useBusinessLocationsQuery>;
export type BusinessLocationsLazyQueryHookResult = ReturnType<typeof useBusinessLocationsLazyQuery>;
export type BusinessLocationsQueryResult = Apollo.QueryResult<BusinessLocationsQuery, BusinessLocationsQueryVariables>;
export const BusinessLocationsNamesDocument = gql`
    query businessLocationsNames($limit: Int, $skip: Int) {
  businessLocations(limit: $limit, skip: $skip) {
    id
    name
  }
}
    `;

/**
 * __useBusinessLocationsNamesQuery__
 *
 * To run a query within a React component, call `useBusinessLocationsNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessLocationsNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessLocationsNamesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useBusinessLocationsNamesQuery(baseOptions?: Apollo.QueryHookOptions<BusinessLocationsNamesQuery, BusinessLocationsNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessLocationsNamesQuery, BusinessLocationsNamesQueryVariables>(BusinessLocationsNamesDocument, options);
      }
export function useBusinessLocationsNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessLocationsNamesQuery, BusinessLocationsNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessLocationsNamesQuery, BusinessLocationsNamesQueryVariables>(BusinessLocationsNamesDocument, options);
        }
export type BusinessLocationsNamesQueryHookResult = ReturnType<typeof useBusinessLocationsNamesQuery>;
export type BusinessLocationsNamesLazyQueryHookResult = ReturnType<typeof useBusinessLocationsNamesLazyQuery>;
export type BusinessLocationsNamesQueryResult = Apollo.QueryResult<BusinessLocationsNamesQuery, BusinessLocationsNamesQueryVariables>;
export const CreateBusinessLocationDocument = gql`
    mutation createBusinessLocation($name: String!, $address: String, $instructions: String, $phone: String, $lat: Float, $lng: Float, $timezone: String, $workingHours: Json, $googlePlaceId: String, $googlePlaceName: String, $googlePlaceAddress: String, $googlePlaceMapUrl: String, $googlePlaceImageUrl: String, $pictureId: ID) {
  createLocation(
    name: $name
    address: $address
    instructions: $instructions
    phone: $phone
    lat: $lat
    lng: $lng
    timezone: $timezone
    workingHours: $workingHours
    googlePlaceId: $googlePlaceId
    googlePlaceName: $googlePlaceName
    googlePlaceAddress: $googlePlaceAddress
    googlePlaceMapUrl: $googlePlaceMapUrl
    googlePlaceImageUrl: $googlePlaceImageUrl
    pictureId: $pictureId
  ) {
    id
    name
    address
    instructions
    phone
    lat
    lng
    timezone
    workingHours
    googlePlaceId
    googlePlaceName
    googlePlaceAddress
    googlePlaceMapUrl
    googlePlaceImageUrl
    picture {
      id
    }
    business {
      id
    }
  }
}
    `;
export type CreateBusinessLocationMutationFn = Apollo.MutationFunction<CreateBusinessLocationMutation, CreateBusinessLocationMutationVariables>;

/**
 * __useCreateBusinessLocationMutation__
 *
 * To run a mutation, you first call `useCreateBusinessLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessLocationMutation, { data, loading, error }] = useCreateBusinessLocationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      address: // value for 'address'
 *      instructions: // value for 'instructions'
 *      phone: // value for 'phone'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      timezone: // value for 'timezone'
 *      workingHours: // value for 'workingHours'
 *      googlePlaceId: // value for 'googlePlaceId'
 *      googlePlaceName: // value for 'googlePlaceName'
 *      googlePlaceAddress: // value for 'googlePlaceAddress'
 *      googlePlaceMapUrl: // value for 'googlePlaceMapUrl'
 *      googlePlaceImageUrl: // value for 'googlePlaceImageUrl'
 *      pictureId: // value for 'pictureId'
 *   },
 * });
 */
export function useCreateBusinessLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateBusinessLocationMutation, CreateBusinessLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBusinessLocationMutation, CreateBusinessLocationMutationVariables>(CreateBusinessLocationDocument, options);
      }
export type CreateBusinessLocationMutationHookResult = ReturnType<typeof useCreateBusinessLocationMutation>;
export type CreateBusinessLocationMutationResult = Apollo.MutationResult<CreateBusinessLocationMutation>;
export type CreateBusinessLocationMutationOptions = Apollo.BaseMutationOptions<CreateBusinessLocationMutation, CreateBusinessLocationMutationVariables>;
export const DeleteBusinessLocationDocument = gql`
    mutation deleteBusinessLocation($id: ID!) {
  deleteLocation(id: $id)
}
    `;
export type DeleteBusinessLocationMutationFn = Apollo.MutationFunction<DeleteBusinessLocationMutation, DeleteBusinessLocationMutationVariables>;

/**
 * __useDeleteBusinessLocationMutation__
 *
 * To run a mutation, you first call `useDeleteBusinessLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBusinessLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBusinessLocationMutation, { data, loading, error }] = useDeleteBusinessLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBusinessLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBusinessLocationMutation, DeleteBusinessLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBusinessLocationMutation, DeleteBusinessLocationMutationVariables>(DeleteBusinessLocationDocument, options);
      }
export type DeleteBusinessLocationMutationHookResult = ReturnType<typeof useDeleteBusinessLocationMutation>;
export type DeleteBusinessLocationMutationResult = Apollo.MutationResult<DeleteBusinessLocationMutation>;
export type DeleteBusinessLocationMutationOptions = Apollo.BaseMutationOptions<DeleteBusinessLocationMutation, DeleteBusinessLocationMutationVariables>;
export const UpdateBusinessLocationDocument = gql`
    mutation updateBusinessLocation($id: ID!, $name: String!, $address: String, $instructions: String, $phone: String, $lat: Float, $lng: Float, $timezone: String, $workingHours: Json, $googlePlaceId: String, $googlePlaceName: String, $googlePlaceAddress: String, $googlePlaceMapUrl: String, $googlePlaceImageUrl: String, $pictureId: ID, $description: String) {
  updateLocation(
    id: $id
    name: $name
    address: $address
    instructions: $instructions
    phone: $phone
    lat: $lat
    lng: $lng
    timezone: $timezone
    workingHours: $workingHours
    googlePlaceId: $googlePlaceId
    googlePlaceName: $googlePlaceName
    googlePlaceAddress: $googlePlaceAddress
    googlePlaceMapUrl: $googlePlaceMapUrl
    googlePlaceImageUrl: $googlePlaceImageUrl
    pictureId: $pictureId
    description: $description
  ) {
    id
    name
    address
    instructions
    phone
    lat
    lng
    timezone
    workingHours
    googlePlaceId
    googlePlaceName
    googlePlaceAddress
    googlePlaceMapUrl
    googlePlaceImageUrl
    description
    picture {
      id
      url
    }
    business {
      id
    }
    services {
      id
    }
  }
}
    `;
export type UpdateBusinessLocationMutationFn = Apollo.MutationFunction<UpdateBusinessLocationMutation, UpdateBusinessLocationMutationVariables>;

/**
 * __useUpdateBusinessLocationMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessLocationMutation, { data, loading, error }] = useUpdateBusinessLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      address: // value for 'address'
 *      instructions: // value for 'instructions'
 *      phone: // value for 'phone'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      timezone: // value for 'timezone'
 *      workingHours: // value for 'workingHours'
 *      googlePlaceId: // value for 'googlePlaceId'
 *      googlePlaceName: // value for 'googlePlaceName'
 *      googlePlaceAddress: // value for 'googlePlaceAddress'
 *      googlePlaceMapUrl: // value for 'googlePlaceMapUrl'
 *      googlePlaceImageUrl: // value for 'googlePlaceImageUrl'
 *      pictureId: // value for 'pictureId'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateBusinessLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessLocationMutation, UpdateBusinessLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessLocationMutation, UpdateBusinessLocationMutationVariables>(UpdateBusinessLocationDocument, options);
      }
export type UpdateBusinessLocationMutationHookResult = ReturnType<typeof useUpdateBusinessLocationMutation>;
export type UpdateBusinessLocationMutationResult = Apollo.MutationResult<UpdateBusinessLocationMutation>;
export type UpdateBusinessLocationMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessLocationMutation, UpdateBusinessLocationMutationVariables>;
export const CreateNoteDocument = gql`
    mutation createNote($appointmentId: ID, $patientId: ID!, $content: String!) {
  createNote(
    appointmentId: $appointmentId
    content: $content
    patientId: $patientId
  ) {
    id
    content
    createdAt
    updatedAt
    appointment {
      id
    }
    patient {
      id
    }
  }
}
    `;
export type CreateNoteMutationFn = Apollo.MutationFunction<CreateNoteMutation, CreateNoteMutationVariables>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      patientId: // value for 'patientId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(CreateNoteDocument, options);
      }
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<CreateNoteMutation, CreateNoteMutationVariables>;
export const DeleteNoteDocument = gql`
    mutation deleteNote($id: ID!) {
  deleteNote(id: $id)
}
    `;
export type DeleteNoteMutationFn = Apollo.MutationFunction<DeleteNoteMutation, DeleteNoteMutationVariables>;

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNoteMutation, DeleteNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(DeleteNoteDocument, options);
      }
export type DeleteNoteMutationHookResult = ReturnType<typeof useDeleteNoteMutation>;
export type DeleteNoteMutationResult = Apollo.MutationResult<DeleteNoteMutation>;
export type DeleteNoteMutationOptions = Apollo.BaseMutationOptions<DeleteNoteMutation, DeleteNoteMutationVariables>;
export const NoteDocument = gql`
    query note($id: ID!) {
  note(id: $id) {
    id
    createdAt
    updatedAt
    staff {
      id
    }
    content
  }
}
    `;

/**
 * __useNoteQuery__
 *
 * To run a query within a React component, call `useNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteQuery(baseOptions: Apollo.QueryHookOptions<NoteQuery, NoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NoteQuery, NoteQueryVariables>(NoteDocument, options);
      }
export function useNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoteQuery, NoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NoteQuery, NoteQueryVariables>(NoteDocument, options);
        }
export type NoteQueryHookResult = ReturnType<typeof useNoteQuery>;
export type NoteLazyQueryHookResult = ReturnType<typeof useNoteLazyQuery>;
export type NoteQueryResult = Apollo.QueryResult<NoteQuery, NoteQueryVariables>;
export const PatientNotesDocument = gql`
    query patientNotes($patientId: ID!, $appointmentId: ID, $limit: Int, $skip: Int) {
  notes: patientNotes(
    patientId: $patientId
    appointmentId: $appointmentId
    limit: $limit
    skip: $skip
  ) {
    id
    createdAt
    content
    appointment {
      id
    }
    staff {
      id
      name
      email
      picture {
        id
        url
      }
    }
  }
  count: patientNotesCount(patientId: $patientId, appointmentId: $appointmentId)
}
    `;

/**
 * __usePatientNotesQuery__
 *
 * To run a query within a React component, call `usePatientNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientNotesQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      appointmentId: // value for 'appointmentId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function usePatientNotesQuery(baseOptions: Apollo.QueryHookOptions<PatientNotesQuery, PatientNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientNotesQuery, PatientNotesQueryVariables>(PatientNotesDocument, options);
      }
export function usePatientNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientNotesQuery, PatientNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientNotesQuery, PatientNotesQueryVariables>(PatientNotesDocument, options);
        }
export type PatientNotesQueryHookResult = ReturnType<typeof usePatientNotesQuery>;
export type PatientNotesLazyQueryHookResult = ReturnType<typeof usePatientNotesLazyQuery>;
export type PatientNotesQueryResult = Apollo.QueryResult<PatientNotesQuery, PatientNotesQueryVariables>;
export const UpdateNoteDocument = gql`
    mutation updateNote($id: ID!, $content: String!) {
  updateNote(id: $id, content: $content) {
    id
    content
    createdAt
    updatedAt
    appointment {
      id
    }
    patient {
      id
    }
  }
}
    `;
export type UpdateNoteMutationFn = Apollo.MutationFunction<UpdateNoteMutation, UpdateNoteMutationVariables>;

/**
 * __useUpdateNoteMutation__
 *
 * To run a mutation, you first call `useUpdateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteMutation, { data, loading, error }] = useUpdateNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpdateNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNoteMutation, UpdateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNoteMutation, UpdateNoteMutationVariables>(UpdateNoteDocument, options);
      }
export type UpdateNoteMutationHookResult = ReturnType<typeof useUpdateNoteMutation>;
export type UpdateNoteMutationResult = Apollo.MutationResult<UpdateNoteMutation>;
export type UpdateNoteMutationOptions = Apollo.BaseMutationOptions<UpdateNoteMutation, UpdateNoteMutationVariables>;
export const BusinessNotificationsDocument = gql`
    query businessNotifications($businessId: ID!, $limit: Int!, $after: ID) {
  notifications: businessNotifications(
    businessId: $businessId
    limit: $limit
    after: $after
  ) {
    id
    title
    content
    type
    read
    createdAt
  }
  totalCount: businessNotificationsCount(businessId: $businessId)
}
    `;

/**
 * __useBusinessNotificationsQuery__
 *
 * To run a query within a React component, call `useBusinessNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessNotificationsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useBusinessNotificationsQuery(baseOptions: Apollo.QueryHookOptions<BusinessNotificationsQuery, BusinessNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessNotificationsQuery, BusinessNotificationsQueryVariables>(BusinessNotificationsDocument, options);
      }
export function useBusinessNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessNotificationsQuery, BusinessNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessNotificationsQuery, BusinessNotificationsQueryVariables>(BusinessNotificationsDocument, options);
        }
export type BusinessNotificationsQueryHookResult = ReturnType<typeof useBusinessNotificationsQuery>;
export type BusinessNotificationsLazyQueryHookResult = ReturnType<typeof useBusinessNotificationsLazyQuery>;
export type BusinessNotificationsQueryResult = Apollo.QueryResult<BusinessNotificationsQuery, BusinessNotificationsQueryVariables>;
export const BookingEventNotificationTemplatesDocument = gql`
    query bookingEventNotificationTemplates($businessId: ID!) {
  templates: businessBookingEventNotificationTemplates(businessId: $businessId) {
    id
    event
    type
    services {
      id
      name
    }
    enabled
    deleted
    recipient
    messageTemplate
    defaultTemplate
  }
  services: businessServices(businessId: $businessId) {
    id
    name
    enabled
  }
  business: Business(id: $businessId) {
    id
    templates
  }
}
    `;

/**
 * __useBookingEventNotificationTemplatesQuery__
 *
 * To run a query within a React component, call `useBookingEventNotificationTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingEventNotificationTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingEventNotificationTemplatesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useBookingEventNotificationTemplatesQuery(baseOptions: Apollo.QueryHookOptions<BookingEventNotificationTemplatesQuery, BookingEventNotificationTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingEventNotificationTemplatesQuery, BookingEventNotificationTemplatesQueryVariables>(BookingEventNotificationTemplatesDocument, options);
      }
export function useBookingEventNotificationTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingEventNotificationTemplatesQuery, BookingEventNotificationTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingEventNotificationTemplatesQuery, BookingEventNotificationTemplatesQueryVariables>(BookingEventNotificationTemplatesDocument, options);
        }
export type BookingEventNotificationTemplatesQueryHookResult = ReturnType<typeof useBookingEventNotificationTemplatesQuery>;
export type BookingEventNotificationTemplatesLazyQueryHookResult = ReturnType<typeof useBookingEventNotificationTemplatesLazyQuery>;
export type BookingEventNotificationTemplatesQueryResult = Apollo.QueryResult<BookingEventNotificationTemplatesQuery, BookingEventNotificationTemplatesQueryVariables>;
export const CreateBookingEventNotificationTemplateDocument = gql`
    mutation createBookingEventNotificationTemplate($businessId: ID!, $recipient: MessageRecipient!, $type: MessageType!, $event: BookingEvent!, $services: [ID!]!) {
  createBookingEventNotificationTemplate(
    businessId: $businessId
    type: $type
    recipient: $recipient
    services: $services
    event: $event
  ) {
    id
    business {
      id
    }
    services {
      id
    }
    type
    event
    recipient
    enabled
    deleted
  }
}
    `;
export type CreateBookingEventNotificationTemplateMutationFn = Apollo.MutationFunction<CreateBookingEventNotificationTemplateMutation, CreateBookingEventNotificationTemplateMutationVariables>;

/**
 * __useCreateBookingEventNotificationTemplateMutation__
 *
 * To run a mutation, you first call `useCreateBookingEventNotificationTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingEventNotificationTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingEventNotificationTemplateMutation, { data, loading, error }] = useCreateBookingEventNotificationTemplateMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      recipient: // value for 'recipient'
 *      type: // value for 'type'
 *      event: // value for 'event'
 *      services: // value for 'services'
 *   },
 * });
 */
export function useCreateBookingEventNotificationTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookingEventNotificationTemplateMutation, CreateBookingEventNotificationTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookingEventNotificationTemplateMutation, CreateBookingEventNotificationTemplateMutationVariables>(CreateBookingEventNotificationTemplateDocument, options);
      }
export type CreateBookingEventNotificationTemplateMutationHookResult = ReturnType<typeof useCreateBookingEventNotificationTemplateMutation>;
export type CreateBookingEventNotificationTemplateMutationResult = Apollo.MutationResult<CreateBookingEventNotificationTemplateMutation>;
export type CreateBookingEventNotificationTemplateMutationOptions = Apollo.BaseMutationOptions<CreateBookingEventNotificationTemplateMutation, CreateBookingEventNotificationTemplateMutationVariables>;
export const CreateFollowupMessageTemplateDocument = gql`
    mutation createFollowupMessageTemplate($businessId: ID!, $minutes: Int!, $recipient: MessageRecipient!, $type: MessageType!, $services: [ID!]!) {
  createPostAppointmentMessageTemplate(
    businessId: $businessId
    minutes: $minutes
    type: $type
    recipient: $recipient
    services: $services
  ) {
    id
    business {
      id
    }
    services {
      id
    }
    minutes
    type
    recipient
    enabled
    deleted
  }
}
    `;
export type CreateFollowupMessageTemplateMutationFn = Apollo.MutationFunction<CreateFollowupMessageTemplateMutation, CreateFollowupMessageTemplateMutationVariables>;

/**
 * __useCreateFollowupMessageTemplateMutation__
 *
 * To run a mutation, you first call `useCreateFollowupMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFollowupMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFollowupMessageTemplateMutation, { data, loading, error }] = useCreateFollowupMessageTemplateMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      minutes: // value for 'minutes'
 *      recipient: // value for 'recipient'
 *      type: // value for 'type'
 *      services: // value for 'services'
 *   },
 * });
 */
export function useCreateFollowupMessageTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateFollowupMessageTemplateMutation, CreateFollowupMessageTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFollowupMessageTemplateMutation, CreateFollowupMessageTemplateMutationVariables>(CreateFollowupMessageTemplateDocument, options);
      }
export type CreateFollowupMessageTemplateMutationHookResult = ReturnType<typeof useCreateFollowupMessageTemplateMutation>;
export type CreateFollowupMessageTemplateMutationResult = Apollo.MutationResult<CreateFollowupMessageTemplateMutation>;
export type CreateFollowupMessageTemplateMutationOptions = Apollo.BaseMutationOptions<CreateFollowupMessageTemplateMutation, CreateFollowupMessageTemplateMutationVariables>;
export const CreateReminderTemplateDocument = gql`
    mutation createReminderTemplate($businessId: ID!, $minutes: Int!, $type: ReminderType!, $resourceType: ReminderResourceType!, $services: [ID!]!) {
  createReminderTemplate(
    businessId: $businessId
    minutes: $minutes
    type: $type
    resourceType: $resourceType
    services: $services
  ) {
    id
    business {
      id
    }
    services {
      id
    }
    minutes
    type
    resourceType
    enabled
    deleted
  }
}
    `;
export type CreateReminderTemplateMutationFn = Apollo.MutationFunction<CreateReminderTemplateMutation, CreateReminderTemplateMutationVariables>;

/**
 * __useCreateReminderTemplateMutation__
 *
 * To run a mutation, you first call `useCreateReminderTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReminderTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReminderTemplateMutation, { data, loading, error }] = useCreateReminderTemplateMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      minutes: // value for 'minutes'
 *      type: // value for 'type'
 *      resourceType: // value for 'resourceType'
 *      services: // value for 'services'
 *   },
 * });
 */
export function useCreateReminderTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateReminderTemplateMutation, CreateReminderTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReminderTemplateMutation, CreateReminderTemplateMutationVariables>(CreateReminderTemplateDocument, options);
      }
export type CreateReminderTemplateMutationHookResult = ReturnType<typeof useCreateReminderTemplateMutation>;
export type CreateReminderTemplateMutationResult = Apollo.MutationResult<CreateReminderTemplateMutation>;
export type CreateReminderTemplateMutationOptions = Apollo.BaseMutationOptions<CreateReminderTemplateMutation, CreateReminderTemplateMutationVariables>;
export const FollowupNotificationTemplatesDocument = gql`
    query followupNotificationTemplates($businessId: ID!) {
  postBookingTemplates: businessPostAppointmentMessageTemplates(
    businessId: $businessId
  ) {
    id
    minutes
    type
    enabled
    deleted
    recipient
    messageTemplate
    defaultTemplate
    services {
      id
      name
    }
  }
  services: businessServices(businessId: $businessId) {
    id
    name
  }
  business: Business(id: $businessId) {
    id
    templates
  }
  defaultMessageTemplates: defaultNotificationsTemplates {
    templates
  }
}
    `;

/**
 * __useFollowupNotificationTemplatesQuery__
 *
 * To run a query within a React component, call `useFollowupNotificationTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowupNotificationTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowupNotificationTemplatesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useFollowupNotificationTemplatesQuery(baseOptions: Apollo.QueryHookOptions<FollowupNotificationTemplatesQuery, FollowupNotificationTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FollowupNotificationTemplatesQuery, FollowupNotificationTemplatesQueryVariables>(FollowupNotificationTemplatesDocument, options);
      }
export function useFollowupNotificationTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FollowupNotificationTemplatesQuery, FollowupNotificationTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FollowupNotificationTemplatesQuery, FollowupNotificationTemplatesQueryVariables>(FollowupNotificationTemplatesDocument, options);
        }
export type FollowupNotificationTemplatesQueryHookResult = ReturnType<typeof useFollowupNotificationTemplatesQuery>;
export type FollowupNotificationTemplatesLazyQueryHookResult = ReturnType<typeof useFollowupNotificationTemplatesLazyQuery>;
export type FollowupNotificationTemplatesQueryResult = Apollo.QueryResult<FollowupNotificationTemplatesQuery, FollowupNotificationTemplatesQueryVariables>;
export const ReminderNotificationTemplatesDocument = gql`
    query reminderNotificationTemplates($businessId: ID!) {
  reminders: allReminderTemplates(businessId: $businessId) {
    id
    minutes
    type
    resourceType
    enabled
    deleted
    messageTemplate
    defaultTemplate
    services {
      id
      name
    }
  }
  business: Business(id: $businessId) {
    id
    templates
  }
  services: businessServices(businessId: $businessId) {
    id
    name
  }
  defaultMessageTemplates: defaultNotificationsTemplates {
    templates
  }
}
    `;

/**
 * __useReminderNotificationTemplatesQuery__
 *
 * To run a query within a React component, call `useReminderNotificationTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReminderNotificationTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReminderNotificationTemplatesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useReminderNotificationTemplatesQuery(baseOptions: Apollo.QueryHookOptions<ReminderNotificationTemplatesQuery, ReminderNotificationTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReminderNotificationTemplatesQuery, ReminderNotificationTemplatesQueryVariables>(ReminderNotificationTemplatesDocument, options);
      }
export function useReminderNotificationTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReminderNotificationTemplatesQuery, ReminderNotificationTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReminderNotificationTemplatesQuery, ReminderNotificationTemplatesQueryVariables>(ReminderNotificationTemplatesDocument, options);
        }
export type ReminderNotificationTemplatesQueryHookResult = ReturnType<typeof useReminderNotificationTemplatesQuery>;
export type ReminderNotificationTemplatesLazyQueryHookResult = ReturnType<typeof useReminderNotificationTemplatesLazyQuery>;
export type ReminderNotificationTemplatesQueryResult = Apollo.QueryResult<ReminderNotificationTemplatesQuery, ReminderNotificationTemplatesQueryVariables>;
export const UpdateBookingEventNotificationTemplateDocument = gql`
    mutation updateBookingEventNotificationTemplate($id: ID!, $deleted: Boolean, $enabled: Boolean, $messageTemplate: Json) {
  updateBookingEventNotificationTemplate(
    id: $id
    enabled: $enabled
    deleted: $deleted
    messageTemplate: $messageTemplate
  ) {
    id
    enabled
    deleted
    messageTemplate
  }
}
    `;
export type UpdateBookingEventNotificationTemplateMutationFn = Apollo.MutationFunction<UpdateBookingEventNotificationTemplateMutation, UpdateBookingEventNotificationTemplateMutationVariables>;

/**
 * __useUpdateBookingEventNotificationTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateBookingEventNotificationTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingEventNotificationTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingEventNotificationTemplateMutation, { data, loading, error }] = useUpdateBookingEventNotificationTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleted: // value for 'deleted'
 *      enabled: // value for 'enabled'
 *      messageTemplate: // value for 'messageTemplate'
 *   },
 * });
 */
export function useUpdateBookingEventNotificationTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookingEventNotificationTemplateMutation, UpdateBookingEventNotificationTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookingEventNotificationTemplateMutation, UpdateBookingEventNotificationTemplateMutationVariables>(UpdateBookingEventNotificationTemplateDocument, options);
      }
export type UpdateBookingEventNotificationTemplateMutationHookResult = ReturnType<typeof useUpdateBookingEventNotificationTemplateMutation>;
export type UpdateBookingEventNotificationTemplateMutationResult = Apollo.MutationResult<UpdateBookingEventNotificationTemplateMutation>;
export type UpdateBookingEventNotificationTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateBookingEventNotificationTemplateMutation, UpdateBookingEventNotificationTemplateMutationVariables>;
export const UpdatePostAppointmentMessageTemplateDocument = gql`
    mutation updatePostAppointmentMessageTemplate($id: ID!, $deleted: Boolean, $enabled: Boolean, $messageTemplate: Json) {
  updatePostAppointmentMessageTemplate(
    id: $id
    enabled: $enabled
    deleted: $deleted
    messageTemplate: $messageTemplate
  ) {
    id
    enabled
    deleted
    messageTemplate
  }
}
    `;
export type UpdatePostAppointmentMessageTemplateMutationFn = Apollo.MutationFunction<UpdatePostAppointmentMessageTemplateMutation, UpdatePostAppointmentMessageTemplateMutationVariables>;

/**
 * __useUpdatePostAppointmentMessageTemplateMutation__
 *
 * To run a mutation, you first call `useUpdatePostAppointmentMessageTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostAppointmentMessageTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostAppointmentMessageTemplateMutation, { data, loading, error }] = useUpdatePostAppointmentMessageTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleted: // value for 'deleted'
 *      enabled: // value for 'enabled'
 *      messageTemplate: // value for 'messageTemplate'
 *   },
 * });
 */
export function useUpdatePostAppointmentMessageTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostAppointmentMessageTemplateMutation, UpdatePostAppointmentMessageTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePostAppointmentMessageTemplateMutation, UpdatePostAppointmentMessageTemplateMutationVariables>(UpdatePostAppointmentMessageTemplateDocument, options);
      }
export type UpdatePostAppointmentMessageTemplateMutationHookResult = ReturnType<typeof useUpdatePostAppointmentMessageTemplateMutation>;
export type UpdatePostAppointmentMessageTemplateMutationResult = Apollo.MutationResult<UpdatePostAppointmentMessageTemplateMutation>;
export type UpdatePostAppointmentMessageTemplateMutationOptions = Apollo.BaseMutationOptions<UpdatePostAppointmentMessageTemplateMutation, UpdatePostAppointmentMessageTemplateMutationVariables>;
export const UpdateReminderTemplateDocument = gql`
    mutation updateReminderTemplate($id: ID!, $deleted: Boolean, $enabled: Boolean, $messageTemplate: Json) {
  updateReminderTemplate(
    id: $id
    enabled: $enabled
    deleted: $deleted
    messageTemplate: $messageTemplate
  ) {
    id
    enabled
    deleted
    messageTemplate
  }
}
    `;
export type UpdateReminderTemplateMutationFn = Apollo.MutationFunction<UpdateReminderTemplateMutation, UpdateReminderTemplateMutationVariables>;

/**
 * __useUpdateReminderTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateReminderTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReminderTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReminderTemplateMutation, { data, loading, error }] = useUpdateReminderTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleted: // value for 'deleted'
 *      enabled: // value for 'enabled'
 *      messageTemplate: // value for 'messageTemplate'
 *   },
 * });
 */
export function useUpdateReminderTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReminderTemplateMutation, UpdateReminderTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReminderTemplateMutation, UpdateReminderTemplateMutationVariables>(UpdateReminderTemplateDocument, options);
      }
export type UpdateReminderTemplateMutationHookResult = ReturnType<typeof useUpdateReminderTemplateMutation>;
export type UpdateReminderTemplateMutationResult = Apollo.MutationResult<UpdateReminderTemplateMutation>;
export type UpdateReminderTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateReminderTemplateMutation, UpdateReminderTemplateMutationVariables>;
export const OutgoingWebhooksDocument = gql`
    query outgoingWebhooks($businessId: ID!) {
  outgoingWebhooks(businessId: $businessId) {
    id
    endpoint
    description
    enabled
  }
}
    `;

/**
 * __useOutgoingWebhooksQuery__
 *
 * To run a query within a React component, call `useOutgoingWebhooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutgoingWebhooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutgoingWebhooksQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useOutgoingWebhooksQuery(baseOptions: Apollo.QueryHookOptions<OutgoingWebhooksQuery, OutgoingWebhooksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutgoingWebhooksQuery, OutgoingWebhooksQueryVariables>(OutgoingWebhooksDocument, options);
      }
export function useOutgoingWebhooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutgoingWebhooksQuery, OutgoingWebhooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutgoingWebhooksQuery, OutgoingWebhooksQueryVariables>(OutgoingWebhooksDocument, options);
        }
export type OutgoingWebhooksQueryHookResult = ReturnType<typeof useOutgoingWebhooksQuery>;
export type OutgoingWebhooksLazyQueryHookResult = ReturnType<typeof useOutgoingWebhooksLazyQuery>;
export type OutgoingWebhooksQueryResult = Apollo.QueryResult<OutgoingWebhooksQuery, OutgoingWebhooksQueryVariables>;
export const OutgoingWebhookDocument = gql`
    query outgoingWebhook($businessId: ID!, $outgoingWebhookId: ID!, $eventsLimit: Int) {
  outgoingWebhook(businessId: $businessId, outgoingWebhookId: $outgoingWebhookId) {
    id
    endpoint
    description
    enabled
    events(limit: $eventsLimit) {
      id
      name
      data
      createdAt
      outgoingWebhookDeliveries {
        id
        event {
          id
        }
        outgoingWebhook {
          id
        }
        duration
        requestHeaders
        requestPayload
        responseStatus
        responseHeaders
        responsePayload
      }
    }
  }
}
    `;

/**
 * __useOutgoingWebhookQuery__
 *
 * To run a query within a React component, call `useOutgoingWebhookQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutgoingWebhookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutgoingWebhookQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      outgoingWebhookId: // value for 'outgoingWebhookId'
 *      eventsLimit: // value for 'eventsLimit'
 *   },
 * });
 */
export function useOutgoingWebhookQuery(baseOptions: Apollo.QueryHookOptions<OutgoingWebhookQuery, OutgoingWebhookQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutgoingWebhookQuery, OutgoingWebhookQueryVariables>(OutgoingWebhookDocument, options);
      }
export function useOutgoingWebhookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutgoingWebhookQuery, OutgoingWebhookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutgoingWebhookQuery, OutgoingWebhookQueryVariables>(OutgoingWebhookDocument, options);
        }
export type OutgoingWebhookQueryHookResult = ReturnType<typeof useOutgoingWebhookQuery>;
export type OutgoingWebhookLazyQueryHookResult = ReturnType<typeof useOutgoingWebhookLazyQuery>;
export type OutgoingWebhookQueryResult = Apollo.QueryResult<OutgoingWebhookQuery, OutgoingWebhookQueryVariables>;
export const CreateOutgoingWebhookDocument = gql`
    mutation createOutgoingWebhook($businessId: ID!, $endpoint: String!) {
  createOutgoingWebhook(businessId: $businessId, endpoint: $endpoint) {
    id
    endpoint
    description
    enabled
  }
}
    `;
export type CreateOutgoingWebhookMutationFn = Apollo.MutationFunction<CreateOutgoingWebhookMutation, CreateOutgoingWebhookMutationVariables>;

/**
 * __useCreateOutgoingWebhookMutation__
 *
 * To run a mutation, you first call `useCreateOutgoingWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOutgoingWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOutgoingWebhookMutation, { data, loading, error }] = useCreateOutgoingWebhookMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      endpoint: // value for 'endpoint'
 *   },
 * });
 */
export function useCreateOutgoingWebhookMutation(baseOptions?: Apollo.MutationHookOptions<CreateOutgoingWebhookMutation, CreateOutgoingWebhookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOutgoingWebhookMutation, CreateOutgoingWebhookMutationVariables>(CreateOutgoingWebhookDocument, options);
      }
export type CreateOutgoingWebhookMutationHookResult = ReturnType<typeof useCreateOutgoingWebhookMutation>;
export type CreateOutgoingWebhookMutationResult = Apollo.MutationResult<CreateOutgoingWebhookMutation>;
export type CreateOutgoingWebhookMutationOptions = Apollo.BaseMutationOptions<CreateOutgoingWebhookMutation, CreateOutgoingWebhookMutationVariables>;
export const UpdateOutgoingWebhookDocument = gql`
    mutation updateOutgoingWebhook($id: ID!, $enabled: Boolean!) {
  updateOutgoingWebhook(id: $id, enabled: $enabled) {
    id
    endpoint
    description
    enabled
  }
}
    `;
export type UpdateOutgoingWebhookMutationFn = Apollo.MutationFunction<UpdateOutgoingWebhookMutation, UpdateOutgoingWebhookMutationVariables>;

/**
 * __useUpdateOutgoingWebhookMutation__
 *
 * To run a mutation, you first call `useUpdateOutgoingWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOutgoingWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOutgoingWebhookMutation, { data, loading, error }] = useUpdateOutgoingWebhookMutation({
 *   variables: {
 *      id: // value for 'id'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdateOutgoingWebhookMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOutgoingWebhookMutation, UpdateOutgoingWebhookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOutgoingWebhookMutation, UpdateOutgoingWebhookMutationVariables>(UpdateOutgoingWebhookDocument, options);
      }
export type UpdateOutgoingWebhookMutationHookResult = ReturnType<typeof useUpdateOutgoingWebhookMutation>;
export type UpdateOutgoingWebhookMutationResult = Apollo.MutationResult<UpdateOutgoingWebhookMutation>;
export type UpdateOutgoingWebhookMutationOptions = Apollo.BaseMutationOptions<UpdateOutgoingWebhookMutation, UpdateOutgoingWebhookMutationVariables>;
export const DeleteOutgoingWebhookDocument = gql`
    mutation deleteOutgoingWebhook($id: ID!) {
  deleteOutgoingWebhook(id: $id)
}
    `;
export type DeleteOutgoingWebhookMutationFn = Apollo.MutationFunction<DeleteOutgoingWebhookMutation, DeleteOutgoingWebhookMutationVariables>;

/**
 * __useDeleteOutgoingWebhookMutation__
 *
 * To run a mutation, you first call `useDeleteOutgoingWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOutgoingWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOutgoingWebhookMutation, { data, loading, error }] = useDeleteOutgoingWebhookMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOutgoingWebhookMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOutgoingWebhookMutation, DeleteOutgoingWebhookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOutgoingWebhookMutation, DeleteOutgoingWebhookMutationVariables>(DeleteOutgoingWebhookDocument, options);
      }
export type DeleteOutgoingWebhookMutationHookResult = ReturnType<typeof useDeleteOutgoingWebhookMutation>;
export type DeleteOutgoingWebhookMutationResult = Apollo.MutationResult<DeleteOutgoingWebhookMutation>;
export type DeleteOutgoingWebhookMutationOptions = Apollo.BaseMutationOptions<DeleteOutgoingWebhookMutation, DeleteOutgoingWebhookMutationVariables>;
export const AddInvoiceItemDocument = gql`
    mutation addInvoiceItem($invoiceId: ID!, $description: String!, $quantity: Int!, $amount: Float!) {
  addInvoiceItem(
    invoiceId: $invoiceId
    description: $description
    quantity: $quantity
    amount: $amount
  ) {
    ...InvoiceFields
  }
}
    ${InvoiceFieldsFragmentDoc}`;
export type AddInvoiceItemMutationFn = Apollo.MutationFunction<AddInvoiceItemMutation, AddInvoiceItemMutationVariables>;

/**
 * __useAddInvoiceItemMutation__
 *
 * To run a mutation, you first call `useAddInvoiceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInvoiceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInvoiceItemMutation, { data, loading, error }] = useAddInvoiceItemMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      description: // value for 'description'
 *      quantity: // value for 'quantity'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useAddInvoiceItemMutation(baseOptions?: Apollo.MutationHookOptions<AddInvoiceItemMutation, AddInvoiceItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInvoiceItemMutation, AddInvoiceItemMutationVariables>(AddInvoiceItemDocument, options);
      }
export type AddInvoiceItemMutationHookResult = ReturnType<typeof useAddInvoiceItemMutation>;
export type AddInvoiceItemMutationResult = Apollo.MutationResult<AddInvoiceItemMutation>;
export type AddInvoiceItemMutationOptions = Apollo.BaseMutationOptions<AddInvoiceItemMutation, AddInvoiceItemMutationVariables>;
export const CreateDraftInvoiceDocument = gql`
    mutation createDraftInvoice($patientId: ID!, $currency: String) {
  createDraftInvoice(patientId: $patientId, currency: $currency) {
    ...InvoiceFields
  }
}
    ${InvoiceFieldsFragmentDoc}`;
export type CreateDraftInvoiceMutationFn = Apollo.MutationFunction<CreateDraftInvoiceMutation, CreateDraftInvoiceMutationVariables>;

/**
 * __useCreateDraftInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateDraftInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftInvoiceMutation, { data, loading, error }] = useCreateDraftInvoiceMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useCreateDraftInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateDraftInvoiceMutation, CreateDraftInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDraftInvoiceMutation, CreateDraftInvoiceMutationVariables>(CreateDraftInvoiceDocument, options);
      }
export type CreateDraftInvoiceMutationHookResult = ReturnType<typeof useCreateDraftInvoiceMutation>;
export type CreateDraftInvoiceMutationResult = Apollo.MutationResult<CreateDraftInvoiceMutation>;
export type CreateDraftInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateDraftInvoiceMutation, CreateDraftInvoiceMutationVariables>;
export const DeleteDraftInvoiceDocument = gql`
    mutation deleteDraftInvoice($invoiceId: ID!) {
  deleted: deleteDraftInvoice(invoiceId: $invoiceId)
}
    `;
export type DeleteDraftInvoiceMutationFn = Apollo.MutationFunction<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables>;

/**
 * __useDeleteDraftInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteDraftInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDraftInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDraftInvoiceMutation, { data, loading, error }] = useDeleteDraftInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useDeleteDraftInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables>(DeleteDraftInvoiceDocument, options);
      }
export type DeleteDraftInvoiceMutationHookResult = ReturnType<typeof useDeleteDraftInvoiceMutation>;
export type DeleteDraftInvoiceMutationResult = Apollo.MutationResult<DeleteDraftInvoiceMutation>;
export type DeleteDraftInvoiceMutationOptions = Apollo.BaseMutationOptions<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables>;
export const DeleteInvoiceItemDocument = gql`
    mutation deleteInvoiceItem($invoiceId: ID!, $invoiceItemId: ID!) {
  deleteInvoiceItem(invoiceId: $invoiceId, invoiceItemId: $invoiceItemId) {
    ...InvoiceFields
  }
}
    ${InvoiceFieldsFragmentDoc}`;
export type DeleteInvoiceItemMutationFn = Apollo.MutationFunction<DeleteInvoiceItemMutation, DeleteInvoiceItemMutationVariables>;

/**
 * __useDeleteInvoiceItemMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceItemMutation, { data, loading, error }] = useDeleteInvoiceItemMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      invoiceItemId: // value for 'invoiceItemId'
 *   },
 * });
 */
export function useDeleteInvoiceItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInvoiceItemMutation, DeleteInvoiceItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInvoiceItemMutation, DeleteInvoiceItemMutationVariables>(DeleteInvoiceItemDocument, options);
      }
export type DeleteInvoiceItemMutationHookResult = ReturnType<typeof useDeleteInvoiceItemMutation>;
export type DeleteInvoiceItemMutationResult = Apollo.MutationResult<DeleteInvoiceItemMutation>;
export type DeleteInvoiceItemMutationOptions = Apollo.BaseMutationOptions<DeleteInvoiceItemMutation, DeleteInvoiceItemMutationVariables>;
export const FinalizeInvoiceDocument = gql`
    mutation finalizeInvoice($invoiceId: ID!) {
  finalizeInvoice(invoiceId: $invoiceId) {
    ...InvoiceFields
  }
}
    ${InvoiceFieldsFragmentDoc}`;
export type FinalizeInvoiceMutationFn = Apollo.MutationFunction<FinalizeInvoiceMutation, FinalizeInvoiceMutationVariables>;

/**
 * __useFinalizeInvoiceMutation__
 *
 * To run a mutation, you first call `useFinalizeInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeInvoiceMutation, { data, loading, error }] = useFinalizeInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useFinalizeInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<FinalizeInvoiceMutation, FinalizeInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinalizeInvoiceMutation, FinalizeInvoiceMutationVariables>(FinalizeInvoiceDocument, options);
      }
export type FinalizeInvoiceMutationHookResult = ReturnType<typeof useFinalizeInvoiceMutation>;
export type FinalizeInvoiceMutationResult = Apollo.MutationResult<FinalizeInvoiceMutation>;
export type FinalizeInvoiceMutationOptions = Apollo.BaseMutationOptions<FinalizeInvoiceMutation, FinalizeInvoiceMutationVariables>;
export const InvoiceDocument = gql`
    query Invoice($id: ID!) {
  invoice: Invoice(id: $id) {
    ...InvoiceFields
  }
}
    ${InvoiceFieldsFragmentDoc}`;

/**
 * __useInvoiceQuery__
 *
 * To run a query within a React component, call `useInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceQuery(baseOptions: Apollo.QueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options);
      }
export function useInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options);
        }
export type InvoiceQueryHookResult = ReturnType<typeof useInvoiceQuery>;
export type InvoiceLazyQueryHookResult = ReturnType<typeof useInvoiceLazyQuery>;
export type InvoiceQueryResult = Apollo.QueryResult<InvoiceQuery, InvoiceQueryVariables>;
export const PatientInvoicesDocument = gql`
    query patientInvoices($patientId: ID!, $statuses: [InvoiceStatus!], $limit: Int, $skip: Int) {
  invoices: patientInvoices(
    patientId: $patientId
    statuses: $statuses
    limit: $limit
    skip: $skip
  ) {
    id
    number
    createdAt
    status
    currency
    dueDate
    amountTotal
    amountOutstanding
  }
  count: patientInvoicesCount(patientId: $patientId, statuses: $statuses)
}
    `;

/**
 * __usePatientInvoicesQuery__
 *
 * To run a query within a React component, call `usePatientInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientInvoicesQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      statuses: // value for 'statuses'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function usePatientInvoicesQuery(baseOptions: Apollo.QueryHookOptions<PatientInvoicesQuery, PatientInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientInvoicesQuery, PatientInvoicesQueryVariables>(PatientInvoicesDocument, options);
      }
export function usePatientInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientInvoicesQuery, PatientInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientInvoicesQuery, PatientInvoicesQueryVariables>(PatientInvoicesDocument, options);
        }
export type PatientInvoicesQueryHookResult = ReturnType<typeof usePatientInvoicesQuery>;
export type PatientInvoicesLazyQueryHookResult = ReturnType<typeof usePatientInvoicesLazyQuery>;
export type PatientInvoicesQueryResult = Apollo.QueryResult<PatientInvoicesQuery, PatientInvoicesQueryVariables>;
export const UpdateDraftInvoiceDocument = gql`
    mutation updateDraftInvoice($invoiceId: ID!, $dueDate: DateTime, $couponCode: String) {
  updateDraftInvoice(
    invoiceId: $invoiceId
    dueDate: $dueDate
    couponCode: $couponCode
  ) {
    ...InvoiceFields
  }
}
    ${InvoiceFieldsFragmentDoc}`;
export type UpdateDraftInvoiceMutationFn = Apollo.MutationFunction<UpdateDraftInvoiceMutation, UpdateDraftInvoiceMutationVariables>;

/**
 * __useUpdateDraftInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateDraftInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDraftInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDraftInvoiceMutation, { data, loading, error }] = useUpdateDraftInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      dueDate: // value for 'dueDate'
 *      couponCode: // value for 'couponCode'
 *   },
 * });
 */
export function useUpdateDraftInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDraftInvoiceMutation, UpdateDraftInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDraftInvoiceMutation, UpdateDraftInvoiceMutationVariables>(UpdateDraftInvoiceDocument, options);
      }
export type UpdateDraftInvoiceMutationHookResult = ReturnType<typeof useUpdateDraftInvoiceMutation>;
export type UpdateDraftInvoiceMutationResult = Apollo.MutationResult<UpdateDraftInvoiceMutation>;
export type UpdateDraftInvoiceMutationOptions = Apollo.BaseMutationOptions<UpdateDraftInvoiceMutation, UpdateDraftInvoiceMutationVariables>;
export const UpdateInvoiceItemDocument = gql`
    mutation updateInvoiceItem($invoiceId: ID!, $invoiceItemId: ID!, $description: String!, $quantity: Int!, $amount: Float!) {
  updateInvoiceItem(
    invoiceId: $invoiceId
    invoiceItemId: $invoiceItemId
    description: $description
    quantity: $quantity
    amount: $amount
  ) {
    ...InvoiceFields
  }
}
    ${InvoiceFieldsFragmentDoc}`;
export type UpdateInvoiceItemMutationFn = Apollo.MutationFunction<UpdateInvoiceItemMutation, UpdateInvoiceItemMutationVariables>;

/**
 * __useUpdateInvoiceItemMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceItemMutation, { data, loading, error }] = useUpdateInvoiceItemMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      invoiceItemId: // value for 'invoiceItemId'
 *      description: // value for 'description'
 *      quantity: // value for 'quantity'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useUpdateInvoiceItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceItemMutation, UpdateInvoiceItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceItemMutation, UpdateInvoiceItemMutationVariables>(UpdateInvoiceItemDocument, options);
      }
export type UpdateInvoiceItemMutationHookResult = ReturnType<typeof useUpdateInvoiceItemMutation>;
export type UpdateInvoiceItemMutationResult = Apollo.MutationResult<UpdateInvoiceItemMutation>;
export type UpdateInvoiceItemMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceItemMutation, UpdateInvoiceItemMutationVariables>;
export const VoidInvoiceDocument = gql`
    mutation voidInvoice($invoiceId: ID!) {
  voidInvoice(invoiceId: $invoiceId) {
    ...InvoiceFields
  }
}
    ${InvoiceFieldsFragmentDoc}`;
export type VoidInvoiceMutationFn = Apollo.MutationFunction<VoidInvoiceMutation, VoidInvoiceMutationVariables>;

/**
 * __useVoidInvoiceMutation__
 *
 * To run a mutation, you first call `useVoidInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidInvoiceMutation, { data, loading, error }] = useVoidInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useVoidInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<VoidInvoiceMutation, VoidInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoidInvoiceMutation, VoidInvoiceMutationVariables>(VoidInvoiceDocument, options);
      }
export type VoidInvoiceMutationHookResult = ReturnType<typeof useVoidInvoiceMutation>;
export type VoidInvoiceMutationResult = Apollo.MutationResult<VoidInvoiceMutation>;
export type VoidInvoiceMutationOptions = Apollo.BaseMutationOptions<VoidInvoiceMutation, VoidInvoiceMutationVariables>;
export const SendPortalInviteDocument = gql`
    mutation sendPortalInvite($patientId: ID!, $subject: String!, $body: String!) {
  success: sendPatientInvite(
    patientId: $patientId
    subject: $subject
    body: $body
  )
}
    `;
export type SendPortalInviteMutationFn = Apollo.MutationFunction<SendPortalInviteMutation, SendPortalInviteMutationVariables>;

/**
 * __useSendPortalInviteMutation__
 *
 * To run a mutation, you first call `useSendPortalInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPortalInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPortalInviteMutation, { data, loading, error }] = useSendPortalInviteMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      subject: // value for 'subject'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useSendPortalInviteMutation(baseOptions?: Apollo.MutationHookOptions<SendPortalInviteMutation, SendPortalInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPortalInviteMutation, SendPortalInviteMutationVariables>(SendPortalInviteDocument, options);
      }
export type SendPortalInviteMutationHookResult = ReturnType<typeof useSendPortalInviteMutation>;
export type SendPortalInviteMutationResult = Apollo.MutationResult<SendPortalInviteMutation>;
export type SendPortalInviteMutationOptions = Apollo.BaseMutationOptions<SendPortalInviteMutation, SendPortalInviteMutationVariables>;
export const SelectPatientsListDocument = gql`
    query selectPatientsList($businessId: ID!, $limit: Int!, $search: String) {
  patients: businessPatients(
    businessId: $businessId
    limit: $limit
    search: $search
  ) {
    id
    name
    email
  }
}
    `;

/**
 * __useSelectPatientsListQuery__
 *
 * To run a query within a React component, call `useSelectPatientsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectPatientsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectPatientsListQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSelectPatientsListQuery(baseOptions: Apollo.QueryHookOptions<SelectPatientsListQuery, SelectPatientsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectPatientsListQuery, SelectPatientsListQueryVariables>(SelectPatientsListDocument, options);
      }
export function useSelectPatientsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectPatientsListQuery, SelectPatientsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectPatientsListQuery, SelectPatientsListQueryVariables>(SelectPatientsListDocument, options);
        }
export type SelectPatientsListQueryHookResult = ReturnType<typeof useSelectPatientsListQuery>;
export type SelectPatientsListLazyQueryHookResult = ReturnType<typeof useSelectPatientsListLazyQuery>;
export type SelectPatientsListQueryResult = Apollo.QueryResult<SelectPatientsListQuery, SelectPatientsListQueryVariables>;
export const SelectServicesListDocument = gql`
    query selectServicesList($businessId: ID!, $limit: Int!, $search: String, $excludeIds: [ID!]) {
  services: businessServices(
    businessId: $businessId
    limit: $limit
    search: $search
    excludeIds: $excludeIds
  ) {
    id
    name
    description
    durations
  }
}
    `;

/**
 * __useSelectServicesListQuery__
 *
 * To run a query within a React component, call `useSelectServicesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectServicesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectServicesListQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      excludeIds: // value for 'excludeIds'
 *   },
 * });
 */
export function useSelectServicesListQuery(baseOptions: Apollo.QueryHookOptions<SelectServicesListQuery, SelectServicesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectServicesListQuery, SelectServicesListQueryVariables>(SelectServicesListDocument, options);
      }
export function useSelectServicesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectServicesListQuery, SelectServicesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectServicesListQuery, SelectServicesListQueryVariables>(SelectServicesListDocument, options);
        }
export type SelectServicesListQueryHookResult = ReturnType<typeof useSelectServicesListQuery>;
export type SelectServicesListLazyQueryHookResult = ReturnType<typeof useSelectServicesListLazyQuery>;
export type SelectServicesListQueryResult = Apollo.QueryResult<SelectServicesListQuery, SelectServicesListQueryVariables>;
export const DeleteCategoryDocument = gql`
    mutation deleteCategory($id: ID!) {
  deleteServiceCategory(id: $id) {
    id
  }
}
    `;
export type DeleteCategoryMutationFn = Apollo.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, options);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const ServiceCategoriesDocument = gql`
    query serviceCategories($businessId: ID!) {
  currentBusiness(businessId: $businessId) {
    id
    name
    serviceCategories {
      id
      name
    }
  }
}
    `;

/**
 * __useServiceCategoriesQuery__
 *
 * To run a query within a React component, call `useServiceCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceCategoriesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useServiceCategoriesQuery(baseOptions: Apollo.QueryHookOptions<ServiceCategoriesQuery, ServiceCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceCategoriesQuery, ServiceCategoriesQueryVariables>(ServiceCategoriesDocument, options);
      }
export function useServiceCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceCategoriesQuery, ServiceCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceCategoriesQuery, ServiceCategoriesQueryVariables>(ServiceCategoriesDocument, options);
        }
export type ServiceCategoriesQueryHookResult = ReturnType<typeof useServiceCategoriesQuery>;
export type ServiceCategoriesLazyQueryHookResult = ReturnType<typeof useServiceCategoriesLazyQuery>;
export type ServiceCategoriesQueryResult = Apollo.QueryResult<ServiceCategoriesQuery, ServiceCategoriesQueryVariables>;
export const AllBusinessServicesDocument = gql`
    query allBusinessServices($businessId: ID!, $limit: Int!, $skip: Int, $search: String, $excludeIds: [ID!]) {
  services: businessServices(
    businessId: $businessId
    limit: $limit
    skip: $skip
    search: $search
    excludeIds: $excludeIds
  ) {
    id
    alias
    name
    description
    durations
    currency
    enabled
    locationType
    locationValue
    business {
      id
      alias
    }
    bookingPageUrl
    staffs {
      id
      name
      picture {
        id
        url
      }
    }
    categories {
      id
      name
    }
    picture {
      id
      url
    }
  }
  count: businessServicesCount(
    businessId: $businessId
    search: $search
    excludeIds: $excludeIds
  )
}
    `;

/**
 * __useAllBusinessServicesQuery__
 *
 * To run a query within a React component, call `useAllBusinessServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBusinessServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBusinessServicesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      search: // value for 'search'
 *      excludeIds: // value for 'excludeIds'
 *   },
 * });
 */
export function useAllBusinessServicesQuery(baseOptions: Apollo.QueryHookOptions<AllBusinessServicesQuery, AllBusinessServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllBusinessServicesQuery, AllBusinessServicesQueryVariables>(AllBusinessServicesDocument, options);
      }
export function useAllBusinessServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllBusinessServicesQuery, AllBusinessServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllBusinessServicesQuery, AllBusinessServicesQueryVariables>(AllBusinessServicesDocument, options);
        }
export type AllBusinessServicesQueryHookResult = ReturnType<typeof useAllBusinessServicesQuery>;
export type AllBusinessServicesLazyQueryHookResult = ReturnType<typeof useAllBusinessServicesLazyQuery>;
export type AllBusinessServicesQueryResult = Apollo.QueryResult<AllBusinessServicesQuery, AllBusinessServicesQueryVariables>;
export const BusinessServiceDocument = gql`
    query businessService($serviceId: ID) {
  service: Service(id: $serviceId) {
    id
    alias
    name
    deleted
    description
    durations
    currency
    leadTime
    cancelLeadTime
    timeIncrement
    categories {
      id
      name
    }
    workingHours
    availabilityLimitDays
    staffs {
      id
      name
      user {
        id
      }
    }
    additionalStaff {
      id
    }
    locationType
    locationValue
    bookingEndpoints {
      id
      alias
    }
    recurrence
    enabled
    requiresStripePayment
    bookingsPerTimeslot
    bookingsRequireApproval
    hideTimeslotsSeats
    bookingPageVisibility
    allowGuests
    locationOverridesStaffs
    bufferBeforeMinutes
    bufferAfterMinutes
    requiredAdditionalStaffCount
    picture {
      id
      url
    }
    location {
      id
    }
  }
}
    `;

/**
 * __useBusinessServiceQuery__
 *
 * To run a query within a React component, call `useBusinessServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessServiceQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useBusinessServiceQuery(baseOptions?: Apollo.QueryHookOptions<BusinessServiceQuery, BusinessServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessServiceQuery, BusinessServiceQueryVariables>(BusinessServiceDocument, options);
      }
export function useBusinessServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessServiceQuery, BusinessServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessServiceQuery, BusinessServiceQueryVariables>(BusinessServiceDocument, options);
        }
export type BusinessServiceQueryHookResult = ReturnType<typeof useBusinessServiceQuery>;
export type BusinessServiceLazyQueryHookResult = ReturnType<typeof useBusinessServiceLazyQuery>;
export type BusinessServiceQueryResult = Apollo.QueryResult<BusinessServiceQuery, BusinessServiceQueryVariables>;
export const BusinessServicesNamesDocument = gql`
    query businessServicesNames($businessId: ID!, $limit: Int!, $skip: Int, $search: String) {
  services: businessServices(
    businessId: $businessId
    limit: $limit
    skip: $skip
    search: $search
  ) {
    id
    name
  }
  count: businessServicesCount(businessId: $businessId)
}
    `;

/**
 * __useBusinessServicesNamesQuery__
 *
 * To run a query within a React component, call `useBusinessServicesNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessServicesNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessServicesNamesQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useBusinessServicesNamesQuery(baseOptions: Apollo.QueryHookOptions<BusinessServicesNamesQuery, BusinessServicesNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessServicesNamesQuery, BusinessServicesNamesQueryVariables>(BusinessServicesNamesDocument, options);
      }
export function useBusinessServicesNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessServicesNamesQuery, BusinessServicesNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessServicesNamesQuery, BusinessServicesNamesQueryVariables>(BusinessServicesNamesDocument, options);
        }
export type BusinessServicesNamesQueryHookResult = ReturnType<typeof useBusinessServicesNamesQuery>;
export type BusinessServicesNamesLazyQueryHookResult = ReturnType<typeof useBusinessServicesNamesLazyQuery>;
export type BusinessServicesNamesQueryResult = Apollo.QueryResult<BusinessServicesNamesQuery, BusinessServicesNamesQueryVariables>;
export const CreateServiceDocument = gql`
    mutation createService($name: String!, $description: String, $businessId: ID!, $durations: Json!, $currency: String!) {
  createService(
    name: $name
    description: $description
    businessId: $businessId
    durations: $durations
    currency: $currency
  ) {
    id
    name
    business {
      id
      servicesCount
    }
  }
}
    `;
export type CreateServiceMutationFn = Apollo.MutationFunction<CreateServiceMutation, CreateServiceMutationVariables>;

/**
 * __useCreateServiceMutation__
 *
 * To run a mutation, you first call `useCreateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceMutation, { data, loading, error }] = useCreateServiceMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      businessId: // value for 'businessId'
 *      durations: // value for 'durations'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useCreateServiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateServiceMutation, CreateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServiceMutation, CreateServiceMutationVariables>(CreateServiceDocument, options);
      }
export type CreateServiceMutationHookResult = ReturnType<typeof useCreateServiceMutation>;
export type CreateServiceMutationResult = Apollo.MutationResult<CreateServiceMutation>;
export type CreateServiceMutationOptions = Apollo.BaseMutationOptions<CreateServiceMutation, CreateServiceMutationVariables>;
export const CreateCategoryDocument = gql`
    mutation createCategory($name: String!, $businessId: ID!) {
  createServiceCategory(name: $name, businessId: $businessId) {
    id
    name
    business {
      id
    }
  }
}
    `;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const DeleteServiceDocument = gql`
    mutation deleteService($id: ID!) {
  updateService(id: $id, deleted: true) {
    id
    business {
      id
      servicesCount
    }
  }
}
    `;
export type DeleteServiceMutationFn = Apollo.MutationFunction<DeleteServiceMutation, DeleteServiceMutationVariables>;

/**
 * __useDeleteServiceMutation__
 *
 * To run a mutation, you first call `useDeleteServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceMutation, { data, loading, error }] = useDeleteServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteServiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteServiceMutation, DeleteServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteServiceMutation, DeleteServiceMutationVariables>(DeleteServiceDocument, options);
      }
export type DeleteServiceMutationHookResult = ReturnType<typeof useDeleteServiceMutation>;
export type DeleteServiceMutationResult = Apollo.MutationResult<DeleteServiceMutation>;
export type DeleteServiceMutationOptions = Apollo.BaseMutationOptions<DeleteServiceMutation, DeleteServiceMutationVariables>;
export const DuplicateServiceDocument = gql`
    mutation duplicateService($id: ID!) {
  service: duplicateService(id: $id) {
    id
  }
}
    `;
export type DuplicateServiceMutationFn = Apollo.MutationFunction<DuplicateServiceMutation, DuplicateServiceMutationVariables>;

/**
 * __useDuplicateServiceMutation__
 *
 * To run a mutation, you first call `useDuplicateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateServiceMutation, { data, loading, error }] = useDuplicateServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicateServiceMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateServiceMutation, DuplicateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateServiceMutation, DuplicateServiceMutationVariables>(DuplicateServiceDocument, options);
      }
export type DuplicateServiceMutationHookResult = ReturnType<typeof useDuplicateServiceMutation>;
export type DuplicateServiceMutationResult = Apollo.MutationResult<DuplicateServiceMutation>;
export type DuplicateServiceMutationOptions = Apollo.BaseMutationOptions<DuplicateServiceMutation, DuplicateServiceMutationVariables>;
export const ServiceDocument = gql`
    query service($id: ID!) {
  service: Service(id: $id) {
    id
    name
    durations
  }
}
    `;

/**
 * __useServiceQuery__
 *
 * To run a query within a React component, call `useServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceQuery(baseOptions: Apollo.QueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
      }
export function useServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
        }
export type ServiceQueryHookResult = ReturnType<typeof useServiceQuery>;
export type ServiceLazyQueryHookResult = ReturnType<typeof useServiceLazyQuery>;
export type ServiceQueryResult = Apollo.QueryResult<ServiceQuery, ServiceQueryVariables>;
export const ServiceWorkingHoursDocument = gql`
    query serviceWorkingHours($id: ID!) {
  service: Service(id: $id) {
    id
    name
    workingHours
    business {
      id
      timezone
      workingHours
    }
  }
}
    `;

/**
 * __useServiceWorkingHoursQuery__
 *
 * To run a query within a React component, call `useServiceWorkingHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceWorkingHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceWorkingHoursQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceWorkingHoursQuery(baseOptions: Apollo.QueryHookOptions<ServiceWorkingHoursQuery, ServiceWorkingHoursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceWorkingHoursQuery, ServiceWorkingHoursQueryVariables>(ServiceWorkingHoursDocument, options);
      }
export function useServiceWorkingHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceWorkingHoursQuery, ServiceWorkingHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceWorkingHoursQuery, ServiceWorkingHoursQueryVariables>(ServiceWorkingHoursDocument, options);
        }
export type ServiceWorkingHoursQueryHookResult = ReturnType<typeof useServiceWorkingHoursQuery>;
export type ServiceWorkingHoursLazyQueryHookResult = ReturnType<typeof useServiceWorkingHoursLazyQuery>;
export type ServiceWorkingHoursQueryResult = Apollo.QueryResult<ServiceWorkingHoursQuery, ServiceWorkingHoursQueryVariables>;
export const UpdateServiceDocument = gql`
    mutation updateService($id: ID!, $alias: String, $name: String, $description: String, $durations: Json, $currency: String, $categories: [ID!], $staffs: [ID!], $leadTime: Int, $timeIncrement: Int, $locationType: String, $locationValue: String, $recurrence: Json, $requiresStripePayment: Boolean, $workingHours: Json, $availabilityLimitDays: Int, $bookingsPerTimeslot: Int, $additionalStaff: [ID!], $bookingsRequireApproval: Boolean, $hideTimeslotsSeats: Boolean, $bookingPageVisibility: String, $allowGuests: Boolean, $locationOverridesStaffs: Boolean, $cancelLeadTime: Int, $bufferBeforeMinutes: Int, $bufferAfterMinutes: Int, $requiredAdditionalStaffCount: Int, $pictureId: ID, $locationId: ID) {
  updateService(
    id: $id
    alias: $alias
    name: $name
    description: $description
    currency: $currency
    durations: $durations
    categoriesIds: $categories
    staffsIds: $staffs
    leadTime: $leadTime
    timeIncrement: $timeIncrement
    locationType: $locationType
    locationValue: $locationValue
    recurrence: $recurrence
    requiresStripePayment: $requiresStripePayment
    workingHours: $workingHours
    availabilityLimitDays: $availabilityLimitDays
    bookingsPerTimeslot: $bookingsPerTimeslot
    additionalStaffIds: $additionalStaff
    bookingsRequireApproval: $bookingsRequireApproval
    hideTimeslotsSeats: $hideTimeslotsSeats
    bookingPageVisibility: $bookingPageVisibility
    allowGuests: $allowGuests
    locationOverridesStaffs: $locationOverridesStaffs
    cancelLeadTime: $cancelLeadTime
    bufferBeforeMinutes: $bufferBeforeMinutes
    bufferAfterMinutes: $bufferAfterMinutes
    requiredAdditionalStaffCount: $requiredAdditionalStaffCount
    pictureId: $pictureId
    locationId: $locationId
  ) {
    id
    alias
    name
    description
    currency
    durations
    leadTime
    timeIncrement
    categories {
      id
    }
    staffs {
      id
    }
    locationType
    locationValue
    recurrence
    requiresStripePayment
    workingHours
    availabilityLimitDays
    bookingsPerTimeslot
    additionalStaff {
      id
    }
    bookingsRequireApproval
    hideTimeslotsSeats
    bookingPageVisibility
    allowGuests
    locationOverridesStaffs
    cancelLeadTime
    bufferBeforeMinutes
    bufferAfterMinutes
    requiredAdditionalStaffCount
    picture {
      id
      url
    }
    business {
      id
      servicesCount
    }
    location {
      id
    }
  }
}
    `;
export type UpdateServiceMutationFn = Apollo.MutationFunction<UpdateServiceMutation, UpdateServiceMutationVariables>;

/**
 * __useUpdateServiceMutation__
 *
 * To run a mutation, you first call `useUpdateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceMutation, { data, loading, error }] = useUpdateServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      alias: // value for 'alias'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      durations: // value for 'durations'
 *      currency: // value for 'currency'
 *      categories: // value for 'categories'
 *      staffs: // value for 'staffs'
 *      leadTime: // value for 'leadTime'
 *      timeIncrement: // value for 'timeIncrement'
 *      locationType: // value for 'locationType'
 *      locationValue: // value for 'locationValue'
 *      recurrence: // value for 'recurrence'
 *      requiresStripePayment: // value for 'requiresStripePayment'
 *      workingHours: // value for 'workingHours'
 *      availabilityLimitDays: // value for 'availabilityLimitDays'
 *      bookingsPerTimeslot: // value for 'bookingsPerTimeslot'
 *      additionalStaff: // value for 'additionalStaff'
 *      bookingsRequireApproval: // value for 'bookingsRequireApproval'
 *      hideTimeslotsSeats: // value for 'hideTimeslotsSeats'
 *      bookingPageVisibility: // value for 'bookingPageVisibility'
 *      allowGuests: // value for 'allowGuests'
 *      locationOverridesStaffs: // value for 'locationOverridesStaffs'
 *      cancelLeadTime: // value for 'cancelLeadTime'
 *      bufferBeforeMinutes: // value for 'bufferBeforeMinutes'
 *      bufferAfterMinutes: // value for 'bufferAfterMinutes'
 *      requiredAdditionalStaffCount: // value for 'requiredAdditionalStaffCount'
 *      pictureId: // value for 'pictureId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useUpdateServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceMutation, UpdateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceMutation, UpdateServiceMutationVariables>(UpdateServiceDocument, options);
      }
export type UpdateServiceMutationHookResult = ReturnType<typeof useUpdateServiceMutation>;
export type UpdateServiceMutationResult = Apollo.MutationResult<UpdateServiceMutation>;
export type UpdateServiceMutationOptions = Apollo.BaseMutationOptions<UpdateServiceMutation, UpdateServiceMutationVariables>;
export const UpdateServiceStatusDocument = gql`
    mutation updateServiceStatus($id: ID!, $enabled: Boolean!) {
  updateService(id: $id, enabled: $enabled) {
    id
    enabled
  }
}
    `;
export type UpdateServiceStatusMutationFn = Apollo.MutationFunction<UpdateServiceStatusMutation, UpdateServiceStatusMutationVariables>;

/**
 * __useUpdateServiceStatusMutation__
 *
 * To run a mutation, you first call `useUpdateServiceStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceStatusMutation, { data, loading, error }] = useUpdateServiceStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdateServiceStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceStatusMutation, UpdateServiceStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceStatusMutation, UpdateServiceStatusMutationVariables>(UpdateServiceStatusDocument, options);
      }
export type UpdateServiceStatusMutationHookResult = ReturnType<typeof useUpdateServiceStatusMutation>;
export type UpdateServiceStatusMutationResult = Apollo.MutationResult<UpdateServiceStatusMutation>;
export type UpdateServiceStatusMutationOptions = Apollo.BaseMutationOptions<UpdateServiceStatusMutation, UpdateServiceStatusMutationVariables>;
export const UpdateBusinessEmailSettingsDocument = gql`
    mutation updateBusinessEmailSettings($businessId: ID!, $ownerNotificationsRecipient: OwnerNotificationsRecipient, $bookingNotificationsReplyTo: NotificationsReplyToType, $ownerNotificationsCC: String, $attachICSFiles: Boolean) {
  updateBusiness(
    id: $businessId
    ownerNotificationsRecipient: $ownerNotificationsRecipient
    bookingNotificationsReplyTo: $bookingNotificationsReplyTo
    ownerNotificationsCC: $ownerNotificationsCC
    attachICSFiles: $attachICSFiles
  ) {
    id
    ownerNotificationsRecipient
    bookingNotificationsReplyTo
    ownerNotificationsCC
    attachICSFiles
  }
}
    `;
export type UpdateBusinessEmailSettingsMutationFn = Apollo.MutationFunction<UpdateBusinessEmailSettingsMutation, UpdateBusinessEmailSettingsMutationVariables>;

/**
 * __useUpdateBusinessEmailSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessEmailSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessEmailSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessEmailSettingsMutation, { data, loading, error }] = useUpdateBusinessEmailSettingsMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      ownerNotificationsRecipient: // value for 'ownerNotificationsRecipient'
 *      bookingNotificationsReplyTo: // value for 'bookingNotificationsReplyTo'
 *      ownerNotificationsCC: // value for 'ownerNotificationsCC'
 *      attachICSFiles: // value for 'attachICSFiles'
 *   },
 * });
 */
export function useUpdateBusinessEmailSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessEmailSettingsMutation, UpdateBusinessEmailSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessEmailSettingsMutation, UpdateBusinessEmailSettingsMutationVariables>(UpdateBusinessEmailSettingsDocument, options);
      }
export type UpdateBusinessEmailSettingsMutationHookResult = ReturnType<typeof useUpdateBusinessEmailSettingsMutation>;
export type UpdateBusinessEmailSettingsMutationResult = Apollo.MutationResult<UpdateBusinessEmailSettingsMutation>;
export type UpdateBusinessEmailSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessEmailSettingsMutation, UpdateBusinessEmailSettingsMutationVariables>;
export const UpdateSmsSettingsDocument = gql`
    mutation updateSMSSettings($businessId: ID!, $sendSMSFromStaffNumber: Boolean) {
  updateBusiness(id: $businessId, sendSMSFromStaffNumber: $sendSMSFromStaffNumber) {
    id
    sendSMSFromStaffNumber
  }
}
    `;
export type UpdateSmsSettingsMutationFn = Apollo.MutationFunction<UpdateSmsSettingsMutation, UpdateSmsSettingsMutationVariables>;

/**
 * __useUpdateSmsSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSmsSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSmsSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSmsSettingsMutation, { data, loading, error }] = useUpdateSmsSettingsMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      sendSMSFromStaffNumber: // value for 'sendSMSFromStaffNumber'
 *   },
 * });
 */
export function useUpdateSmsSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSmsSettingsMutation, UpdateSmsSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSmsSettingsMutation, UpdateSmsSettingsMutationVariables>(UpdateSmsSettingsDocument, options);
      }
export type UpdateSmsSettingsMutationHookResult = ReturnType<typeof useUpdateSmsSettingsMutation>;
export type UpdateSmsSettingsMutationResult = Apollo.MutationResult<UpdateSmsSettingsMutation>;
export type UpdateSmsSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateSmsSettingsMutation, UpdateSmsSettingsMutationVariables>;
export const AllManagedStaffDocument = gql`
    query allManagedStaff($limit: Int, $skip: Int, $search: String, $roles: [StaffRole!], $status: [StaffStatus!]) {
  staff: managedStaffs(
    limit: $limit
    skip: $skip
    search: $search
    roles: $roles
    status: $status
  ) {
    id
    alias
    name
    enabled
    description
    email
    lastInvitedAt
    roles
    status
    createdAt
    business {
      id
      alias
    }
    bookingPageUrl
    stripeProductIds
    user {
      id
    }
    picture {
      id
      url
    }
    managedStaff {
      id
      name
      picture {
        id
        url
      }
    }
  }
  count: managedStaffsCount(search: $search, roles: $roles, status: $status)
}
    `;

/**
 * __useAllManagedStaffQuery__
 *
 * To run a query within a React component, call `useAllManagedStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllManagedStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllManagedStaffQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      search: // value for 'search'
 *      roles: // value for 'roles'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useAllManagedStaffQuery(baseOptions?: Apollo.QueryHookOptions<AllManagedStaffQuery, AllManagedStaffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllManagedStaffQuery, AllManagedStaffQueryVariables>(AllManagedStaffDocument, options);
      }
export function useAllManagedStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllManagedStaffQuery, AllManagedStaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllManagedStaffQuery, AllManagedStaffQueryVariables>(AllManagedStaffDocument, options);
        }
export type AllManagedStaffQueryHookResult = ReturnType<typeof useAllManagedStaffQuery>;
export type AllManagedStaffLazyQueryHookResult = ReturnType<typeof useAllManagedStaffLazyQuery>;
export type AllManagedStaffQueryResult = Apollo.QueryResult<AllManagedStaffQuery, AllManagedStaffQueryVariables>;
export const AllManagedStaffCompactDocument = gql`
    query allManagedStaffCompact {
  staff: managedStaffs(limit: 0) {
    id
    name
  }
}
    `;

/**
 * __useAllManagedStaffCompactQuery__
 *
 * To run a query within a React component, call `useAllManagedStaffCompactQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllManagedStaffCompactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllManagedStaffCompactQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllManagedStaffCompactQuery(baseOptions?: Apollo.QueryHookOptions<AllManagedStaffCompactQuery, AllManagedStaffCompactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllManagedStaffCompactQuery, AllManagedStaffCompactQueryVariables>(AllManagedStaffCompactDocument, options);
      }
export function useAllManagedStaffCompactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllManagedStaffCompactQuery, AllManagedStaffCompactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllManagedStaffCompactQuery, AllManagedStaffCompactQueryVariables>(AllManagedStaffCompactDocument, options);
        }
export type AllManagedStaffCompactQueryHookResult = ReturnType<typeof useAllManagedStaffCompactQuery>;
export type AllManagedStaffCompactLazyQueryHookResult = ReturnType<typeof useAllManagedStaffCompactLazyQuery>;
export type AllManagedStaffCompactQueryResult = Apollo.QueryResult<AllManagedStaffCompactQuery, AllManagedStaffCompactQueryVariables>;
export const StaffFiltersDocument = gql`
    query staffFilters($filterIds: [ID!]) {
  staffs: managedStaffs(limit: 0, filterIds: $filterIds) {
    id
    name
  }
}
    `;

/**
 * __useStaffFiltersQuery__
 *
 * To run a query within a React component, call `useStaffFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffFiltersQuery({
 *   variables: {
 *      filterIds: // value for 'filterIds'
 *   },
 * });
 */
export function useStaffFiltersQuery(baseOptions?: Apollo.QueryHookOptions<StaffFiltersQuery, StaffFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffFiltersQuery, StaffFiltersQueryVariables>(StaffFiltersDocument, options);
      }
export function useStaffFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffFiltersQuery, StaffFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffFiltersQuery, StaffFiltersQueryVariables>(StaffFiltersDocument, options);
        }
export type StaffFiltersQueryHookResult = ReturnType<typeof useStaffFiltersQuery>;
export type StaffFiltersLazyQueryHookResult = ReturnType<typeof useStaffFiltersLazyQuery>;
export type StaffFiltersQueryResult = Apollo.QueryResult<StaffFiltersQuery, StaffFiltersQueryVariables>;
export const SelectStaffListDocument = gql`
    query selectStaffList($limit: Int, $skip: Int, $search: String, $excludeIds: [ID!], $serviceId: ID) {
  staff: managedStaffs(
    limit: $limit
    skip: $skip
    search: $search
    excludeIds: $excludeIds
    serviceId: $serviceId
  ) {
    id
    name
    email
  }
}
    `;

/**
 * __useSelectStaffListQuery__
 *
 * To run a query within a React component, call `useSelectStaffListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectStaffListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectStaffListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      search: // value for 'search'
 *      excludeIds: // value for 'excludeIds'
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useSelectStaffListQuery(baseOptions?: Apollo.QueryHookOptions<SelectStaffListQuery, SelectStaffListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectStaffListQuery, SelectStaffListQueryVariables>(SelectStaffListDocument, options);
      }
export function useSelectStaffListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectStaffListQuery, SelectStaffListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectStaffListQuery, SelectStaffListQueryVariables>(SelectStaffListDocument, options);
        }
export type SelectStaffListQueryHookResult = ReturnType<typeof useSelectStaffListQuery>;
export type SelectStaffListLazyQueryHookResult = ReturnType<typeof useSelectStaffListLazyQuery>;
export type SelectStaffListQueryResult = Apollo.QueryResult<SelectStaffListQuery, SelectStaffListQueryVariables>;
export const CreateStaffDocument = gql`
    mutation createStaff($email: String!, $name: String!, $description: String, $businessId: ID!, $phoneMobile: String, $roles: [StaffRole!], $stripeProductIds: [String!]) {
  staff: createStaff(
    email: $email
    name: $name
    description: $description
    businessId: $businessId
    phoneMobile: $phoneMobile
    roles: $roles
    stripeProductIds: $stripeProductIds
  ) {
    id
    name
    description
    email
    phoneMobile
    roles
    stripeProductIds
  }
}
    `;
export type CreateStaffMutationFn = Apollo.MutationFunction<CreateStaffMutation, CreateStaffMutationVariables>;

/**
 * __useCreateStaffMutation__
 *
 * To run a mutation, you first call `useCreateStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStaffMutation, { data, loading, error }] = useCreateStaffMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      businessId: // value for 'businessId'
 *      phoneMobile: // value for 'phoneMobile'
 *      roles: // value for 'roles'
 *      stripeProductIds: // value for 'stripeProductIds'
 *   },
 * });
 */
export function useCreateStaffMutation(baseOptions?: Apollo.MutationHookOptions<CreateStaffMutation, CreateStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStaffMutation, CreateStaffMutationVariables>(CreateStaffDocument, options);
      }
export type CreateStaffMutationHookResult = ReturnType<typeof useCreateStaffMutation>;
export type CreateStaffMutationResult = Apollo.MutationResult<CreateStaffMutation>;
export type CreateStaffMutationOptions = Apollo.BaseMutationOptions<CreateStaffMutation, CreateStaffMutationVariables>;
export const CreateWorkingHoursScheduleDocument = gql`
    mutation createWorkingHoursSchedule($staffId: ID!, $from: DateTime!, $to: DateTime, $workingHours: Json!) {
  createWorkingHoursSchedule(
    staffId: $staffId
    from: $from
    to: $to
    workingHours: $workingHours
  ) {
    id
    staff {
      id
      workingHours(time: $from)
      business {
        id
        workingHours
        timezone
      }
    }
  }
}
    `;
export type CreateWorkingHoursScheduleMutationFn = Apollo.MutationFunction<CreateWorkingHoursScheduleMutation, CreateWorkingHoursScheduleMutationVariables>;

/**
 * __useCreateWorkingHoursScheduleMutation__
 *
 * To run a mutation, you first call `useCreateWorkingHoursScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkingHoursScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkingHoursScheduleMutation, { data, loading, error }] = useCreateWorkingHoursScheduleMutation({
 *   variables: {
 *      staffId: // value for 'staffId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      workingHours: // value for 'workingHours'
 *   },
 * });
 */
export function useCreateWorkingHoursScheduleMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkingHoursScheduleMutation, CreateWorkingHoursScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkingHoursScheduleMutation, CreateWorkingHoursScheduleMutationVariables>(CreateWorkingHoursScheduleDocument, options);
      }
export type CreateWorkingHoursScheduleMutationHookResult = ReturnType<typeof useCreateWorkingHoursScheduleMutation>;
export type CreateWorkingHoursScheduleMutationResult = Apollo.MutationResult<CreateWorkingHoursScheduleMutation>;
export type CreateWorkingHoursScheduleMutationOptions = Apollo.BaseMutationOptions<CreateWorkingHoursScheduleMutation, CreateWorkingHoursScheduleMutationVariables>;
export const DeleteStaffDocument = gql`
    mutation deleteStaff($id: ID!) {
  deleteStaff(id: $id) {
    id
  }
}
    `;
export type DeleteStaffMutationFn = Apollo.MutationFunction<DeleteStaffMutation, DeleteStaffMutationVariables>;

/**
 * __useDeleteStaffMutation__
 *
 * To run a mutation, you first call `useDeleteStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStaffMutation, { data, loading, error }] = useDeleteStaffMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStaffMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStaffMutation, DeleteStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStaffMutation, DeleteStaffMutationVariables>(DeleteStaffDocument, options);
      }
export type DeleteStaffMutationHookResult = ReturnType<typeof useDeleteStaffMutation>;
export type DeleteStaffMutationResult = Apollo.MutationResult<DeleteStaffMutation>;
export type DeleteStaffMutationOptions = Apollo.BaseMutationOptions<DeleteStaffMutation, DeleteStaffMutationVariables>;
export const DeleteWorkingHoursSchedulesDocument = gql`
    mutation deleteWorkingHoursSchedules($staffId: ID!) {
  defaultToBusinessWorkingHours(staffId: $staffId)
}
    `;
export type DeleteWorkingHoursSchedulesMutationFn = Apollo.MutationFunction<DeleteWorkingHoursSchedulesMutation, DeleteWorkingHoursSchedulesMutationVariables>;

/**
 * __useDeleteWorkingHoursSchedulesMutation__
 *
 * To run a mutation, you first call `useDeleteWorkingHoursSchedulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkingHoursSchedulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkingHoursSchedulesMutation, { data, loading, error }] = useDeleteWorkingHoursSchedulesMutation({
 *   variables: {
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useDeleteWorkingHoursSchedulesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkingHoursSchedulesMutation, DeleteWorkingHoursSchedulesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkingHoursSchedulesMutation, DeleteWorkingHoursSchedulesMutationVariables>(DeleteWorkingHoursSchedulesDocument, options);
      }
export type DeleteWorkingHoursSchedulesMutationHookResult = ReturnType<typeof useDeleteWorkingHoursSchedulesMutation>;
export type DeleteWorkingHoursSchedulesMutationResult = Apollo.MutationResult<DeleteWorkingHoursSchedulesMutation>;
export type DeleteWorkingHoursSchedulesMutationOptions = Apollo.BaseMutationOptions<DeleteWorkingHoursSchedulesMutation, DeleteWorkingHoursSchedulesMutationVariables>;
export const ResendStaffInviteDocument = gql`
    mutation resendStaffInvite($staffId: ID!) {
  updateStaff(id: $staffId, resendInvite: true) {
    id
    enabled
    status
    lastInvitedAt
    name
    email
  }
}
    `;
export type ResendStaffInviteMutationFn = Apollo.MutationFunction<ResendStaffInviteMutation, ResendStaffInviteMutationVariables>;

/**
 * __useResendStaffInviteMutation__
 *
 * To run a mutation, you first call `useResendStaffInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendStaffInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendStaffInviteMutation, { data, loading, error }] = useResendStaffInviteMutation({
 *   variables: {
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useResendStaffInviteMutation(baseOptions?: Apollo.MutationHookOptions<ResendStaffInviteMutation, ResendStaffInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendStaffInviteMutation, ResendStaffInviteMutationVariables>(ResendStaffInviteDocument, options);
      }
export type ResendStaffInviteMutationHookResult = ReturnType<typeof useResendStaffInviteMutation>;
export type ResendStaffInviteMutationResult = Apollo.MutationResult<ResendStaffInviteMutation>;
export type ResendStaffInviteMutationOptions = Apollo.BaseMutationOptions<ResendStaffInviteMutation, ResendStaffInviteMutationVariables>;
export const StaffDocument = gql`
    query staff($staffId: ID!) {
  staff: Staff(id: $staffId) {
    id
    alias
    name
    roles
    enabled
    status
    description
    email
    occupation
    phoneMobile
    timezone
    alias
    bookingPageUrl
    locationType
    locationValue
    business {
      id
      alias
    }
    managedStaff {
      id
      name
    }
    picture {
      id
      url
    }
  }
}
    `;

/**
 * __useStaffQuery__
 *
 * To run a query within a React component, call `useStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffQuery({
 *   variables: {
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useStaffQuery(baseOptions: Apollo.QueryHookOptions<StaffQuery, StaffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffQuery, StaffQueryVariables>(StaffDocument, options);
      }
export function useStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffQuery, StaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffQuery, StaffQueryVariables>(StaffDocument, options);
        }
export type StaffQueryHookResult = ReturnType<typeof useStaffQuery>;
export type StaffLazyQueryHookResult = ReturnType<typeof useStaffLazyQuery>;
export type StaffQueryResult = Apollo.QueryResult<StaffQuery, StaffQueryVariables>;
export const StaffIsAvailableDocument = gql`
    query staffIsAvailable($staffId: ID!, $serviceId: ID!, $date: DateTime!, $duration: Int!, $ignoreAppointmentId: ID) {
  isAvailable: staffIsAvailable(
    staffId: $staffId
    serviceId: $serviceId
    date: $date
    duration: $duration
    ignoreAppointmentId: $ignoreAppointmentId
  )
}
    `;

/**
 * __useStaffIsAvailableQuery__
 *
 * To run a query within a React component, call `useStaffIsAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffIsAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffIsAvailableQuery({
 *   variables: {
 *      staffId: // value for 'staffId'
 *      serviceId: // value for 'serviceId'
 *      date: // value for 'date'
 *      duration: // value for 'duration'
 *      ignoreAppointmentId: // value for 'ignoreAppointmentId'
 *   },
 * });
 */
export function useStaffIsAvailableQuery(baseOptions: Apollo.QueryHookOptions<StaffIsAvailableQuery, StaffIsAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffIsAvailableQuery, StaffIsAvailableQueryVariables>(StaffIsAvailableDocument, options);
      }
export function useStaffIsAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffIsAvailableQuery, StaffIsAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffIsAvailableQuery, StaffIsAvailableQueryVariables>(StaffIsAvailableDocument, options);
        }
export type StaffIsAvailableQueryHookResult = ReturnType<typeof useStaffIsAvailableQuery>;
export type StaffIsAvailableLazyQueryHookResult = ReturnType<typeof useStaffIsAvailableLazyQuery>;
export type StaffIsAvailableQueryResult = Apollo.QueryResult<StaffIsAvailableQuery, StaffIsAvailableQueryVariables>;
export const UpdateStaffDocument = gql`
    mutation updateStaff($id: ID!, $alias: String, $enabled: Boolean, $name: String, $description: String, $email: String, $phoneMobile: String, $pictureId: ID, $locationType: String, $locationValue: String, $timezone: String, $roles: [StaffRole!], $managedStaff: [ID!], $occupation: String, $marketingMeta: Json, $onboardingCompleted: Boolean) {
  updateStaff(
    id: $id
    alias: $alias
    enabled: $enabled
    name: $name
    description: $description
    email: $email
    phoneMobile: $phoneMobile
    pictureId: $pictureId
    locationType: $locationType
    locationValue: $locationValue
    timezone: $timezone
    roles: $roles
    managedStaff: $managedStaff
    occupation: $occupation
    marketingMeta: $marketingMeta
    onboardingCompleted: $onboardingCompleted
  ) {
    id
    alias
    enabled
    status
    name
    description
    email
    phoneMobile
    locationType
    locationValue
    timezone
    roles
    onboardingCompletedAt
    picture {
      id
      url
    }
    managedStaff {
      id
      name
    }
    occupation
    marketingMeta
  }
}
    `;
export type UpdateStaffMutationFn = Apollo.MutationFunction<UpdateStaffMutation, UpdateStaffMutationVariables>;

/**
 * __useUpdateStaffMutation__
 *
 * To run a mutation, you first call `useUpdateStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStaffMutation, { data, loading, error }] = useUpdateStaffMutation({
 *   variables: {
 *      id: // value for 'id'
 *      alias: // value for 'alias'
 *      enabled: // value for 'enabled'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      email: // value for 'email'
 *      phoneMobile: // value for 'phoneMobile'
 *      pictureId: // value for 'pictureId'
 *      locationType: // value for 'locationType'
 *      locationValue: // value for 'locationValue'
 *      timezone: // value for 'timezone'
 *      roles: // value for 'roles'
 *      managedStaff: // value for 'managedStaff'
 *      occupation: // value for 'occupation'
 *      marketingMeta: // value for 'marketingMeta'
 *      onboardingCompleted: // value for 'onboardingCompleted'
 *   },
 * });
 */
export function useUpdateStaffMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStaffMutation, UpdateStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStaffMutation, UpdateStaffMutationVariables>(UpdateStaffDocument, options);
      }
export type UpdateStaffMutationHookResult = ReturnType<typeof useUpdateStaffMutation>;
export type UpdateStaffMutationResult = Apollo.MutationResult<UpdateStaffMutation>;
export type UpdateStaffMutationOptions = Apollo.BaseMutationOptions<UpdateStaffMutation, UpdateStaffMutationVariables>;
export const WorkingHoursSchedulesDocument = gql`
    query workingHoursSchedules($staffId: ID!, $time: DateTime!) {
  staff: Staff(id: $staffId) {
    id
    name
    workingHours(time: $time)
    business {
      id
      workingHours
      timezone
    }
    timezone
  }
}
    `;

/**
 * __useWorkingHoursSchedulesQuery__
 *
 * To run a query within a React component, call `useWorkingHoursSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingHoursSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingHoursSchedulesQuery({
 *   variables: {
 *      staffId: // value for 'staffId'
 *      time: // value for 'time'
 *   },
 * });
 */
export function useWorkingHoursSchedulesQuery(baseOptions: Apollo.QueryHookOptions<WorkingHoursSchedulesQuery, WorkingHoursSchedulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkingHoursSchedulesQuery, WorkingHoursSchedulesQueryVariables>(WorkingHoursSchedulesDocument, options);
      }
export function useWorkingHoursSchedulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkingHoursSchedulesQuery, WorkingHoursSchedulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkingHoursSchedulesQuery, WorkingHoursSchedulesQueryVariables>(WorkingHoursSchedulesDocument, options);
        }
export type WorkingHoursSchedulesQueryHookResult = ReturnType<typeof useWorkingHoursSchedulesQuery>;
export type WorkingHoursSchedulesLazyQueryHookResult = ReturnType<typeof useWorkingHoursSchedulesLazyQuery>;
export type WorkingHoursSchedulesQueryResult = Apollo.QueryResult<WorkingHoursSchedulesQuery, WorkingHoursSchedulesQueryVariables>;
export const BusinessStripeAccountDocument = gql`
    query businessStripeAccount($id: ID!) {
  business: Business(id: $id) {
    id
    stripeConnectAccount {
      id
      onboardingCompletedAt
    }
  }
}
    `;

/**
 * __useBusinessStripeAccountQuery__
 *
 * To run a query within a React component, call `useBusinessStripeAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessStripeAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessStripeAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusinessStripeAccountQuery(baseOptions: Apollo.QueryHookOptions<BusinessStripeAccountQuery, BusinessStripeAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessStripeAccountQuery, BusinessStripeAccountQueryVariables>(BusinessStripeAccountDocument, options);
      }
export function useBusinessStripeAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessStripeAccountQuery, BusinessStripeAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessStripeAccountQuery, BusinessStripeAccountQueryVariables>(BusinessStripeAccountDocument, options);
        }
export type BusinessStripeAccountQueryHookResult = ReturnType<typeof useBusinessStripeAccountQuery>;
export type BusinessStripeAccountLazyQueryHookResult = ReturnType<typeof useBusinessStripeAccountLazyQuery>;
export type BusinessStripeAccountQueryResult = Apollo.QueryResult<BusinessStripeAccountQuery, BusinessStripeAccountQueryVariables>;
export const CompleteStripeConnectAccountOnboardingDocument = gql`
    mutation completeStripeConnectAccountOnboarding($id: ID!) {
  completeStripeConnectAccountOnboarding(id: $id) {
    id
    onboardingCompletedAt
  }
}
    `;
export type CompleteStripeConnectAccountOnboardingMutationFn = Apollo.MutationFunction<CompleteStripeConnectAccountOnboardingMutation, CompleteStripeConnectAccountOnboardingMutationVariables>;

/**
 * __useCompleteStripeConnectAccountOnboardingMutation__
 *
 * To run a mutation, you first call `useCompleteStripeConnectAccountOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteStripeConnectAccountOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeStripeConnectAccountOnboardingMutation, { data, loading, error }] = useCompleteStripeConnectAccountOnboardingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteStripeConnectAccountOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<CompleteStripeConnectAccountOnboardingMutation, CompleteStripeConnectAccountOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteStripeConnectAccountOnboardingMutation, CompleteStripeConnectAccountOnboardingMutationVariables>(CompleteStripeConnectAccountOnboardingDocument, options);
      }
export type CompleteStripeConnectAccountOnboardingMutationHookResult = ReturnType<typeof useCompleteStripeConnectAccountOnboardingMutation>;
export type CompleteStripeConnectAccountOnboardingMutationResult = Apollo.MutationResult<CompleteStripeConnectAccountOnboardingMutation>;
export type CompleteStripeConnectAccountOnboardingMutationOptions = Apollo.BaseMutationOptions<CompleteStripeConnectAccountOnboardingMutation, CompleteStripeConnectAccountOnboardingMutationVariables>;
export const CreateStripeConnectAccountDocument = gql`
    mutation createStripeConnectAccount($country: String!) {
  createStripeConnectAccount(country: $country) {
    success
    stripeConnectAccountId
    session {
      clientSecret
      expiresAt
    }
  }
}
    `;
export type CreateStripeConnectAccountMutationFn = Apollo.MutationFunction<CreateStripeConnectAccountMutation, CreateStripeConnectAccountMutationVariables>;

/**
 * __useCreateStripeConnectAccountMutation__
 *
 * To run a mutation, you first call `useCreateStripeConnectAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeConnectAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeConnectAccountMutation, { data, loading, error }] = useCreateStripeConnectAccountMutation({
 *   variables: {
 *      country: // value for 'country'
 *   },
 * });
 */
export function useCreateStripeConnectAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeConnectAccountMutation, CreateStripeConnectAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeConnectAccountMutation, CreateStripeConnectAccountMutationVariables>(CreateStripeConnectAccountDocument, options);
      }
export type CreateStripeConnectAccountMutationHookResult = ReturnType<typeof useCreateStripeConnectAccountMutation>;
export type CreateStripeConnectAccountMutationResult = Apollo.MutationResult<CreateStripeConnectAccountMutation>;
export type CreateStripeConnectAccountMutationOptions = Apollo.BaseMutationOptions<CreateStripeConnectAccountMutation, CreateStripeConnectAccountMutationVariables>;
export const CreateStripeConnectAccountSessionDocument = gql`
    mutation createStripeConnectAccountSession {
  session: createStripeConnectAccountSession {
    clientSecret
    expiresAt
  }
}
    `;
export type CreateStripeConnectAccountSessionMutationFn = Apollo.MutationFunction<CreateStripeConnectAccountSessionMutation, CreateStripeConnectAccountSessionMutationVariables>;

/**
 * __useCreateStripeConnectAccountSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripeConnectAccountSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeConnectAccountSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeConnectAccountSessionMutation, { data, loading, error }] = useCreateStripeConnectAccountSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateStripeConnectAccountSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeConnectAccountSessionMutation, CreateStripeConnectAccountSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeConnectAccountSessionMutation, CreateStripeConnectAccountSessionMutationVariables>(CreateStripeConnectAccountSessionDocument, options);
      }
export type CreateStripeConnectAccountSessionMutationHookResult = ReturnType<typeof useCreateStripeConnectAccountSessionMutation>;
export type CreateStripeConnectAccountSessionMutationResult = Apollo.MutationResult<CreateStripeConnectAccountSessionMutation>;
export type CreateStripeConnectAccountSessionMutationOptions = Apollo.BaseMutationOptions<CreateStripeConnectAccountSessionMutation, CreateStripeConnectAccountSessionMutationVariables>;
export const StripeProductsDocument = gql`
    query stripeProducts($businessId: ID!) {
  business: Business(id: $businessId) {
    stripeData
    stripeProducts {
      id
      name
      description
      featureList
      prices {
        id
        currency
        unitAmount
        interval
      }
    }
  }
}
    `;

/**
 * __useStripeProductsQuery__
 *
 * To run a query within a React component, call `useStripeProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeProductsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useStripeProductsQuery(baseOptions: Apollo.QueryHookOptions<StripeProductsQuery, StripeProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StripeProductsQuery, StripeProductsQueryVariables>(StripeProductsDocument, options);
      }
export function useStripeProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StripeProductsQuery, StripeProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StripeProductsQuery, StripeProductsQueryVariables>(StripeProductsDocument, options);
        }
export type StripeProductsQueryHookResult = ReturnType<typeof useStripeProductsQuery>;
export type StripeProductsLazyQueryHookResult = ReturnType<typeof useStripeProductsLazyQuery>;
export type StripeProductsQueryResult = Apollo.QueryResult<StripeProductsQuery, StripeProductsQueryVariables>;
export const SyncStripeCustomerDocument = gql`
    mutation syncStripeCustomer {
  syncStripeCustomer {
    business {
      id
      stripeCustomerId
      stripeSubscriptionId
      stripeData
    }
  }
}
    `;
export type SyncStripeCustomerMutationFn = Apollo.MutationFunction<SyncStripeCustomerMutation, SyncStripeCustomerMutationVariables>;

/**
 * __useSyncStripeCustomerMutation__
 *
 * To run a mutation, you first call `useSyncStripeCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncStripeCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncStripeCustomerMutation, { data, loading, error }] = useSyncStripeCustomerMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncStripeCustomerMutation(baseOptions?: Apollo.MutationHookOptions<SyncStripeCustomerMutation, SyncStripeCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncStripeCustomerMutation, SyncStripeCustomerMutationVariables>(SyncStripeCustomerDocument, options);
      }
export type SyncStripeCustomerMutationHookResult = ReturnType<typeof useSyncStripeCustomerMutation>;
export type SyncStripeCustomerMutationResult = Apollo.MutationResult<SyncStripeCustomerMutation>;
export type SyncStripeCustomerMutationOptions = Apollo.BaseMutationOptions<SyncStripeCustomerMutation, SyncStripeCustomerMutationVariables>;
export const AllTagsDocument = gql`
    query allTags {
  allTags {
    id
    alias
    name
    description
    color
  }
}
    `;

/**
 * __useAllTagsQuery__
 *
 * To run a query within a React component, call `useAllTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTagsQuery(baseOptions?: Apollo.QueryHookOptions<AllTagsQuery, AllTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTagsQuery, AllTagsQueryVariables>(AllTagsDocument, options);
      }
export function useAllTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTagsQuery, AllTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTagsQuery, AllTagsQueryVariables>(AllTagsDocument, options);
        }
export type AllTagsQueryHookResult = ReturnType<typeof useAllTagsQuery>;
export type AllTagsLazyQueryHookResult = ReturnType<typeof useAllTagsLazyQuery>;
export type AllTagsQueryResult = Apollo.QueryResult<AllTagsQuery, AllTagsQueryVariables>;
export const TagDocument = gql`
    query tag($id: ID!) {
  tag: Tag(id: $id) {
    id
    alias
    name
    description
    color
  }
}
    `;

/**
 * __useTagQuery__
 *
 * To run a query within a React component, call `useTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagQuery(baseOptions: Apollo.QueryHookOptions<TagQuery, TagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagQuery, TagQueryVariables>(TagDocument, options);
      }
export function useTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagQuery, TagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagQuery, TagQueryVariables>(TagDocument, options);
        }
export type TagQueryHookResult = ReturnType<typeof useTagQuery>;
export type TagLazyQueryHookResult = ReturnType<typeof useTagLazyQuery>;
export type TagQueryResult = Apollo.QueryResult<TagQuery, TagQueryVariables>;
export const CreateTagDocument = gql`
    mutation createTag($alias: String!, $name: String!, $color: String!, $description: String!) {
  createTag(alias: $alias, name: $name, color: $color, description: $description) {
    id
    alias
    name
    color
    description
    createdAt
  }
}
    `;
export type CreateTagMutationFn = Apollo.MutationFunction<CreateTagMutation, CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      alias: // value for 'alias'
 *      name: // value for 'name'
 *      color: // value for 'color'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, options);
      }
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>;
export const UpdateTagDocument = gql`
    mutation updateTag($id: ID!, $name: String!, $color: String!, $description: String!) {
  updateTag(id: $id, name: $name, color: $color, description: $description) {
    id
    alias
    name
    color
    description
  }
}
    `;
export type UpdateTagMutationFn = Apollo.MutationFunction<UpdateTagMutation, UpdateTagMutationVariables>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      color: // value for 'color'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UpdateTagDocument, options);
      }
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<UpdateTagMutation, UpdateTagMutationVariables>;
export const UpdatePatientTagsDocument = gql`
    mutation updatePatientTags($patientId: ID!, $tagIds: [String!]!) {
  setPatientTags(patientId: $patientId, tagIds: $tagIds) {
    id
    tagIds
  }
}
    `;
export type UpdatePatientTagsMutationFn = Apollo.MutationFunction<UpdatePatientTagsMutation, UpdatePatientTagsMutationVariables>;

/**
 * __useUpdatePatientTagsMutation__
 *
 * To run a mutation, you first call `useUpdatePatientTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientTagsMutation, { data, loading, error }] = useUpdatePatientTagsMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useUpdatePatientTagsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientTagsMutation, UpdatePatientTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePatientTagsMutation, UpdatePatientTagsMutationVariables>(UpdatePatientTagsDocument, options);
      }
export type UpdatePatientTagsMutationHookResult = ReturnType<typeof useUpdatePatientTagsMutation>;
export type UpdatePatientTagsMutationResult = Apollo.MutationResult<UpdatePatientTagsMutation>;
export type UpdatePatientTagsMutationOptions = Apollo.BaseMutationOptions<UpdatePatientTagsMutation, UpdatePatientTagsMutationVariables>;
export const ThirdPartyConnectionsDocument = gql`
    query thirdPartyConnections($businessId: ID!, $staffId: ID!) {
  businessZoomConnections: thirdPartyConnections(
    businessId: $businessId
    staffId: null
    provider: ZOOM
  ) {
    id
    provider
    name
    email
  }
  staffZoomConnections: thirdPartyConnections(staffId: $staffId, provider: ZOOM) {
    id
    provider
    name
    email
  }
}
    `;

/**
 * __useThirdPartyConnectionsQuery__
 *
 * To run a query within a React component, call `useThirdPartyConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useThirdPartyConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThirdPartyConnectionsQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useThirdPartyConnectionsQuery(baseOptions: Apollo.QueryHookOptions<ThirdPartyConnectionsQuery, ThirdPartyConnectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThirdPartyConnectionsQuery, ThirdPartyConnectionsQueryVariables>(ThirdPartyConnectionsDocument, options);
      }
export function useThirdPartyConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThirdPartyConnectionsQuery, ThirdPartyConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThirdPartyConnectionsQuery, ThirdPartyConnectionsQueryVariables>(ThirdPartyConnectionsDocument, options);
        }
export type ThirdPartyConnectionsQueryHookResult = ReturnType<typeof useThirdPartyConnectionsQuery>;
export type ThirdPartyConnectionsLazyQueryHookResult = ReturnType<typeof useThirdPartyConnectionsLazyQuery>;
export type ThirdPartyConnectionsQueryResult = Apollo.QueryResult<ThirdPartyConnectionsQuery, ThirdPartyConnectionsQueryVariables>;
export const CreateThirdPartyConnectionOAuthSessionDocument = gql`
    mutation createThirdPartyConnectionOAuthSession($provider: ThirdPartyConnectionProvider!, $resourceType: ThirdPartyConnectionResourceType!, $redirectUrl: String!) {
  createThirdPartyConnectionOAuthSession(
    provider: $provider
    resourceType: $resourceType
    redirectUrl: $redirectUrl
  ) {
    redirectUrl
  }
}
    `;
export type CreateThirdPartyConnectionOAuthSessionMutationFn = Apollo.MutationFunction<CreateThirdPartyConnectionOAuthSessionMutation, CreateThirdPartyConnectionOAuthSessionMutationVariables>;

/**
 * __useCreateThirdPartyConnectionOAuthSessionMutation__
 *
 * To run a mutation, you first call `useCreateThirdPartyConnectionOAuthSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateThirdPartyConnectionOAuthSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createThirdPartyConnectionOAuthSessionMutation, { data, loading, error }] = useCreateThirdPartyConnectionOAuthSessionMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      resourceType: // value for 'resourceType'
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function useCreateThirdPartyConnectionOAuthSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateThirdPartyConnectionOAuthSessionMutation, CreateThirdPartyConnectionOAuthSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateThirdPartyConnectionOAuthSessionMutation, CreateThirdPartyConnectionOAuthSessionMutationVariables>(CreateThirdPartyConnectionOAuthSessionDocument, options);
      }
export type CreateThirdPartyConnectionOAuthSessionMutationHookResult = ReturnType<typeof useCreateThirdPartyConnectionOAuthSessionMutation>;
export type CreateThirdPartyConnectionOAuthSessionMutationResult = Apollo.MutationResult<CreateThirdPartyConnectionOAuthSessionMutation>;
export type CreateThirdPartyConnectionOAuthSessionMutationOptions = Apollo.BaseMutationOptions<CreateThirdPartyConnectionOAuthSessionMutation, CreateThirdPartyConnectionOAuthSessionMutationVariables>;
export const UpdateThirdPartyConnectionDocument = gql`
    mutation updateThirdPartyConnection($id: ID!, $enabled: Boolean!) {
  updateThirdPartyConnection(id: $id, enabled: $enabled) {
    id
    provider
    name
    email
    staff {
      id
      zoomConnection {
        id
      }
    }
    business {
      id
      zoomConnection {
        id
      }
    }
  }
}
    `;
export type UpdateThirdPartyConnectionMutationFn = Apollo.MutationFunction<UpdateThirdPartyConnectionMutation, UpdateThirdPartyConnectionMutationVariables>;

/**
 * __useUpdateThirdPartyConnectionMutation__
 *
 * To run a mutation, you first call `useUpdateThirdPartyConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThirdPartyConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThirdPartyConnectionMutation, { data, loading, error }] = useUpdateThirdPartyConnectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdateThirdPartyConnectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateThirdPartyConnectionMutation, UpdateThirdPartyConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateThirdPartyConnectionMutation, UpdateThirdPartyConnectionMutationVariables>(UpdateThirdPartyConnectionDocument, options);
      }
export type UpdateThirdPartyConnectionMutationHookResult = ReturnType<typeof useUpdateThirdPartyConnectionMutation>;
export type UpdateThirdPartyConnectionMutationResult = Apollo.MutationResult<UpdateThirdPartyConnectionMutation>;
export type UpdateThirdPartyConnectionMutationOptions = Apollo.BaseMutationOptions<UpdateThirdPartyConnectionMutation, UpdateThirdPartyConnectionMutationVariables>;
export const DeleteThirdPartyConnectionDocument = gql`
    mutation deleteThirdPartyConnection($id: ID!) {
  deleteThirdPartyConnection(id: $id)
}
    `;
export type DeleteThirdPartyConnectionMutationFn = Apollo.MutationFunction<DeleteThirdPartyConnectionMutation, DeleteThirdPartyConnectionMutationVariables>;

/**
 * __useDeleteThirdPartyConnectionMutation__
 *
 * To run a mutation, you first call `useDeleteThirdPartyConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteThirdPartyConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteThirdPartyConnectionMutation, { data, loading, error }] = useDeleteThirdPartyConnectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteThirdPartyConnectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteThirdPartyConnectionMutation, DeleteThirdPartyConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteThirdPartyConnectionMutation, DeleteThirdPartyConnectionMutationVariables>(DeleteThirdPartyConnectionDocument, options);
      }
export type DeleteThirdPartyConnectionMutationHookResult = ReturnType<typeof useDeleteThirdPartyConnectionMutation>;
export type DeleteThirdPartyConnectionMutationResult = Apollo.MutationResult<DeleteThirdPartyConnectionMutation>;
export type DeleteThirdPartyConnectionMutationOptions = Apollo.BaseMutationOptions<DeleteThirdPartyConnectionMutation, DeleteThirdPartyConnectionMutationVariables>;
export const CreateTimeBlockerDocument = gql`
    mutation createTimeBlocker($staffId: ID, $startDate: DateTime!, $endDate: DateTime!, $reason: String) {
  createTimeBlocker(
    staffId: $staffId
    startDate: $startDate
    endDate: $endDate
    reason: $reason
  ) {
    id
    business {
      id
    }
    staff {
      id
      name
    }
    startDate
    endDate
    reason
  }
}
    `;
export type CreateTimeBlockerMutationFn = Apollo.MutationFunction<CreateTimeBlockerMutation, CreateTimeBlockerMutationVariables>;

/**
 * __useCreateTimeBlockerMutation__
 *
 * To run a mutation, you first call `useCreateTimeBlockerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeBlockerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeBlockerMutation, { data, loading, error }] = useCreateTimeBlockerMutation({
 *   variables: {
 *      staffId: // value for 'staffId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCreateTimeBlockerMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimeBlockerMutation, CreateTimeBlockerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimeBlockerMutation, CreateTimeBlockerMutationVariables>(CreateTimeBlockerDocument, options);
      }
export type CreateTimeBlockerMutationHookResult = ReturnType<typeof useCreateTimeBlockerMutation>;
export type CreateTimeBlockerMutationResult = Apollo.MutationResult<CreateTimeBlockerMutation>;
export type CreateTimeBlockerMutationOptions = Apollo.BaseMutationOptions<CreateTimeBlockerMutation, CreateTimeBlockerMutationVariables>;
export const CalendarTimeBlockersDocument = gql`
    query calendarTimeBlockers($dateStart: DateTime!, $dateEnd: DateTime!, $staffsIds: [ID!]) {
  timeBlockers: allTimeBlockers(
    staffsIds: $staffsIds
    dateStart: $dateStart
    dateEnd: $dateEnd
  ) {
    id
    startDate
    endDate
    reason
    staff {
      id
      name
    }
  }
}
    `;

/**
 * __useCalendarTimeBlockersQuery__
 *
 * To run a query within a React component, call `useCalendarTimeBlockersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarTimeBlockersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarTimeBlockersQuery({
 *   variables: {
 *      dateStart: // value for 'dateStart'
 *      dateEnd: // value for 'dateEnd'
 *      staffsIds: // value for 'staffsIds'
 *   },
 * });
 */
export function useCalendarTimeBlockersQuery(baseOptions: Apollo.QueryHookOptions<CalendarTimeBlockersQuery, CalendarTimeBlockersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarTimeBlockersQuery, CalendarTimeBlockersQueryVariables>(CalendarTimeBlockersDocument, options);
      }
export function useCalendarTimeBlockersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarTimeBlockersQuery, CalendarTimeBlockersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarTimeBlockersQuery, CalendarTimeBlockersQueryVariables>(CalendarTimeBlockersDocument, options);
        }
export type CalendarTimeBlockersQueryHookResult = ReturnType<typeof useCalendarTimeBlockersQuery>;
export type CalendarTimeBlockersLazyQueryHookResult = ReturnType<typeof useCalendarTimeBlockersLazyQuery>;
export type CalendarTimeBlockersQueryResult = Apollo.QueryResult<CalendarTimeBlockersQuery, CalendarTimeBlockersQueryVariables>;
export const UpdateSeatsDocument = gql`
    mutation updateSeats($productQuantities: Json!, $interval: StripeInterval!) {
  updateSeats(productQuantities: $productQuantities, interval: $interval) {
    business {
      id
      stripeData
    }
    checkoutSession {
      id
      url
    }
  }
}
    `;
export type UpdateSeatsMutationFn = Apollo.MutationFunction<UpdateSeatsMutation, UpdateSeatsMutationVariables>;

/**
 * __useUpdateSeatsMutation__
 *
 * To run a mutation, you first call `useUpdateSeatsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeatsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeatsMutation, { data, loading, error }] = useUpdateSeatsMutation({
 *   variables: {
 *      productQuantities: // value for 'productQuantities'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useUpdateSeatsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSeatsMutation, UpdateSeatsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSeatsMutation, UpdateSeatsMutationVariables>(UpdateSeatsDocument, options);
      }
export type UpdateSeatsMutationHookResult = ReturnType<typeof useUpdateSeatsMutation>;
export type UpdateSeatsMutationResult = Apollo.MutationResult<UpdateSeatsMutation>;
export type UpdateSeatsMutationOptions = Apollo.BaseMutationOptions<UpdateSeatsMutation, UpdateSeatsMutationVariables>;
export const UpdateUserPersonalDataDocument = gql`
    mutation updateUserPersonalData($name: String!) {
  updateUserPersonalData(name: $name) {
    id
    name
  }
}
    `;
export type UpdateUserPersonalDataMutationFn = Apollo.MutationFunction<UpdateUserPersonalDataMutation, UpdateUserPersonalDataMutationVariables>;

/**
 * __useUpdateUserPersonalDataMutation__
 *
 * To run a mutation, you first call `useUpdateUserPersonalDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPersonalDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPersonalDataMutation, { data, loading, error }] = useUpdateUserPersonalDataMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateUserPersonalDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPersonalDataMutation, UpdateUserPersonalDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPersonalDataMutation, UpdateUserPersonalDataMutationVariables>(UpdateUserPersonalDataDocument, options);
      }
export type UpdateUserPersonalDataMutationHookResult = ReturnType<typeof useUpdateUserPersonalDataMutation>;
export type UpdateUserPersonalDataMutationResult = Apollo.MutationResult<UpdateUserPersonalDataMutation>;
export type UpdateUserPersonalDataMutationOptions = Apollo.BaseMutationOptions<UpdateUserPersonalDataMutation, UpdateUserPersonalDataMutationVariables>;
export const WorkingHoursSlotsDocument = gql`
    query workingHoursSlots($staffIds: [ID!]!, $timeIncrement: Int, $from: DateTime!, $to: DateTime!) {
  workingHoursSlots(
    staffIds: $staffIds
    timeIncrement: $timeIncrement
    from: $from
    to: $to
  ) {
    slots
    staffSlots
  }
}
    `;

/**
 * __useWorkingHoursSlotsQuery__
 *
 * To run a query within a React component, call `useWorkingHoursSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkingHoursSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkingHoursSlotsQuery({
 *   variables: {
 *      staffIds: // value for 'staffIds'
 *      timeIncrement: // value for 'timeIncrement'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useWorkingHoursSlotsQuery(baseOptions: Apollo.QueryHookOptions<WorkingHoursSlotsQuery, WorkingHoursSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkingHoursSlotsQuery, WorkingHoursSlotsQueryVariables>(WorkingHoursSlotsDocument, options);
      }
export function useWorkingHoursSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkingHoursSlotsQuery, WorkingHoursSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkingHoursSlotsQuery, WorkingHoursSlotsQueryVariables>(WorkingHoursSlotsDocument, options);
        }
export type WorkingHoursSlotsQueryHookResult = ReturnType<typeof useWorkingHoursSlotsQuery>;
export type WorkingHoursSlotsLazyQueryHookResult = ReturnType<typeof useWorkingHoursSlotsLazyQuery>;
export type WorkingHoursSlotsQueryResult = Apollo.QueryResult<WorkingHoursSlotsQuery, WorkingHoursSlotsQueryVariables>;